import React, { forwardRef } from 'react';
import { uid } from 'uid';
import {
	NumericTextBox,
} from "@progress/kendo-react-inputs";
import { objectValue } from '../../../core/@components/form/utilities';
import { KendoLabelContainer } from '../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../sizesUtilities';

const KendoNumeric = forwardRef(({
	id,
	label,
	onChange,
	value,
	selectOnClick = false,
	sideLabel = false,
	...others
}, ref) => {

	const { size, className } = kendoSizeClasses(others);
	const editorId = id || `numeric_${uid()}`;
	value = isNaN(parseFloat(value)) ? null : parseFloat(value);

	const handleChange = (e) => {
		if (typeof onChange === "function") {
			const value = e?.value ?? e?.target?.value ?? null;
			onChange(objectValue(e, value, false));
		}
		return e;
	};

	const onFocus = (e) => {
		if (!selectOnClick || !e.target?.element) return;
		e.target?.element?.select();
	}

	return (
		<KendoLabelContainer
			label={label}
			editorId={editorId}
			editorValue={value}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			<NumericTextBox
				{...others}
				className={className}
				spinners={false}
				size={size}
				id={editorId}
				ref={ref}
				label=""
				value={value}
				onFocus={onFocus}
				onChange={handleChange}
			/>
		</KendoLabelContainer>
	);
});

KendoNumeric.displayName = "KendoNumeric";
export default KendoNumeric;