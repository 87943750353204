import PropTypes from "prop-types"
import React from "react";
import Grid from "@mui/material/Grid";
import RadioGroup from "../../../../@components/radio/RadioGroup";
import { t } from "i18next";

function TemplateAlert({ msg, type_action }) {

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>{msg}</Grid>
      <Grid item style={{ textAlign: "-webkit-center" }}>
        <RadioGroup
          controlled={false}
          defaultValue="process"
          value={type_action.current}
          onChange={({ value }) => {
            type_action.current = value;
          }}
          name="controlled-radio-buttons-group"
          options={[
            { id: "process", name: t("Calcular o procesar") },
            { id: "report", name: t("generate-report") },
          ]}
        />
      </Grid>
    </Grid>
  );
}

TemplateAlert.propTypes = {
  msg: PropTypes.any,
  type_action: PropTypes.any
}

export default TemplateAlert;