import lang from "i18next";
const { t } = lang;

export const initValues = {
    compute_type: 1,
    apply_option: 0,
    structures: [],
    workers: null,
    structure_view: 0
}

export const dataCards = [
    { id: 1, icon: "Corporate", title: t('general'), desc: t('Recalculará las horas extraordinarias de todos los colaboradores de la empresa',) },
    { id: 2, icon: "Structure-Change", title: t('by-structure'), desc: t('Permite seleccionar una o más estructuras empresariales para recalcular las horas extraordinarias de los colaboradores asociados',) },
    { id: 3, icon: "Human-Resources", title: t('by-workers'), desc: t('Recalculará las horas extraordinarias colaboradores especificos',) },
];

export const overtimeUtilitiesApplyOptions = [
    { id: 0, name: t('Hoy') },
    { id: 1, name: t('Inicio del periodo') },
    { id: 2, name: t('Inicio corte administrativo') },
];
