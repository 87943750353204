import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../../App/components/dialog/FormDialog";
import DialogContent from "../../../../../../styled/DialogContent";
import { useModalsContext } from "../hooks/useModals";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import FormInput from "../../../../../../@components/form/Field";
import { useForm, useWatch } from "react-hook-form";
import { BaseButton } from "../../../../../../../App/components/Buttons";
import IconUse from "../../../../../../common/IconUse";
import { InputAdorn } from "../../../../../../../App/components/Templates/IconAdornment";
import { useRequestLoad } from "../../../../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { companyApi } from "../../../../../../services/company";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

function Token() {
    const { t } = useTranslation();
    const { openToken, handleCloseToken } = useModalsContext();

    return (
        <FormDialog
            open={openToken}
            className="minimalist-dialog-design"
            maxWidth="sm"
            headerDivider
            handleClose={handleCloseToken}
            title={t('Token para migración')}
            innerSubtitle={'Genera un token único para realizar la migración de datos de manera segura entre plataformas. Este token se utiliza exclusivamente para la migración y no es posible asignarle un nombre.'}
        >
            <DialogContent>
                <Content />
            </DialogContent>
        </FormDialog>
    );
};

export default Token;

const Content = () => {
    const { selected } = useModalsContext();

    const {
        control,
        setValue,
    } = useForm({
        mode: "onChange"
    });

    return (
        <Grid container spacing={3} className="token-generation-content">
            <Grid item xs={12} className="new-height-input">
                <CopyInput control={control} />
            </Grid>
            <Grid item xs={12}>
                <GenerateToken setValue={setValue} id={selected?.id} />
            </Grid>
        </Grid>
    );
};

export const CopyInput = ({ control, name = "token" }) => {
    const [copied, setCopied] = useState(false);
    const token = useWatch({ control, name: name });

    const handleCopy = () => {
        if (!token || copied) return;
        if (navigator?.clipboard?.writeText) {
            navigator.clipboard.writeText(token);
        } else {
            unsecuredCopyToClipboard();
        }
        notifyCopy();
    }

    // SOLO PARA EL SERVER DE DESARROLLO (UNSECURE DOMAINS)
    const unsecuredCopyToClipboard = () => {
        const input = document.getElementById('copy-token-input');
        if (!input) return;
        input.focus();
        input.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
    }

    const notifyCopy = () => {
        setCopied(true);
        uncopy();
    }

    const uncopy = useRef(debounce(() => setCopied(false), 2500)).current;

    return (
        <FormInput
            id="copy-token-input"
            fieldInput="TextFieldIcon"
            control={control}
            name={name}
            uppercase={false}
            className={`${copied ? 'copied-state' : ''}`}
            adornment={<InputAdorn icon={!copied ? <IconUse icon="Copy" /> : <FontAwesomeIcon icon={faCheck} />} onClick={handleCopy} />}
            inputProps={{ readOnly: true }}
        />
    );
};

export const GenerateToken = ({ setValue, id }) => {
    const { t } = useTranslation();

    const [fetch, loading] = useRequestLoad();

    const handleGenerate = () => {
        fetch({
            api: companyApi.generateToken(id),
            callback: resp => setValue('token', resp['api-key']),
        });
    }

    return (
        <BaseButton
            label={t('generate')}
            design="contained"
            className="bluenight-button"
            loading={loading}
            onClick={handleGenerate}
        />
    );
};

GenerateToken.propTypes = {
    setValue: PropTypes.func,
    id: PropTypes.number
};

CopyInput.propTypes = {
    control: PropTypes.object,
    name: PropTypes.string,
};