import React from 'react';
import { useWatch } from "react-hook-form";
import logo from "../../../assets/images/abrhil-logo.png";
import { valueOrOption } from '../../../../../common/GeneralUtilities';

export const CompanyLogo = ({
	control
}) => {

	const company = useWatch({ control, name: "companyObj" });

	const onError = ({ currentTarget }) => {
		currentTarget.onerror = null; // prevents looping
		currentTarget.src = logo;
	};
	const prefix = valueOrOption(process.env.REACT_APP_API, "").replace("/api/admin", "");
	return (
		<div className='company-logo'>
			<img src={`${prefix}${company?.logo?.full_size}`} onError={onError} alt="abrhil-logo" />
		</div>
	);
};
