import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isUndefined } from "lodash";
import { useRequestLoad } from "../../hooks/useResolveIncidence";
import { workersApi } from "../../../../../../../services/worker";
import { isFromMopers } from "../../hooks/useModals";

const fields = [
    'id',
    'enterprise_structure_organization.settings.overtime_config',
].join(',');

const include = [
    'enterprise_structure_organization.settings.*'
].join(',');

function TxTGate({
    children,
    worker,
}) {
    const show = useHasTxT(worker);

    return (
        <>{show && children}</>
    );
};

export default TxTGate;

const getTxT = (item) => item?.enterprise_structure_organization?.settings?.overtime_config?.multiple_time_for_time;
const getValue = (item) => parseFloat(getTxT(item) ?? 0) > 0;

export const hasTxT = worker => getValue(worker);

export const useHasTxT = worker => {
    const [has, setHas] = useState(false);
    const [fetch] = useRequestLoad();

    useEffect(() => {
        if (!worker?.id) return;
        const txtMulti = getTxT(worker);
        if (!isUndefined(txtMulti)) {
            setHas(getValue(worker));
            return;
        }
        getTxTValue();
    }, [worker?.id])

    const getTxTValue = () => {
        fetch({
            api: workersApi.getOne(worker?.id, { fields, include, ...isFromMopers() }),
            callback: res => setHas(getValue(res)),
        });
    }

    return has;
};

TxTGate.propTypes = {
    children: PropTypes.any,
    worker: PropTypes.object,
};