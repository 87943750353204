import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { CustomTooltip } from '../../../../../App/components/Templates/cells';
import IconUse from '../../../../common/IconUse';

const Helpdesk = () => {
	const { t } = useTranslation();
	return (
		<div className='helpdesk-container'>
			<NavLink
				to={"https://abrhilhelpdesk.com/login"}
				className={"sidebar-item"}
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomTooltip title={t("help")} className="menu-item-tooltip" placement="left">
					<span className='side-menu-item-icon'>
						<IconUse icon={"help"} className={`metismenu-icon`} />
					</span>
				</CustomTooltip>
			</NavLink>
		</div>
	);
};

export default Helpdesk;