
import PropTypes from "prop-types"
import React, {useEffect} from "react";
import { t } from "i18next";
import { useWatch } from "react-hook-form";
import FormInput from "./Field";

const DefaultField = ({
  control,
  setValue,
  onChange,
  conditionField = "is_active"
}) => {

  const fieldControl = useWatch({ control, name: conditionField });

  useEffect(() => {
      if (fieldControl) return;

      setValue('is_default', false);

  }, [fieldControl])

  return (
      <FormInput
          control={control}
          fieldInput="Switch"
          label={t('is-default')}
          name="is_default"
          onChange={onChange}
          disabled={!fieldControl}
      />
  );
};

DefaultField.propTypes = {
  conditionField: PropTypes.string,
  control: PropTypes.any,
  onChange: PropTypes.func,
  setValue: PropTypes.any
}

export default DefaultField;