import PropTypes from "prop-types"
import React, { useState } from 'react';
import { t } from "i18next";
import Grid from "@mui/material/Grid";
import { format, parseISO } from "date-fns";
import Dialog from '../../../../../styled/Dialog';
import DialogTitle from '../../../../../styled/DialogTitle';
import DialogContent from '../../../../../styled/DialogContent';
import DialogActions from '../../../../../styled/DialogActions';
import './styles.scss';
import Button from '../../../../../styled/Button';
import IconUse from '../../../../../common/IconUse';
import { messages_2, messages_3 } from '../util';
import { ModalProvider, usePopUpController } from './usePopUp';
import TextField from '../../../../../../App/components/textInputs/TextField';
import { dateFormat } from '../../../../../../App/components/Dates/utilities';
import KendoDropdown from '../../../../../../App/components/Select/KendoDropDown';
import { overtimeUtilitiesApplyOptions } from '../../../../OvertimeUtilities/ConstantsAndUtilities/constants';

function ComponentsAlert(props) {
  const { 
    open, 
    type, 
    closeWarning, 
    resultAll, 
    resultData, 
    saveData 
  } = props
  
  const [dateType, setDateType] = useState(0)

  const view = {
    1: <Content1 {...props} dateType={dateType} setDateType={setDateType} />,
    2: <Content2 {...props} dateType={dateType} setDateType={setDateType} />,
    3: <Content3 {...props} />,
  };


  function sendData() {

    const resultDataNew = {
      "overtime-config": resultData,
      "validate_change": true,
      "choice_period": dateType
    }

    const resultAllNew = {
      ...resultAll,
    }

    if (Object.keys(resultAllNew).length) {
      resultAllNew.validate_change = true;
      resultAllNew.choice_period = dateType;
    }

    saveData(resultDataNew, resultAllNew)
  }

  return (
    <Dialog
      disableEscapeKeyDown={false}
      maxWidth="sm"
      open={open}
      className="title-custom"
    >
      <div style={{ padding: '20px', width: '500px' }}>

        <DialogTitle
          title={
            <div style={{ gap: "10px", display: "flex", alignItems: "center" }}>
              <IconUse icon={"warning"} style={{ width: "20px", height: "20px" }} />
              {t("warning-general-title")}
            </div>
          }
          onClose={closeWarning}
        />
        <DialogContent dividers>
          <ModalProvider>
            {view[type]}
          </ModalProvider>
        </DialogContent>
        <DialogActions>
          <Button design="text" onClick={closeWarning}>{t("cancel")}</Button>
          <Button design="contained" onClick={sendData}>{t("accept")}</Button>
        </DialogActions>
      </div>

    </Dialog>
  );
}

ComponentsAlert.propTypes = {
  closeWarning: PropTypes.func,
  open: PropTypes.bool,
  resultAll: PropTypes.any,
  resultData: PropTypes.any,
  saveData: PropTypes.func,
  type: PropTypes.any
}

export default ComponentsAlert;

function Content1({ dateType, setDateType }) {
  const { periodsData } = usePopUpController();

  return <Grid container spacing={2}>

    <Grid item xs={12}>
      Modificar {messages_2["more_configs"]}
    </Grid>

    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        Fecha de Aplicación del Cambio
      </Grid>
      <Grid item xs={12}>
        <KendoDropdown
          value={dateType}
          onChange={({ value }) => setDateType(value)}
          noDefault={true}
          data={overtimeUtilitiesApplyOptions}
          name="date"
          label={t("")}
          isRequired={true}
          textField="name"
          dataItemKey="id"
          valueField="id"
        />
      </Grid>
    </Grid>
    {parseInt(dateType) !== 0 &&
      <PeriodComponent dateType={dateType} periodsData={periodsData} />
    }
  </Grid>;
}

Content1.propTypes = {
  dateType: PropTypes.any,
  setDateType: PropTypes.func
}

function Content2({ messages, dateType, setDateType }) {
  const { periodsData } = usePopUpController();

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        Modificar {messages_2[messages]}
      </Grid>

      <Grid item container spacing={2} xs={12}>
        <Grid item xs={12}>
          Fecha de Aplicación del Cambio
        </Grid>
        <Grid item xs={12}>
          <KendoDropdown
            value={dateType}
            onChange={({ value }) => setDateType(value)}
            noDefault={true}
            data={overtimeUtilitiesApplyOptions}
            name="date"
            label={t("")}
            isRequired={true}
            textField="name"
            dataItemKey="id"
            valueField="id"
          />
        </Grid>
      </Grid>
      {parseInt(dateType) !== 0 &&
        <PeriodComponent dateType={dateType} periodsData={periodsData} />
      }
    </Grid>
  );
}

Content2.propTypes = {
  dateType: PropTypes.any,
  messages: PropTypes.any,
  setDateType: PropTypes.func
}

export const PeriodComponent = ({ dateType, periodsData }) => {
  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        {t("period")}
      </Grid>
      {periodsData?.map((element, ) => {
        const date = parseInt(dateType) === 2 ? element?.start_admin_court_date : element?.start_date;
        const value = `${element?.key ?? ""} - ${element?.payroll_type?.name ?? ""} ${format(new Date(parseISO(date, { representation: 'complete' })), dateFormat)}`;
        return (
          <Grid item xs={12} key={element.id}>
            <TextField
              value={value}
              disabled={true}
            />
          </Grid>
        )
      })}
    </Grid>
  );
}

PeriodComponent.propTypes = {
  dateType: PropTypes.any,
  periodsData: PropTypes.any
}

function Content3({ messages }) {
  return <div>
    Modificar {messages_3[messages]} <div className='label-question'>¿Desea continuar?</div>
  </div>;
}

Content3.propTypes = {
  messages: PropTypes.any
}
