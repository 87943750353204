import React from 'react';
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import DialogActions from '@mui/material/DialogActions';
import useAdvanceFilters from '../useAdvanceFilters';
import Button from '../../../../styled/Button';
import { Form } from '../../../form/Container';
import { faFilterSlash, faFilter } from "@fortawesome/pro-light-svg-icons";
import IconUse from '../../../../common/IconUse';

const Buttons = () => {

	const { t } = useTranslation();
	const {
		onClean,
		onSubmit,
		handleSubmit,
		defaultValues,
		fieldsLabels,
	} = useAdvanceFilters();

	return (
		<DialogActions>
			<Form
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				defaultValues={defaultValues}
				fieldsLabels={fieldsLabels}
			>
				<Grid container spacing={2} sx={{ padding: "8px !important" }}>
					<Grid size={"auto"}>
						<Button onClick={onClean} className={`systemButton`} data-cy={"advance-filters-clean"}>
							<IconUse icon={faFilterSlash} />
							<span className={`buttonLabel`}>
								{t("clean")}
							</span>
						</Button>
					</Grid>
					<Grid size={"auto"}>
						<Button type={"submit"} design="contained" className={`systemButton`} data-cy={"advance-filters-filter"}>
							<IconUse icon={faFilter} />
							<span className={`buttonLabel`}>
								{t("filter")}
							</span>
						</Button>
					</Grid>
				</Grid>
			</Form>
		</DialogActions>
	);
};

export default Buttons;