import React from 'react'
import Header from './Header'
import Container from './Container'
import { PackagesProvider, usePackages } from './usePackages'

function index(props) {
  return (
    <PackagesProvider {...props}>
      <Content />
    </PackagesProvider>
  )
}

export default index

function Content() {
  const { selectDefault } = usePackages()

  const styleContainer = {
    background: "var(--neutral)",
    position: "absolute",
    top: !selectDefault ? "0px" : "10px",
    left: "0px",
    height: "97%",
    width: "100%"
  }

  return <div style={styleContainer}>
    {!selectDefault && <div style={{ marginBottom: "13px" }}>
      <Header />
    </div>}
    <div style={{ height: !selectDefault ? "auto" : "100%",  margin: "8px" }}>
      <Container />
    </div>
  </div>
}