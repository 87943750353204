import { isNumber, isObject, uniq } from "lodash";

// ICONS
import AbsenteeismIcon from "../icons/ausentismos.png";
import VacationsIcon from "../icons/vacaciones.png";
import AssistAdjustmentIcon from "../icons/ajuste_de_asistencia.png";
import completeTurnsIcon from "../icons/completar_turnos.png";
import ExtraHoursTxtIcon from "../icons/txt.png";
import ExtraHoursMoneyIcon from "../icons/dinero.png";
import DoubleTurnIcon from "../icons/turnos_dobles.png";
import WorkedRestsIcon from "../icons/descanso_trabajado.png";
import HiringStaffIcon from "../icons/staff.png";
import ManualHiringIcon from "../icons/contratacion_manual.png";
import SpecialConceptsIcon from "../icons/conceptos_especiales.png";
import PromotionsIcon from "../icons/promociones.png";
// import ListSignatures from "../icons/firmar_lista.png";

import MopersVideo from "../assets/video/mopers_splash.mp4";

export const mopersSplashSrc = MopersVideo;

export const formatMoney = (dataItem) => {
    dataItem = parseFloat(dataItem);
    if ((!dataItem && !isNumber(dataItem)) || isNaN(dataItem)) return;
    return dataItem.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });
};

// ADD MOPERS HERE
export const moperOptions = {
    1: { image: AbsenteeismIcon, params: { moper_catalog: '1,2' }, order: 2 },
    3: { image: VacationsIcon, params: { moper_catalog: '3' }, order: 1 },
    4: { image: AssistAdjustmentIcon, params: { moper_catalog: '4' }, order: 3 },
    5: { image: completeTurnsIcon, params: { moper_catalog: '5' }, order: 4 },
    6: {
        2: { image: ExtraHoursMoneyIcon, params: { moper_catalog: '6', sub_catalog: '1' }, order: 7 },
        1: { image: ExtraHoursTxtIcon, params: { moper_catalog: '6', sub_catalog: '2' }, order: 8 },
        3: { image: WorkedRestsIcon, params: { moper_catalog: '6', sub_catalog: '3' }, order: 5 },
        4: { image: DoubleTurnIcon, params: { moper_catalog: '6', sub_catalog: '4' }, order: 6 },
    },
    7: {
        5: { image: HiringStaffIcon, order: 13, params: { moper_catalog: '7', sub_catalog: '6' } },
        6: { image: ManualHiringIcon, order: 12, params: { moper_catalog: '7', sub_catalog: '5' } },
    },
    8: { image: SpecialConceptsIcon, params: { moper_catalog: '8' }, order: 9 },
    9: { image: PromotionsIcon, params: { moper_catalog: '9' }, order: 10 },
    // 10: { image: ListSignatures, params: { moper_catalog: '10' }, order: 11 }
    11: { image: PromotionsIcon, params: { moper_catalog: '11' }, order: 11 },
};

// ADD MOPERS HERE
export const actionFilters = [
    { label: 'Conceptos especiales', name: 'special_concepts', catalog: 8 },
    { label: 'Descansos trabajados', name: 'worked_rests', catalog: 6, sub_catalog: 3 },
    { label: 'Horas extra dinero', name: 'extra_hours_money', catalog: 6, sub_catalog: 1 },
    { label: 'Turnos dobles', name: 'double_shifts', catalog: 6, sub_catalog: 4 },
    { label: 'Ajustes de asistencia', name: 'assist_settings', catalog: 4 },
    { label: 'Completar turnos', name: 'complete_shifts', catalog: 5 },
    { label: 'Horas extra TxT', name: 'extra_hours_txt', catalog: 6, sub_catalog: 2 },
    { label: 'Promociones', name: 'promotions', catalog: 9 },
    { label: 'Ausentismos con goce', name: 'absen_with_goce', catalog: 1 },
    { label: 'Ausentismos sin goce', name: 'absen_without_goce', catalog: 2 },
    { label: 'Vacaciones', name: 'vacations', catalog: 3 },
    { label: 'Requisición de personal', name: 'staff_requisition', catalog: 11 },
    { label: 'Contrataciones manuales', name: 'manual_hirings', catalog: 7, sub_catalog: 5 },
    { label: 'Contrataciones por STAFF', name: 'staff_hirings', catalog: 7, sub_catalog: 6 },
    // { label: 'Firmas de lista de asistencia', name: 'attendance_list_signs' },
];

export const getUrlParam = paramName => {
    const location = window.location;
    const query = new URLSearchParams(location.search);
    return query.get(paramName);
};

export function extractCatalogs(data) {
    const moper_catalogs = [];
    const sub_catalogs = [];

    function traverse(obj) {
        for (const key in obj) {
            if (isObject(obj[key]) && obj[key] !== null) {
                if (obj[key].params) {
                    if (obj[key].params.moper_catalog) {
                        moper_catalogs.push(...obj[key].params.moper_catalog.split(','));
                    }
                    if (obj[key].params.sub_catalog) {
                        sub_catalogs.push(...obj[key].params.sub_catalog.split(','));
                    }
                } else {
                    traverse(obj[key]);
                }
            }
        }
    }

    traverse(data);
    const catalogs = uniq(moper_catalogs).filter(el => el !== '6').join(',');
    const subCatalogs = uniq(sub_catalogs).join(',');
    return { moper_catalog: catalogs ?? undefined, sub_catalog: subCatalogs ?? undefined };
};

export const allCatalogs = extractCatalogs(moperOptions);