import { object } from "yup";
import { validateNumeric } from '../../../../../common/validations/numeric';
import { hasValue } from '../../../../../common/GeneralUtilities';

export const defaultValues = {
	corporate: null,
	company: null,
	role: null,
};

export const ValidationSchema = (isSuper) => object().shape({
	corporate: validateNumeric({ required: true }),
	company: validateNumeric({ required: true }),
	role: validateNumeric({ required: !isSuper }),
});

export const dropdownProps = {
	size: "small",
	noDefault: true,
	valueField: "id",
	textField: "name",
	className: "blueLabel",
	fieldInput: "Dropdown",
	sideLabel: "up",
};

export const getObjStorage = (storageName) => {
	try {
		const itemStored = localStorage.getItem(storageName);
		const parsedValue = JSON.parse(itemStored);
		return { valid: hasValue(parsedValue), value: parsedValue };
	} catch (e) {
		return { valid: false, value: null };
	}
};

export const setObjStorage = (storageName, objValue = {}) => {
	if (!hasValue(storageName)) { return; }

	if (!hasValue(objValue)) {
		return localStorage.removeItem(storageName);
	}
	localStorage.setItem(storageName, JSON.stringify(objValue));
};