//--project imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from 'i18next';
//--project imports
import "./app.scss";
import ErrorNotification from "../../components/dialog/ErrorNotification";
import SuccessNotification from "../../components/dialog/SuccessNotification";
import WarningNotification from "../../components/dialog/WarningNotification";
import ImportNotification from "../../components/dialog/ImportNotification";
import RemoveNotification from "../../components/dialog/RemoveNotification";
import ControlledError from "../../components/dialog/ControlledError";
import { setSearch } from "../../../store/actions";
import { logout } from "../../../core/common/auth";
import locales from './locales';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

import {
	IntlProvider,
	loadMessages,
	LocalizationProvider,
} from "@progress/kendo-react-intl";
import { load } from '@progress/kendo-intl';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es-MX/numbers.json";
import localCurrency from "cldr-numbers-full/main/es-MX/currencies.json";
import caGregorian from "cldr-dates-full/main/es-MX/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es-MX/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es-MX/timeZoneNames.json";
/* local languages */
import esMessages from "../../../core/language/es/kendoMessages.json";
import AdvanceFilters from "../../../core/@components/filter/AdvanceFiltersV2";
import ConfirmNotification from "../../components/dialog/ConfirmNotification";
import Navigation from "../../../core/@components/navigation";
import InfoNotification from '../../components/dialog/InfoNotification';
import Dialog from '@mui/material/Dialog';
import { StatusSkeleton, Transition } from "../../components/dialog/DialogSkeleton";
import { ChangePasswordForm } from '../../../core/@components/navigation/components/userSection/ChangePasswordOption';
import CustomNotification from "../../components/dialog/CustomNotification";
import useSession from "./useSession";

load(
	likelySubtags,
	currencyData,
	weekData,
	localCurrency,
	numbers,
	caGregorian,
	dateFields,
	timeZoneNames
);

loadMessages(esMessages, "es-MX");

function minToMillSec(min) {
	return min * 60 * 1000;
}

const min = {
	0: 60,
	1: 5,
	2: 10,
	3: 15,
	4: 20,
};

function AdminLayout() {
	const dispatch = useDispatch();
	const [viewCount, setViewCount] = useState(false);
	const [modalQuestionLogout, setModalQuestionLogout] = useState(false);
	const inactivityTime = useSelector((state) => state.configuration.abrhil_package?.inactivity_time?.value || 0);

	const locale = locales[i18next.t("systemLanguage") === "es" ? 1 : 0];

	useSession();

	function onConfirm() {
		setViewCount(false);
		setModalQuestionLogout(false);
		message('setViewCount', true);
	}

	function showInactivityModal() {
		if (process.env.REACT_APP_ABR_MODE === "localhost" || process.env.REACT_APP_ABR_MODE === "development") return;
		dispatch(setSearch(null));
		setModalQuestionLogout(true);
		setViewCount(true);
	}


	const onMessage = (e) => {
		if (e === "setViewCount") {
			setModalQuestionLogout(false);
			setViewCount(false);
		}
	};

	const {
		message
	} = useIdleTimer({
		onIdle: showInactivityModal,
		onMessage,
		timeout: minToMillSec(min[inactivityTime]),
		crossTab: true,
		disabled: !inactivityTime,
		leaderElection: true,
		syncTimers: 200
	});

	return (
		<LocalizationProvider language={locale.language}>
			<IntlProvider locale={locale.locale}>
				<Navigation />
				{viewCount && <ForceLogout />}
				{modalQuestionLogout && <ModalQuestionLogout onConfirm={onConfirm} />}
				<AdvanceFilters />
				<SuccessNotification />
				<ErrorNotification />
				<WarningNotification />
				<ImportNotification />
				<ConfirmNotification />
				<RemoveNotification />
				<InfoNotification />
				<CustomNotification />
				<ControlledError />
				{/* <IconsViewer /> */}
				<ChangePasswordForm />
			</IntlProvider>
		</LocalizationProvider>
	);
}

export default AdminLayout;

function ModalQuestionLogout({ onConfirm }) {

	const [count, setCount] = useState(60);

	useEffect(() => {
		const interval = setInterval(() => {
			setCount((prevCount) => prevCount - 1);
		}, 1000);

		return () => clearInterval(interval); // Limpiar el intervalo cuando el componente se desmonte
	}, []);

	useEffect(() => {
		if (count != 0) return;
		logout();
	}, [count]);

	return <Dialog
		open={true}
		TransitionComponent={Transition}
		className={"myDialog warningDialog"}
		maxWidth={"lg"}
	>
		<StatusSkeleton
			lottieIcon={"warning-icon"}
			message={"Se detecto inactividad, ¿Desea continuar con su sesión abierta?"}
			close={logout}
			confirm={onConfirm}
			hasConfirm={true}
			confirmButtonText="Continuar"
			cancelButtonText={`Cerrar sesión en ${count}`}
			title={"Cierre de sesión por inactividad"}
		/>
	</Dialog>;
}

function ForceLogout() {
	//Componente que forza la salida
	const handleOnId = () => {
		logout();
	};

	return <IdleTimerProvider
		timeout={minToMillSec(1)} //tiempo de inactividad 1 min
		onIdle={handleOnId}
	/>;
}