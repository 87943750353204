import React, {
	useState,
	useEffect
} from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
/**
 * Componente de uso rapido para los lottifiles
 * @param {object} params
 * @param {"cash" | "paycheck"} params.icon -  el nombre del icon que se va a usar de la lista disponible en la carpeta de este archivo
 *
 * @returns {Player}
 */

const LottieUse = ({
	icon = "cash" | "paycheck",
	...otherProps
}) => {

	const [importedIcon, setImportedIcon] = useState(null);

	const importComponent = async () => {
		const awaitedIcon = await import(`../../../public/assets/lottie/${icon}.json`);
		setImportedIcon(awaitedIcon);
	};

	useEffect(() => {
		importComponent();
		//eslint-disable-next-line
	}, [icon]);

	return (
		<Player
			autoplay
			keepLastFrame
			src={importedIcon}
			{...otherProps}
		/>
	);
}

export default LottieUse;