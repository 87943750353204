import { getLabelValue, hasValue, valueOrOption } from '../../common/GeneralUtilities';
import { trim } from "../../common/validations/validationFilter";
import i18next from 'i18next';

const { t } = i18next;
const structureBase = "enterprise_structure_organization.enterprise_structure";

export const workerFullName = ({
	first_surname,
	second_surname,
	name,
	key,
	include_key = false
} = {}) => {

	const key_data = include_key && hasValue(key) ? getLabelValue(key, "-") : "";
	first_surname = valueOrOption(first_surname, "");
	second_surname = valueOrOption(second_surname, "");
	name = valueOrOption(name, "");

	return trim([key_data, first_surname, second_surname, name].join(" "));
};

export const workerNameTooltip = ({
	first_surname,
	second_surname,
	name,
} = {}) => {

	let title = "";
	title += getLabelValue(t("name"), name, "\n");
	title += getLabelValue(t("first-surname"), first_surname, "\n");
	title += getLabelValue(t("second-surname"), second_surname, "\n");

	return title;
};

export const orderTypes = () => (
	[
		{
			id: 0,
			key: 0,
			name: `${t("key")} - ${t("worker")}`,
			value: "key",
		},
		{
			id: 1,
			key: 1,
			name: `${t("structure-enterprise")}`,
			value: "enterprise_structure_organization",

		},
	]
);

export const customColumnsOptions = () => (
	[
		{ id: 0, key: 0, name: t("employer-registration"), value: "employer-registration" },
		{ id: 1, key: 1, name: t("area"), value: "area" },
		{ id: 2, key: 2, name: t("department"), value: "department" },
	]
);


/**
 * Funcion que regresa el prefijo a usar para los fields del trabajador
 * @param {?string} prefix
 * @returns {string}
**/
export const getPrefix = (prefix) => {
	return hasValue(prefix) ? `${prefix}.` : "";
};

export const tableFields = (prefix) => {

	prefix = getPrefix(prefix);

	return [
		`${prefix}id`,
		`${prefix}key`,
		`${prefix}first_surname`,
		`${prefix}second_surname`,
		`${prefix}name`,
		`${prefix}photo`,
		`${prefix}phone`,
		`${prefix}gender`,
		`${prefix}marital_status`,
		`${prefix}birth_entity`,
		`${prefix}migratory_state`,
		`${prefix}nationality`,
		`${prefix}visitor_validity`,
		`${prefix}last_contract_type_name`,
		`${prefix}current_status`,
		`${prefix}last_day_date`,
		`${prefix}last_contract_date`,
		`${prefix}last_contract_end_date`,
		`${prefix}accumulate_contract_days`,
		`${prefix}contract_type.id`,
		`${prefix}contract_type.key`,
		`${prefix}contract_type.name`,
		`${prefix}${structureBase}.branch.key`,
		`${prefix}${structureBase}.branch.name`,
		`${prefix}${structureBase}.sub_branch.key`,
		`${prefix}${structureBase}.sub_branch.name`,
		`${prefix}${structureBase}.area.key`,
		`${prefix}${structureBase}.area.name`,
		`${prefix}${structureBase}.sub_area.key`,
		`${prefix}${structureBase}.sub_area.name`,
		`${prefix}${structureBase}.department.key`,
		`${prefix}${structureBase}.department.name`,
		`${prefix}${structureBase}.sub_department.key`,
		`${prefix}${structureBase}.sub_department.name`,
		`${prefix}${structureBase}.job_position.key`,
		`${prefix}${structureBase}.job_position.name`,
		`${prefix}${structureBase}.job_position.job_position_type.key`,
		`${prefix}${structureBase}.job_position.job_position_type.name`,
		`${prefix}enterprise_structure_organization.employer_registration.key`,
		`${prefix}enterprise_structure_organization.employer_registration.name`,
		`${prefix}enterprise_structure_organization.employer_registration.rfc`,
	];
};

export const detailRowFields = (prefix) => {

	prefix = getPrefix(prefix);

	return [
		/* GeneralData tab */
		`${prefix}birthday`,
		`${prefix}gender_detail`,
		`${prefix}marital_status_detail`,
		`${prefix}old_key`,
		`${prefix}nss`,
		`${prefix}rfc`,
		`${prefix}curp`,
		/* AdressTab  */
		`${prefix}zipcode`,
		`${prefix}address`,
		/* MovementDates tab */
		`${prefix}imss_dates`,
		`${prefix}hiring`,
		/* Validity  tab */
		`${prefix}health_card_expires`,
		`${prefix}driving_card_expires`,
		`${prefix}position_validity_expires`,
		/* Salary  tab */
		`${prefix}salary`,
		`${prefix}real_salary`,
	];
};

const structure = (model) => `enterprise_structure_organization.${model}`
export const include = (prefix) => {

	prefix = getPrefix(prefix);
	return [
		`${prefix.replace(".", "")}`,
		`${prefix}zipcode.city.municipal.federal_entity.country.*`,
		`${prefix}contract_type`,
		`${prefix}${structure("enterprise_structure")}.*`,
		`${prefix}${structure("enterprise_structure")}.job_position.job_position_type.*`,
		`${prefix}${structure("employer_registration")}.*`,
		`${prefix}gender`,
		`${prefix}marital_status`,
		`${prefix}migratory_state`,
	];
};