import axios from "axios";
import {
  ServerAPI,
  getUrl,
  ParseParams,
} from './ServerAPI';

const ReportsApi = () => {

  const baseName = "reports";

  const baseApi = ServerAPI(baseName, true, true);
  const configs = { include_config: true, include_role: true };

  const addFavorite = async (id) => {
    const url = getUrl(`${baseName}/${id}/add-favorite`);
    const params = ParseParams(configs);
    return axios.get(url, { ...params })
      .then(async (response) => response.data);
  }

  const removeFavorite = (id) => {
    const url = getUrl(`${baseName}/${id}/remove-favorite`);
    const params = ParseParams(configs);
    return axios.get(url, params).then((response) => response.data);
  };


  const tags = () => {
    const url = getUrl(`report-tags`);
    const params = ParseParams(configs);
    return axios.get(url, params).then((response) => response.data);
  };

  const download = (path, request, isSocket) => {
    const url = getUrl(`${path}`);
    const params = ParseParams({ params: request, ...configs });
    if (isSocket) {
      return axios.get(url, { ...params },)
        .then(async (response) => response.data);
    } else {
      return axios
        .get(url, { ...params, responseType: 'blob' })
        .then(response => window.URL.createObjectURL(response.data));
    }

  }

  return {
    ...baseApi,
    addFavorite,
    removeFavorite,
    tags,
    download
  };
}

export const reportsApi = ReportsApi();