import React, { useState, createContext, useContext, useEffect } from "react";
import { implementService } from "../../services/implemet-service";
import { packagesV2Api } from "../../services/packages";
import { iconsModule } from "./util";
import { successUpdated } from "../../common/notification-messages";
import { showConfirmNotification, showNotificationSuccess } from "../../../store/actions";
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";

const useController = ({ tenant, selectDefault, persistSettings = [], hideSettings = [], selectChill=null }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()
  useEffect(() => {
    getData()
  }, [])

  const [selectPackage, setSelectPackage] = useState(null)
  const [selectCategory, setSelectCategory] = useState(null)
  const [classifications, setClassifications] = useState(null)
  const [chill, setChill] = useState(null)

  const [data, setData] = useState([])

  function editPackage(request) {
    implementService(packagesV2Api(tenant).packagesUpdate.putConcatName(request), () => {
      getData()
      dispatch(showNotificationSuccess(successUpdated()))
    })
  }

  function handleEditPackage(prop, val, { alias, value, editable, id, key }) {
    const request = {
      package: {
        alias,
        value,
        editable,
        id,
        key
      }
    }
    request.package[prop] = val
    if (prop === "value") {

      dispatch(
        showConfirmNotification({
          type: 'warning',
          maxWidth: 'sm',
          message: !value
            ? t('ask-activate-package')
            : t('ask-deactivate-package'),
          onConfirm: () => {
            editPackage(request)
          },
        })
      )
    } else {
      editPackage(request)
    }
  }

  function getData() {
    implementService(packagesV2Api(tenant).packages.get(), result => {
      result.map(m => {
        m.icon = iconsModule[m.key]
        return m
      })
      const findIndex = result?.findIndex(f => f.key === selectPackage?.key)
      if (findIndex != -1) {
        setSelectPackage(result[findIndex])
      } else {
        let item = {}
        if (selectDefault) {
          item = result.find(f => f.key === selectDefault)
          if (selectChill) {
            const result = item.sub_packages.find(f => f.key === selectChill)
            setChill(result)
          }
        } else {
         item = result.find(f => f.id === (selectPackage?.id || result[0].id))
        }
        setSelectPackage(item)
      }
      setData(result)
    })
  }

  return {
    data,
    tenant,
    selectPackage,
    setSelectPackage,
    selectCategory,
    setSelectCategory,
    handleEditPackage,
    setClassifications,
    classifications,
    selectDefault,
    persistSettings,
    hideSettings,
    chill, 
    setChill,
    selectChill
  };
};

const PackagesContext = createContext();

export const PackagesProvider = (props) => (
  <PackagesContext.Provider value={useController(props)}>
    {props.children}
  </PackagesContext.Provider>
);

export const usePackages = () => useContext(PackagesContext);
