import React from "react";
import Controller from "./Controller";
import PermissionsTree from "./Tree";

/**
* Este es un componente específico, se utiliza para visualizar, asignar o desasignar
* permisos del sistema a los usuarios o roles
* @param { object } props
* @param { boolean } props.open : Es el valor que controla si el modal se abre o se cierra (opcional)
* @param { string } props.name : Valor con el que aparecerá en el esquema de hook form, permite manejar los datos por schema de hook-form
* @param { object } props.dataItem : Arreglo de permisos conformado por numeros enteros (ids), se puede utilizar en lugar de usar 'name' para manejar la entrada de datos sin hook-form
* @param { controller } props.control : Es el control del useForm utilizado donde se instancia el componente
* @param { setValue } props.setValue : Es el setValue del useForm utilizado donde se instancia el componente
* @param { string } props.maxHeight : Define el tamaño máximo en el cual se activará el scroll vertical
* @param { function } props.getValues : Función de react-hook-form para mandar a traer los datos mediante el name (en caso de no usar dataItem)
* @param { boolean } props.extraSettings : Configuración para mostrar detalles de los items al seleccionarlos
* @param { string } props.errorName : Nombre del cual se validará en el esquema si hay errores, por defecto se utiliza el mismo de name al no pasarle la prop
* @param { boolean } props.validate : Se define el comportamiento del componente en cuanto a validación, si este validará con cada actualización de datos
* @example
* <Permission
*	name="permissions"
*	open={formModal}
*	dataItem={selected}
*   getValues={getValues}
*	control={control}
*	setValue={setValue}
* 	extraSettings={false}
*	errorName='name'
*	validate={false}
* />
* @version 1.2.1
* @author Emilio Jarey Mendez Torres
**/

const Permission = ({
	open = true,
	control,
	setValue,
	getValues,
	dataItem,
	animate= true,
	extraSettings = false,
	dashboardSettings = false,
	dashboardName,
	dashboardItem,
	maxHeight,
	name,
	errorName,
	validate = true,
	validateDash = true,
}) => {

	const {
		formatedData,
		disabled,
	} = Controller({
		open,
		dataItem,
		dashboardName,
		dashboardItem,
		name,
		getValues,
	});

	return (
		<PermissionsTree
			permissions={formatedData}
			animate={animate}
			setValue={setValue}
			additionalSettings={extraSettings}
			dashboardSettings={dashboardSettings}
			maxHeight={maxHeight ?? '400px'}
			control={control}
			disabled={disabled}
			dashboardName={dashboardName}
			name={name}
			errorName={errorName}
			validateDash={validateDash}
			validate={validate}
		/>
	);
};

export default React.memo(Permission);
