import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import Button from "../../../core/styled/Button";
import PermissionsGate from '../../../permissions/PermissionsGate';
import IconUse from '../../../core/common/IconUse';

/**
 *
 * @param {object} params
 * @param {?object} params.sx - Estilos que se le aplican al icono del botón
 * @param {function} params.onClick - Función que se aplica al clickear sobre el botón
 * @returns {ExportButton}
 */
function Export({
	sx,
	onClick,
	className,
	disabled = false
}) {
	const { t } = useTranslation();
	return (
		<PermissionsGate scopes={['read']}>
			<Button disabled={disabled} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy="export">
				<IconUse icon={faFileExport} size={sx || "1x"} />
				<span className="buttonLabel">{t('export')}</span>
			</Button>
		</PermissionsGate>
	);
}

Export.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: PropTypes.string
};

export default Export;
