import EventEmitter from 'events';
import { store } from '../../store';

export const socketEventEmitter = new EventEmitter();
export const lockedSockedEmitter = new EventEmitter();

/**
	Función para mandar a llamar al socket por su nombre y así activar la notificación socket
	@param {string} name - Nombre del socket registrado en SocketsDictionary.jsx
	@param {string} taskID - ID de tarea devuelta por una API del backend
	@param {function} finishCallback - Función a ejecutar cuando el socket termine (a diferencia de las que se declaran en el diccionario de sockets, está función no se ejecutará si se llega a recargar la página)
	@returns {function}
	@example
	TriggerNotificationSocket('worker_movements', response.task_id, onFinish);
*/

const TriggerNotificationSocket = (name, taskID, finishCallback) => socketEventEmitter.emit('socket_connection', { name, taskID, callback: finishCallback, module: getCurrentWindow()?.title });

export default TriggerNotificationSocket;

/**
 * Funcion para cancelar todas las conexiones y tareas masivamente
 */
export const cancelTasks = () => socketEventEmitter.emit('massive_socket_abort');
/**
 * Funcion para cancelar todas las conexiones y tareas masivamente de sockets que bloquean la pantalla
 */
export const cancelLockedTasks = () => lockedSockedEmitter.emit('massive_lock_socket_abort');

/**
	Función para mandar a llamar al socket por su nombre y así activar la notificación socket de sockets que bloquean la pantalla
	@param {string} name - Nombre del socket registrado en SocketsDictionary.jsx
	@param {string} taskID - ID de tarea devuelta por una API del backend
	@param {function} finishCallback - Función a ejecutar cuando el socket termine (a diferencia de las que se declaran en el diccionario de sockets, está función no se ejecutará si se llega a recargar la página)
	@returns {function}
	@example
	TriggerLockedSocket('worker_movements', response.task_id, onFinish);
*/

export const TriggerLockedSocket = (name, taskID, finishCallback) => lockedSockedEmitter.emit('socket_lock_connection', { name, taskID, callback: finishCallback });

export const getCurrentWindow = () => {
	const navigateState = store.getState().navigate;
	const focused = navigateState?.focused_window;
	return navigateState?.[focused];
}
