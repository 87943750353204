import PropTypes from "prop-types"
import React, {
	useState,
	useCallback,
	useMemo,
	useEffect,
} from 'react';
import { isEqual, size as _size, each, isObject, isFunction } from "lodash";
/* libraries */
import { useTranslation } from 'react-i18next';
import { uid } from 'uid';
import {
	MultiSelectTree,
	getMultiSelectTreeValue
} from '@progress/kendo-react-dropdowns';
import {
	processMultiSelectTreeData,
	expandedState,
	constructData,
	// getTagText
} from "./utilities";
import { ListNoData } from '../Templates/listNoDataRender';
import { hasValue, valueOrOption } from '../../../core/common/GeneralUtilities';
import { KendoLabelContainer } from '../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../sizesUtilities';
import { formatLabel } from '../Select/utilities';

const checkField = 'checkField';
const checkIndeterminateField = 'checkIndeterminateField';
const expandField = "expanded";

const getObjectValues = (valuesByKeyField, newValue, data, dataItemKey, subItemsField) => {
	newValue = valueOrOption(newValue, []);
	if (!valuesByKeyField) {
		return newValue;
	}

	if (!_size(newValue) || !_size(data)) {
		return [];
	}

	if (isObject(newValue[0])) {
		return newValue;
	}

	let finalValue = [];
	each(data, item => {
		const key = item?.[dataItemKey];
		if (key !== "tree-select-all-items" && newValue.includes(key)) {
			item[checkField] = true;
			finalValue.push(item);
		}
		const subItems = item?.[subItemsField];
		if (hasValue(subItems)) {
			finalValue = [
				...finalValue,
				...getObjectValues(valuesByKeyField, newValue, subItems, dataItemKey, subItemsField)
			];
		}
	});
	return finalValue;
};

const KendoTreeMultiselect = ({
	id,
	options,
	value: selectedValue,
	data: optionsData,
	onChange,
	dataItemKey,
	keyField,
	valueField,
	textField,
	name,
	rounded,
	childrenField,
	format,
	prevChangeParser,
	popupSettings = {},
	sideLabel = false,
	label = "",
	withAllSelectionLabel = true,
	valuesByKeyField = false,
	...others
}) => {

	const { t } = useTranslation();
	const { size, className } = kendoSizeClasses(others);
	const comName = name ?? `name_${uid()}`;
	const subItemsField = valueOrOption(childrenField, "children");
	let renderLabelField = textField;
	const fieldId = valueOrOption(id, uid());
	dataItemKey = valueOrOption(valueOrOption(dataItemKey, keyField), valueField);
	dataItemKey = valueOrOption(dataItemKey, "id");
	options = valueOrOption(options, optionsData);
	if (format) {
		renderLabelField = 'renderLabelField';
		options = constructData(options, keyField, textField, format, withAllSelectionLabel, subItemsField);
	}

	const fields = {
		dataItemKey,
		checkField,
		checkIndeterminateField,
		expandField,
		subItemsField
	};

	const data = (options || []).length > 0 ? [{
		[`${dataItemKey}`]: "tree-select-all-items",
		[`${renderLabelField}`]: t("all-items"),
		[`${subItemsField}`]: options
	}] : [];

	const [value, setValue] = useState([]);
	const [filter, setFilter] = useState(null);
	const [expanded, setExpanded] = useState(['tree-select-all-items']);

	useEffect(() => {
		const actualVal = getObjectValues(valuesByKeyField, selectedValue, options, dataItemKey, subItemsField);
		if (!isEqual(value, actualVal)) {
			setValue(actualVal);
		}
		// eslint-disable-next-line
	}, [selectedValue, options]);

	const handleChange = event => {

		const selected = value;
		const newValue = getMultiSelectTreeValue(data, {
			...fields,
			...event,
			...{ value: selected }
		});

		setValue(newValue);
		let finalValue = newValue.filter((v) => v[dataItemKey] !== "tree-select-all-items");

		if (isFunction(prevChangeParser)) {
			finalValue = prevChangeParser(finalValue);
		}

		if (typeof onChange === "function") {
			onChange(finalValue);
		}
	};

	// eslint-disable-next-line
	const onExpandChange = useCallback(event => setExpanded(expandedState(event.item, dataItemKey, expanded)), [expanded]);

	const treeData = useMemo(() => processMultiSelectTreeData(data, {
		expanded,
		value,
		filter,
		...fields
	}), [expanded, value, filter, data, fields]);

	const filterChange = event => setFilter(event.filter);

	const actualVal = treeData.length ? (value || []) : [];
	let tags = [];

	switch (actualVal.length) {
		case 0:
			tags = [];
			break;
		case 1:
			tags = [{
				// text: getTagText(actualVal[0], format ?? textField),
				text: valueOrOption(actualVal?.[0]?.[renderLabelField], formatLabel(format ?? textField, actualVal?.[0])),
				data: [...actualVal]
			}];
			break;
		default:
			tags = [{
				text: treeData[0].checkField ? t("all-items") : `${actualVal.length} ${t("selected")}`,
				data: [...actualVal]
			}];
			break;
	}

	return (
		<KendoLabelContainer
			label={label}
			editorId={fieldId}
			editorValue={_size(actualVal) > 0}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			<MultiSelectTree
				{...others}
				id={fieldId}
				data={treeData}
				onChange={handleChange}
				value={actualVal}
				name={comName}
				filterable={true}
				onFilterChange={filterChange}
				listNoData={ListNoData}
				dataItemKey={dataItemKey}
				textField={renderLabelField}
				checkField={checkField}
				checkIndeterminateField={checkIndeterminateField}
				expandField={expandField}
				subItemsField={subItemsField}
				onExpandChange={onExpandChange}
				size={size}
				className={`${className} abrhil-tree`}
				rounded={rounded}
				tags={tags}
				popupSettings={{
					...popupSettings,
					popupClass: "customPopup",
					width: "auto",
					height: "40vh"
				}}
			/>
		</KendoLabelContainer>
	);
};

KendoTreeMultiselect.propTypes = {
	childrenField: PropTypes.any,
	data: PropTypes.any,
	dataItemKey: PropTypes.any,
	format: PropTypes.any,
	id: PropTypes.any,
	keyField: PropTypes.any,
	label: PropTypes.string,
	name: PropTypes.any,
	onChange: PropTypes.func,
	options: PropTypes.array,
	popupSettings: PropTypes.object,
	prevChangeParser: PropTypes.func,
	rounded: PropTypes.string,
	sideLabel: PropTypes.bool,
	textField: PropTypes.any,
	value: PropTypes.any,
	valueField: PropTypes.any,
	valuesByKeyField: PropTypes.bool,
	withAllSelectionLabel: PropTypes.bool
}

export default KendoTreeMultiselect;