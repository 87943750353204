import React from 'react';
import useAllMenuSearch from '../../../contextsAndControllers/useAllMenuSearch';
import GridNoRecordsRender from '../../../../../../App/components/Templates/GridNoRecordsRender';
import { Items } from './Items';

export const Menu = ({ onClose, menuItems }) => {

	const { search } = useAllMenuSearch();

	return (
		<>
			<div className="empty-search">
				<GridNoRecordsRender init={true} filter={true} />
			</div>
			<Items
				items={menuItems}
				search={search}
				menuCollapsed={false}
				firstLevel
				breadcrumbs={[]}
				handleClick={onClose}
			/>
		</>
	);
};
