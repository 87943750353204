import React, {
	useContext,
	createContext,
	useState,
} from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Display } from '../../Responsive';
import { store } from '../../../../store';
import { showNotificationError, showNotificationWarning } from '../../../../store/actions';
import { focusWindow, openWindow } from '../../../../store/triggers/navigate';
import { windowFixedWarning, windowOpenWarning } from '../../../common/notification-messages';
import { hasValue } from '../../../common/GeneralUtilities';

const useController = () => {

	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();
	const {
		cosidereMobile,
		menuCollapsed,
		breadcrumbs,
	} = useSelector(state => state.navigate);
	const navigate = useNavigate();
	const isMobile = Display("custom", { maxWidth: cosidereMobile });
	const [showed, setShowed] = useState(null);

	const backToInit = () => {
		navigate("/app/welcome");
	};

	const openWindowItem = item => {
		if (!hasValue(item)) {
			return dispatch(showNotificationError({
				title: t("wait"),
				message: t("window-item-invalid"),
				maxWidth: "sm"
			}));
		}

		const navState = store.getState().navigate ?? {};
		const current_location = (location?.pathname ?? location ?? "welcome").toString();
		let index = 1;
		let freeWindow = false;
		let key = null;
		let toFocus = false;
		if (current_location.includes(item.path)) {
			return dispatch(showNotificationWarning(windowFixedWarning()));
		}

		const maxIterations = current_location.includes("welcome") ? 6 : 5;
		while (!freeWindow && !toFocus && index <= maxIterations) {
			key = `window_${index}`;
			freeWindow = !hasValue(navState?.[key]?.id);
			if (!freeWindow && navState?.[key]?.id === item?.id) {
				dispatch(focusWindow(key));
				toFocus = true;
			}
			index++;
		};

		if (toFocus) {
			return;
		}

		if (!freeWindow) {
			setTimeout(() => {
				dispatch(showNotificationWarning(windowOpenWarning()));
			}, 250);
			return;
		}
		dispatch(openWindow(key, item));
	};

	return {
		showed,
		isMobile,
		menuCollapsed,
		breadcrumbs,
		setShowed,
		backToInit,
		openWindowItem
	};
};

const AbrhilNavigationContext = createContext();

export const AbrhilNavigationProvider = ({ children }) => (
	<AbrhilNavigationContext.Provider value={useController()}>
		{children}
	</AbrhilNavigationContext.Provider>
);

/**
 * Controlador de contexto para los conceptos de nomina
 * @returns {useController}
 * */
export const useAbrhilNavigation = () => useContext(AbrhilNavigationContext);

export default useAbrhilNavigation;