import React from 'react';
/* idioma */
import { useTranslation } from 'react-i18next';
/* material-ui */
import Grid from "@mui/material/Grid";
import MuiAlert from '../general/MuiAlert';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faInfo
// } from "@fortawesome/pro-light-svg-icons";
import { Collapse } from '@mui/material';

/**
 *
 * @param {string} params.extensionFile - Extensión del archivo que se está cargando al upload
 * @param {Array} params.srcImage - Lista de extensiones permitidas para el archivo a subir
 */

const PhotoInputWarning = ({
    extensionFile,
    allowedExtensions
}) => {

    const { t } = useTranslation();
    allowedExtensions = allowedExtensions ?? [".jpg", ".png", ".jpeg", ".webp"];

    const show = !(allowedExtensions.includes(extensionFile) || !extensionFile);

    // if (allowedExtensions.includes(extensionFile) || !extensionFile) {
    //     return null;
    // }

    return (
        <Collapse in={show}>
            <Grid item xs={12}>
                <MuiAlert severity={"error"} style={{ fontSize: '10px' }}>
                    {/* <div>
                        {t("upload-image-fail")}
                    </div> */}
                    <div>
                        {`${t("invalid-image-extension")}: ${extensionFile}`}
                    </div>
                    <div >
                        {/* <FontAwesomeIcon icon={faInfo} style={{ paddingRight: "10px", color: "var(--MuiBlue)" }} /> */}
                        {`${t("allowed-extensions")}: ${allowedExtensions.join(", ")}`}
                    </div>
                </MuiAlert>
            </Grid>
        </Collapse>
    );

};

export default PhotoInputWarning;