import React from 'react'
import ExportDialog from '../../../App/components/Export/ExportDialog';
import { ExportFunctions } from '../general/Utilities';
import { currentTenant } from '../../common/validate-tenant';

/**
 * Componente de exportación de datos del sistema, manejado con reducers, en conjunto con las tablas
 * @param {object} params
 * @param {object} params.filters - Los filtros activos en la tabla del módulo
 * @param {object} params.page - Loas datos de página de la tabla del módulo
 * @param {string} params.module - El nombre del permiso del módulo en que se está usando
 * @returns
 */
const Export = ({
	filters,
	page,
	module,
}) => {

	const {
		exportState,
		changeFields,
		closeExport,
	} = ExportFunctions(true);

	if(!exportState.open){ return null; }

	return (
		<ExportDialog
			open={exportState.open}
			fieldSelection={exportState.fields}
			setFieldSelection={changeFields}
			handleClose={closeExport}
			currentTenant={currentTenant()}
			filters={filters}
			page={page}
			moduleFactory={module}
		/>
	);
}

export default Export

