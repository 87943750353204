import React, { useEffect, useRef } from "react";
import { useDialogContext } from "../../../hooks/useDialog";
import Grid from '@mui/material/Grid2';
import { gridSize } from "..";
import IconUse from "../../../../../../common/IconUse";
import { useFormState, useWatch } from "react-hook-form";
import { AnimatePresence, motion, stagger, useAnimate } from "framer-motion";

function Tabs() {
    const scope = useStaggerAnimation();

    const {
        tab,
        tabs,
        open,
        isEdit,
        control,
        setTabs,
        defTabs,
        onTabChange,
    } = useDialogContext();

    const { errors } = useFormState({ control });
    const errorKeys = Object.keys(errors ?? {});

    const loginMode = useWatch({ control, name: 'login_mode' });
    const isActive = useWatch({ control, name: 'is_active' });
    const isSSO = loginMode === 2;

    useEffect(() => {
        const newTabs = defTabs(isEdit, isActive, isSSO);
        setTabs(newTabs);
    }, [isSSO, isActive, open])

    const hasError = errorFields => errorKeys.some(el => errorFields?.includes(el));

    return (
        <Grid
            ref={scope}
            container
            {...gridSize(12)}
            direction="column"
            spacing={2}
        >
            <AnimatePresence>
                {tabs.map((el, i) =>
                    <Grid
                        key={`${el.key}-${el.id}`}
                        className={`company-tab-item ${i === tab ? 'selected' : ''} ${hasError(el.errorFields) ? 'has-error' : ''}`}
                        {...gridSize(12)}
                        container
                        spacing={2}
                        onClick={() => onTabChange(i)}
                        component={motion.div}
                        layout
                        exit={{ opacity: 0, x: -30 }}
                        initial={{ opacity: 0, x: -30 }}
                    >
                        <Grid><IconUse icon={el.icon} /></Grid>
                        <Grid className="vertical-center">{el.label}</Grid>
                        {!!el.customRender && <Grid style={{ marginLeft: 'auto' }}>{el.customRender}</Grid>}
                    </Grid>
                )}
            </AnimatePresence>
        </Grid>
    );
};

export default Tabs;

const useStaggerAnimation = () => {
    const { open, tabs } = useDialogContext();
    const [scope, animate] = useAnimate();
    const firstRender = useRef(true);

    useEffect(() => {
        if (!open) return;
        animate(
            '.company-tab-item',
            { opacity: 1, x: 0 },
            { delay: stagger(0.1, { startDelay: firstRender.current ? 0.2 : 0, ease: "easeOut" }), type: "linear" }
        );
        firstRender.current = false;
    }, [open, tabs])

    return scope;
}