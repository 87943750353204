import {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import {useSelector} from 'react-redux';
import {initialFilter} from '../../../../../App/components/GridCustomProps';

const useStructureConfigs = () => {
  const tenant = useSelector (state => state.tenant);
  const [filters, setFilters] = useState (initialFilter ());
  const [edit, setEdit] = useState (false);
  const [refreshCounter, setRefreshCounter] = useState (0);

  const {
    control,
    reset,
    setValue
  } = useForm({
    mode: "onChange",
  });
  useEffect (
    () => {
      setRefreshCounter (1);
      setFilters (initialFilter ());
      // eslint-disable-next-line
    },
    [tenant]
  );

  const refresh = () => {
    setRefreshCounter (counter => {
      return counter + 1;
    });
  };


  function cancel() {
    setEdit(false)
    reset()
  }

  return {
    filters,
    setFilters,
    refreshCounter,
    refresh,
    edit,
    setEdit,
    cancel,
    control,
    setValue
  };
};

export default useStructureConfigs;
