import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { size } from "lodash";
//-- excel
import { hideExport, showExport, showNotificationImport, showNotificationWarning } from "../../../store/actions";
import { hasErrorTetant } from '../../common/validate-tenant';
import { errorTenant } from '../../common/notification-messages';

import lang from "i18next";

const defaultFields = [
	{ name: "key", text: lang.t("key"), isSelected: true },
	{ name: "name", text: lang.t("name"), isSelected: true },
	{ name: "description", text: lang.t("description"), isSelected: true },
	{ name: "is_active", text: lang.t("is-active"), isSelected: true },
];

export const ImportFunctions = () => {

	const dispatch = useDispatch();

	const handleOpenImport = () => {

		let data = [
			{ id: 1, name: "resultado 1" },
			{ id: 2, name: "resultado 2" },
			{ id: 3, name: "resultado 3" }
		];

		dispatch(showNotificationImport(data));
	};

	// Método para leer archivos del botón import
	const readFileOnChange = () => {

	};

	return {
		handleOpenImport,
		readFileOnChange,
	};
}


export const ExportFunctions = (useReducer) => {

	const dispatch = useDispatch();
	const {
		show,
		fields,
	} = useSelector(state => state.exportData);

	const [exportState, setExportState] = useState({
		open: false,
		fields: defaultFields,
	});

	useEffect(() => {
		setExportState(state => ({
			fields: fields ?? state.fields ?? defaultFields,
			open: show
		}));
		//eslint-disable-next-line
	}, [show]);

	const openExport = (fields = {}) => {
		if (hasErrorTetant()) {
			dispatch(showNotificationWarning(errorTenant()));
			return;
		}

		const selectable = size(fields) ? (fields ?? defaultFields) : defaultFields;
		if (useReducer) {
			dispatch(showExport({
				fields: selectable
			}));
			return;
		}

		setExportState({
			open: true,
			fields: selectable,
		});
	};

	const closeExport = () => {
		if (useReducer) {
			dispatch(hideExport());
			return;
		}
		openExport(state => ({ ...state, open: false }))
	};

	const changeFields = (newFields) => {
		setExportState(state => ({ ...state, fields: newFields }))
	}

	return {
		exportState,
		changeFields,
		openExport,
		closeExport,
	};
}