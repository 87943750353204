// import FileSaver from "file-saver";
import { exportFile } from "../services/export-catalog";
// import { store } from '../../store';
// actions
// import { lockedWindow, unlockedWindow } from "../../store/actions";
import { resolveError } from "./resolve-error";
import { needUserRole } from "../services/ServerAPI";
import { currentGroup } from "./validate-tenant";
import TriggerNotificationSocket from "./trigger-notification-socket";

export function fieldsToString(collection) {
  return collection
    .filter((f) => f.isSelected)
    .map((m) => m.name)
    .join(',');
}

export function fieldsColumnsToString(collection) {
  return collection.map(column => {
    return column
      .filter((f) => f.isSelected)
      .map((m) => m.name)
  })
    .filter((f) => f.length)
    .join(',')
}

export async function downloadFactory(resource, params) {
  if (needUserRole(resource)) {
    params.current_user_group = currentGroup();
  }
  try {
    exportFile(resource, params).then(response => {
      TriggerNotificationSocket('generic_export', response?.task_id);
    });
  } catch (error) {
    resolveError(error);
  }
}