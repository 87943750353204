import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { uid } from "uid";
import BranchPlaceholder from "../../assets/branch_placeholder.png";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import PhotoUpload from "../../../../@components/modal/PhotoUpload";
import { isFunction } from "lodash";
import { CircularProgress } from "@mui/material";
import "../../styles/style.scss";

function Photo({
    photo,
    setValue,
    saveName,
    onSave,
    withBorder = true,
    showButton = true,
    size = 80,
    offset = -4,
    aspect = 1
}) {
    const [open, setOpen] = useState(false);
    const [fileImage, setFileImage] = useState(photo);
    const [srcImage, setSrcImage] = useState(photo);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isFunction(setValue)) return;
        setValue("logo", fileImage);
    }, [fileImage])

    useEffect(() => {
        if (isFunction(setValue)) return;
        setSrcImage(photo);
    }, [photo])

    const onClick = () => setOpen(true);

    const onLoad = () => setLoading(false);

    const buildSizes = (sizeValue) => ({
        width: sizeValue,
        height: sizeValue,
        minHeight: sizeValue,
        minWidth: sizeValue,
    });

    const photoSize = `${size}px`;
    const loaderSize = `${size * 0.4}px`;
    const cameraOffset = `${offset}px`;

    return (
        <div
            className={`photo-general-component ${withBorder ? 'with-border' : ''}`}
            style={buildSizes(photoSize)}
        >
            <div className="image-wrapper">
                {loading && <CircularProgress style={{ width: loaderSize, height: loaderSize }} />}
                <img alt={uid()} src={srcImage ?? BranchPlaceholder} onLoad={onLoad} />
            </div>
            {showButton &&
                <button
                    type="button"
                    className="camera-icon-wrapper"
                    onClick={onClick}
                    style={{ right: cameraOffset, bottom: cameraOffset }}
                >
                    <CameraIcon />
                </button>}
            {showButton &&
                <PhotoUpload
                    aspect={aspect}
                    open={open}
                    photo={srcImage}
                    close={() => setOpen(false)}
                    onSubmit={onSave}
                    setSrcImage={setSrcImage}
                    setFileImage={setFileImage}
                    imageName={saveName}
                />}
        </div>
    );
}

export default Photo;

Photo.propTypes = {
    aspect: PropTypes.number,
    offset: PropTypes.number,
    onSave: PropTypes.func,
    photo: PropTypes.any,
    saveName: PropTypes.string,
    setValue: PropTypes.func,
    showButton: PropTypes.bool,
    size: PropTypes.number,
    withBorder: PropTypes.bool
}