import PropTypes from "prop-types"
import React, { useState } from "react";
import KendoTable from "../../../../@components/grid/KendoTable";
import { useGridContext } from "../../hooks/useGridController";
import { GridColumn } from "@progress/kendo-react-grid";
import { CustomHeader, columnProps } from "../../../../../App/components/GridCustomProps";
import { useTranslation } from "react-i18next";
import { establishmentKeyAPI } from "../../../../services/training";
import { useWatch } from "react-hook-form";
import FormInput from "../../../../@components/form/Field";
import { capitalizeFirstWithLowercase, keyFormat } from "../../../../@components/form/utilities";
import TextField from "../../../../../App/components/textInputs/TextField";
import { keyName } from "../../../SignaturesByWorker/utils/Constants";

function Table() {
    const { t } = useTranslation();

    const {
        counter,
        filters,
        setTotal,
        setFilters,
        handleEdit,
        modified,
    } = useGridContext();

    return (
        <KendoTable
            Api={establishmentKeyAPI}
            refreshCounter={counter}
            filters={filters}
            setFilters={setFilters}
            is_active={false}
            setTotal={setTotal}
            onEdit={handleEdit}
            callback={() => modified.current = []}
            extraOptions={{
                include: 'branch,employer_registration'
            }}
            deleteContext={{
                maxWidth: 'sm',
                detail: 'de clave establecimiento',
                customFormat: (item) => {
                    return `${keyName(item?.branch)} / ${keyName(item?.employer_registration)}`;
                }
            }}
        >
            <GridColumn
                {...columnProps('branch', filters, ['keyName', 'branch'])}
                title={t('branch')}
            />
            <GridColumn
                {...columnProps('employer_registration', filters, ['keyName', 'employer_registration'])}
                title={t('employer-registration')}
            />
            <GridColumn
                title={t('stps-key')}
                {...columnProps('stps_key', filters, 'input')}
                width={120}
                headerCell={HeaderCellText}
                cell={(props) => <BodyCellText {...props} title={t('stps-key')} />}
            />
            <GridColumn
                title={t('company-stps-representative')}
                {...columnProps('company_representative', filters, 'input')}
                headerCell={HeaderCellText}
                cell={(props) => <BodyCellText {...props} title={t('company-stps-representative')} />}
            />
            <GridColumn
                title={t('_sign')}
                {...columnProps('company_signature', filters, 'none')}
                headerCell={HeaderCellFile}
                cell={BodyCellFile}
            />
            <GridColumn
                title={t('workers-stps-representative')}
                {...columnProps('worker_representative', filters, 'input')}
                headerCell={HeaderCellText}
                cell={(props) => <BodyCellText {...props} title={t('workers-stps-representative')} />}
            />
            <GridColumn
                title={t('_sign')}
                {...columnProps('worker_signature', filters, 'none')}
                headerCell={HeaderCellFile}
                cell={BodyCellFile}
            />
        </KendoTable>
    );
};

export default Table;

const HeaderCellText = (props) => {

    const { title, field } = props;

    const {
        massiveEdit,
        control,
    } = useGridContext();

    return (
        <>
            {
                !massiveEdit ?
                    <CustomHeader
                        title={title}
                        {...props}
                    />
                    :
                    <FormInput
                        className="table-textfield"
                        fieldInput="TextField"
                        name={`massive_${field}`}
                        control={control}
                        label={title}
                        onChange={field?.includes('key') ? keyFormat : capitalizeFirstWithLowercase}
                    />
            }
        </>
    );
};

HeaderCellText.propTypes = {
  field: PropTypes.string,
  title: PropTypes.string
}

const BodyCellText = ({ dataItem, field, title }) => {

    const {
        control,
        massiveEdit,
        modified,
        onTextChange
    } = useGridContext();

    const massiveValue = useWatch({ control, name: `massive_${field}` });
    const foundModified = modified.current.find(el => el?.id === dataItem.id);
    const foundValue = (massiveValue && !foundModified) ? massiveValue : (foundModified?.[field] ?? dataItem[field] ?? massiveValue);
    const [value, setValue] = useState(foundValue ?? null);

    const onChange = (e) => {
        e = field?.includes('key') ? keyFormat(e) : capitalizeFirstWithLowercase(e);
        setValue(e.value);
        onTextChange(e, dataItem, field);
        return e;
    }

    return (
        <td className={`cell-input-wrap body`}>
            {
                !massiveEdit ?
                    foundValue
                    :
                    <TextField
                        className="table-textfield"
                        value={value}
                        label={title}
                        onChange={onChange}
                    />
            }
        </td>
    );
};

BodyCellText.propTypes = {
  dataItem: PropTypes.any,
  field: PropTypes.string,
  title: PropTypes.string
}

const HeaderCellFile = (props) => {

    const { title, field } = props;

    const {
        massiveEdit,
        control,
    } = useGridContext();

    return (
        <>
            {
                !massiveEdit ?
                    <CustomHeader
                        title={title}
                        filterable={false}
                        {...props}
                    />
                    :
                    <FormInput
                        className="table-textfield"
                        fieldInput="File"
                        name={`massive_${field}`}
                        control={control}
                        label={title}
                    />
            }
        </>
    );
};

export const getFileName = value => value?.split('/')?.pop();

const BodyCellFile = ({ dataItem, field }) => {

    const value = dataItem[field] ?? '';

    return (
        <td className={`cell-input-wrap body`}>
            {getFileName(value)}
        </td>
    );
};