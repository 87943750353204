import React from "react";
import {
	concidence,
	filterChildrens,
	markText
} from "../../../utilities";
import { UlItem, NavItem } from "./NavItem";
import { hasValue } from '../../../../../common/GeneralUtilities';

export const Items = ({
	showByParent = false,
	...props
}) => {

	const {
		items,
		breadcrumbs,
		search,
	} = props;

	return items.map((item, index) => {
		const childrens = filterChildrens(item.children, search, item?.title);
		const navType = item?.showInMenu ? item?.type : "noNav";
		const titleMatch = hasValue(search) && !showByParent ? concidence(item.title, search) : true;

		switch (navType) {
			case "group":
				if (!hasValue(childrens)) { return null; }
				return (
					<UlItem
						{...props}
						key={index}
						item={{ ...item, title: getTitle(item.title, search) }}
					>
						<Items
							{...props}
							items={childrens}
							showByParent={titleMatch}
						/>
					</UlItem>
				);
			case "item":
				if (!titleMatch) { return null; }
				return (
					<NavItem
						{...props}
						to={item.path}
						item={item}
						breadcrumbs={breadcrumbs}
						key={index}
						title={<>{markText(item.title, search)}</>}
					/>
				);
			default:
				return null;
		}
	});
};

const getTitle = (title, search) => {
	title = title?.split("|&|");

	return (
		<>
			<span className='principal-parent-name'>
				{markText(title[0], search)}
			</span>
			{title?.[1] &&
				<span className='secondary-parents-names'>
					{markText(title[1], search)}
				</span>
			}
		</>
	);
};