import React from 'react';
import { concidence, getTabIcon, markText } from '../../../utilities';
import { ItemIcon } from '../Search/NavItem';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import { hasValue } from '../../../../../common/GeneralUtilities';

export const RootElement = ({ item }) => {

	const { tab, search, navigateInside } = useMegaMenu();
	const { title, is_accesible } = item ?? {};
	const disabled = is_accesible === false;

	const onOpen = () => {
		if (disabled) return;
		navigateInside(item);
	};

	if (hasValue(search) && !concidence(item.title, search)) {
		return null;
	}

	return (
		<div className='module-item' onClick={onOpen}>
			<span className={`${disabled ? 'disabled' : ''}`}>
				<span className='referer'>
					<ItemIcon icon={getTabIcon(item, tab)} />
					{markText(title, search)}
				</span>
				<span className='cover-line' />
			</span>
		</div>
	);
};
