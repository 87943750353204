import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback } from "react";
import {
	format as formatDate,
	parse,
} from 'date-fns';
import {
	DatePicker,
	CalendarHeaderTitle
} from "@progress/kendo-react-dateinputs";
import { uid } from 'uid';
import { hasValue, valueOrOption } from '../../../core/common/GeneralUtilities';
import CustomCalendar from './CustomCalendar';
import { KendoLabelContainer } from '../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../sizesUtilities';

/**
 * Hook de fechas tipo year o month basado en el kendo datepicker
 * @param {object} params
 * @param {number} params.value - El valor del componente
 * @param {function} params.onChange - Funcion que se lanza al cambiar el valor del componente
 * @param {?string} params.format - El formato con que se muestran los datos
 * @param {?string} params.id - El id del componente
 * @param {?string} params.size - El tamaño del componente
 * @param {?string} params.label - El label que se va a mostrar para el objeto
 * @param {?string} params.name - El nombre del componente
 * @param {?date} params.min - El valor minimo que se puede seleccionar para el componente, 1-12 para meses y en formato yyyy para años
 * @param {?date} params.max - El valor maximo que se puede seleccionar por el componente, 1-12 para meses y en formato yyyy para años
 * @param {?boolean} params.sideLabel - Indica se el label se va a mostrar a un lado
 * @param {?string} params.others
 *
 * @returns {KendoDateMonthOrYearComponent}
 * @example
 * <KendoDateMonthOrYear
 * 	name='month'
 * 	label={t("month")}
 * 	max={9}
 * 	format={"MMMM"}
 * 	value={3}
 * />
 */

const KendoDateMonthOrYear = ({
	value,
	onChange,
	format,
	id,
	label,
	name,
	min,
	max,
	popupSettings = {},
	disabled = false,
	sideLabel = false,
	openOnMount = false,
	...others
}) => {

	format = valueOrOption(format, "yyyy");
	const inOutFormat = format === "yyyy" ? "yyyy" : "MM";
	const editorId = id ?? "dateP_" + uid();
	const { size, className } = kendoSizeClasses(others);
	const view = format.toLowerCase() === "yyyy" ? "decade" : "year";
	const isYearv = view === "year";

	min = minMax(min, "min", isYearv);
	max = minMax(max, "max", isYearv);

	const inputValue = dateValue(value, inOutFormat);
	const inputRef = useRef(null);

	const change = event => {
		let newValue = event.value;
		if (newValue) {
			newValue = formatDate(newValue, inOutFormat);
		}
		changeValue(event, newValue);
	};

	const verifyMin = () => {
		if (inOutFormat === "yyyy" && hasValue(value)) {
			let year = parseInt(value);
			const minYear = parseInt(formatDate(min, "yyyy"));
			const maxYear = parseInt(formatDate(max, "yyyy"));
			year = year >= minYear ? year : minYear;
			year = year <= maxYear ? year : maxYear;
			changeValue({}, year);
		}
	};

	const changeValue = (event, newValue) => {
		if (typeof onChange === "function" && theValue(newValue) !== theValue(value)) {
			onChange({
				...event,
				type: "change",
				value: newValue,
				target: { value: newValue }
			});
		}
	};

	const CustomCalendarComponent = useCallback(props => <CustomCalendar
		{...props}
		navigation={true}
		bottomView={view}
		topView={view}
		defaultActiveView={view}
		headerTitle={CustomHeaderTitle}
	/>, []);

	const openCalendar = () => {
		const input = inputRef?.current;
		if (disabled || !input || format.includes("y")) { return; }
		if (input.handleIconClick) {
			input.handleIconClick();
		}
	};

	useEffect(() => {
		if (openOnMount) {
			setTimeout(() => {
				openCalendar();
			}, [370]);
		}
		//eslint-disable-next-line
	}, []);


	return <KendoLabelContainer
		label={label}
		editorId={editorId}
		editorValue={true}
		className={"custom-floating-label"}
		sideLabel={sideLabel}
	>
		<span className='month-year-picker-span' onClick={openCalendar} >
			<DatePicker
				ref={inputRef}
				disabled={disabled}
				popupSettings={{
					...popupSettings,
					popupClass: "customPopup datepicker-popup"
				}}
				{...others}
				size={size}
				className={`${className} capitalize`}
				// placeholder={inputValue ? "" : label}
				format={format}
				calendar={CustomCalendarComponent}
				value={inputValue}
				id={editorId}
				name={name ?? editorId}
				onChange={change}
				label=""
				min={min}
				max={max}
				onBlur={verifyMin}
			/>
		</span>
	</KendoLabelContainer>;
};

KendoDateMonthOrYear.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.any,
  id: PropTypes.any,
  label: PropTypes.any,
  max: PropTypes.any,
  min: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func,
  openOnMount: PropTypes.bool,
  popupSettings: PropTypes.object,
  sideLabel: PropTypes.bool,
  value: PropTypes.any
}

export default KendoDateMonthOrYear;

const theValue = (value) => {
	return isNaN(parseInt(value)) ? null : parseInt(value);
};

/* days per month */
const days = (index, year) => {
	const feb = year % 4 === 0 ? "29" : "28";
	return ["31", feb, "31", "30", "31", "30", "31", "31", "30", "31", "30", "31"][index];
};

const minMax = (value, type, forYear) => {

	if (!forYear) {
		const defaultYear = {
			"min": `1920`,
			"max": `2099`
		}[type];

		const year = formatDate(dateValue((value ?? defaultYear), "yyyy"), "yyyy");
		const date = `${year}/${type === "min" ? "01/01" : "12/31"}`;

		return new Date(date);
	}

	const defaultMonth = {
		"min": "01",
		"max": "12"
	}[type];

	const actualYear = formatDate(new Date(), 'yyyy');
	const month = formatDate(dateValue((value ?? defaultMonth), "MM"), "MM");
	const date = `${actualYear}/${month}/${type === "min" ? "01" : days(parseInt(month) - 1)}`;

	return new Date(date);
};

const dateValue = (value, inOutFormat) => {
	const valueType = typeof value;

	if (!hasValue(value) || !["string", "number", "date", 'object'].includes(valueType)) {
		return null;
	}

	if (inOutFormat && value) {
		if (["string", "number"].includes(valueType)) {
			return parse(value, inOutFormat, new Date());
		}
	}

	return value;
};

const CustomHeaderTitle = (props) => (
	<CalendarHeaderTitle {...props}>
		{props.view === 1 ? "" : props.value}
	</CalendarHeaderTitle>
);