import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	size,
	filter,
	uniq,
	find,
	isFunction,
	each,
	isBoolean,
	first,
	isObject,
	isArray,
	uniqBy,
	clamp,
	min,
	max,
} from "lodash";
import { getter } from "@progress/kendo-react-common";

/* Own components */
import { pageable, paginationInitials } from "../../../App/components/GridCustomProps";

/* Store actions */
import { showNotificationRemove, showNotificationWarning } from "../../../store/actions";

/* Utilities */
import { questionDelete, successDeleted } from "../../common/notification-messages";
import { parseRequest } from '../../common/parse-request';
import { loadService } from '../../services/implemet-service';
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
import { formatLabel } from '../../../App/components/Select/utilities';
import CustomPager from '../pager/CustomPager';
import { useRequestLoad } from '../../modules/MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence';
import { resolveError } from '../../common/resolve-error';
import { getSelectedState } from '@progress/kendo-react-data-tools';
import { showSuccessNotification } from '../../../App/components/Notifications';
/**
 *
 * Controlador para la tabla general del sistema
 * @param {object} param
 * @param {Api} param.Api - El Api configurado para el módulo, debe tener al emnos los metodos get y destroy.
 * @param {object} param.filters - Son los filtros que se manejan en el sistema. se manejan por fuera para trabajar en conjunto con los filtros avanzados
 * @param {function} param.setFilters - Es la funcion que se realiza al momento de actualizar los filtros, puede ser unicamente el segundo parametro del useState
 * @param {number} param.refreshCounter - Este nos va a servir para menejar el refresh de la tabla, lo debe accionar el botón refresh del módulo
 * @param {function} param.onRefresh - Función que actualiza el contador del refresh
 * @param {?object} param.extraOptions - Objeto que nos va a servir para opciones como el include, o select en los datos
 * @param {?Component} param.Detail - (opcional) Componente para manejar El detalle de un item
 * @param {?object} param.filterChanges - (opcional) Sirve para cambiar nombres de los filtros aplicados al mandar a buscar, * mas detalles en el archivo "parse-request".
 * @param {?object} param.filterLabels - (opcional) Sirve para cuando el filtro no se manda de manera correcta, al mostrar el error controlado, salga con la traducción correcta
 * @param {?object} param.deleteContext - (opcional) Objeto con las propiedades a mostrar al momento de mostrar el mensaje de eliminar.
 * @param {string} deleteContext.prop - La propiedad del item que sera tomada para mostrar en el mensaje de eliminación por default se toma el atributo en orden de existencia el name > key > id.
 * @param {?string} deleteContext.detail - Breve mensaje antes del item a mostrar.
 * @param {?string} deleteContext.format - El formato que le queramos aplicar al elemento despues de obtener su valor del item.
 * @param {?function} deleteContext.customFormat Una función custom para formatear el dato que se requiera de manera mas personalizada, como parametro debe recibir on objeto(el item a eliminar) y regresar un string(el dato formateado)
 * @param {?object[]|number[]|string[]} param.selected - array con items dentro de la tabla que están seleccionados
 * @param {?"multi"|"all"|"one"} param.selectionMode - Indica el mod de selección que se va usar en la tabla
 * @param {?string} param.selectBy - Indica por que propiedad de cada row de la tabla se va a selecciionar, por default "id"
 * @param {?function} param.onRowClick - Función que se ejecuta al hacer click sobre una fila de la tabla, util en las selecciones
 * @param {?boolean} param.generalSelect - Indica se se tiene seleccionado todo, si está en true todas las filas de la tabla se mostraran como seleccionadas
 * @param {?string} param.pagerItems - Propiedad útil para cambiar el default "Elementos" de los mensajes del paginador
 * @param {?object} param.pagerDefault - Propiedad para inicializar el paginador
 * @param {?function} param.responseFormatter - (opcional) Función que sirve para formatear el response de la api antes de ser devuelto
 * @param {?number[]} param.pageSizes - Lista personalizada de elemntos por página
 * !NOTA: con este parametro la selección solo es visual y el backend al llegar este parametro debe hacer la selección de todo por temas de performance
 * @returns
 */

const GridController = ({
	Api,
	filters,
	setFilters,
	refreshCounter,
	onRefresh,
	extraOptions,
	Detail,
	onFormatFilters,
	filterChanges,
	filterLabels = {},
	deleteContext,
	selected,
	selectionMode,
	onPageSelect,
	selectBy,
	generalSelect,
	usePaginate = true,
	buttonCount,
	onEdit,
	handleDelete,
	disabled,
	rowSelected,
	validateBeefAction,
	pagerItems,
	onSelectAll,
	selectedField,
	responseFormatter,
	onSelectionChange,
	onRowClick,
	callback,
	rowSelection = true,
	onRowDoubleClick,
	pageSizes,
	pageable: isPageable,
	data: externalData,
	setData: setExternalData,
	setTotal: setExternalTotal,
	pagerDefault,
	completeItemSelection = false,
}) => {

	const initialPage = pagerDefault || paginationInitials(pageSizes);

	const DATA_ITEM_KEY = valueOrOption(selectBy, "id");
	const SELECTED_FIELD = valueOrOption(selectedField, "selected");
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const idGetter = getter(DATA_ITEM_KEY);
	const hasSelection = ["multi", "all", "one"].includes(selectionMode);
	const [page, setPage] = useState(initialPage);
	const [allSelected, setAllSelected] = useState(generalSelect ?? false);
	const [selectedState, setSelectedState] = useState({});
	const [fetch, loading] = useRequestLoad();
	const allIds = useRef([]);
	const update = useRef(false);
	const [customData, setCustomData] = useState(externalData ?? []);
	const [total, setTotal] = useState(0);
	const data = externalData ?? customData;
	const setData = isFunction(setExternalData) ? setExternalData : setCustomData;
	const allData = useRef([]);

	const selectedTrue = (state) => Object.entries(state).filter((entry) => entry[1]).map(entry => parseInt(entry[0]));
	const onlySelected = (state) => Object.entries(state).filter(el => el[1]).map(el => parseInt(el[0]));
	const getItem = (item) => selectBy ? item?.[selectBy] : item;
	const areAllSelected = () => allIds.current.every(el => selectedTrue(selectedState)?.includes(el));

	useEffect(() => {
		if (refreshCounter === 0) {
			if (size(data) > 0) {
				setData([]);
				setTotal(0);
				if (isFunction(setExternalTotal)) {
					setExternalTotal(0);
				}
			}
			return;
		}
		getData(filters, page);
		//eslint-disable-next-line
	}, [page, filters, refreshCounter]);

	useEffect(() => {
		if (selectionMode !== 'all') return;
		getAllData();
	}, [filters, refreshCounter]);

	useEffect(() => {
		if (!size(data) && page.skip && refreshCounter !== 0) {
			setPage(initialPage);
		}
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		setAllSelected(areAllSelected());
	}, [selectedState, data]);

	useEffect(() => {
		if (update.current) return;
		if (!hasSelection) {
			setSelectedState({});
			return;
		};
		initialSelection();
	}, [selectionMode, selected]);

	useEffect(() => {
		if (!update.current) return;
		updateSelection(selectedState);
	}, [selectedState]);

	const updateSelection = (state) => {
		const selecteds = onlySelected(state);
		const unique = first(selecteds);
		if (isFunction(onSelectionChange)) {
			let propValue = selecteds;
			if (completeItemSelection) {
				propValue = propValue.map(el => allData.current.find(li => idGetter(li) === el));
			}
			if (selectionMode === 'one') {
				propValue = getItem(data.find(el => idGetter(el) === unique));
			}
			onSelectionChange(propValue);
		}
		update.current = false;
	};

	/* remove action */
	const onDelete = (item) => {
		if (isFunction(validateBeefAction)) {
			if (!validateBeefAction("write")) {
				return;
			}
		}
		deleteContext = valueOrOption(deleteContext, {});
		let context = "";
		if (isFunction(deleteContext?.customFormat)) {
			context = deleteContext.customFormat(item);
		} else {
			const format = valueOrOption(deleteContext?.format, "");
			const value = item[deleteContext?.prop] ?? item.name ?? item.key ?? item.id;
			context = hasValue(format) ? formatLabel(format, value) : value;
		}

		const detail = deleteContext?.detail ? `${t(deleteContext?.detail)} ` : "";

		dispatch(showNotificationRemove({
			...deleteContext,
			...questionDelete(context, handleRemove, item),
			description: <strong>{t('question-delete-description')}</strong>,
			message: (
				<Trans i18nKey="question-delete-trans" t={t} values={{
					detail: detail,
					context: context
				}}>
					it will be eliminated {{ detail }} <strong> {{ context }} </strong>
				</Trans>
			)
		}));
	};

	const onSwitchSelectAll = useCallback(({ value }) => {
		const newSelected = { ...selectedState };
		each(allIds.current, item => {
			newSelected[item] = value;
		});
		setAllSelected(value);
		update.current = true;
		setSelectedState(newSelected);
		if (isFunction(onSelectAll)) {
			onSelectAll(value);
		}
	}, [selectedState]);

	const handleRemove = async (item) => {
		if (isFunction(handleDelete)) {
			return await handleDelete(item);
		}
		loadService({
			apiMethod: async () => await Api.destroy(item.id, {}),
			onSuccess: onSuccesRemove,
			unlockWhenFinally: false
		});
	};

	const onSuccesRemove = async () => {
		await getData(filters, page);
		showSuccessNotification(successDeleted());
	};

	/* Filter and page changes Handles */
	const onPageChange = event => setPage(event.page);
	const onFilterChange = event => {
		if (isFunction(setFilters)) {
			setFilters(event.filter);
		}
		if (isFunction(onRefresh)) {
			onRefresh();
		}
	};

	const getAllData = async () => {
		if (refreshCounter === 0) { return; }

		let extras = valueOrOption(extraOptions, {});
		if (isFunction(extraOptions)) {
			extras = extraOptions();
		}
		const allDataParams = parseRequest(
			{
				...extras,
				[`~${DATA_ITEM_KEY}s`]: idGetter(rowSelected),
				tree: true,
				fields: DATA_ITEM_KEY,
			},
			filters,
		);
		try {
			const result = await Api.get(allDataParams);
			allIds.current = result.map((item) => idGetter(item));
			const allSelected = areAllSelected() || selected?.length >= allIds.current.length;
			setAllSelected(allSelected);
		} catch (error) {
			resolveError(error);
		}
	};

	/* Data get */
	const getData = async (filters, pages) => {
		if (refreshCounter === 0) { return; }
		const payloadFilters = isFunction(onFormatFilters) ? onFormatFilters(filters) : filters;
		let extras = valueOrOption(extraOptions, {});
		if (isFunction(extraOptions)) {
			extras = extraOptions();
		}
		if (!usePaginate) {
			extras["tree"] = true;
		}

		const params = parseRequest(
			{
				...(extras ?? {})
			},
			payloadFilters,
			pages,
			filterChanges
		);
		fetch({
			api: Api.get(params),
			callback: response => {
				const data = response.results ?? response;
				const dataLength = response.count ?? size(response);
				const results = isFunction(responseFormatter) ? responseFormatter(data) : data;
				setData(results);
				setTotal(dataLength);
				if (isFunction(setExternalTotal)) {
					setExternalTotal(dataLength);
				}
				if (hasSelection && completeItemSelection) {
					allData.current = uniqBy([...allData.current, ...selected, ...results], 'id');
				}
				if (isFunction(callback)) {
					callback(results);
				}
			},
			paramLabels: filterLabels,
		});
	};

	const expandChange = useCallback(({ dataItem }) => {
		const newData = data.map((item) => {
			if (item.id === dataItem.id) {
				item.expanded = !dataItem.expanded;
			}
			return item;
		});
		setData(newData);
	}, [data]);

	const initialSelection = () => {
		const initialState = {};
		if (isArray(selected)) {
			each(selected, item => {
				const itemValue = isObject(item) ? idGetter(item) : item;
				initialState[itemValue] = true;
			});
		} else if (hasValue(selected)) {
			const value = isObject(selected) ? idGetter(selected) : selected;
			initialState[value] = true;
		}
		setSelectedState(initialState);
	};

	const onSelect = useCallback((e) => {
		if (!hasSelection || disabled) return;
		if (selectionMode !== 'one') {
			const newSelectedRecord = getSelectedState({
				event: e,
				selectedState: selectedState,
				dataItemKey: DATA_ITEM_KEY,
			});
			update.current = true;
			setSelectedState(newSelectedRecord);
		} else {
			onRowSelect(e);
		}
		if (isFunction(onRowClick)) {
			onRowClick(getItem(e.dataItem));
		}
	}, [selectedState, DATA_ITEM_KEY, selectionMode, disabled]);

	const onRowSelect = useCallback(({ dataItem }) => {
		if (!rowSelection || disabled) return;
		const newSelected = selectionMode !== "one" && hasSelection ? { ...selectedState } : {};
		let exists = newSelected[idGetter(dataItem)];
		if (isBoolean(exists)) {
			newSelected[idGetter(dataItem)] = !exists;
		} else {
			newSelected[idGetter(dataItem)] = true;
		}
		update.current = true;
		setSelectedState(newSelected);
		if (isFunction(onRowClick)) {
			onRowClick(getItem(dataItem));
		}
	}, [selectedState, selectionMode, rowSelection, disabled]);

	const rowDetail = Detail ? {
		expandField: "expanded",
		detail: Detail,
		onExpandChange: expandChange,
	} : {};

	const onSelectPage = (e) => {
		if (!hasSelection || disabled) return;
		const newSelected = { ...selectedState };
		const checkboxElement = e.syntheticEvent.target;
		const checked = checkboxElement.checked;
		each(e.dataItems, item => {
			newSelected[idGetter(item)] = checked;
		});
		update.current = true;
		setSelectedState(newSelected);
		if (isFunction(onPageSelect)) {
			onPageSelect();
		}
	};

	const pagination = !usePaginate ? {} : {
		...page,
		total: total,
		// customCounter
		pageable: isPageable === false ? isPageable : pageable(total, buttonCount, 15, pageSizes),
		onPageChange: onPageChange,
		pager: (props) => <CustomPager {...props} pagerItems={pagerItems} />
	};

	const customEdit = (item) => {
		if (isFunction(validateBeefAction)) {
			if (!validateBeefAction("write")) {
				return;
			}
		}
		onRowSelect({ dataItem: item });
		if (isFunction(onEdit)) {
			onEdit(item);
		}
	};

	const onNoAction = () => {
		dispatch(showNotificationWarning({
			title: "¡ Ooops !",
			message: t("not-allowed-actions-detail")
		}));
	};

	const handleRowClick = useCallback(
		(e) => {
			if (hasValue(rowDetail?.expandField)) {
				expandChange(e);
			}
			onRowSelect(e);
			// eslint-disable-next-line
		},
		[selectedState, DATA_ITEM_KEY, data]
	);

	const rowSelect = {
		selectedField: SELECTED_FIELD,
		onRowClick: handleRowClick,
		onRowDoubleClick: (e) => {
			if (isFunction(onRowDoubleClick)) {
				onRowDoubleClick(e);
				return;
			}
			customEdit(e.dataItem);
		},
		onSelectionChange: onSelect,
		onHeaderSelectionChange: onSelectPage,
	};

	return {
		data,
		page,
		total,
		loading,
		selectedState,
		hasSelection,
		DATA_ITEM_KEY,
		SELECTED_FIELD,
		idGetter,
		onDelete,
		onPageChange,
		onFilterChange,
		onSwitchSelectAll,
		allSelected,
		rowDetail,
		rowSelect,
		onSelectPage,
		pagination,
		customEdit,
		onNoAction,
	};
};

export default GridController;

export const getSelectRow = ({
	selectBy,
	selecteds,
	dataItem,
	selectionMode,
}) => {

	const byValue = hasValue(selectBy);
	const item = byValue ? dataItem[selectBy] : dataItem;
	if (["multi", "all"].includes(selectionMode)) {
		if (byValue) {
			if (selecteds.includes(item) || selecteds.includes(item.toString())) {
				return uniq(
					filter(selecteds, value => {
						return value !== item && value.toString() !== item.toString();
					})
				);
			}
		} else if (size(filter(selecteds, { id: item.id }))) {
			return uniq(
				filter(selecteds, value => {
					return !value.id !== item.id;
				})
			);
		}


		return uniq([...selecteds, item]);
	}

	return item;
};


/**
 * Utility for local grids
 * @param {object} params
 */
export const commandController = ({
	data,
	setData,
	originalData, /* debe ser un ref de la data original */
	idField = "id",
	alternativeId = "id",
	newField = "isNew",
	editField = "inEdit",
}) => {


	/* data operators */
	const getItemId = item => item?.[alternativeId] ?? item?.[idField];

	const deleteItem = item => {
		const identifier = getItemId(item);
		let newData = filter(data, dataItem => getItemId(dataItem) !== identifier);
		originalData.current = newData;
		setData(newData);
	};

	/* For create new item button */
	const addNew = () => {
		const newDataItem = {
			[newField]: true,
			[editField]: false,
			[idField]: `generated_id_${new Date().getTime()}`
		};
		const newData = [newDataItem, ...data];
		originalData.current = newData;
		setData(newData);
	};

	/* For each updated field in item */
	const itemChange = event => {
		const field = event.field || '';
		const identifier = getItemId(event.dataItem);
		const newData = data.map(item => getItemId(item) === identifier ? {
			...item,
			[field]: event.value
		} : item);
		setData(newData);
	};

	/* Operators for new added item */
	const add = (dataItem, dataIndex) => {
		let newData = [...data];
		dataItem[newField] = false;
		dataItem[editField] = false;
		newData[dataIndex] = dataItem;

		originalData.current = newData;
		setData(newData);
	};

	/* Operators for item in edit mode */
	const update = (dataItem, dataIndex) => {
		let newData = [...data];
		dataItem[newField] = false;
		dataItem[editField] = false;
		newData[dataIndex] = dataItem;
		originalData.current = newData;
		setData(newData);
	};

	const cancel = dataItem => {
		let identifier = getItemId(dataItem);
		const originalItem = find(originalData.current, item => getItemId(item) === identifier);
		identifier = getItemId(originalItem);
		const newData = data.map(item => {
			if (identifier !== undefined && getItemId(item) === identifier) {
				originalItem[newField] = false;
				originalItem[editField] = false;
				return originalItem;
			}
			return item;
		});
		setData(newData);
	};

	/* Operators for item not new and not editing item */
	const edit = dataItem => {
		const identifier = getItemId(dataItem);
		const newData = data.map(item => {
			item[editField] = getItemId(item) === identifier;
			return item;
		});
		originalData.current = newData;
		setData(newData);
	};

	return {
		/* For create new item button */
		addNew,
		/* For each updated field in item */
		itemChange,
		/* Operators for new added item */
		add,
		discard: deleteItem,
		/* Operators for item in edit mode */
		update,
		cancel,
		/* Operators for item not new and not editing item */
		edit,
		remove: deleteItem,
	};
};

export const useWidthsHelper = ({
	children_cols = [],
	columns = [],
	is_active = false,
	actions = false,
	CustomActionsWidth = null,
	hasSelection = false
}) => {

	const sumIfActive = (is_active = false, width = 0) => (valueOrOption(is_active, false) ? valueOrOption(width, 0) : 0);

	const colWidth = (column) => {

		const minWidth = valueOrOption(column?.props?.minWidth, column?.minWidth);
		let width = valueOrOption(column?.props?.width, column?.width);
		width = valueOrOption(minWidth, width);
		width = valueOrOption(width, 0).toString().replace("px", "");
		width = parseInt(width);

		return {
			width,
			hasWidth: width > 0
		};
	};

	const getAvailablesChilds = (columns) => {
		let colWidths = 0;
		let noVisibles = 0;
		let noWidths = 0;

		each(valueOrOption(columns, []), column => {
			const is_hidden = valueOrOption(column?.props?.hidden, column?.hidden);
			const { width, hasWidth } = colWidth(column);
			colWidths += sumIfActive(hasWidth && !is_hidden, width);
			noVisibles += sumIfActive(is_hidden, 1);
			noWidths += sumIfActive(!hasWidth, 1);
		});

		return { colWidths, noVisibles, noWidths };
	};

	const getChildrensCols = (columns) => {
		let cols = valueOrOption(columns, []);
		cols = isArray(cols) ? cols : [cols];

		if (!size(cols)) { return []; }

		let mapped = [];
		each(cols, column => {
			if (isArray(column)) {
				mapped = [...mapped, ...getChildrensCols(column)];
			} else if ("KendoReactGridColumn" === column?.type?.displayName) {
				const colProps = column.props;
				if (hasValue(colProps?.children)) {
					mapped = [...mapped, ...getChildrensCols(colProps?.children)];
				} else {
					mapped.push(colProps);
				}
			}
		});

		return mapped;
	};

	const getFixedWidths = () => {

		let fixedWidhts = 0;
		fixedWidhts += sumIfActive(is_active, 120);
		fixedWidhts += sumIfActive(actions, valueOrOption(CustomActionsWidth, 100));
		fixedWidhts += sumIfActive(hasSelection, 50);

		const { colWidths: columsColWidths, noWidths: columsNoWidths } = getAvailablesChilds(columns);
		const { colWidths: childColWidths, noWidths: childNoWidths } = getAvailablesChilds(getChildrensCols(children_cols));

		fixedWidhts += columsColWidths + childColWidths;

		return { fixedWidhts, noWidths: (columsNoWidths + childNoWidths) };
	};

	const getStyle = (style, containerWidth, divWidth) => {
		const { fixedWidhts, noWidths } = getFixedWidths();
		divWidth = valueOrOption(divWidth, 0);
		containerWidth = valueOrOption(containerWidth, 0);

		let tableWidth = '';
		if (containerWidth === 0 && divWidth === 0) {
			const windowsContainer = document.getElementById("windows-container")?.clientWidth;
			tableWidth = valueOrOption(windowsContainer, 1500) - 40;
		} else {
			const values = [divWidth, containerWidth];
			tableWidth = Math.abs(divWidth - containerWidth) < 35 ? min(values) : max(values);
		}

		const cols = valueOrOption(noWidths, 0) <= 0 ? 1 : noWidths;
		const finalWidth = (tableWidth - fixedWidhts) / cols;
		const colWidths = clamp(finalWidth, 100, 800);

		return {
			...style,
			"--fixed-widths": `${fixedWidhts}px`,
			"--columns-widths": colWidths > 100 ? "auto" : `${colWidths}px`,
			"--grid-width": `${tableWidth}`,
		};
	};


	const getWidths = (gridRef, divRef) => {
		const tableElement = gridRef?.current?.tableElement;
		const divWidth = divRef?.current?.clientWidth;
		const containerWidth = valueOrOption(tableElement?.offsetParent?.clientWidth, tableElement?.offsetWidth);

		return { divWidth, containerWidth };
	};

	const loadStyles = (style, gridRef, divRef, callback) => {
		const { divWidth, containerWidth } = getWidths(gridRef, divRef);
		setTimeout(() => {
			const newStyle = getStyle(style, containerWidth, divWidth);
			callback(newStyle);
		}, 500);
	};

	return {
		loadStyles,
		getWidths,
	};
};
