import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-light-svg-icons";
import "./styles.scss";
import SearchInput from '../../../../../App/components/search/Input';
import SwitchMui from '../../../../../App/components/Booleans/SwitchMui';
import { usePackages } from '../../usePackages';
import useBarList from './useBarList';
import { isAbrhil } from '../../../../common/auth';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { iconsModule, searchLabel } from '../../util';
import { CustomTooltip } from '../../../../../App/components/Templates/cells';

function Index() {
  const [height, setHeight] = useState(0);
  const { selectPackage, handleEditPackage, selectDefault, selectChill } = usePackages()
  const {
    filterData,
    handleSelectCategory,
    search,
    debounceSearch,
    setSearch,
    setLoad,
    load,
    refreshList,
    backTabs,
    selectTab,
    chill
  } = useBarList()

  const STYLE_LIST = {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  }

  if (selectDefault) {
    STYLE_LIST.height = "calc(100vh - 216px)"
  }

  useEffect(() => {
    const element = document.getElementsByClassName('k-window-content')[0];

    if (!element) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);
  const Icon = chill ? iconsModule.BACK : selectPackage?.icon

  function ViewList() {
    if (filterData.chill) {
      return filterData?.classifications?.map((m, i) => <div key={i}>
        <ListSubItems
          m={m}
          search={search}
          selectTab={selectTab}
        />
      </div>
      )
    } else {
      return filterData?.classifications?.map((m, i) => <div key={i}>
        <ListView
          m={m}
          handleSelectCategory={handleSelectCategory}
          search={search} />
      </div>
      )
    }
  }

  return (
    <div id="bar-list">
      <div className='container'>
        <div className='item-select'>
          {(selectPackage?.icon && !selectChill)&& <div>
            <Icon width="25" height="25" style={chill ? { cursor: "pointer" } : {}} onClick={backTabs} />
          </div>}
          <div className={`${searchLabel(selectPackage?.alias) ? '':'capitalize-first-letter'}`}>
            {chill ? chill.alias.toUpperCase() : selectPackage?.alias.toUpperCase()}
          </div>
          {chill ? (isAbrhil() && chill?.editable) && <SwitchMui
            checked={chill?.value}
            size="small"
            onChange={(e) => handleEditPackage("value", e.value, chill)}
          /> : (isAbrhil() && selectPackage?.editable) && <SwitchMui
            checked={selectPackage?.value}
            size="small"
            onChange={(e) => handleEditPackage("value", e.value, selectPackage)}
          />}
        </div>
        <div>
          <SearchInput
            label={<div className='capitalize-first-letter'>Buscar paquetes</div>}
            className={"focused-table-input upper-text"}
            name={`conceptKey_cell_field`}
            value={search}
            onChange={(e) => {
              setLoad(true)
              setSearch(e.value)
              debounceSearch(e)
            }}
          />
        </div>
        {!refreshList && <div style={{ height: height - 185, ...STYLE_LIST }}>
          {!load && ViewList()}
        </div>}
      </div>
    </div>
  )
}

export default Index

function ListSubItems({ m, selectTab, chill, handleSelectCategory, search }) {
  const { handleEditPackage } = usePackages()

  if (chill) return <ListView m={m} handleSelectCategory={handleSelectCategory} search={search} />

  const ITEM_LIST = {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    alignItems: "center",
    border: "0px",
    background: "var(--backgroundText, white)"
  }

  return <div>
    <button className='title-sub item-list' style={ITEM_LIST} onClick={(e) => selectTab(e, m)}>
      <div className='capitalize-first-letter'>
        {m.alias.toUpperCase()}
      </div>
      <SwitchMui
        size="small"
        checked={m?.value}
        onChange={(e) => handleEditPackage("value", e.value, m)}
      />
    </button>
  </div>
}


function ListView({ 
  m, 
  handleSelectCategory, 
  search 
}) {
  
  const { selectCategory, classifications } = usePackages()
  const [open, setOpen] = useState(classifications?.id === m?.id);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (search) {
      setOpen(true)
    } else {
      setOpen(classifications?.id === m.id)
    }
  }, [search])


  const ITEM_LIST = {
    padding: "5px 3px",
    display: "flex",
    justifyContent: "space-between"
  }
  return (
    <div>
      <ListItemButton onClick={handleClick} style={ITEM_LIST}>
        <div className={`${searchLabel(m.alias) ? '':'capitalize-first-letter'} title-sub`} style={classifications?.id === m.id ? { textDecoration: "underline" } : {}}>
          {m.alias.toUpperCase()}
        </div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div>
          {m?.categories?.map((item, i) => <button
            key={i}
            className={`title-chill ${(selectCategory?.id === item.id && classifications?.id === m.id) ? 'title-chill-select' : ''}`}
            onClick={() => handleSelectCategory(item, m)}>
            <FontAwesomeIcon icon={faGear} />
            <CustomTooltip title={item.alias.toUpperCase()} >
              <div className={`${searchLabel(item.alias) ? '':'capitalize-first-letter'} label-items`}>
                {item.alias.toUpperCase()}
              </div>
            </CustomTooltip>
          </button>)}
        </div>
      </Collapse>
    </div>
  );
}
ListView.propTypes = {
  handleSelectCategory: PropTypes.func,
  m: PropTypes.any,
  search: PropTypes.any
}
