import React from "react";
import { useSelector, useDispatch } from "react-redux";
//--project imports
import { hideNotificationDialog } from '../../../store/actions';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import { StatusSkeleton, Transition } from "./DialogSkeleton";


const InfoNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		title,
		message,
		description,
		maxWidth,
		onConfirm,
		...others
	} = useSelector(state => state.notification.dialogNotification);

	const handleClose = () => {
		dispatch(hideNotificationDialog());
		if (typeof onConfirm === "function") {
			onConfirm();
		}
	}

	return (
		<Dialog
			open={show}
			TransitionComponent={Transition}
			className="myDialog infoDialog"
			maxWidth={maxWidth ?? "lg"}
			onClose={handleClose}
		>
			<StatusSkeleton
				lottieIcon={"info-icon"}
				title={title || t("general-information")}
				message={message || ""}
				description={description || ""}
				close={handleClose}
				{...others}
			/>
		</Dialog>
	);
};


export default InfoNotification;