import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
	centerCrop,
	makeAspectCrop
} from 'react-image-crop';
/* material-ui */
import Grid from "@mui/material/Grid";
/* Kendo */
import { Upload } from "@progress/kendo-react-upload";
/* components */
import Button from '../../styled/Button';
import DialogTitle from '../../styled/DialogTitle';
import Dialog from '../../styled/Dialog';
import DialogContent from '../../styled/DialogContent';
import DialogActions from '../../styled/DialogActions';
import PhotoInputWarning from '../photo/PhotoInputWarning';
import { resolveError } from '../../common/resolve-error';
import { scanFile } from '../../services/ServerAntivirus';
import { uid } from 'uid';
import { CircularProgress, Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowsRotate, faTrash } from '@fortawesome/pro-light-svg-icons';
import BaseButton from '../../../App/components/Buttons/BaseButton';
import { isFunction } from 'lodash';
import "./style.scss";
import { hasValue } from '../../common/GeneralUtilities';
import MuiAlert from '../general/MuiAlert';

/**
 * Hook que nos va a servir como Input para las fotos
 * @param {object} params
 * @param {boolean} params.open - Inidca se el modal el input está abierto
 * @param {function} params.close - Función que cierra el modal de input
 * @param {function} params.setSrcImage - Función que setea el valor url de la imagen
 * @param {function} params.setFileImage - Funcón que setea la imagen tipo file
 * @param {?string}  params.imageName - Nombre custom de la imagen a subir con formato foto.jpg
 * @param {?object} params.cropProps - Atributos custom para el crop de la imagen
 * @returns {PhotoUploadComponent}
 */
const PhotoUpload = ({
	open,
	close,
	photo,
	onSubmit,
	setSrcImage,
	setFileImage,
	imageName,
	...otherProps
}) => {

	const { t } = useTranslation();
	const base64Image = useRef(photo);
	const upImageBackup = useRef(photo);
	const previewCanvasRef = useRef(null);
	const [upImg, setUpImg] = useState(photo);
	const [completedCrop, setCompletedCrop] = useState(true);
	const [extensionFile, setExtensionFile] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		upImageBackup.current = photo;
		setUpImg(photo);
		const ext = getExtension();
		setExtensionFile(ext ? `.${getExtension()}` : null);
	}, [photo])

	const getExtension = () => {
		const regex = /(?:\.([^.]+))?$/;
		const match = regex.exec(photo);
		return match[1];
	}

	const closeModal = () => {
		setExtensionFile(null);
		setCompletedCrop(false);
		setLoading(false);
		close();
	};

	const savePhoto = () => {
		setLoading(true);
		if (base64Image.current) {
			const realExt = extensionFile?.replace(/\./g, '') ?? 'png';
			const imageInfo = `image/${realExt}`;
			const urlFile = base64Image.current.toDataURL(imageInfo, .5);
			urltoFile(urlFile, (`${imageName || uid()}.${realExt}`), imageInfo)
				.then(file => {
					setFileImage(file);
					setSrcImage(urlFile);
					if (isFunction(onSubmit)) {
						onSubmit(file, urlFile);
					}
					closeModal();
				});
			return;
		}
		setFileImage(null);
		setSrcImage(null);
		if (isFunction(onSubmit)) {
			onSubmit(null, null);
		}
		closeModal();
	};

	const onAdd = (event) => {
		const files = event.affectedFiles[0];
		setExtensionFile(files.extension);
		if ((files?.validationErrors || []).length > 0) {
			setUpImg(null);
			setCompletedCrop(false);
			return;
		}
		const reader = new FileReader();
		reader.addEventListener('load', async () => {
			try {
				await scanFile(await urltoFile(reader.result))
				setUpImg(reader.result)
			} catch (error) {
				resolveError(error)
				setUpImg(null)
			}
		});
		reader.readAsDataURL(files.getRawFile());
	};

	const onRemove = () => {
		const canvas = previewCanvasRef.current;
		const ctx = canvas.getContext('2d');
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		base64Image.current = null;
		setUpImg(null);
	};

	const handleCancel = () => {
		photo = upImageBackup.current;
		setUpImg(upImageBackup.current);
		closeModal();
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth='sm'
			open={open}
			style={{ width: '650px', margin: 'auto' }}
			className='modern-dialog-design'
			aria-labelledby="photo-selection-upload"
		>
			<DialogTitle title={t("upload-image")} onClose={handleCancel} />

			<DialogContent dividers >
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<ImageCropPreview
							{...otherProps}
							upImg={upImg}
							onAdd={onAdd}
							onRemove={onRemove}
							previewCanvasRef={previewCanvasRef}
							extensionFile={extensionFile}
							setCompletedCrop={setCompletedCrop}
							base64Image={base64Image}
						/>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button
					design="text"
					type="button"
					onClick={handleCancel}
				>
					{t('cancel')}
				</Button>
				<Button
					design="contained"
					type="submit"
					disabled={!completedCrop}
					onClick={savePhoto}
					style={{ minWidth: '76px' }}
				>
					{!loading ? t('save') : <CircularProgress className='button-loader' />}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

PhotoUpload.propTypes = {
	close: PropTypes.func,
	imageName: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.any,
	photo: PropTypes.any,
	setFileImage: PropTypes.func,
	setSrcImage: PropTypes.func
}

export default PhotoUpload;

export const urltoFile = (url, filename, mimeType) => {
	return (fetch(url)
		.then(res => { return res.arrayBuffer(); })
		.then(buf => { return new File([buf], filename, { type: mimeType }); })
	);
};

export const initialCrop = { unit: 'px', x: 0, y: 0, width: 100, height: 100 };

const ImageCropPreview = ({
	upImg,
	aspect,
	onAdd,
	onRemove,
	extensionFile,
	setCompletedCrop,
	previewCanvasRef,
	base64Image,
	customMessage = ''
}) => {

	const { t } = useTranslation();

	const imgRef = useRef(null);
	const [crop, setCrop] = useState(initialCrop);
	const allowedExtensions = [".jpg", ".png", ".jpeg", ".webp"];

	const onCompleteCrop = (PixelCrop) => {
		if (!PixelCrop || !previewCanvasRef.current || !imgRef.current) {
			setCompletedCrop(false);
			return;
		}

		const image = imgRef.current;
		const canvas = previewCanvasRef.current;
		const crop = PixelCrop;

		const ctx = canvas.getContext('2d');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const pixelRatio = window.devicePixelRatio;
		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = 'medium';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);

		base64Image.current = canvas;
		setCompletedCrop(true);
	};

	const notUndefined = (size) => {
		if ([null, undefined, ""].includes(size)) {
			return 50;
		}
		return size;
	};

	const onImageLoad = (e) => {
		const { width, height } = e.currentTarget;
		const initial = centerAspectCrop(notUndefined(width), notUndefined(height), aspect);
		setCrop(initial);
		onCompleteCrop(initial);
	};

	const centerAspectCrop = (mediaWidth, mediaHeight, initialAspect) => {
		if (!mediaWidth || !mediaHeight) { return; }
		initialAspect = initialAspect ?? 16 / 9;
		const makedspect = makeAspectCrop(
			{
				...initialCrop,
				width: mediaWidth,
				height: mediaHeight,
			},
			initialAspect,
			mediaWidth,
			mediaHeight,
		);
		return centerCrop(makedspect, mediaWidth, mediaHeight);
	};

	return (
		<Grid item container xs={12} spacing={2}>
			<Grid item xs={12} md={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{
					border: '3px solid var(--lighterBlueGradient)',
					borderRadius: '50%',
					overflow: 'hidden',
					width: '150px',
					height: '150px',
					boxShadow: 'var(--shadow) 0px 4px 6px -1px, var(--shadow) 0px 2px 4px -1px',
				}}>
					<canvas
						className='upload-component-preview'
						ref={previewCanvasRef}
						style={{
							width: "100%",
							height: "100%",
							objectFit: 'cover'
						}}
					/>
				</div>
			</Grid>
			<Grid item container xs={12} md={7} style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
				<Collapse in={!!upImg}>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<ReactCrop
							crop={crop}
							onChange={(pixelCrop) => setCrop(pixelCrop)}
							onComplete={onCompleteCrop}
							aspect={aspect}
							minWidth={100}
							minHeight={100}
						>
							<img
								ref={imgRef}
								crossOrigin="anonymous"
								alt={t("crop-me")}
								className='upload-component-crop'
								src={upImg}
								onLoad={onImageLoad}
								style={{ maxHeight: '200px' }}
							/>
						</ReactCrop>
					</Grid>
				</Collapse>
				<Grid item container xs={12} style={{ display: 'flex', gap: '5px', marginTop: !upImg ? '-70px' : 0, transition: 'all 300ms' }}>
					<Grid item container spacing={2} xs={12} sm={12} md={12} style={{ display: 'flex', height: 'auto' }}>
						<Grid item xs={6} style={{ display: 'flex', height: 'auto' }}>
							<Upload
								className='custom-upload-component'
								batch={true}
								multiple={false}
								restrictions={{ allowedExtensions }}
								onAdd={onAdd}
								onRemove={onRemove}
								autoUpload={false}
								showActionButtons={false}
								selectMessageUI={() => <UploadButton text={`${upImg ? 'Cambiar' : 'Subir'} foto`} upImg={upImg} />}
							/>
						</Grid>
						<Grid item xs={6} style={{ display: 'flex', height: 'auto' }}>
							<Remove onRemove={onRemove} upImg={upImg} />
						</Grid>
					</Grid>
					<Collapse in={!upImg} style={{ width: '100%' }}>
						<Grid item xs={12}>
							<span className='custom-upload-sizes'>Medidas recomendadas 1024px por 1024px</span>
						</Grid>
						{hasValue(customMessage) && <Grid item xs={12}>
							<MuiAlert severity="info" >
								{customMessage}
							</MuiAlert>
						</Grid>
						}
					</Collapse>
					<PhotoInputWarning
						extensionFile={extensionFile}
						allowedExtensions={allowedExtensions}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

ImageCropPreview.propTypes = {
	aspect: PropTypes.any,
	base64Image: PropTypes.any,
	customMessage: PropTypes.string,
	extensionFile: PropTypes.any,
	onAdd: PropTypes.any,
	onRemove: PropTypes.any,
	previewCanvasRef: PropTypes.any,
	setCompletedCrop: PropTypes.func,
	upImg: PropTypes.any
}

const UploadButton = ({ text, upImg }) => {

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '10px'
			}}
		>
			<FontAwesomeIcon icon={!upImg ? faArrowUp : faArrowsRotate} />
			<span>{text}</span>
		</div>
	);
}

UploadButton.propTypes = {
	text: PropTypes.any,
	upImg: PropTypes.any
}

const Remove = ({ onRemove, upImg }) => {

	return (
		<BaseButton
			icon={faTrash}
			label={'Eliminar foto'}
			onClick={onRemove}
			disabled={!upImg}
			className='custom-upload-remove'
		/>
	);
}
Remove.propTypes = {
	onRemove: PropTypes.any,
	upImg: PropTypes.any
}
