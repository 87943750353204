import PropTypes from "prop-types";
import React from 'react';

/* library components */
import Grid1 from '@mui/material/Grid';
import Grid2 from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { hasValue } from '../../common/GeneralUtilities';

const SubTitle = ({
	title,
	children,
	blueTitle = true,
	className = "",
	startElement = null,
	separator = false,
	asDivider = false,
	titleStyle = {},
	asV2 = false,
	...others
}) => {

	const Grid = asV2 ? Grid2 : Grid1;
	const baseSize = asV2 ? { size: 12 } : { item: true, xs: 12 };
	const autoSize = asV2 ? { size: "auto" } : { item: true, xs: "auto" };
	const coverSize = asV2 ? { size: "grow" } : { item: true, xs: true };

	if (asDivider) {
		return (
			<Grid {...baseSize} {...others} className={`${className} abrhil-subtitle ${separator ? "separator" : ""}`} >
				<Grid container spacing={1} alignItems='center'>
					<Grid {...autoSize}>
						<Typography
							variant="subtitle1"
							gutterBottom
							component="span"
						>
							{title}
						</Typography>
					</Grid>
					{hasValue(startElement) && (
						<Grid {...autoSize}>
							{startElement}
						</Grid>
					)}
					<Grid {...coverSize}>
						<div className='subtitle-middle-divider'>
						</div>
					</Grid>
					{hasValue(children) && (
						<Grid {...autoSize}>
							{children}
						</Grid>)
					}
				</Grid>
			</Grid>);
	}

	return (
		<Grid {...baseSize} {...others} className={`${className} abrhil-subtitle ${separator ? "separator" : ""}`} >
			<Typography
				variant="subtitle1"
				gutterBottom
				component="span"
				sx={titleStyle}
				className={!blueTitle ? 'black-title' : ''}
			>
				{title}
			</Typography>
			{children}
		</Grid>
	);
};

SubTitle.propTypes = {
	asDivider: PropTypes.bool,
	blueTitle: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
	separator: PropTypes.bool,
	startElement: PropTypes.any,
	title: PropTypes.string,
	titleStyle: PropTypes.object,
	asV2: PropTypes.bool,
};

export default SubTitle;