import React from 'react'
import Grid from '@mui/material/Grid';
import "./styles.scss";
import BarList from "./BarList"
import Configurations from "./Configurations"

function index() {
  return (
    <div id="p-container">
      <Grid container className="p-container">
        <Grid item xs={2.5}>
          <BarList />
        </Grid>
        <Grid item xs={9.5}>
          <Configurations />
        </Grid>
      </Grid>
    </div>
  )
}

export default index