import { t } from "i18next";
import * as yup from "yup";
import arrayValidation from "../../../common/validations/array";
import { validateNumeric } from "../../../common/validations/numeric";
import { isArray, size } from "lodash";
import emailValidation from "../../../common/validations/emailValidation";
import { stringValidation } from "../../../common/validations/stringValidation";
import { hasValue } from "../../../common/GeneralUtilities";

const passwordValidation = (id, password) => {
    return [null, undefined, 0, ""].includes(id) || hasValue(password);
};

function validateTestPassword(edit, value, regex) {
    if (edit && !value) {
        return true;
    }
    return regex.test(value);
}

export const passwordSchema = (forEdit = false, isSSO = false) => {
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasDigit = /\d/;
    const hasSpecialChar = /[^A-Za-z0-9]/;

    let baseSchema = yup.string().transform((curr, orig) => (orig === "" ? null : curr))

    if (isSSO) {
        return baseSchema.nullable();
    }
    if (forEdit) {
        baseSchema = baseSchema.nullable()
    } else {
        baseSchema = baseSchema.required(t('validation:required'))
    }

    return (
        baseSchema
            .min(8, t('validation:min.string', { min: 8 }))
            .max(50, t('validation:max.string', { max: 50 }))
            .test('has-uppercase', 'Falta al menos una letra mayúscula', value =>
                validateTestPassword(forEdit, value, hasUpperCase)
            )
            .test('has-lowercase', 'Falta al menos una letra minúscula', value =>
                validateTestPassword(forEdit, value, hasLowerCase)
            )
            .test('has-digit', 'Falta al menos un dígito', value =>
                validateTestPassword(forEdit, value, hasDigit)
            )
            .test('has-special-char', 'Falta al menos un carácter especial', value =>
                validateTestPassword(forEdit, value, hasSpecialChar)
            )
    )
}

const emailSchema = (loginMode) => {
    const requiredEmail = [1, 2].includes(loginMode);

    return emailValidation(requiredEmail)
        .matches(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/, t('validation:valid-email-domain'))
        .test(
            'email-error',
            t('email-already-exists'),
            (value, context) => {
                if (value !== "") {
                    return value !== context.parent.existingEmail;
                }
                return true;
            },
        );
}

const usernameValidation = (loginMode) => {
    const isSSO = loginMode === 2;
    return stringValidation({
        required: loginMode !== 1 && !isSSO,
        max: 50,
        type: "alphanumericPC",
    }).test(
        'username-error',
        t('password-similar-to-user'),
        (value, context) => {
            if (value !== null) {
                return !(context.parent.password ?? '').toLowerCase().includes((value ?? '').toLowerCase())
            } else {
                return true;
            }
        },
    ).test(
        'username-existing',
        t('username-already-exists'),
        (value, context) => {
            if (value !== "") {
                return value !== context.parent.existingUser;
            }
            return true;
        }
    )
}

const groupPermShape = yup.object().shape({
    group: yup.object().shape({
        id: validateNumeric({ required: true }),
        config: yup.object().shape({
            payroll_social: yup.boolean(),
        }),
    }),
    branches_employer: arrayValidation({ required: false })
        .when('structure_data', {
            is: (value) => !size(value) && isArray(value),
            then: () => arrayValidation({ required: false }),
        }),
    rolePerms: arrayValidation({ required: true, min: 1 }),
    // dashboard: yup.object().shape({
    //     element_id: validateNumeric({ required: true }),
    // }),
});

export const ValidationSchemaNew = (attendance, loginMode) => {
    const isSSO = loginMode === 2;
    const requiredEmail = [1, 2].includes(loginMode);

    const step2Val = !attendance ? yup.object().shape({
        payroll_permissions: arrayValidation({ required: false }).of(
            yup.object().shape({
                payroll_access: validateNumeric({ required: false }),
                calculate_permissions: arrayValidation({ required: false }),
            })
        ),
    })
        :
        yup.object().shape({
            admin_workgroups: arrayValidation({ required: false })
                .nullable()
                .when(["attendance", "all_workgroups"], {
                    is: (attendance, workgroups) => attendance === true && workgroups !== true,
                    then: () => arrayValidation({ required: false }),
                })
        });

    const step1Val = !attendance ? yup.object().shape({
        groups_perms: arrayValidation({ required: true, min: 1 }).of(groupPermShape)
            .when("attendance", {
                is: (attendance) => attendance === true,
                then: () => arrayValidation({ required: false }),
            }),
    })
        :
        yup.object().shape({
            extra_perms: yup.object(),
        })

    return {
        0: yup.object().shape(
            {
                username: usernameValidation(loginMode),
                email: emailValidation(requiredEmail)
                    .when({
                        is: (value) => value && value.length > 0,
                        then: () => emailSchema(loginMode),
                    }),
                password: passwordSchema(false, isSSO).when("username", {
                    is: (username) => !!username,
                    then: () => passwordSchema(false, isSSO)
                        .test(
                            'password-error',
                            t('password-similar-to-user'),
                            (value, context) => !(value ?? '').includes((context.parent.username ?? '').toLowerCase())
                        ),
                }),
                password2: passwordSchema(false, isSSO)
                    .oneOf([yup.ref("password"), null], t("password-not-match"))
                    .when("username", {
                        is: (username) => !!username,
                        then: () => passwordSchema(false, isSSO)
                            .oneOf([yup.ref("password"), null], t("password-not-match"))
                            .test(
                                'password2-error',
                                t('password-similar-to-user'),
                                (value, context) => !(value ?? '').includes((context.parent.username ?? '').toLowerCase())
                            ),
                    }),
                external_name: yup.string().nullable().when("internal_user", {
                    is: false,
                    then: () => stringValidation({ required: true, max: 100 }),
                }),
                worker: yup.object().nullable().when("internal_user", {
                    is: true,
                    then: () => yup.object().shape({
                        id: validateNumeric({ required: true }),
                        key: stringValidation({ required: true, max: 150, type: "alphanumericKey" }),
                    })
                }),
            },
            [
                ["password", "password2"],
            ]
        ),
        1: step1Val,
        2: step2Val,
        3: yup.object().shape({
            extra_perms: yup.object(),
        }),
        4: yup.object().shape({
            cancel_moper_permissions: arrayValidation({ required: false }),
        }),
        5: yup.object().shape(
            {
                tenants: yup.array().of(
                    yup.object().shape({
                        id: validateNumeric({ required: true }),
                    })
                ).when("attendance", {
                    is: (attendance) => attendance === true,
                    then: () => arrayValidation({ required: false }),
                }),
            },
        ),
    }
};

export const ValidationSchemaEdit = (loginMode) => {
    const isSSO = loginMode === 2;
    const requiredEmail = [1, 2].includes(loginMode);

    return yup.object().shape(
        {
            username: usernameValidation(loginMode),
            email: emailValidation(requiredEmail)
                .when({
                    is: (value) => value && value.length > 0,
                    then: () => emailSchema(loginMode),
                }),
            password: passwordSchema(true, isSSO)
                .when(["id", "password2"], {
                    is: (id, password2) => passwordValidation(id, password2) === true,
                    then: (schema) => schema.required(t("validation:required")),
                })
                .when("username", {
                    is: (username) => !!username,
                    then: () => passwordSchema(true, isSSO)
                        .test(
                            'password-error',
                            t('password-similar-to-user'),
                            (value, context) => !(value ?? '').includes((context.parent.username ?? '').toLowerCase())
                        ),
                }),
            password2: passwordSchema(true, isSSO)
                .oneOf([yup.ref("password"), null], t("password-not-match"))
                .when(["id", "password"], {
                    is: (id, password) => passwordValidation(id, password) === true,
                    then: (schema) => schema.required(t("validation:required")),
                }).when("username", {
                    is: (username) => !!username,
                    then: () => passwordSchema(true, isSSO)
                        .oneOf([yup.ref("password"), null], t("password-not-match"))
                        .test(
                            'password2-error',
                            t('password-similar-to-user'),
                            (value, context) => !(value ?? '').includes((context.parent.username ?? '').toLowerCase())
                        ),
                }),
            external_name: yup.string().nullable().when("internal_user", {
                is: false,
                then: () => stringValidation({ required: true, max: 100 }),
            }),
            worker: yup.object().nullable().when("internal_user", {
                is: true,
                then: () => yup.object().shape({
                    id: validateNumeric({ required: true }),
                    key: stringValidation({ required: true, max: 150, type: "alphanumericKey" }),
                })
            }),
            configs: yup.object(),
            payroll_permissions: arrayValidation({ required: false }).nullable(),
            groups_perms: arrayValidation({ required: true, min: 1 }).of(groupPermShape)
                .when(["attendance", "is_superuser"], {
                    is: (attendance, superuser) => attendance === true || superuser === true,
                    then: () => arrayValidation({ required: false }).of(groupPermShape),
                }),
            admin_workgroups: arrayValidation({ required: false })
                .nullable()
                .when(["attendance", "all_workgroups"], {
                    is: (attendance, workgroups) => attendance === true && workgroups !== true,
                    then: () => arrayValidation({ required: false }),
                }),
            cancel_moper_permissions: arrayValidation({ required: false }),
            tenants: yup.array().of(
                yup.object().shape({
                    id: validateNumeric({ required: true }),
                })
            ).when("attendance", {
                is: (attendance) => attendance === true,
                then: () => arrayValidation({ required: false }),
            }),
        },
        [
            ["password", "password2"],
        ]
    )
};