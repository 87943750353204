import React, { useContext } from 'react'
// import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import FormInput from '../../../@components/form/Field';
import { ModuleContext } from '../util';

function DescriptionFooter() {
  // const { t } = useTranslation();

  const { control } = useContext(ModuleContext);
  return (
    <Grid className="mb-3">
      <Grid className="card-title p-2 pt-3 pb-3">
        Descripción pie de pagina
      </Grid>
      <Grid className="card-container p-2">
        <FormInput
          fieldInput="textarea"
          control={control}
          name="footer_description"
          rows={9}
          maxLength={1501}
          style={{
            minHeight: '220px'
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DescriptionFooter