import PropTypes from "prop-types"
import React, { useContext, createContext, useEffect, useState } from "react";
import { implementService } from "../../../../../services/implemet-service";
import { parseRequest } from "../../../../../common/parse-request";
import { ServerAPI } from "../../../../../services/ServerAPI";
import { usePackages } from "../../../usePackages";

const usePopUp = () => {
  const { tenant } = usePackages()

  const [periodsData, setPeriodsData] = useState([]);

  const getCurrentPeriods = async () => {
    const params = parseRequest({
      include: 'payroll_type',
      tenant,
      omit_predetermined: true
    })
    await implementService(ServerAPI(`payroll-periods/get-current-periods`, false).get(params),
      response => {
        const listData = response?.filter(element => element?.list);
        setPeriodsData(listData);
      });
  };

  useEffect(() => {
    getCurrentPeriods();
  }, []);

  return {
    periodsData,
  };
};

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => (

  <ModalContext.Provider value={usePopUp()}>
    {children}
  </ModalContext.Provider>

);

ModalProvider.propTypes = {
  children: PropTypes.any
}

export const usePopUpController = () => useContext(ModalContext);

export default usePopUp;
