/* React tools */
import PropTypes from "prop-types"
import React, { useState, Fragment, useEffect, useRef } from 'react';

/* Language */
import { useTranslation } from 'react-i18next';

/* Libraries Components */
import Grid from "@mui/material/Grid2";
import { format } from 'date-fns';
import { uid } from 'uid';

/* Own components */
import KendoDatePicker from "./KendoDatePicker";
import { pick } from 'lodash';

const initialValids = {
	start: true,
	start_message: "",
	end: true,
	end_message: ""
};

const KendoDateRange = ({
	size,
	name,
	colSize,
	startValue,
	endValue,
	startProps,
	endProps,
	colProps,
	onChange,
	isRequired,
	showValidationMessages,
	disabled
	// byName
}) => {

	const { t } = useTranslation();
	const uniqueId = uid();
	const [values, setValues] = useState({ start: null, end: null });
	const datesValid = useRef(initialValids);

	useEffect(() => {
		datesValid.current = validate(date(startValue, true), date(endValue, true), "start", isRequired);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setValues({
			start: startValue,
			end: endValue
		});
		// eslint-disable-next-line
	}, [startValue, endValue]);

	const changeDate = (e) => {
		const valueSeted = date(e.value, true);
		const toDate = [`start_${uniqueId}`, startProps?.id].includes(e.target.props.id) ? "start" : "end";
		const startV = toDate === "start" ? valueSeted : date(values.start, true);
		const endV = toDate === "start" ? date(values.end, true) : valueSeted;

		datesValid.current = validate(startV, endV, toDate, isRequired);

		const newValues = { ...values, [toDate]: valueSeted };
		setValues(newValues);
		onChange({ value: valueSeted, fieldChanged: toDate, values: newValues });
	};

	const commonProps = {
		onChange: changeDate,
		size: (size || "small"),
	};

	const sizes = { md: colSize || 6, ...pick(colProps, ["xs", "sm", "md", "lg", "xl",]), ...colProps?.size };

	return (
		<Fragment>
			<Grid {...colProps} size={sizes}>
				<KendoDatePicker
					id={`start_${uniqueId}`}
					name={`start_${name || uniqueId}`}
					label={t("initial-date")}
					{...startProps}
					value={date(values.start)}
					disabled={disabled}
					valid={datesValid.current?.start}
					{...commonProps}
				/>
				{
					!datesValid.current.start && showValidationMessages &&
					<p className="form-error">{t(datesValid.current.start_message)}</p>
				}
			</Grid>
			<Grid {...colProps} size={sizes}>
				<KendoDatePicker
					id={`end_${uniqueId}`}
					name={`end_${name || uniqueId}`}
					label={t("final-date")}
					{...endProps}
					value={date(values.end)}
					valid={datesValid.current?.end}
					{...commonProps}
					disabled={disabled}
				/>
				{
					!datesValid.current.end && showValidationMessages &&
					<p className="form-error">{t(datesValid.current.end_message)}</p>
				}
			</Grid>
		</Fragment>
	);
};

KendoDateRange.propTypes = {
	colProps: PropTypes.any,
	colSize: PropTypes.any,
	disabled: PropTypes.boolean,
	endProps: PropTypes.any,
	endValue: PropTypes.any,
	isRequired: PropTypes.any,
	name: PropTypes.any,
	onChange: PropTypes.func,
	showValidationMessages: PropTypes.any,
	size: PropTypes.string,
	startProps: PropTypes.any,
	startValue: PropTypes.any
}

export default KendoDateRange;

const date = (value, transform) => {
	if (!value) {
		return null;
	}

	return transform ? format(new Date(value), 'yyyy-MM-dd') : new Date(value);
};

const validate = (start, end, changeField, isRequired) => {
	const validity = () => {
		if (!start) { return 2; }
		if (!end) { return 3; }
		return 4;
	};
	/* 1-> ambos false, 2-> inicio false, 3-> final false, 4-> ambos true*/
	const falseFor = (!start && !end) ? 1 : validity();

	switch (falseFor) {
		case 1:
			return {
				...initialValids,
				start: (!isRequired || false),
				end: (!isRequired || false),
				start_message: 'date-required',
				end_message: 'date-required'
			};
		case 2:
			return {
				...initialValids,
				start: (!isRequired || false),
				start_message: 'date-required'
			};
		case 3:
			return {
				...initialValids,
				end: (!isRequired || false),
				end_message: 'date-required'
			};
		default:
			return {
				...initialValids,
				[changeField]: new Date(`${end} 00:00`) >= new Date(`${start} 00:00`),
				[`${changeField}_message`]: (changeField === "start" ? "start-over-end" : "end-under-start")
			};
	}
};