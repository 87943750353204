import { each, isFunction } from "lodash";
import { object, string } from "yup";
import { hasValue, valueOrOption } from '../../../../common/GeneralUtilities';
import { stringDateValidation } from '../../../../common/validations/dateValidation';
import { validateNumeric } from '../../../../common/validations/numeric';
import { stringValidation } from '../../../../common/validations/stringValidation';

const basicValidations = {
	date: () => stringDateValidation({ min: '1930-01-01', max: '2099-12-31' }),
	datetime: () => stringDateValidation({ isDatetime: true, min: '1930-01-01 00:00:00', max: '2099-12-31 23:59:59' }),
	dateyear: () => validateNumeric({ min: 1930, max: 2099 }),
	datemonth: () => validateNumeric({ min: 1, max: 12 }),
	numbericon: () => validateNumeric({}),
	numberinput: () => validateNumeric({}),
	numeric: () => validateNumeric({}),
	textarea: () => stringValidation({ min: 3, max: 50, }),
	textbox: () => stringValidation({ min: 3, max: 50, }),
	textfield: () => stringValidation({ min: 3, max: 50, }),
	textfieldicon: () => stringValidation({ min: 3, max: 50, }),
	// time: null,
};

export const defaultValidSchema = (show, fields) => {
	let shapeFields = {};
	let pairs = [];

	if (show) {
		each(fields, groupFields => {
			each(groupFields, field => {
				if (!hasValue(field)) { return; }

				const type = valueOrOption(field?.[2]?.fieldInput, "none").toLowerCase();
				if (type === "daterange") {
					const startName = valueOrOption(field[2].startProps?.name, `${field[0]}_start`);
					const endName = valueOrOption(field[2].endProps?.name, `${field[0]}_end`);
					pairs.push([startName, endName]);
					const startValidation = getRangeValidation(endName, "max");
					const endValidation = getRangeValidation(startName, "min");
					shapeFields = getValidation(startName, shapeFields, startValidation, "date");
					shapeFields = getValidation(endName, shapeFields, endValidation, "date");
					return;
				}

				shapeFields = getValidation(field?.[0], shapeFields, field?.[2]?.validation, type);
			});
		});
	}

	return object().shape(shapeFields, pairs);
};

const getValidation = (name, shapeFields, validation, type) => {
	const usedValidation = validation ?? basicValidations[type] ?? null;

	if (isFunction(usedValidation)) {
		shapeFields[name] = usedValidation();
	}

	return shapeFields;
};

const getRangeValidation = (contraField, validName = "min") => {
	return () => string()
		.when([contraField], ([contra]) => {
			if (hasValue(contra)) {
				return stringDateValidation({
					min: '1930-01-01', max: '2099-12-31', [validName]: contra
				});
			}
			return stringDateValidation({});
		});
};