import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { logout } from "../../../core/common/auth";

// TODO: Implement differente method to avoid localStorage security issues
function useSession() {
    const exitOnClose = useSelector(state => state.userConfigs?.close_session_on_exit) ?? false;
    const activeTabs = useRef(0);

    useEffect(() => {
        if (!exitOnClose) return;
        handleLoad();
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        }
    }, [exitOnClose])

    const handleUnload = () => {
        const current = parseInt(localStorage.getItem('active_tabs') ?? 0);
        const isActive = sessionStorage.getItem('isUserActive');
        const tabs = Math.max(0, current - 1);
        localStorage.setItem('active_tabs', tabs);
        if (current && !isActive) {
            sessionStorage.setItem('isUserActive', true);
        }
    };

    const handleLoad = () => {
        const current = parseInt(localStorage.getItem('active_tabs') ?? 0);
        const isActive = sessionStorage.getItem('isUserActive');
        const tabs = current + 1;
        activeTabs.current = tabs;
        localStorage.setItem('active_tabs', tabs);
        let sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId) {
            sessionId = Date.now().toString();
            sessionStorage.setItem('sessionId', sessionId);
            localStorage.setItem('sessionId', sessionId);
        }
        if (!current && (!isActive || localStorage.getItem('sessionId') !== sessionStorage.getItem('sessionId'))) {
            logout();
        }
    }
};

export default useSession;