import React from "react";
import FormDialog from "../../../../../../../App/components/dialog/FormDialog";
import DialogContent from "../../../../../../styled/DialogContent";
import { useModalsContext } from "../hooks/useModals";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import FormInput from "../../../../../../@components/form/Field";
import { useForm } from "react-hook-form";
import { BasicDialogActions } from "../../../../../../styled/DialogActions";
import { useRequestLoad } from "../../../../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { companyApi } from "../../../../../../services/company";
import { showSuccessNotification } from "../../../../../../../App/components/Notifications";
import { successUpdated } from "../../../../../../common/notification-messages";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stringValidation } from "../../../../../../common/validations/stringValidation";

function Payroll() {
    const { t } = useTranslation();
    const { openPayroll, handleClosePayroll } = useModalsContext();

    return (
        <FormDialog
            open={openPayroll}
            className="minimalist-dialog-design"
            handleClose={handleClosePayroll}
            maxWidth="sm"
            headerDivider
            title={t('Asignar Configuraciones de Nómina')}
            innerSubtitle="Selecciona una configuración de nómina para aplicarla a está empresa, asegurando que el proceso de pago cumpla con los parámetros establecidos."
        >
            <Content />
        </FormDialog>
    );
};

export default Payroll;

const datasource = [
    { label: "Predeterminado", value: "-" },
    { label: "SMI", value: "SMI" },
];

const validationSchema = yup.object().shape({
    version: stringValidation({ required: true }),
});

const Content = () => {
    const { t } = useTranslation();
    const { handleClosePayroll, selected } = useModalsContext();
    const [fetch, loading] = useRequestLoad();

    const {
        control,
        handleSubmit,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    const saveData = (data) => {
        const payload = { ...data };
        payload.version = payload.version?.replaceAll("-", "");
        fetch({
            api: companyApi.assignPayrollSettings(selected.id, payload),
            callback: onSuccess,
        });
    }

    const onSuccess = () => {
        showSuccessNotification(successUpdated());
        handleClosePayroll();
    }

    return (
        <>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="new-height-input">
                        <FormInput
                            fieldInput="Dropdown"
                            control={control}
                            name="version"
                            data={datasource}
                            textField="label"
                            valueField="value"
                            label={t('settings')}
                            noDefault
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <BasicDialogActions
                onAccept={handleSubmit(saveData)}
                onCancel={handleClosePayroll}
                loading={loading}
            />
        </>
    );
}