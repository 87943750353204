import { chain } from "lodash";

const MAP_CONCEPTS = (conceptData) => {
  const map_concept = chain(conceptData)
  .pickBy(value => value === true)
  .keys()
  .map(key => key.replace('_', ''))
  .value()
  .toString();

  return map_concept;
}

export const MAP_REQUEST_PAYROLL = (key, data) => {
  data.area=  data?.area === '*' ? data?.area : data?.area?.toString();
  data.branch = data?.branch === '*' ? data?.branch : data?.branch?.toString();
  data.department= data?.department === '*' ? data?.department : data?.department?.toString();

  //Funcion para mapear la data antes de ser enviada al back
  const item = {
    'reports-payroll-by-concepts': ({
      period,
      employer_registration,
      branch,
      area,
      department,
      backend_path,
      vacation_cap4,
      total_zero,
      order1,
      concept,
      single_file
    }) => {

      const order_by = order1 ? "1,3,5" : "10,7,1"
      const request = {
        period,
        employer_registration,
        branch,
        area,
        department,
        vacation_cap4,
        total_zero,
        order_by,
        concept: MAP_CONCEPTS(concept),
        backend_path,
        single_file
      }
      return request
    },
    "reports-differences-between-periods":({
      employer_registration,
      branch,
      backend_path,
      difference,
      payroll_period_initial,
      payroll_period_final
    }) => {
      const request = {
        payroll_period_initial,
        payroll_period_final,
        employer_registration,
        branch,
        backend_path,
        difference
      }
      return request
    },
    "reports-comparison-payroll-perception-net-payable":({
      payroll_period_initial,
      payroll_period_final,
      backend_path,
      employer_registration,
      branch,
      assignment_type,
      difference,
      order_by
    }) => {

      const request = {
        payroll_period_initial,
        payroll_period_final,
        backend_path,
        employer_registration,
        branch,
        difference,
        assignment_type,
        order_by: order_by.map(m => m.id).toString(),
      }
      return request
    },
    "reports-loans": (data) => {

      const request = {
        branch: data.branch,
        area: data.area,
        department: data.department,
        job_position: data.job_position,
        employer_registration: data.employer_registration,
        is_loan: data.is_loan,
        worker: data.worker,
        status: data.status === 0 ? null : data.status,
        concept: MAP_CONCEPTS(data.concept),
        initial_date: data.search_type === 1 ? data.initial_date : undefined,
        end_date: data.search_type === 1 ? data.end_date : undefined,
        period: data.search_type === 2 ? data.period : undefined,
        year: data.search_type === 3 || data.search_type === 4 ? data.year : undefined,
        month: data.search_type === 3 ? data.month : undefined,
      }

      return request;
    },
    "mopers": ({
      branch,
      area,
      department,
      job_position,
      employer_registration,
      backend_path,
      period,
      start_date,
      end_date,
      report_type,
      workgroup,
      worker,
      order_by,
      by_worker,
      by_workgroup,
      moper_status,
      date_to_use
    }) => {
      const request = {
        branch,
        area,
        department,
        job_position,
        employer_registration,
        backend_path,
        period,
        start_date,
        end_date,
        report_type,
        workgroup,
        worker,
        order_by,
        by_worker,
        by_workgroup,
        moper_status,
        date_to_use
      }
      return request;
    },
    "reports-mopers-pending": ({
      backend_path,
      payroll_type,
      payroll_calculation,
      attendance,
    }) => {
      const request = {
        backend_path,
        payroll_type,
        payroll_calculation,
        attendance,
      }
      return request;
    }
  }


  if (item[key]) {
    return item[key](data)
  }

  return data
}