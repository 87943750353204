import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { implementService } from "../../../services/implemet-service";
import { successUpdated } from "../../../common/notification-messages";
import { validationSchema } from "../util"
import { showSuccessNotification } from "../../../../App/components/Notifications";
import { ServerAPI } from "../../../services/ServerAPI";
import { usePackages } from "../../PackagesV2/usePackages";
import { configSettlementAPI } from "../../../services/enterprise-structure";

function useConfig() {
  const { tenant } = usePackages()
  const _tenant = useSelector((state) => state.tenant);
  const [loadFirms, setLoadFirms] = useState(false)
  const [loadDescription, setLoadDescription] = useState(false)
  const [reload, setReload] = useState(false)

  const { control, setValue, handleSubmit, clearErrors } =
    useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {},
      mode: "onChange",
    });

  useEffect(() => {
    if (!reload) return
    setReload(false)
    getData()
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    setLoadFirms(false)
    setLoadDescription(false)
    getData()
    // eslint-disable-next-line
  }, [_tenant]);

  function submit(data) {
    setLoadFirms(false)
    setLoadDescription(false)

    data.signatures.map((m, i) => {
      m.order = i + 1
      m.signature = m.signature.map(value => value.name)
      return m
    })
    const { footer_description, template_version, signatures } = data;
    const requestReport = {
      tenant,
      footer_description,
      template_version
    }
    const requestSignature = {
      tenant,
      signatures
    }
    implementService(configSettlementAPI.settlement(requestReport, {},false), (() => {
      implementService(configSettlementAPI.signatures(requestSignature,{},false), (() => {
        showSuccessNotification(successUpdated());
        getData()
      }))
    }))
  }

  function mapSignature(m1) {
    return  m1.signature.map((m2, id) => {
      return {
        idParent: m1.order,
        id,
        name: m2
      }
    })
  }

  function getData() {
    const request = {
      tree: "",
      key:"payroll_general",
      tenant,
    }

    implementService(ServerAPI('settlement-report-config',false).getOne(1, request), (({ template_version, footer_description }) => {
      setValue("footer_description", footer_description)
      setValue("template_version", template_version)
      setLoadDescription(true)
    }))

    implementService( ServerAPI('report-signatures', false).get(request), (signatures => {
      setLoadFirms(true)
      signatures.map((m1) => {
        m1.signature = mapSignature(m1)
        return m1
      })
      setValue("signatures", signatures)
    }))
  }

  function resetValues() {
    clearErrors()
    setReload(true)
    setLoadFirms(false)
    setLoadDescription(false)
  }

  return {
    control,
    loadFirms,
    loadDescription,
    setValue,
    resetValues,
    handleSubmit,
    submit,
    clearErrors
  };
}

export default useConfig;
