import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import { useTranslation } from "react-i18next";
import {
    TextBox
} from '@progress/kendo-react-inputs';
import { SearchPrefix } from './SearchPrefix';
import { SearchSufix } from './SearchSufix';
import { hasValue, valueOrOption } from '../../../../common/GeneralUtilities';

const SearchTextBox = ({
    tab = "",
    search,
    debouncedSearch,
    ...others
}) => {

    const { t } = useTranslation();
    const [value, setValue] = useState("");

    const handleChange = useCallback(event => {
        const tValue = event.target.value;
        setValue(tValue);
        debouncedSearch(tValue);
    }, []);

    const handleClear = useCallback(() => {
        setValue("");
        debouncedSearch("");
    }, []);

    useEffect(() => {
        setValue(valueOrOption(search, ""));
    }, [search, setValue, valueOrOption]);

    const suffix = () => <SearchSufix handleClear={handleClear} value={value} />;

    const searchLabel = hasValue(tab) ? "search-into" : "search";

    return (
        <TextBox
            {...others}
            value={value}
            onChange={handleChange}
            placeholder={t(searchLabel, { item: t(tab) })}
            className={"modern-search-box search-box"}
            prefix={SearchPrefix}
            suffix={suffix}
            autoComplete={"off"}
        />
    );
};

export default SearchTextBox;