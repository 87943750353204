import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import {
    accessTypes,
    copyObject,
    handleInitialState,
    treatAccessData,
} from "../../../../utils/utilities";
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import GridNoRecordsRender from "../../../../../../../App/components/Templates/GridNoRecordsRender";
import { columnProps, initialFilter, initialPage, pageable } from "../../../../../../../App/components/GridCustomProps";
import { useTranslation } from "react-i18next";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import KendoCheckbox from "../../../../../../../App/components/Booleans/KendoCheckbox";
import { size, uniqBy, xor } from "lodash";
import { useSelector } from "react-redux";
import { branchEmpAPI } from "../../../../../../services/enterprise-structure";
import { parseRequest } from "../../../../../../common/parse-request";
import { resolveError } from "../../../../../../common/resolve-error";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { uid } from 'uid';
import { useWatch } from "react-hook-form";
import { hasValue } from "../../../../../../common/GeneralUtilities";
import FormError from "../../../../../../@components/form/Error";
import { Checkbox } from "@progress/kendo-react-inputs";
import { scaleOpacityAnimation } from "../../../../../../@components/form/Permission/Utils";
import SwitchMuiCustom from "../../../../../../../App/components/Booleans/SwitchMui";
import StyledFormControlLabel from "../../../../../../styled/StyledFormControlLabel";
import { useDialogContext } from "../../../../hooks/DialogController";

const DATA_ITEM_KEY = "comp_key";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const indeterminateAccess = { id: 999, name: 'Indeterminado' };

const BranchesPerms = ({
    item,
    roleIndex,
}) => {
    const {
        setValue,
        setItemBack,
        mainTreatedItems,
        setInAccessView,
        setInCustomItem,
        getValues,
        control,
    } = useDialogContext();

    const { t } = useTranslation();
    const { current } = useSelector(state => state.tenant);
    const config = item?.group?.config?.only_branch;
    const pathValue = `groups_perms[${roleIndex}].branches_employer`;
    const update = useRef(false);
    const [totalData, setTotalData] = useState(0);
    const [selectedState, setSelectedState] = useState({});
    const [dataState, setDataState] = useState([]);
    const [dataShow, setDataShow] = useState([]);
    const [showChecked, setShowChecked] = useState(false);
    const [orderSelected, setOrderSelected] = useState(true);
    const [branchFilters, setBranchFilters] = useState(initialFilter());
    const [branchPages, setBranchPages] = useState(initialPage);
    const [loading, setLoading] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const dataBackup = useRef([]);
    const countBackup = useRef(0);
    const customEvent = { value: true };
    const disableSelection = size(branchFilters?.filters) > 0 || Object.values(selectedState).every(el => !el);

    useEffect(() => {
        const rolesView = mainTreatedItems.find(el => el.step === 2);
        setInAccessView(true);
        setInCustomItem(true);
        setItemBack(rolesView);
        setSelectedState(handleInitialState(item, config));
        return () => {
            setInAccessView(false);
            setInCustomItem(false);
            setItemBack(null);
        }
    }, []);

    useEffect(() => {
        const withFilters = size(branchFilters?.filters) > 0;
        if (withFilters) {
            setShowChecked(false);
            setBranchPages(initialPage);
        }
        getData();
    }, [branchFilters])

    useEffect(() => {
        if (size(item?.branches_employer) > 0) {
            onShowCheckedChange({ ...customEvent, value: true });
        }
    }, [])

    useEffect(() => {
        if (!dataState) return;
        const dataOrdered = orderData(dataState, orderSelected);
        const dataSelected = filterSelected(dataOrdered, showChecked);
        const dataFiltered = filterData(dataSelected, branchPages);
        validateCheckAll();
        setDataShow(dataFiltered);
    }, [branchPages, dataState])

    useEffect(() => {
        if (update.current && dataState) {
            const selecteds = getSelectedIds();
            const actualValues = [...(getValues(pathValue) ?? [])];
            const selectedItems = dataState.filter(el => selecteds.includes(el.comp_key)).map(el => {
                let foundItem = actualValues.find(li => li.branch_id === el.branch_id);
                if (!config) {
                    foundItem = actualValues.find(li => li.branch_id === el.branch_id && li.employer_id === el.employer_id);
                }
                const newItem = { ...el, ...(foundItem ?? {}) };
                if (!newItem.id) {
                    newItem.id = newItem.comp_key;
                }
                return newItem;
            });
            setValue(pathValue, selectedItems);
            onOrderSelected({ ...customEvent, value: orderSelected });
            const everyFalse = Object.values(selectedState).every(el => !el);
            if (everyFalse) {
                onShowCheckedChange({ ...customEvent, value: false });
            }
            update.current = false;
        }
    }, [selectedState, dataState]);

    const getSelectedIds = () => Object.entries(selectedState ?? {}).filter(el => el[1]).map(el => el[0]);

    function validateCheckAll() {
        if (!size(selectedState)) return;
        const dataValidate = getSelectedIds();
        const currentData = dataState.map(el => el.comp_key);
        const diffData = xor(dataValidate, currentData);
        setCheckAll(size(diffData) === 0);
    }

    async function getData() {
        setLoading(true);
        const request = parseRequest(
            {
                tenant: current?.id,
                tree: "",
                ...(!config ? { branches_employer: true } : {}),
            },
            branchFilters,
            {},
        );
        try {
            const response = await branchEmpAPI.get(request);
            const treatData = treatAccessData(response, config);
            if (!size(branchFilters?.filters)) {
                dataBackup.current = copyObject(treatData);
            }
            countBackup.current = treatData.length;
            update.current = true;
            setTotalData(treatData.length);
            setDataState(treatData);
            setLoading(false);
            return treatData;
        } catch (error) {
            resolveError(error);
        }
    }

    const onSelectionChange = useCallback(
        (event) => {
            update.current = true;
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        update.current = true;
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState({ ...selectedState, ...newSelectedState });
    }, [dataState]);

    const handleCheckAll = useCallback((e) => {
        update.current = true;
        const event = {
            dataState,
            syntheticEvent: {
                target: { checked: e.target.checked },
            },
        };
        const checked = e.target.checked;
        const newSelectedState = {};
        event.dataItems = copyObject(dataState);
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState({ ...selectedState, ...newSelectedState });
    }, [dataState]);

    const onShowCheckedChange = (e) => {
        let dataUpdate = copyObject(dataState);
        let countData = 0;
        dataUpdate = filterSelected(dataUpdate, e.value);
        countData = dataUpdate.length;
        dataUpdate = filterData(dataUpdate, branchPages);
        setShowChecked(e.value);
        setTotalData(countData);
        setDataShow(dataUpdate);
    }

    function filterSelected(data, value) {
        if (value) {
            return data.filter(el => {
                const compKey = el.comp_key;
                return selectedState[compKey];
            });
        }
        return dataState;
    }

    const onOrderSelected = (e) => {
        let dataOrdered = [...dataState];
        dataOrdered = orderData(dataOrdered, e.value);
        setOrderSelected(e.value);
        setDataState(dataOrdered);
        setTotalData(dataOrdered.length);
    }

    function orderData(data, value) {
        if (value) {
            return data.sort((a, b) => {
                const item1 = selectedState[a.comp_key];
                const item2 = selectedState[b.comp_key];
                if (item1 && !item2) {
                    return -1;
                }
                if (!item1 && item2) {
                    return 1;
                }
                return 0;
            });
        }
        return data.sort((a, b) => parseInt(a.branch_id) - parseInt(b.branch_id));
    }

    const onPageChange = (e) => {
        setBranchPages({
            ...branchPages,
            skip: e.page.skip,
            take: e.page.take
        });
    }

    const onFilterChange = (e) => {
        setBranchFilters(() => ({
            ...e.filter
        }))
    }

    return (
        <AnimatePresence mode='wait' initial={false}>
            <motion.div
                className='access-permissions-container'
                key='access-permissions'
                {...scaleOpacityAnimation}
            >
                <div className="grid-controls">
                    <div className="controls-1">
                        <KendoCheckbox
                            label="Filtrar SOLO seleccionados"
                            checked={showChecked}
                            onChange={onShowCheckedChange}
                            disabled={disableSelection}
                            formAlign={false}
                        />
                        <KendoCheckbox
                            label="Ordenar por seleccionados"
                            checked={orderSelected}
                            onChange={onOrderSelected}
                            formAlign={false}
                        />
                    </div>
                    <div className="controls-2">
                        <MassiveAccess
                            control={control}
                            roleIndex={roleIndex}
                            setValue={setValue}
                        />
                    </div>
                </div>
                <Grid
                    data={
                        !loading ?
                            dataShow.map((element) => ({
                                ...element,
                                [SELECTED_FIELD]: selectedState[idGetter(element)],
                            }))
                            :
                            []
                    }
                    pageable={pageable(totalData)}
                    className='access-permissions-grid'
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    skip={branchPages.skip}
                    take={branchPages.take}
                    total={totalData}
                    onPageChange={onPageChange}
                    filter={branchFilters}
                    onFilterChange={onFilterChange}
                >
                    <GridNoRecords>
                        <GridNoRecordsRender init={true} filter={true} loading={loading} />
                    </GridNoRecords>

                    <Column
                        title="Marcar todos"
                        headerCell={() => {
                            return dataShow.length > 0 ?
                                <span>
                                    <Checkbox
                                        label={"Marcar todos"}
                                        value={checkAll}
                                        onClick={handleCheckAll}
                                    />
                                </span> : null
                        }}
                    >
                        <Column
                            field={SELECTED_FIELD}
                            width={30}
                            headerSelectionValue={
                                dataShow.findIndex((item) => !selectedState[idGetter(item)]) === -1
                            }
                        />
                        <Column
                            {...columnProps("branch_key", branchFilters, 'input')}
                            width={config ? 65 : 55}
                            title={t("key")}
                        />
                    </Column>

                    <Column
                        {...columnProps("branch_name", branchFilters, 'input')}
                        title={t("name")}
                    />
                    {
                        !config ?
                            <Column
                                {...columnProps("rfc", branchFilters, 'input')}
                                width={90}
                                title={`${t("RFC")}`}
                            />
                            :
                            null
                    }
                    {
                        !config ?
                            <Column
                                {...columnProps("employer_key", branchFilters, 'input')}
                                width={85}
                                title={`${t("key")} Reg. Patronal`}
                            />
                            :
                            null
                    }
                    {
                        !config ?
                            <Column
                                {...columnProps("employer_name", branchFilters, 'input')}
                                title={`${t("name")} Reg. Patronal`}
                            />
                            :
                            null
                    }
                    <Column
                        title={`Utilizar lista`}
                        width={100}
                        cell={
                            (cellProps) =>
                                <AttendaceColumn
                                    {...cellProps}
                                    control={control}
                                    setValue={setValue}
                                    roleIndex={roleIndex}
                                    getValues={getValues}
                                    config={config}
                                />
                        }
                    />
                    <Column
                        title={t("access")}
                        width={110}
                        cell={
                            (cellProps) =>
                                <AccessColumn
                                    {...cellProps}
                                    control={control}
                                    setValue={setValue}
                                    roleIndex={roleIndex}
                                    getValues={getValues}
                                    config={config}
                                />
                        }
                    />
                </Grid>
                <FormError control={control} name={`groups_perms[${roleIndex}].branches_employer`} />
            </motion.div>
        </AnimatePresence>
    );
};

export default BranchesPerms;

const AccessColumn = ({
    dataItem,
    setValue,
    roleIndex,
    control,
    dataIndex,
}) => {
    const pathValue = `groups_perms[${roleIndex}].branches_employer[${dataIndex}].confidential`;
    const userValue = useWatch({ control, name: pathValue }) ?? 0;
    const option = accessTypes.find(el => el.id === userValue);
    const disabled = !dataItem.selected;

    const handleChange = (event) => {
        const value = event.target.value?.id;
        setValue(pathValue, value);
    };

    return (
        <td className={`dropdown-cell ${disabled ? 'disabled' : ''}`}>
            <CustomDropdown
                style={{ width: "100%", background: 'var(--backgroundText)' }}
                onChange={handleChange}
                itemRender={itemRender}
                disabled={disabled}
                textField="name"
                dataItemKey="id"
                value={option}
                data={accessTypes}
            />
        </td>
    )
};

const AttendaceColumn = ({
    control,
    dataItem,
    setValue,
    roleIndex,
    dataIndex,
}) => {
    const pathValue = `groups_perms[${roleIndex}].branches_employer[${dataIndex}].attendance_access`;
    const userValue = useWatch({ control, name: pathValue });
    const disabled = !dataItem.selected;

    const handleChange = (event) => {
        setValue(pathValue, event.value);
    };

    return (
        <td className={`custom-switch-cell ${disabled ? 'disabled' : ''}`}>
            <StyledFormControlLabel
                control={
                    <SwitchMuiCustom
                        checked={userValue ?? false}
                        onChange={handleChange}
                        disabled={disabled}
                        size="small"
                    />
                }
            />
        </td>
    )
};

const CustomDropdown = (props) => {
    return (
        <DropDownList
            {...props}
            itemRender={itemRender}
        />
    )
}

const itemRender = (li) => {
    const itemChildren = (
        <span
            style={{
                padding: 0,
                fontSize: '10px',
            }}
        >
            {li.props.children}
        </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
};

export function filterData(data, pages) {
    const take = pages.take;
    const skip = pages.skip;
    return data.slice(skip, skip + take);
}

const MassiveAccess = ({ control, roleIndex, setValue }) => {
    const pathValue = `groups_perms[${roleIndex}].branches_employer`;
    const accessTypesMassive = useWatch({ control, name: pathValue });
    const [massiveAccess, setMassiveAccess] = useState(indeterminateAccess);

    useEffect(() => {
        const everySame = everyAccessSame(accessTypesMassive, 'confidential');
        const newValue = everySame ? accessTypes[accessTypesMassive[0]?.confidential] : indeterminateAccess;
        setMassiveAccess(newValue);
    }, [accessTypesMassive])

    function everyAccessSame(data, property) {
        const uniqueValues = uniqBy(data, property).map(obj => obj[property]);
        return uniqueValues.length === 1;
    };

    const handleMassiveAccessChange = (e) => {
        const newAccess = accessTypesMassive.map(el => {
            el.confidential = e.value.id;
            return el;
        });
        setValue(pathValue, newAccess);
        setMassiveAccess(e.value);
    }

    return (
        <FloatingLabel
            label={'Acceso masivo:'}
            editorId={"dropdown_" + uid()}
            editorValue={hasValue(massiveAccess)}
        >
            <CustomDropdown
                className='custom-dropdown-massive'
                onChange={handleMassiveAccessChange}
                itemRender={itemRender}
                textField="name"
                dataItemKey="id"
                value={massiveAccess}
                data={accessTypes}
            />
        </FloatingLabel>
    );
};

BranchesPerms.propTypes = {
    item: PropTypes.object,
    roleIndex: PropTypes.number,
};

AccessColumn.propTypes = {
    dataItem: PropTypes.object,
    setValue: PropTypes.func,
    roleIndex: PropTypes.number,
    control: PropTypes.object,
    dataIndex: PropTypes.number,
};

AttendaceColumn.propTypes = {
    dataItem: PropTypes.object,
    setValue: PropTypes.func,
    roleIndex: PropTypes.number,
    control: PropTypes.object,
    dataIndex: PropTypes.number,
};

MassiveAccess.propTypes = {
    setValue: PropTypes.func,
    roleIndex: PropTypes.number,
    control: PropTypes.object,
};