import React from "react";
//-- mui
import StyledFormControlLabel from '../../../../../core/styled/StyledFormControlLabel';
import SwitchMuiCustom from "../../../Booleans/SwitchMui";

const SwitchItem = (props) => {

  const {
    field,
    fieldId,
    columnIndex = 0,
    handleSelectedChange,
  } = props;

  return (
    <>
      <StyledFormControlLabel
        control={
          <SwitchMuiCustom
            checked={ field.isSelected }
            onChange={() => {
              handleSelectedChange(fieldId, !field.isSelected, columnIndex);
            }}
          />
        }
        label={field.text}
      />
      {field.isSelected}
    </>
  );
};

export default SwitchItem;