import { t } from 'i18next';
import { array } from "yup";
import * as yup from "yup";
import {isEmpty, filter, get} from 'lodash';
import { hasValue } from '../GeneralUtilities';

yup.addMethod(yup.array, 'unique', function(propertyPath, message) {
	return this.test('unique', '', function(list) {
		const errors = [];

		if(list){
			list.forEach((item, index) => {
				const propertyValue = get(item, propertyPath);
		
				if (propertyValue && filter(list, [propertyPath, propertyValue]).length > 1) {
					errors.push(
						this.createError({
							path: `${this.path}[${index}].${propertyPath}`,
							message,
						})
					);
				}
			});
		}
  
	  if (!isEmpty(errors)) {
			throw new yup.ValidationError(errors);
		}
  
		return true;
	});
});

const arrayValidation = ({ required, min, max, uniqueField }) => {

	let validation = array();

	/* if array selection is required */
	if (required) {
		validation = validation.required(t("validation:required"));
	} else {
		validation = validation.nullable();
	}

	/* if array need a minimun selection */
	if (hasValue(min)) {
		const msg = min === 1 ? "array-one" : "array";
		validation = validation.min(min, t(`validation:min.${msg}`, { min: min }));
	}

	/* if array need a maximun selection */
	if (hasValue(max)) {
		validation = validation.max(max, t("validation:max.array", { max: max }));
	}

	if(hasValue(uniqueField)){
		validation = validation.unique(uniqueField, t("validation:unique"));
	}

	return validation;
};

export default arrayValidation;