import React from 'react'
import { useTranslation } from "react-i18next";

function Option1() {

  const { t } = useTranslation();

  return (
    <React.Fragment>

      <table className="table table-sm table-borderless">
        <tbody>
          <tr>
            <td scope="col" colSpan="3">
              <span className='table-title'>{t("perceptions")}</span>
              <table className="table table-sm table-borderless mb-0 ">
                <thead className="sub-bg">
                  <tr>
                    <th className="py-0 text-center">{t("key")}</th>
                    <th className="py-0">{t("perception")}</th>
                    <th className="py-0">{t("unity")}</th>
                    <th className="py-0 text-end">{t("total")}</th>
                  </tr>
                </thead>

                <tbody>

                  <tr>
                    <td className="text-center py-0">P001</td>
                    <td scope="col" className="py-0"> SUELDO</td>
                    <td scope="col" className="py-0 text-center">3</td>
                    <td scope="col" className="text-end px-0 py-0">3,000.00</td>
                  </tr>

                  <tr>
                    <td className="text-center py-0">P004</td>
                    <td scope="col" className="py-0"> VACACIONES POR FINIQUITO</td>
                    <td scope="col" className="py-0 text-center">24</td>
                    <td scope="col" className="text-end px-0 py-0">23,540.00</td>
                  </tr>

                  <tr>
                    <td className="text-center py-0">P006</td>
                    <td scope="col" className="py-0"> PREMIO PUNTUALIDAD</td>
                    <td scope="col" className="py-0 text-center"></td>
                    <td scope="col" className="text-end px-0 py-0">300.00</td>
                  </tr>

                </tbody>

              </table>
            </td>
            <td scope="col" colSpan="3">
              <span className='table-title'>{t("deductions")}</span>
              <table className="table table-sm table-borderless mb-0">
                <thead className="sub-bg">
                  <tr >
                    <th className="text-center py-0">{t("key")}</th>
                    <th className="py-0">{t("deduction")}</th>
                    <th className="py-0 text-end">{t("total")}</th>
                  </tr>
                </thead>

                <tbody>

                  <tr>
                    <td className="text-center py-0">D006</td>
                    <td scope="col" className="py-0"> IMSS CESANTIA Y VEJEZ</td>
                    <td scope="col" className="text-end px-0 py-0">39.09</td>
                  </tr>


                  <tr>
                    <td className="text-center py-0">D001</td>
                    <td scope="col" className="py-0"> ISR</td>
                    <td scope="col" className="text-end px-0 py-0">6,711.98</td>
                  </tr>


                  <tr>
                    <td className="text-center py-0">D005</td>
                    <td scope="col" className="py-0"> IMSS</td>
                    <td scope="col" className="text-end px-0 py-0">53.60</td>
                  </tr>


                  <tr>
                    <td className="text-center py-0">D008</td>
                    <td scope="col" className="py-0"> DESCUENTO VALES DE DESPENSA ELECTRONICO</td>
                    <td scope="col" className="text-end px-0 py-0">500.00</td>
                  </tr>


                  <tr>
                    <td className="text-center py-0">D009</td>
                    <td scope="col" className="py-0"> DESCUENTO VALES</td>
                    <td scope="col" className="text-end px-0 py-0">1.00</td>
                  </tr>

                </tbody>

              </table>
            </td>
          </tr>
          <tr className="nowrap" style={{ fontSize: "11px" }}>
            <th scope="col" colSpan="2" className="text-end"><b>{t("total-perceptions")}</b></th>
            <th scope="col" className="text-end p-1"><b>$   26,840.00</b></th>
            <th scope="col" colSpan="2" className="text-end"><b>{t("total-deductions")}</b></th>
            <th scope="col" className="text-end"><b>$   7,305.67</b></th>
          </tr>

          <tr className="nowrap" style={{ fontSize: "11px" }}>
            <th scope="col" colSpan="5" className="text-end"><b>Subtotal a pagar</b></th>
            <th scope="col" className="text-end blue-text"><b>$  19,534.33</b></th>
          </tr>
        </tbody>
      </table>
      <span className='table-title'>{t("Otros")}</span>
      <table className="table table-sm table-borderless mb-0 ">
        <thead className="sub-bg">
          <tr>
            <th className="py-0 text-center col-1">{t("key")}</th>
            <th className="py-0 ps-2" colSpan="2">{t("description")}</th>
            <th className="py-0 text-center" colSpan="3">{t("unity")}</th>
            <th className="py-0 text-end" colSpan="1">{t("amount")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-center py-0" colSpan="1">X1</td>
            <td scope="col" className="py-0 ps-2" colSpan="2"> Otros</td>
            <td scope="col" className="py-0 ps-2 text-center" colSpan="3"> - </td>
            <td scope="col" className="text-end p-0" colSpan="1">1,662.50</td>
          </tr>
        </tbody>
      </table>
      <table className="table table-sm table-borderless mb-0 ">
        <tbody>
          <tr className="nowrap" style={{ fontSize: "11px" }}>
            <th scope="col" className="text-end px-4"><b>Total a pagar</b></th>
            <th scope="col" className="col-2 text-end blue-text"><b>$  19,534.33</b></th>
          </tr>
        </tbody>

      </table>
    </React.Fragment>

  )
}

export default Option1