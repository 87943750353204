import PropTypes from "prop-types"
import React, {
	Fragment
} from 'react';
import Radio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleQuestion } from "@fortawesome/pro-light-svg-icons";

import { useTranslation } from "react-i18next";
import { uid } from 'uid';
import { objectValue } from '../form/utilities';
import { CustomTooltip } from '../../../App/components/Templates/cells';
import image from "../../../assets/images/user/avatar-3.jpg";
import { valueOrOption } from '../../common/GeneralUtilities';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

/**
 * Componente radiogroup para los formularios del sistema basado en MUI, para más propiedades leer la documentación oficial
 * @param {object} params
 * @param {?string} params.id - Id del grupo de radios
 * @param {string|number} params.value - Valor que está eleccionado en el grupo
 * @param {?string} params.name - Nombre del grupo
 * @param {?string} params.groupLabel - Label que que muestra como cabecera del grupo
 * @param {function} params.onChange - La funcón a realizar cuando se seleccione algún elemento del grupo
 * @param {?boolean} params.row - Indica si los radios se van a mostrar de manera horizontal(true) ó vertical(false), por default true
 * @param {[{id : string|number,name : string,desc : string }]} params.options - Son las opciones que se van a mostrar, este ()
 * @param {?string} params.valueField - Es la propiedad que sera utilizada por cada elemento como valor al seleccionar, por default(id)
 * @param {?string} params.textField - Es la propiedad que sera utilizada por cada elemento como label a motrar, por default(name)
 * @param {?boolean} params.disabled - Indica si el grupo va a estar deshabilitado o no, por default (false)
 * @param {?boolean} params.error - Este atributo indica si el grupo de elementos cuenta con una validación erronea y lo pinta de rojo, por default (false)
 * @param {?object} params.style - Este atributo es para aplicar estilos al radiogroup
 * @param {?number|string} params.fontSize - Este atributo indica el tamaño de letra para el radio y su label, por default 1em
 * @param {?boolean} params.isNumericValue - Indica si el valor del elemento se maneja como un número (Por default es un string)
 * @param {?boolean} params.isBoolValue - Indica si el valor del elemento se maneja como un boleano (Por default es un string)
 * @returns {RadioGroupComponent}
 * @example
 * const [value,setValue] = useState(0);
 * <RadioGroup
 *		onChange={({value}) => setValue(value)}
 * 	value={value}
 * 	name="RadioGroup_example"
 * 	options={[
 *	 		{ id: 0, label: "opción 1" },
 *	 		{ id: 1, label: "opción 2" },
 *		]}
 * 	valueField="id"
 * 	textField="label"
 *	/>
 */

const RadioGroup = ({
	controlled = true,
	id,
	value,
	name,
	groupLabel,
	onChange,
	row = true,
	options = [],
	textField = "name",
	valueField = "id",
	disabled = false,
	error = false,
	gap = 0,
	fontSize,
	itemPadding,
	highlight,
	style = {},
	className = '',
	outlined = false,
	isNumericValue = false,
	isBoolValue = false,
	sideLabel = false,
	modernDesign = false,
	...others
}) => {

	const { t } = useTranslation();
	const editorId = id ?? `dateP_${uid()}`;
	const classLabel = `${className} ${modernDesign ? 'modern-design' : ''} ${outlined ? 'outlined' : ''}`;

	const handleChange = (e) => {
		if (typeof onChange === "function") {
			const tValue = e?.target?.value;
			const valueType = `${isBoolValue ? 1 : 0}-${isNumericValue ? 1 : 0}`;
			const finalValue = {
				"1-0": valueOrOption(tValue, "false") === "true",
				"0-1": parseInt(tValue)
			}[valueType] ?? tValue;
			const valueGetted = objectValue(e, finalValue, false);
			onChange(valueGetted);
		}
	};

	const sx = {
		'& .MuiSvgIcon-root': {
			fontSize: fontSize ?? "1em",
		}
	};


	return (
		<FormControl style={{ ...style, ...(modernDesign ? { width: '100%' } : {}) }}>
			{(groupLabel || sideLabel) &&
				<FormLabel id={editorId} sx={sx}>
					{groupLabel}
				</FormLabel>
			}
			<MuiRadioGroup
				row={row}
				aria-labelledby={editorId}
				name={name ?? editorId}
				{...(controlled ? { value: valueOrOption(value, null) } : {})}
				{...others}
				style={{ gap }}
				className={classLabel}
				onChange={handleChange}
			>
				{(options ?? []).map((option, index) => (
					<Fragment key={option.id ?? option.value}>
						<div className={`radio-group-item ${highlight ? 'should-highlight' : ''}`} style={{ padding: itemPadding }}>
							<FormControlLabel
								disabled={disabled || option.disabled}
								value={valueOrOption(option?.[valueField], index)}
								label={getLabel(fontSize, t(option?.[textField] ?? index))}
								style={disabled || option.disabled ? { cursor: "not-allowed" } : {}}
								control={
									<Radio
										sx={sx}
										className={`systemRadio ${error ? "error" : ""}`}
										{...(modernDesign ? {
											checkedIcon: <FontAwesomeIcon icon={faCircleCheck} />,
											icon: <FontAwesomeIcon icon={faCircle} />
										} : {})}
									/>}
							/>
							{!!option?.desc && <span className='radio-button-desc'>{option.desc}</span>}
							{!!option?.bottomRender && option?.bottomRender}
						</div>
						{option.img && <CustomTooltip placement="bottom" title={<img src={image} alt="" />}>
							<FontAwesomeIcon icon={faCircleQuestion} className='icon-helper' style={{
								color: "var(--textAndIcons)",
								margin: "0px 15px 10px -13px",
								cursor: disabled ? "not-allowed" : 'pointer'
							}} />
						</CustomTooltip>
						}
					</Fragment>
				))}
			</MuiRadioGroup>
		</FormControl>
	);
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fontSize: PropTypes.string,
  gap: PropTypes.number,
  groupLabel: PropTypes.any,
  highlight: PropTypes.any,
  id: PropTypes.any,
  isBoolValue: PropTypes.bool,
  isNumericValue: PropTypes.bool,
  itemPadding: PropTypes.any,
  modernDesign: PropTypes.bool,
  name: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  outlined: PropTypes.bool,
  row: PropTypes.bool,
  sideLabel: PropTypes.bool,
  style: PropTypes.object,
  textField: PropTypes.string,
  value: PropTypes.any,
  valueField: PropTypes.string
}

export default RadioGroup;

const getLabel = (fontSize, text) => {
	return (
		<Typography sx={{ fontSize: fontSize ?? "0.85rem" }}>
			{text}
		</Typography>
	);
};