import React, { useContext } from 'react'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
// import SelectTemplate from "./SelectTemplate";
import DescriptionFooter from "./DescriptionFooter";
import Firms from "./Firms";
import { ModuleContext } from '../util';

function Configuration() {
  const { t } = useTranslation()
  const { loadFirms, loadDescription, resetValues, handleSubmit, submit } = useContext(ModuleContext);
  const is_active = useSelector((state) => state.configuration.payroll_package.value);
  return (
    <Grid sx={{ position: "relative", height: "100%" }}>
      <div className="label-title"><b className="title-item">{t("settings")}</b></div>
      <form onSubmit={handleSubmit(submit)} autoComplete="off" style={{height: "100%"}}>
        <Grid className="container p-2" style={{height: "97%"}}>
          {/* {loadDescription && <SelectTemplate />} */}
          {loadDescription && <DescriptionFooter />}
          {loadFirms && <Firms />}
          {(loadFirms && is_active) && <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <button className="btn-cancel" onClick={resetValues}>{t("cancel")}</button>
            <button type="submit" className="btn-save">{t("save")}</button>
          </Grid>}
        </Grid>
      </form>
    </Grid>
  )
}

export default Configuration