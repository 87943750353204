import React from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import { useTranslation } from "react-i18next";
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
import { upperFirst } from 'lodash';

const CustomPager = ({
	pagerItems,
	...props
}) => {

	const { t } = useTranslation("kendo-messages");

	const objMSg = (message, key) => ({ defaultMessage: message, messageKey: key });

	const messagesMap = (key) => {
		const simple = key.replace("pager.", "");
		let returnedMsg = objMSg(t(key), simple);

		if (["info", "itemsPerPage", "pagerItems",].includes(simple)) {
			pagerItems = valueOrOption(pagerItems, t("pager.pagerItems"));
			pagerItems = upperFirst(pagerItems.toString().toLowerCase());
			const option = { elements: pagerItems };
			returnedMsg = {
				"info": objMSg(t("pager.customInfo", option), simple),
				"itemsPerPage": objMSg(t("pager.customPerPage", option), simple),
				"pagerItems": objMSg(pagerItems, simple),
			}[simple];
		}

		return returnedMsg;
	};

	return (
		<Pager
			{...props}
			messagesMap={hasValue(pagerItems) ? messagesMap : undefined}
		/>
	);
};


export default CustomPager;