import axios from "axios";
import { ParseParams, ServerAPI, encodeURIParams, getUrl } from './ServerAPI';
import { config, configMedia } from './config';

export const trainingModeApi = ServerAPI("training-modes");
export const thematicAreasApi = ServerAPI("thematic-areas");
export const trainingObjectivesApi = ServerAPI("training-objectives");
export const trainingPlacesApi = ServerAPI("training-places");
export const coursesClassificationApi = ServerAPI("courses-classification");
export const coursesStatusApi = ServerAPI("courses-status");
export const agentTypesApi = ServerAPI("agent-types");
export const nonAttendanceReasonsApi = ServerAPI("non-attendance-reasons");
export const trainingCoursesApi = ServerAPI("training-courses");
export const trainingInstructorsApi = ServerAPI("training-instructors");
export const disabilitiesAPI = ServerAPI('disability');

export const institutionTypesApi = ServerAPI("institution-types");
export const productiveAreasApi = ServerAPI("productive-areas");
export const subproductiveAreasApi = ServerAPI("sub-productive-areas");
export const productiveTrainingApi = ServerAPI("productive-training");
export const workerOccupationsApi = ServerAPI("worker-occupations");
export const positionMappingsApi = ServerAPI("position_mappings");
export const positionMappingsMassiveApi = {
    multi: (params, extra, include_tenant = true) => {
        const url = getUrl(`position_mappings/multi-update`, include_tenant);
        const payload = ParseParams({ params, include_tenant });
        return axios.put(url, payload, config(extra)).then((response) => response.data)
    }
}


const PublishedTrainingCoursesApi = () => {

    const baseName = "published-training-courses";

    const baseApi = ServerAPI(baseName);

    const getParticipants = (id, params) => {
        const url = getUrl(`${baseName}/${id}/grades-course`, true);

        return axios.get(url, ParseParams({ params, include_tenant: false, include_config: true }))
            .then(response => response.data);
    };

    const updateGrades = (id, request) => {
        const url = getUrl(`${baseName}/${id}/grades-course`, true);

        return axios.patch(url, request, config())
            .then((response) => response.data);
    };

    return {
        ...baseApi,
        updateGrades,
        getParticipants
    };
};

export const cirseReportApi = ServerAPI("cirse-document");
export const dc3ReportApi = ServerAPI("dc-3");
export const listCourseReportApi = ServerAPI("list-course-report");
export const publishedTrainingCoursesApi = PublishedTrainingCoursesApi();

const establishmentKeyApi = () => {
    const baseName = 'establishment-key';
    const baseApi = ServerAPI(baseName);

    const createNew = (request) => {
        const url = getUrl(baseName, true);
        return axios.post(url, request, configMedia()).then(resp => resp.data);
    }

    const updateItem = (id, request) => {
        const url = getUrl(`${baseName}/${id}`, true);
        return axios.put(url, request, configMedia()).then(resp => resp.data);
    }

    const updateMulti = (request) => {
        const url = getUrl(`${baseName}/multi`, true);
        return axios.put(url, ParseParams({ params: request }), config())
            .then(resp => resp.data);
    }

    const massiveUpdate = (request, filters) => {
        const filterValues = filters?.filters ?? filters;
        const filterParams = encodeURIParams(filterValues);
        const url = getUrl(`${baseName}/multi-update/${filterParams}`);
        return axios.put(url, request, configMedia()).then(resp => resp.data);
    }

    return {
        ...baseApi,
        createNew,
        updateItem,
        updateMulti,
        massiveUpdate,
    };
};

export const establishmentKeyAPI = establishmentKeyApi();

const catalogsActiveElements = () => {

    const getData = (url, params) => {
        const fUrl = getUrl(`${url}/active-list`, true);
        return axios.get(fUrl, ParseParams({ params, include_config: true })).then(resp => resp.data);
    }

    const patchData = (url, params) => {
        const fUrl = getUrl(`${url}/active-list-update`, true);
        return axios.patch(fUrl, ParseParams({ params }), config()).then(resp => resp.data);
    }

    const genActions = name => ({
        get: (params) => getData(name, params),
        patch: (params) => patchData(name, params),
    });

    const productiveAreas = () => {
        const baseName = 'productive-areas';
        return genActions(baseName);
    };

    const subProductiveAreas = () => {
        const baseName = 'sub-productive-areas';
        return genActions(baseName);
    };

    const productiveTraining = () => {
        const baseName = 'productive-training';
        return genActions(baseName);
    };

    const workerOccupations = () => {
        const baseName = 'worker-occupations';
        return genActions(baseName);
    };

    const thematicAreas = () => {
        const baseName = 'thematic-areas';
        return genActions(baseName);
    };

    const coursesClassification = () => {
        const baseName = 'courses-classification';
        return genActions(baseName);
    };

    const trainingObjectives = () => {
        const baseName = 'training-objectives';
        return genActions(baseName);
    };

    const trainingModes = () => {
        const baseName = 'training-modes';
        return genActions(baseName);
    };

    const coursesStatus = () => {
        const baseName = 'courses-status';
        return genActions(baseName);
    };

    const agentTypes = () => {
        const baseName = 'agent-types';
        return genActions(baseName);
    };

    const institutionTypes = () => {
        const baseName = 'institution-types';
        return genActions(baseName);
    };

    return {
        thematicAreas,
        productiveAreas,
        subProductiveAreas,
        productiveTraining,
        coursesClassification,
        workerOccupations,
        institutionTypes,
        trainingObjectives,
        trainingModes,
        coursesStatus,
        agentTypes,
    };
};

export const activeElementsAPIS = catalogsActiveElements();