import {
	each,
	pick,
	keys,
	isObject,
	isArray,
	size,
	isEqual,
	concat,
	uniq,
	find,
	filter
} from "lodash";
import i18next from "i18next";
import { hasValue, parseNumber } from './GeneralUtilities';
const { t } = i18next;

/**
 * Regresa el array con las diferencias entre los datos originales y los que se cambiarion
 *
 * @param {object} params
 * @param {object} params.draftBase : Objeto original
 * @param {object} params.draftAter : Objecto cambiado
 * @param {?object} params.fieldsValues : Objeto con los valores reales delos campos , util para los cambios que implican selectores el formato debe ser
 * * {field : {values : [], valueBy : values.prop, labelBy : values.prop,  }}
 * @param {?string} params.index : Sirve para indicar si el campo pertenece a una profundidad especifica
 * @param {?object} params.deepPick : Sirve para indicar si el campo pertenece a una profundidad especifica
 * @param {?object} params.fieldsLabels : Objeto con los labels reales de los formularios a aplicar
 * @param {?array} params.numericFields : Array con los valores que se evaluan como números
 */
function differences({
	draftBase = {},
	draftAter = {},
	fieldsValues = {},
	index = "",
	deepPick = {},
	fieldsLabels = {},
	numericFields = [],
}) {

	const fields = uniq([...keys(draftBase), ...keys(draftAter)]);
	let list = [];

	each(fields, (field) => {
		let fOld = getValue(draftBase, field, fieldsValues, numericFields);
		let fNew = getValue(draftAter, field, fieldsValues, numericFields);
		const fieldLabel = getLabel(index, field, fieldsLabels);

		if (!isEqual(fOld.value, fNew.value)) {
			if ([fOld.type, fNew.type].includes("object")) {
				fOld.value = emptyObject(fOld.value);
				fNew.value = emptyObject(fNew.value);

				list = concat(list, differences({
					draftBase: deepPick?.[index] ? pick(fOld.value, deepPick[index]) : fOld.value,
					draftAfter: deepPick?.[index] ? pick(fNew.value, deepPick[index]) : fNew.value,
					index: fieldLabel,
					deepPick: deepPick,
					fieldsValues: fieldsValues,
					fieldsLabels: fieldsLabels,
					numericFields: numericFields,
				}));
			} else {
				list.push({
					field: fieldLabel,
					old: fOld.value,
					new: fNew.value
				});
			}
		}
	});

	return list;
}

const emptyObject = (value) => value === t("null") ? {} : value;

export const getLabel = (index, field, fieldsLabels) => {
	field = fieldsLabels[field] ?? field;
	index = index ? `${index?.name ?? index} - ` : "";
	let translated = t(field);
	translated = translated === field ? t(translated.replaceAll("_", "-")) : translated;

	return `${index}${translated}`;
};

const multiselectValues = (
	{
		values,
		valueBy,
		labelBy,
	},
	selected
) => {
	if (!size(values) || !size(selected)) {
		return t("empty");
	}

	return filter(values, item => {
		return selected.includes(item[valueBy ?? "value"]);
	}).map(item => item[labelBy ?? "label"]);
};

/**
 * Helper para no tener campos vacios
 * @param {any} value
 * @returns {any}
*/
function getValue(draft, field, fieldsValues, numericFields = []) {

	const values = fieldsValues?.[field];
	let value = draft?.[field];

	if (!hasValue(value)) {
		return {
			value: t(typeof value === "string" && !numericFields.includes(field) ? "empty" : "null"),
			type: "empty"
		};
	}

	if (numericFields.includes(field)) {
		return {
			value: parseNumber(parseFloat(value)),
			type: "numeric"
		};
	}

	if (values) {
		if (isArray(value)) {
			return {
				value: multiselectValues(values, value),
				type: "multi"
			};
		} else {
			const finded = find(values.values, function (item) {
				return item[values.valueBy ?? "value"] === value;
			});
			value = finded?.[values.labelBy ?? "label"];
		}
	}

	if (typeof value === 'boolean') {
		return {
			value: t(value ? "true-alt" : "false-alt"),
			type: 'boolean'
		};
	}

	return {
		value,
		type: isObject(value) ? "object" : "other"
	};
}

/**
 * Funcion que nos regresa un array con las diferencias de los parametros, entre el objeto original y el cambiado
 * @param {object} params
 * @param {array} params.fields : Array con los indices que se van a tomar de ambos objetos
 * @param {object} params.base : Objeto original
 * @param {object} params.after : Objeto con los cambios
 * @param {?object} params.fieldsValues : Objeto con los valores reales delos campos , util para los cambios que implican selectores el formato debe ser
 * * {field : {values : [], valueBy : values.prop, labelBy : values.prop,  }}
 * @param {?object} params.deepPick : Objeto con indices de propiedades que necesitan un filtro extra de los datos que se quieren usar
 * @param {?object} params.fieldsLabels : Objeto con los labels reales de los formularios a aplicar
 * @param {?array} params.numericFields : Array con los valores que se evaluan como números
 * @returns {array}
 */
export function changes({
	fields = [],
	base = {},
	after = {},
	...others
}) {
	
	if (!fields.length) { return []; }

	const draftBase = pick(base, fields);
	const draftAter = pick(after, fields);

	if (!size(draftBase) || !size(draftAter)) { return []; }

	return differences({
		...others,
		draftBase,
		draftAter
	});
}