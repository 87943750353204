import { useDispatch } from "react-redux";
import { lockedWindow, unlockedWindow } from "../../../../store/actions";
import { resolveError } from "../../../common/resolve-error";
import { currentTenant } from "../../../common/validate-tenant";
import { workersApi } from "../../../services/worker";
import { each, trim } from "lodash";
import { getFieldsRequest } from "../../../@components/employees/PersonalInformation/RequestSearch";

export const useReloadWorker = (worker, setWorker, extraOptions = {}, includeRequest = "kardex_worker") => {
  const dispatch = useDispatch();

  async function getDataWorker(key) {
    dispatch(lockedWindow());
    const request = {
      tenant: currentTenant(),
      include: getFieldsRequest(includeRequest),
      ...extraOptions,
      worker: key ?? worker?.key ?? worker,
      limit: 1
    };
    try {
      const response = await workersApi.get(request);
      const responseMatch = response.results[0];
      setWorker(responseMatch);
      return responseMatch
    } catch (error) {
      resolveError(error);
    } finally {
      dispatch(unlockedWindow());
    }
  }

  return [getDataWorker];
};

export const setSearchParam = (newParams, navigate) => {
  if (!window.history.pushState) {
    return;
  }

  const params = Object.entries(newParams);
  const oldParams = new URLSearchParams(window.location.search);

  each(params, (param) => {
    const value = param[1];
    const key = param[0];
    if (value === undefined || value === null) {
      oldParams.delete(key);
    } else {
      oldParams.set(key, value);
    }
  });

  let url = new URL(window.location.href);
  const searchParamsString = oldParams.toString();
  url.search = searchParamsString ? `?${searchParamsString}` : '';

  const newUrl = url.toString();
  window.history.replaceState({ url: newUrl }, null, newUrl);

  if (navigate) {
    navigate(trim(window.location.pathname + url.search));
  }
};