import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ItemIcon } from '../Search/NavItem';
import { valueOrOption } from '../../../../../common/GeneralUtilities';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import { CustomTooltip } from '../../../../../../App/components/Templates/cells';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";

const Header = () => {

	const { t } = useTranslation();
	const {
		title,
		subMenu,
		navigateInside,
		isSubmenu,
		forceHide,
		withBackToMain
	} = useMegaMenu();

	const handleEsc = (e) => {
		const code = e?.keyCode?.toString();
		if (parseInt(code) === 27) {
			forceHide();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEsc);

		return () => document.removeEventListener("keydown", handleEsc);
		//eslint-disable-next-line
	}, []);

	const onReturn = () => {
		if (!isSubmenu) {
			return null;
		}
		navigateInside(null);
	};

	return (
		<Grid
			container
			spacing={0}
			direction="row"
			justifyContent="flex-end"
			alignItems="center"
			columns={{ lg: 14, xl: 15 }}
		>
			<Grid item xs sm md lg xl>
				{withBackToMain && isSubmenu && (
					<CustomTooltip
						title={t("return")}
						className="menu-item-tooltip"
						placement="right"
					>
						<span className='megamenu-return' onClick={onReturn}>
							<ItemIcon icon={'go-back'} />
						</span>
					</CustomTooltip>
				)}
				<span className={`megamenu-title ${isSubmenu ? "pointer" : ""}`} onClick={onReturn}  >
					{valueOrOption(subMenu?.title, title)}
				</span>
			</Grid>
			<Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
				<Stack spacing={1} direction="row" justifyContent={"flex-end"}>
					<IconButton aria-label="close" onClick={forceHide} sx={{ padding: "8px 12px" }}>
						<FontAwesomeIcon icon={faXmark} className="closeModalIcon" />
					</IconButton>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default Header;