import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { format } from 'date-fns';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { uid } from 'uid';
import {
	backDateFormat,
	dateFormat,
	dateValue,
	defaultDate,
	defaultMask,
	makeMask,
	rules,
	validDate
} from "./utilities";
import CustomCalendar from './CustomCalendar';
import { hasValue } from '../../../core/common/GeneralUtilities';
import { KendoLabelContainer } from '../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../sizesUtilities';

/**
 * Input de fechas para el sistema, Estab basado en el Kendo datePicker asi que puede recibir todos los atributos de este
 * @param {object} params
 * @param {?string} params.id - Es el id que sera usado por el input
 * @param {?string} params.size - El tamaño del input
 * @param {?string} params.label - La etiqueta que se muestra en el input
 * @param {?string} params.name - El nombre del input
 * @param {?date} params.min - La fecha mínima que se podra seleccionar con el input
 * @param {?date} params.max - La fecha máxima que se podra seleccionar con el input
 * @param {string|date} params.value - El valor a mostrar en el input, solo puede ser un string cuando se maneja junto con value format
 * @param {string} params.valueFormat - El formato que se maneja tras bambalinas para la fecha
 * @param {string} params.outFormat - El formato que se maneja tras bambalinas para la fecha
 * @param {function} params.onChange - La funcion que se ejecuta al cambiar el valor del input
 * @param {?boolean} params.sideLabel - Indica si se va a manejar el label del input a un lado por default false, flotante
 * @param {?boolean} params.useBackFormats - Indica se para la entrada y salida de la valor del input se va a manejar el formato del backend
 * @returns
 */
const KendoDatePicker = ({
	id,
	label,
	name,
	min,
	max,
	value,
	highlight,
	valueFormat,
	outFormat,
	onChange,
	popupSettings = {},
	sideLabel = false,
	useBackFormats = false,
	...others
}) => {

	if (useBackFormats) {
		valueFormat = backDateFormat;
		outFormat = backDateFormat;
	}

	const editorId = id || "dateP_" + uid();
	const { size, className } = kendoSizeClasses(others);
	value = !value ? null : value;

	const [mask, setMask] = useState(defaultMask());
	const actualValue = useRef(value);

	const change = e => {

		e.targetValue = e.syntheticEvent?.value;
		setMask(e.syntheticEvent?.value);

		if (e.nativeEvent) {
			const parent = e.target._element.parentElement;
			let classN = parent.getAttribute("class").replace("k-focus", "");
			parent.setAttribute("class", classN);
		}

		let newValue = e.value;
		actualValue.current = newValue;
		if (outFormat && newValue) {
			newValue = format(newValue, outFormat);
			e = {
				type: "change",
				value: newValue,
				targetValue: e.targetValue,
				target: { value: newValue },
				dateValue: e.value
			};
			onChange(e);
			return;
		}
		e.type = "change";
		onChange(e);
	};

	useEffect(() => {
		if (actualValue.current !== value) {
			setMask(makeMask(value, "date"));
			actualValue.current = value;
		}
		//eslint-disable-next-line
	}, [value]);

	const inputValue = dateValue(value, valueFormat);

	return <KendoLabelContainer
		label={label}
		editorId={editorId}
		editorValue={true}
		className={"custom-floating-label"}
		sideLabel={sideLabel}
	>
		<DatePicker
			popupSettings={{
				...popupSettings,
				popupClass: "customPopup datepicker-popup"
			}}
			{...others}
			value={inputValue}
			id={editorId}
			name={name ?? editorId}
			onChange={change}
			label=""
			format={dateFormat}
			min={defaultDate(min, "1920/01/01", valueFormat)}
			max={defaultDate(max, "2099/12/31", valueFormat)}
			size={size}
			className={`${className} ${highlight ? 'input-highlight' : ''}`}
			dateInput={MaskedTexForDateInput}
			calendar={CustomCalendar}
			formatPlaceholder={{ mask: mask, maskSize: size }}
		/>
	</KendoLabelContainer>;
};

KendoDatePicker.propTypes = {
  id: PropTypes.any,
  label: PropTypes.any,
  max: PropTypes.any,
  min: PropTypes.any,
  name: PropTypes.any,
  highlight: PropTypes.bool,
  onChange: PropTypes.func,
  outFormat: PropTypes.any,
  popupSettings: PropTypes.object,
  sideLabel: PropTypes.bool,
  useBackFormats: PropTypes.bool,
  value: PropTypes.any,
  valueFormat: PropTypes.any
}

export default KendoDatePicker;

export const MaskedTexForDateInput = ({
	value,
	onChange,
	name,
	formatPlaceholder,
	min,
	max,
	...others
}) => {

	const [maskValue, setMaskValue] = useState(defaultMask());

	useEffect(() => {
		if (hasValue(value)) {
			const formated = format(value, dateFormat);
			if (formated !== maskValue) {
				setMaskValue(formated);
			}
			return;
		}

		setMaskValue(makeMask(formatPlaceholder?.mask, "date"));
		// eslint-disable-next-line
	}, [value, formatPlaceholder?.mask]);

	const handleChange = (e) => {
		if (maskValue === e.target.value) {
			return;
		}
		const eValue = e.value || "";
		const dateParts = eValue.split("/");
		const values = validDate({
			day: dateParts[0],
			month: dateParts[1],
			year: dateParts[2],
			min: min,
			max: max,
		});
		setMaskValue(values[1]);
		onChange({
			value: values[0],
			syntheticEvent: e,
			target: this,
		});
	};

	return (
		<MaskedTextBox
			{...others}
			value={maskValue}
			mask="dD/mM/yYYY"
			rules={rules}
			name={name}
			onChange={handleChange}
			size={formatPlaceholder?.maskSize}
		/>
	);
};
