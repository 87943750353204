import React, { cloneElement, Fragment } from 'react';
// import { useSelector } from 'react-redux';
import { store } from '../store';
import { hasValue, valueOrOption } from '../core/common/GeneralUtilities';
import { getModulePerm } from '../core/@components/navigation/utilities';

/*
 * Método que regresa true o false si el/los permisos
 * a validar existen en los permisos del módulo.
 */

const hasPermission = ({ item, scopes }) => {
	const scopesMap = {};

	scopes.forEach((scope) => {
		Object.values(item).map((permission) => {
			const perm = permission?.action?.toLowerCase();
			if (scope?.toLowerCase() === perm) {
				scopesMap[scope] = permission.has_perm ? true : false;
			}
			return '';
		});
	});

	return Object.values(scopesMap).every((item) => item === true);
};

/*
 * Método que obtiene los permisos por módulo a renderizar
 * y recibe por props los permisos a validar para aplicarlos
 * a un componete hijo recibido por props.
 */

const PermissionsGate = ({ children, scopes = [], errorProps = null }) => {
	const item = getItem();

	if (item === null || item === undefined) {
		return null;
	}

	if (hasPermission({ item, scopes })) {
		return <Fragment>{children}</Fragment>;
	}

	if (errorProps) {
		return cloneElement(children, { ...errorProps });
	}

	return null;
};

export default PermissionsGate;

//* Funcion para leer los permisos del modulo en focus o mediante un path en especifico
const getItem = (modulePath = null) => {
	const focusedWindow = store.getState().navigate?.focused_window;
	const element = store.getState().navigate?.[focusedWindow];
	if (!hasValue(modulePath)) return element?.perms;
	const path = valueOrOption(modulePath, element?.path);
	let currentPath = valueOrOption(path, window.location.pathname).replace('/app/', '');
	if (/\/\d+\//.test(currentPath)) { currentPath = currentPath.replace(/\/\d+\//, '/'); }
	if (currentPath.endsWith('/')) currentPath = currentPath.slice(0, -1);

	return searchItemByPath(currentPath);
};

export const searchItemByPath = (currentPath) => {
	//const routes = useSelector((state) => state.navigate?.routes);
	const routes = store.getState().navigate?.routes;
	let itemPermissions = null;
	if (routes) {
		for (const route of routes) {
			const foundItem = findItemByPath(route, currentPath);
			if (foundItem) {
				itemPermissions = foundItem?.perms;
				break;
			}
		}
	}
	return itemPermissions;
};

const findItemByPath = (item, path) => {
	if (item?.path === path) {
		return item;
	}
	if (item?.children) {
		for (const child of item.children) {
			const response = findItemByPath(child, path);
			if (response) {
				return response;
			}
		}
	}
	return null;
};

export const isEnabledElement = (scopes = [], modulePath = null) => {
	const item = getItem(modulePath);

	if (item === null || item === undefined) {
		return false;
	}

	return hasPermission({ item, scopes });
};

export const ExternalPermission = ({ scope, children, moduleId = null }) => {
	if (!scope || !moduleId || !getModulePerm(moduleId, scope)) return null;
	return children;
};