import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form';
import { ModuleContext } from '../util';

function Preview() {
  const { control } = useContext(ModuleContext);
  const signatures = useWatch({ control, name: `signatures` })

  return (
    <div className="page report" id="preview-payroll-report">
      <table className="table table-sm">
        <thead className="border-top-0">
          <tr className="info-top info-sides text-center">
            <th scope="col" colSpan="7" className="text-center">XXXXXXXXXXXXXX</th>
          </tr>
          <tr className="info-top info-sides">
            <th scope="col" colSpan="3">XXXXXXXXXXXXXX</th>
            <td scope="col" colSpan="3"><b>RFC: </b> XXXXXXXXXX</td>
            <td scope="col" colSpan="3"><b>R. PATRONAL:</b> XXXXXXXXXX</td>
          </tr>
          <tr className="info-sides info-bottom">
            <td scope="col" colSpan="1"><b>Año:</b> 2023</td>
            <td scope="col" colSpan="3"><b>Periodo:</b>

              2314 del 16/07/2023 al 31/07/2023
            </td>
            <td scope="col" colSpan="2"><b>F. CORTE ADMIN.:</b>

              07/07/2023 al: 22/07/2023
            </td>
          </tr>
        </thead>
      </table>

      <br />

      <table className="table table-sm">
        <thead className="sub-bg">
          <tr className="info-sides info-top">
            <th colSpan="2" className="border-end mx-5">Percepciones</th>
            <th className="mx-5"></th>
            <th colSpan="2" className="border-end mx-5">Deducciones</th>
            <th className="mx-5"></th>
            <th colSpan="2" className="mx-5">Conceptos</th>
          </tr>
        </thead>
        <tbody>
          <tr className="info-sides">
            <td colSpan="2" className="border-end mx-4">
              <table className="table table-borderless table-sm">
                <tbody>

                  <tr>
                    <th>P001 - SUELDO</th>
                    <th className="text-end">1,809,917.90</th>
                  </tr>



                  <tr>
                    <th>P039 - COMISIONES</th>
                    <th className="text-end">1,022,435.92</th>
                  </tr>

                  <tr>
                    <th>P098 - COMPENSACIONES</th>
                    <th className="text-end">60,252.90</th>
                  </tr>

                  <tr>
                    <th>P092 - COMPENSACION TEMPORAL</th>
                    <th className="text-end">13,305.62</th>
                  </tr>

                  <tr>
                    <th>P093 - BONO DE PERMANENCIA</th>
                    <th className="text-end">48,000.00</th>
                  </tr>

                  <tr>
                    <th>P095 - GRATIFICACIÓN K REFERIDO</th>
                    <th className="text-end">6,500.00</th>
                  </tr>

                  <tr>
                    <th>P063 - DISPONIBLE</th>
                    <th className="text-end">1,696,562.55</th>
                  </tr>

                  <tr>
                    <th>P097 - INCENTIVOS</th>
                    <th className="text-end">46,100.00</th>
                  </tr>

                  <tr>
                    <th>P088 - COMISIÓN TEC</th>
                    <th className="text-end">8,149.75</th>
                  </tr>

                </tbody>
              </table>
            </td>
            <td className="mx-5"></td>
            <td colSpan="2" className="border-end mx-4">
              <table className="table table-borderless table-sm">
                <tbody>

                  <tr>
                    <th>D001 - ISR</th>
                    <th className="text-end">973,927.39</th>
                  </tr>

                  <tr>
                    <th>D004 - SUBS AL EMPLEO PAGADO</th>
                    <th className="text-end">-303.10</th>
                  </tr>

                  <tr>
                    <th>D005 - IMSS</th>
                    <th className="text-end">78,263.31</th>
                  </tr>

                  <tr>
                    <th>D008 - DESCUENTO VALES DE DESPENSA ELECTRONICO</th>
                    <th className="text-end">112,415.00</th>
                  </tr>

                  <tr>
                    <th>D009 - DESCUENTO VALES</th>
                    <th className="text-end">213.00</th>
                  </tr>


                </tbody>
              </table>
            </td>
            <td className="mx-5"></td>
            <td colSpan="2" className="border-end mx-4">
              <table className="table table-borderless table-sm">
                <tbody>

                  <tr>
                    <th>DIASG - DIAS GRAVADOS</th>
                    <th className="text-end">3,701.00</th>
                  </tr>

                </tbody>
              </table>
            </td>
          </tr>
          <tr className="info-sides info-bottom">
            <th className="text-end">Total Percepciones:</th>
            <th className="text-end border-end">$ 5,260,087.78</th>
            <td className="mx-5"></td>
            <th className="text-end">Total Deducciones</th>
            <th className="text-end border-end">$ 1,403,240.45</th>
            <td className="mx-5"></td>
            <th className="text-end">Total Neto:</th>
            <th className="text-end border-end">$ 3,856,847.33</th>
          </tr>
          <tr>
            <th colSpan="3"  ></th>
            <th colSpan="3" className="pe-2 ms-0">
              <table className="table table-sm">
                <thead className="sub-bg">
                  <tr className="info-sides info-top">
                    <th colSpan="2" className="border-end mx-5">Resumen información</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="info-sides info-bottom">
                    <td colSpan="2" className="border-end mx-4">
                      <table className="table table-borderless table-sm">
                        <tbody>

                          <tr>
                            <th>FAhorro Confianza</th>
                            <th className="text-end">0</th>
                          </tr>

                          <tr>
                            <th>FAhorro Sindicato</th>
                            <th className="text-end">0</th>
                          </tr>

                          <tr>
                            <th>Total Colaboradores</th>
                            <th className="text-end">253</th>
                          </tr>

                          <tr>
                            <th>Pagos Efectivo</th>
                            <th className="text-end">1</th>
                          </tr>

                          <tr>
                            <th>Depósitos CHeq</th>
                            <th className="text-end">252</th>
                          </tr>

                          <tr>
                            <th>Ajuste a Ceros</th>
                            <th className="text-end">1</th>
                          </tr>

                          <tr>
                            <th>Registros sin Pago</th>
                            <th className="text-end">0</th>
                          </tr>

                          <tr>
                            <th>Registros con Pago</th>
                            <th className="text-end">252</th>
                          </tr>

                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </th>
            <th colSpan="3"></th>
          </tr>
        </tbody>
      </table>

      <div id="myChart2" style={{ height: "50px" }}></div>
      <div className="body-spacer"></div>
      <div className="signatures">
        <table className="table table-borderless">
          <tbody>
            <tr>
              {signatures?.map((m, i) => (
                m.is_hidden ? null :
                  <th className="text-center py-0" key={i}>
                    __________________________
                  </th>
              ))}
            </tr>
            <tr>
              {signatures?.map((m, i) => (
                m.is_hidden ? null : <th key={i} className="text-center pt-0">
                  {m.signature.map((m2, i) => (
                    <div key={i}>{m2.name}</div>
                  ))}
                </th>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Preview