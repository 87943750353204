import React from "react";
import Grid from '@mui/material/Grid2';
import { gridSize } from "..";
import { Skeleton } from "@mui/material";

function ViewSkeleton() {

    return (
        <Grid container spacing={5} {...gridSize(12)}>
            {Array.from(Array(2).keys()).map(el =>
                <Grid key={`company-skeleton-group-${el}`} container spacing={2} {...gridSize(12)}>
                    {Array.from(Array(4).keys()).map(el =>
                        <Grid key={`company-skeleton-item-${el}`} {...gridSize(12)}>
                            <Skeleton className="view-section-skeleton" />
                        </Grid>)}
                </Grid>
            )}
        </Grid>
    );
};

export default ViewSkeleton;