import abrhilIcons from "../../../assets/scss/custom-icons/icons/Abrhil";
import applicationsIcons from "../../../assets/scss/custom-icons/icons/Aplicaciones";
import toolsBarIcons from "../../../assets/scss/custom-icons/icons/ToolsBar";
import { ReactComponent as notification } from "../../../assets/scss/custom-icons/svg/notification.svg";
import { ReactComponent as configuration } from "../../../assets/scss/custom-icons/icons/ConfigIcon.svg"
import { ReactComponent as back } from "../../../assets/scss/custom-icons/icons/BackCircle.svg"
import i18next from 'i18next';

export const iconsModule = {
    abrhil_package: toolsBarIcons.abrhil.Icon,
    human_resource_package: applicationsIcons.humanresources.Icon,
    payroll_package: abrhilIcons.payrolls.Icon,
    TIMBRASOFT: toolsBarIcons.timbrasoft.Icon,
    ATTENDANCE_LIST: abrhilIcons.attendance.Icon,
    kiosk: applicationsIcons.kiosk.Icon,
    notification_package: notification,
    additional_packages: configuration,
    BACK: back,
}

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}


export function buildTitle({name, alias}) {
  if (alias?.toLowerCase() === name?.toLowerCase()) {
    return i18next.t(name?.replaceAll("_","-"));
  }
  else if(i18next.t(name) === i18next.t(alias)){
    return `${i18next.t(name?.replaceAll("_","-"))}`;
  }
  return `${alias}`;
}

export function buildTitlePackages({name, alias}) {
  if(i18next.t(name) === i18next.t(alias)){
    return `${i18next.t(name?.replaceAll("_","-"))}`;
  }
  return `${alias}`;
}

export function searchLabel(label) {
  return ACRONYM.some(elemento => elemento === label?.toLowerCase())
}

const ACRONYM = [
  "rrhh",
  "nss",
  "rfc",
  "curp",
  "imss",
  "idse y sua",
  "txt",
  "stps"
]