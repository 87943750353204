import React, { createContext, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { initialFilter } from "../../../../App/components/GridCustomProps";
import { initialDialog } from "../../../../App/components/dialog/FormDialog";
import { useForm } from "react-hook-form";
import { size, uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import { showConfirmNotification } from "../../../../store/actions";
import { useTranslation } from "react-i18next";
import { useRequestLoad } from "../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { establishmentKeyAPI } from "../../../services/training";
import { showSuccessNotification } from "../../../../App/components/Notifications";
import { successUpdated } from "../../../common/notification-messages";
export const initValues = {
    massive_stps_key: null,
    massive_company_representative: null,
    massive_company_signature: null,
    massive_worker_representative: null,
    massive_worker_signature: null,
};

function useGridController() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [filters, setFilters] = useState(initialFilter);
    const [dialog, setDialog] = useState(initialDialog);
    const [counter, setCounter] = useState(0);
    const [massiveEdit, setMassiveEdit] = useState(false);
    const [total, setTotal] = useState(0);
    const modified = useRef([]);

    const [fetch, loading] = useRequestLoad();

    const {
        control,
        reset,
        handleSubmit,
    } = useForm({
        mode: 'onChange'
    });

    const onRefresh = () => setCounter(prev => prev + 1);

    const handleEdit = (item) => setDialog({ data: item, isEdit: !!item, open: true });

    const handleOpen = () => setDialog(prev => ({ ...prev, open: true }));

    const handleCancelMassive = () => {
        modified.current = [];
        reset(initValues);
        setMassiveEdit(false);
    }

    const handleMassiveEdit = () => setMassiveEdit(true);

    const addModified = dataItem => {
        const newData = [...modified.current];
        const itemIndex = newData.findIndex(el => el?.id === dataItem?.id);
        if (newData?.[itemIndex]) {
            newData[itemIndex] = { ...newData?.[itemIndex], ...dataItem };
        } else {
            newData.push(dataItem);
        }
        modified.current = uniqBy(newData, 'id');
    };

    const onSaveMassive = (params) => {
        const filterRequest = filterEmptyProps(params);
        if (!size(filterRequest) && !size(modified.current)) {
            setMassiveEdit(false);
            return;
        };
        dispatch(showConfirmNotification({
            title: t('warning-general-title'),
            message: 'Confirmación de actualización masiva a claves establecimiento',
            onConfirm: async () => {
                let total = 0;
                const resp = await saveMassive(filterRequest);
                total = resp?.data;
                if (!resp) return;
                if (size(modified.current)) {
                    const resp2 = await saveGroup();
                    total += size(resp2?.data);
                }
                if (total) {
                    setMassiveEdit(false);
                    showSuccessNotification(`${total} ${successUpdated().message}`);
                    reset(initValues);
                    onRefresh();
                }
            }
        }));
    }

    const saveMassive = async (request) => {
        const finalRequest = removeMassiveLabel(request);
        if (!size(finalRequest)) return { data: 0 };
        return await fetch({
            api: establishmentKeyAPI.massiveUpdate(finalRequest, filters),
            callback: (resp) => resp,
        });
    }

    const removeMassiveLabel = request => {
        return Object.entries(request)
            .reduce((obj, item) => {
                const newLabel = item[0]?.split('_')?.slice(1)?.join('_')
                obj[newLabel] = request[item[0]];
                return obj;
            }, {});
    }

    const saveGroup = async () => {
        return await fetch({
            api: establishmentKeyAPI.updateMulti({ establish: modified.current }),
            callback: (resp) => resp,
        });
    }

    const onTextChange = (e, dataItem, field) => {
        const selection = e.value;
        const copy = { id: dataItem?.id, [field]: selection };
        addModified(copy);
    }

    return {
        control,
        counter,
        filters,
        dialog,
        loading,
        modified,
        total,
        massiveEdit,
        setTotal,
        handleSubmit,
        onTextChange,
        onRefresh,
        setFilters,
        handleEdit,
        handleOpen,
        setDialog,
        onSaveMassive,
        handleMassiveEdit,
        handleCancelMassive,
        setMassiveEdit,
    };
};

const GridContext = createContext({});

/**
 * @typedef {Object} GridContextValue
 * @property {number} counter
 * @property {Object} filters
 * @property {Object} dialog
 * @property {Function} onRefresh
 * @property {Function} setFilters
 * @property {Function} handleEdit
 * @property {Function} setDialog
 * @property {Function} handleOpen
 */

/**
 * @returns {GridContextValue}
 */

export const useGridContext = () => useContext(GridContext);

export default function GridController({
    children,
}) {
    const values = useGridController();

    return (
        <GridContext.Provider value={values}>
            {children}
        </GridContext.Provider>
    );
};

GridController.propTypes = {
    children: PropTypes.any,
};

export const filterEmptyProps = (object = {}) => {
    return Object.entries(object)
        .filter(item => item[1])
        .reduce((obj, item) => {
            obj[item[0]] = object[item[0]];
            return obj;
        }, {});
};