import { config } from "./config";
import axios from "axios";
import {
	getUrl,
} from './ServerAPI';
import { valueOrOption } from '../common/GeneralUtilities';

const SocketsApi = () => {

	const downloadReport = async (taskID, type = "report") => {
		type = valueOrOption(type, "report");
		const url = getUrl(`download-${type}/${taskID}`, true);
		let headers = new Headers(config().headers);

		return fetch(url, {
			headers: headers
		})
			.then((response) => {
				if (response.ok) {
					return response.blob();
				} else {
					return Promise.reject("error 404");
				}
			})
			.then((blob) => blob);
	};

	const cancelTask = async (taskID, apiName) => {
		const url = getUrl(`${apiName}/${taskID}/cancel`, true);
		return axios.delete(url, config()).then((response) => response.data);
	};

	// const report = async (taskID, type = "report") => {

	//   const url = getUrl(`download-${type}/${taskID}`, true);
	//   let headers = new Headers(config().headers);

	//   return fetch(url, {
	//     headers: headers
	//   })
	//     .then((response) => {
	//       if (response.ok) {
	//         return response.blob();
	//       } else {
	//         return Promise.reject("error 404");
	//       }
	//     })
	//     .then((blob) => blob);
	// }


	return {
		downloadReport,
		cancelTask,
	};
};

export const socketsApi = SocketsApi();