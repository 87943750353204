import React from 'react'
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Preview from './Preview';

function File() {
  const { t } = useTranslation()

  return (
    <Grid sx={{ position: "relative" }}>
      <div className="label-title"><b className="title-item">{t("preview")}</b></div>
      <Grid className="container p-2" style={{ overflow: "auto" }}>
        <Preview />
      </Grid>
    </Grid>
  )
}

export default File