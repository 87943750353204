import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { setIcons, setSearch, showCustomNotificationDialog } from '../../../../store/actions';
import { closeFocusedWindow } from '../../../../store/triggers/navigate';
import IconUse from '../../../common/IconUse';

const usePreventZoom = (scrollCheck = true, keyboardCheck = true) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [zoom, setZoom] = useState(100);

	const hasEspecialKey = (e) => (e.ctrlKey || e.metaKey);

	const updateZoom = () => {
		setTimeout(() => {
			const { outerWidth, innerWidth } = window;
			const zoomUsed = parseInt((outerWidth / innerWidth) * 100);
			setZoom(zoomUsed);
		}, [300]);
	};

	const handleKeydown = (e) => {
		const zoomKeys = ["61", "107", "173", "109", "187", "189"];
		const closeKeys = ["81", "69"];
		const avoidedKeys = ["80", "87"];
		const searchCommandKeys = ["66", "83", '70'];
		const iconsMenuCommandKeys = ['73'];
		const code = e?.keyCode?.toString();

		if (
			keyboardCheck &&
			hasEspecialKey(e) &&
			(
				zoomKeys.includes(code) ||
				closeKeys.includes(code) ||
				avoidedKeys.includes(code) ||
				searchCommandKeys.includes(code)
				|| iconsMenuCommandKeys.includes(code)
			)
		) {
			if (zoomKeys.includes(code)) {
				return updateZoom();
			}

			e.preventDefault();
			if (closeKeys.includes(code)) {
				return dispatch(closeFocusedWindow());
			}

			if (searchCommandKeys.includes(code)) {
				return dispatch(setSearch(true));
			}
			if (iconsMenuCommandKeys.includes(code) && process.env.REACT_APP_ABR_MODE === "localhost") {
				return dispatch(setIcons(true));
			}
		}
	};

	const handleWheel = (e) => {
		if (scrollCheck && hasEspecialKey(e)) {
			updateZoom();
		}
	};

	useEffect(() => {
		updateZoom();
		//eslint-disable-next-line
	}, [zoom]);

	useEffect(() => {
		document.addEventListener("keydown", handleKeydown);
		document.addEventListener("wheel", handleWheel, { passive: false });

		return () => {
			document.removeEventListener("keydown", handleKeydown);
			document.removeEventListener("wheel", handleWheel);
		};
		//eslint-disable-next-line
	}, [scrollCheck, keyboardCheck]);

	const isApple = /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);

	const onClick = () => {
		dispatch(showCustomNotificationDialog({
			maxWidth: "sm",
			title: t("Ajusta el Zoom de tu Navegador"),
			// message: <ZoomInformtion />,
			description: <ZoomDescriptionTips isApple={isApple} />,
			icon: "expand",
		}));
	};

	return {
		zoom,
		onClick
	};
};

export default usePreventZoom;

// const ZoomInformtion = () => {
// 	const { t } = useTranslation();
// 	const { zoom } = usePreventZoom();
// 	return (
// 		<div className='blue-strongs' style={{ paddingTop: "30px" }}>
// 			<span>
// 				{t(`browser-zoom`)} <strong > {zoom}%</strong>
// 			</span>
// 		</div>
// 	);
// };

const ZoomDescriptionTips = ({
	isApple
}) => {

	const { t } = useTranslation();
	return (
		<div style={{ paddingInline: "5px", paddingBottom: "20px" }}>
			<span>{t("zoom-used-msg")}:</span>
			<div className='keyboard-card'>
				<div className='keyboard-card-item'>
					<span>{t("Para acercar")}</span>
					<div className='keyboard-group'>
						<CommandKey isApple={isApple} />
						<span>+</span>
						<ComplementKey symbol="+" />
					</div>
				</div>
				<div className='keyboard-card-item'>
					<span>{t("Para alejar")}</span>
					<div className='keyboard-group'>
						<CommandKey isApple={isApple} />
						<span>+</span>
						<ComplementKey symbol="-" />
					</div>
				</div>
			</div>
		</div>
	);
};

const ComplementKey = ({ symbol }) => {
	return (
		<div className='key-wrap'>
			<IconUse
				icon={"key"}
				style={{ fontSize: "100%" }}
			/>
			<span>{symbol}</span>
		</div>
	);
}

const CommandKey = ({ isApple }) => {
	return (
		<div className='key-wrap'>
			{isApple ?
				<IconUse
					icon={"commandKey"}
					style={{ fontSize: "100%" }}
				/>
				:
				<>
					<IconUse
						icon={"key"}
						style={{ fontSize: "100%" }}
					/>
					<span className={'other'}>CTRL</span>
				</>
			}
		</div>
	)
}