import React from 'react';
import { Button } from "@progress/kendo-react-buttons";
import i18next from 'i18next';
const { t } = i18next;

/**
 * Formulario que maneja el column menu de las tablas del sistema
 * @param {object} params
 * @param {boolean} params.disabled - Deshabilita el botón que permite filtrar
 * @param {function} params.cleanFilter - Función que quita el filtro aplicado
 * @param {function} params.changeFilters - Función que realiza el fitro al servidor
 * @param {ReactElement} params.children - Elemento que se muestra como filtro a aplicar
 * @returns {TableFilterComponent}
 */
const Form = ({
	disabled,
	cleanFilter,
	changeFilters,
	children,
}) => {

	return (
		<div className="kendo-grid-filter-menu-container">
			<div className="k-filter-menu k-group k-reset k-state-border-up" onSubmit={changeFilters}>
				<div className="k-filter-menu-container class-first-open" id={"grid-filter-container"}>
					{children}
					<div className="k-actions k-actions-stretched">
						<Button className={"default-k"} type={disabled ? "submit" : "reset"} onClick={cleanFilter}>
							{t("clean")}
						</Button>
						<Button className={"default-k"}  onClick={changeFilters} disabled={disabled} themeColor={"primary"} >
							{t("filter")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form;