import PropTypes from "prop-types"
import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Grid } from '@mui/material'
import Fieldset from '../../../../@components/layout/Fieldset';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import Masonry from '@mui/lab/Masonry';
import useConfigurations from './useConfigurations';
import GridNoRecordsRender from '../../../../../App/components/Templates/GridNoRecordsRender';
import Item from './Item';
import { usePackages } from '../../usePackages';
import useItem from './useItem';
import ModalComponent from './ModalComponent';
import Button from '../../../../styled/Button';

function Index() {

  const { selectCategory } = usePackages();
  const { data, getData, viewComponent } = useConfigurations();
  
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const element = document.getElementsByClassName('k-window-content')[0];
    if (!element) return;
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setHeight(entry.contentRect.height);
      }
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);

  if(viewComponent){
    return <div style={{ height: height - 49 }} id="p-configurations" className='p-3'>
      {selectCategory?.component}
    </div>
  }
  
  return (
    <div style={{ height: height - 49 }} id="p-configurations">
      {data?.length ? <Container data={data} getData={getData}/> : <Empty />}
    </div>
  )
}

export default Index

function Container({ data, getData }) {
  return (
    <Masonry columns={2} spacing={2} style={{ margin: "0px" }}>
      {data?.map((m) => 
        <Card data={m} key={m.id} getData={getData}/>
      )}
    </Masonry>
  )
}

Container.propTypes = {
  data: PropTypes.any,
  getData: PropTypes.any
}


function Card({ data, getData }) {

  const { selectPackage, chill } = usePackages();

  const { 
    handleSubmit, 
    handleSend, 
    control, 
    fields, 
    edit, 
    setEdit, 
    handleCancel,
    modalDiff,
    toggle,
    changes,
    updatePackages,
    handleChange
  } = useItem(data, getData);

  return (
    <form autoComplete='off' onSubmit={handleSubmit(handleSend)}>
      <Fieldset
        title={data.alias.toUpperCase()}
        design="box"
      >

        <Grid item xs={12}>
          {fields.map((m, i) => {
            return <Item control={control} m={m} key={m.id} i={i} edit={edit} handleChange={handleChange}/>
          })}
        </Grid>
        {(selectPackage?.value || chill?.value) && <div className='btn-edit'>
          {edit ? <BtnSave handleCancel={handleCancel} /> : <BtnEdit setEdit={setEdit} />}
        </div>}
      </Fieldset>
      {modalDiff && <ModalComponent updatePackages={updatePackages} toggle={toggle} modalDiff={modalDiff} changes={changes} />}
    </form>
  )
}

Card.propTypes = {
  data: PropTypes.any,
  getData: PropTypes.any
}


function BtnSave({ handleCancel }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button onClick={handleCancel}>
        {t('cancel')}
      </Button>
      <Button type='submit' >
        {t('save')}
      </Button>
    </React.Fragment>
  )
}

BtnSave.propTypes = {
  handleCancel: PropTypes.any
}

function BtnEdit({ setEdit }) {

  const { t } = useTranslation();

  function handleEdit() {
    setEdit(true)
  }

  return (
    <Button className='btn-p' type="button" onClick={handleEdit}>
      <FontAwesomeIcon icon={faPen} />
      {t('edit')}
    </Button>
  )
}

BtnEdit.propTypes = {
  setEdit: PropTypes.func
}

function Empty() {
  const { t } = useTranslation();

  return (
    <div style={{
      height: "100%",
      alignContent: "center"
    }}>
      <GridNoRecordsRender
        init={true}
        description={t("no-results")}
      />
    </div>
  )
}