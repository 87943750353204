// --language
import i18next from "i18next";
const { t } = i18next;
// --yup
import * as yup from 'yup';
import { format } from 'date-fns';
import { validateNumeric } from "../../../../../../common/validations/numeric";
import arrayValidation from "../../../../../../common/validations/array";
import { stringValidation } from "../../../../../../common/validations/stringValidation";
import { structureDefaults } from "../../../../../../common/StructureUtilities";
import { computedType } from "../../../../../PayrollPeriods/dictionaries";
import { current_date, current_month, current_year } from '../../../../../../../App/components/Dates/utilities';
// import { FirstStepLinealDifference, SecondStepLinealDifference } from "../components/Modal/Payroll/LinealDifference";
// import { FirstStepPerceptionDifference, SecondStepPerceptionDifference } from "../components/Modal/Payroll/PayrollPerceptionsDifference";

// Diccionario de reportes a mostrar en cálculo de nómina
export const PayrollCalculateDictionary = [
  { id: 0, name: "payroll-report" },
  { id: 1, name: "payroll-report-employer-registration" },
  { id: 2, name: "payroll-report-incidences" },
  { id: 3, name: "payroll-report-excel-general" },
  { id: 4, name: "payroll-report-concept" },
];

// Diccionario de reportes a mostrar cuando hay permisos
export const PayrollPermissionsDictionary = [
  "payroll-report-dedu-perc"
];
/** STEPPER **/

// // Diccionario de reportes con stepper
export const StepperDictionary = [
  "payroll-report-difference-linear",
  "payroll-report-perceptions-difference",
];

// // FieldLabels
// export const LinealDifferenceFieldLabels = {
//   payroll_period_initial: "initial_period",
//   initial_year: `${t("initial_period")} - ${t("year")}`,
//   initial_month: `${t("initial_period")} - ${t("month")}`,
//   difference: t("difference"),
//   payroll_period_final: t("end_period"),
//   end_year: `${t("end_period")} - ${t("year")}`,
//   end_month: `${t("end_period")} - ${t("month")}`,
// }

// export const PayrollPerceptionsDifferenceFieldLabels = {
//   payroll_period_initial: "initial_period",
//   initial_year: `${t("initial_period")} - ${t("year")}`,
//   initial_month: `${t("initial_period")} - ${t("month")}`,
//   assignment: t("assignment"),
//   difference: t("difference"),
//   payroll_period_final: "end_period",
//   end_year: `${t("end_period")} - ${t("year")}`,
//   end_month: `${t("end_period")} - ${t("month")}`,
// }

// // Steps
// export const steps = {
//   initialPayroll: 1,
//   finalPayroll: 2,
// }

// export const LinealDifferenceSteps = [
//   {
//     id: steps.initialPayroll,
//     container: < FirstStepLinealDifference />,
//     stepFields: ["initial_year", "initial_month", "difference", "payroll_period_initial", "end_year", "end_month", "payroll_period_final"],
//   }, {
//     id: steps.finalPayroll,
//     container: < SecondStepLinealDifference />,
//     stepFields: [],
//   }
// ]

// export const PayrollPerceptionsDifferenceSteps = [
//   {
//     id: steps.initialPayroll,
//     container: < FirstStepPerceptionDifference />,
//     stepFields: ["initial_year", "initial_month", "difference", "payroll_period_initial", "assignment", "end_year", "end_month", "payroll_period_final",],
//   }, {
//     id: steps.finalPayroll,
//     container: < SecondStepPerceptionDifference />,
//     stepFields: [],
//   }
// ]

/** FORM **/
export const formDefault = {
  open: false,
  item: {},
  type: ""
};

/** STRUCTURE BY REPORT **/
export const structureKeysByReport = {
  payrollReportNetosConstant: {
    structure_keys: ['branch', 'employer_registration']
  },

  payrollReportDeduPercConstant: {
    structure_keys: ['employer_registration']
  },
  payrollReportDifferenceLinearConstant: {
    structure_keys: ['branch', 'employer_registration']
  },
  payrollReportCaptureNotAppliedConstant: {
    structure_keys: ['branch', 'employer_registration']
  },
  payrollReportPerceptionsDifferenceConstant: {
    structure_keys: ['branch', 'employer_registration']
  },
  payrollReportExcelGeneralConstant: {
    structure_keys: ['branch', 'employer_registration', 'area',]
  },
};

/** MODAL CONSTANTS **/
export const computed_types = [
  { id: computedType.normal, key: "01", prefix: "n", name: t("normal") },
  { id: computedType.settlement, key: "02", prefix: "f", name: t("settlement") },
  { id: computedType.payrollTips, key: "03", prefix: "p", name: t("payroll-tips") },
  { id: computedType.bonusDays, key: "04", prefix: "a", name: t("bonus-days") },
  { id: computedType.ptu, key: "05", prefix: "u", name: t("PTU") },
  { id: computedType.savingFund, key: "06", prefix: "s", name: t("saving-fund") },
  { id: computedType.special, key: "07", prefix: "e", name: t("special") },
  // { id: 8, key: "08", prefix: "fa", name: t("settlement accumulated") },
  // { id: 9, key: "09", prefix: "ft", name: t("settlement test") },
];

export const structureClearValue = '*';


export const actionType = {
  byStructure: 1,
  byWorkers: 2
};

export const REPORT_MAP = [
  { id: 1, name: "Reporte de vacaciones" },
  { id: 2, name: "Reporte de vacaciones autorizadas" },
  { id: 3, name: "Reporte de ausentismos" },
  { id: 4, name: "Reporte de ausentismos autorizados" },
  { id: 5, name: "Reporte de ajustes de asistencia" },
  { id: 6, name: "Reporte de ajustes de asistencia por colaborador" },
  { id: 7, name: "Reporte de requisiciones" },
  { id: 8, name: "Reporte de conceptos" },
  { id: 9, name: "Reporte de horas extras" },
  { id: 10, name: "Reporte de horas extras autorizadas" },
  { id: 11, name: "Reporte de descanso trabajado" },
  { id: 12, name: "Reporte de turnos doblesr" },
];

export const ORDER_BY = [
  { id: 1, name: "Reg. Patronal - Sucursal - Área - Depto - clave trabajador" },
  { id: 2, name: "Clave de trabajador" },
  { id: 3, name: "Sucursal - Clave de trabajador" },
  { id: 4, name: "Área - Clave de trabajador" },
  { id: 5, name: "Departamento - Clave de trabajador" },
];

export const MOPERS_STATUS = [
  { id: 1, name: "mopers en proceso" },
  { id: 2, name: "mopers autorizados" },
  { id: 3, name: "mopers cancelados" },
];

export const DATE_TO_USE = [
  { id: 1, name: "Inicio de movimiento" },
  { id: 2, name: "Solicitud" },
  { id: 3, name: "Autorizacion" },
  { id: 4, name: "Cancelacion" }
];

export const typeRange = [
  { id: 1, name: t("period") },
  { id: 2, name: t("month") },
  { id: 3, name: t("year") }
];

export const cutTypes = [
  { id: 1, name: t("period") },
  { id: 2, name: t("month") },
];

export const assignmentOptions = [
  { id: 1, name: t("PERC") },
  { id: 2, name: t("total-net") }
];

export const cutOptions = [
  {
    id: 1, name: t("active-zero-totals")
  },
  { id: 2, name: t("show-CAP4") },
];

export const totalOptions = [
  { id: 1, name: t("perceptions") },
  { id: 2, name: `${t("perceptions")} ${t("and")} ${t("deductions")}` },
  { id: 3, name: `${t("perceptions")},  ${t("deductions")} ${t("and")} ${t("concepts")}` },
];


export const groupOptions = [
  { id: 1, name: t("group-compensations") },
  { id: 2, name: t("close-period") },
  { id: 3, name: `${t("group-by")} ${t("periods")}` },
];


export const anexOptions = [
  { id: 1, name: t("data-of.employee") },
  { id: 2, name: t("worker-structure-info") },
  { id: 3, name: t("benefits") },
  { id: 4, name: t("movement") },
  { id: 5, name: t("absenteeism_") },
  { id: 6, name: `${t("exempt")} ${t("and")} ${t("aggravated")}` },
];

export const generalPayrollOptions = [
  { id: 1, name: t("zeros-by-worker-leave"), value: 'add_settlement' },
  { id: 2, name: t("not-pay-payroll"), value: 'add_payroll_not_fixed' },
  { id: 3, name: t("zeros-by-absenteeism-or-medical-leave"), value: 'add_payroll_zero_AD' },
  { id: 4, name: t("split-IMP3"), value: 'divide_51ISPT_MIP3' },
  { id: 5, name: t("Bajas con saldo real"), value: 'divide' },
];


export const generalViewActions = {
  generalPayroll: [
    { id: 1, name: t("general"), value: 'general_view', desc: "Genera un reporte con todos los colaboradores en la nómina general." },
    { id: 2, name: t("individual"), value: 'individual_view', desc: "Genera un reporte por colaborador específico." },
  ],
  perceptionsAndDeductions: [
    { id: 1, name: t("report-by-year"), value: 'by_year_view' },
    { id: 2, name: t("report-by-worker"), value: 'by_worker_view' },
  ]
};

export const orderTypes = [
  { id: 1, name: t("key") },
  { id: 2, name: t("name") },
  { id: 10, name: t("payment-type") }
];

export const sortableItems = [
  { id: 1, value: t("key") },
  { id: 2, value: t("name") },
  { id: 3, value: t("department") },
  { id: 4, value: t("job-position") },
  { id: 5, value: t("movement-type") },
  { id: 6, value: t("user"), },
  { id: 7, value: t("date") }
];

export const sortableItemsConcepts = [
  { id: 1, value: t("key") },
  { id: 2, value: t("name") },
  { id: 3, value: t("employer-registration") },
  { id: 4, value: t("business-name") },
  { id: 5, value: t("branch") },
  { id: 6, value: t("area"), },
  { id: 7, value: t("department") },
  { id: 8, value: t("concept") }
];

export const sortableDifferenceItems = [
  { id: 1, value: t("key") },
  { id: 2, value: t("name") },
  { id: 8, value: `${t("percentage")} ${t("periods")}` },
  { id: 9, value: `${t("percentage")} ${t("incidences")}` },
];

export const loansReportModes = [
  {
    value: true,
    label: t("Resumen por préstamo"),
    desc: "Genera un reporte con todos los préstamos"
  },
  {
    value: false,
    label: t("Detalle de abonos"),
    desc: "Genera un reporte con todos los préstamos que tienen abonos"
  }
];

export const loansStatus = [
  { id: 0, name: t("_all") },
  { id: 1, name: t("active") },
  { id: 2, name: t("inactive") },
  { id: 3, name: t("payed") },
];

export const loansSearch = [
  { id: 1, name: t("Rango de fechas") },
  { id: 2, name: t("payroll-period") },
  { id: 3, name: t("year") },
  { id: 4, name: `${t("year")} ${t("and")} ${t("month")}` },
];


/** INIT VALUES **/

// -- INITIAL VALUES BY REPORT
const commonPayrollInitValues = (structure, period) => ({
  ...structureDefaults(structure, structureClearValue),
  computed_type: computedType.normal,
  payroll_type: period?.payroll_type ?? 1,
  year: period?.year ?? current_year(),
  month: period?.month ?? current_month(),
  period: null,
  is_accumulated: true,
  is_cancelled: true,
  is_socket: true,
  worker_type: [1, 2],
  type_range: 1,
  type_cut: 1,
  single_file: true,
});

const generalPayrollInitValues = () => ({
  is_cut_employer_registration: true,
  view: 'general_view',
});

const payrollSummaryInitValues = () => ({
  type_manager: [1, 2],
});

const liquidPaymentInitValues = () => ({
  order_by: 1,
  payment_types: [1, 2, 3, 4],
});

const perceptionsAndDeductionsValues = () => ({
  view: 'by_year_view',
  is_total_general: true
});

const periodsDifferenceValues = (period) => ({
  assignment_type: 1,
  difference: 10,
  order_by: [],
  initial_computed_type: computedType.normal,
  initial_payroll_type: period?.payroll_type ?? 1,
  initial_year: period?.year ?? format(new Date(), "yyyy"),
  initial_month: period?.month ?? parseInt(format(new Date(), "MM")),
  payroll_period_initial: null,
});

const excelGeneralValues = () => ({
  is_total_general: true
});

const loansInitValues = () => ({
  is_loan: true,
  individual: false,
  worker: null,
  status: 0,
  search_type: 1,
  initial_date: current_date(),
  start_date: current_date(),
  end_date: current_date(),
  concept: null
});

const initMoperReportsPending = () => ({
  payroll_calculation: true,
  attendance: true,
});

const moperReportInitValues = () => ({
  start_date: current_date(),
  end_date: current_date(),
  by_structure: true,
  order_by: 1
});

const commonDiffValues = (structure, period) => ({
  ...commonPayrollInitValues(structure, period),
  ...periodsDifferenceValues(period),
});

/* General initValues */
export const initialValues = (structure, period, type) => ({
  'payrollReportConceptConstant': {
    ...commonPayrollInitValues(structure, period),
    order1: true
  },
  'payrollReportConstant': {
    ...commonPayrollInitValues(structure, period),
    ...generalPayrollInitValues(),
  },
  'payrollReportEmployerRegistrationConstant': {
    ...commonPayrollInitValues(structure, period),
    ...payrollSummaryInitValues(),
  },
  'payrollReportNetosConstant': {
    ...commonPayrollInitValues(structure, period),
    ...liquidPaymentInitValues(),
  },
  'payrollReportDeduPercConstant': {
    ...commonPayrollInitValues(structure, period),
    ...perceptionsAndDeductionsValues(),
  },
  'payrollReportDifferenceLinearConstant': commonDiffValues(structure, period),
  'payrollReportPerceptionsDifferenceConstant': commonDiffValues(structure, period),
  'payrollReportExcelGeneralConstant': {
    ...commonPayrollInitValues(structure, period),
    ...excelGeneralValues(),
  },
  'payrollReportLoansConstant': {
    ...commonPayrollInitValues(structure, period),
    ...loansInitValues(),
  },
  "moperReportsConstant": {
    ...commonPayrollInitValues(structure, period),
    ...moperReportInitValues(),
  },
  "moperReportsPendingConstant": {
    ...initMoperReportsPending(),
  },
  "moperReportsAuthorizedExcelConstant": {
    ...commonPayrollInitValues(structure, period),
    ...moperReportInitValues(),
  },
}[type] ?? commonPayrollInitValues(structure, period));

/** VALIDATIONS **/

//-- VALIDATIONS BY REPORT
const commonPayrollSchema = yup.object().shape({
  year: validateNumeric({ required: true }),
  month: yup.number().when(['type_range'], {
    is: (type_range) => type_range !== 3,
    then: (schema) => {
      return schema
        .required(t('validation:required'));
    },
    otherwise: () => validateNumeric({ required: false }),
  }),
  period: yup.number().when(['backend_path'], {
    is: (backend_path) => backend_path !== 'payroll-report-difference-linear' && backend_path !== 'payroll-report-dedu-perc' && backend_path !== 'payroll-report-perceptions-difference',
    then: (schema) => {
      return schema
        .required(t('validation:required'));
    },
    otherwise: () => validateNumeric({ required: false }),
  }),
  worker: yup.string().when(['view', 'backend_path', 'perceptions_view'], {
    is: (view, backend_path) => (view === 'individual_view' || (backend_path === 'payroll-report-dedu-perc' && view === 'by_worker_view') || (backend_path === 'payroll-report-taxes')),
    then: (schema) => {
      return schema
        .required(t('validation:required'));
    },
    otherwise: () => stringValidation({ required: false })
  }),
  worker_type: yup.array().when(['backend_path'], {
    is: (backend_path) => backend_path === 'payroll-report-excel-general' || backend_path === 'payroll-report-concept' || backend_path === 'payroll-report-capture-not-applied',
    then: (schema) => {
      return schema
        .required(t('validation:required'))
        .min(1, t('validation:gte.array', { value: 1 }));
    },
    otherwise: () => arrayValidation({ required: false }),
  }),
});

const payrollSummarySchema = yup.object().shape({
  type_manager: arrayValidation({ required: true, min: 1 }),
});

const liquidPaymentSchema = yup.object().shape({
  payment_types: arrayValidation({ required: true, min: 1 }),
});

const perceptionsAndDeductionsSchema = yup.object().shape({
  initial_date: yup.string().when(['view'], {
    is: (view) => view === 'by_year_view',
    then: (schema) => {
      return schema
        .required(t('validation:required'));
    },
    otherwise: () => stringValidation({ required: false }),
  }),
  final_date: stringValidation({ required: true }),
});

const linealDifferenceSchema = yup.object().shape({
  initial_year: validateNumeric({ required: true }),
  end_year: validateNumeric({ required: true }),
  initial_month: validateNumeric({ required: true }),
  end_month: validateNumeric({ required: true }),
  difference: validateNumeric({ required: true }),
  payroll_period_initial: validateNumeric({ required: true }),
  payroll_period_final: validateNumeric({ required: true }),
});

const payrollReportPaymentTransferSchema = yup.object().shape({
  payment_type: validateNumeric({ required: true }),
});

const payrollLoansSchema = yup.object().shape({
  worker: yup.string().when("individual", {
    is: true,
    then: () => stringValidation({ required: true }),
    otherwise: () => stringValidation({ required: false })
  }),
  concept: yup.object().test(
    "oneRequired",
    t("validation:concept_required"),
    function (value) {
      return value && Object.values(value).some(v => v === true);
    }
  ),
});

const moperReportsPending = yup.object().shape({
});

const moperReportSchema = yup.object().shape({
  start_date: stringValidation({ required: true }),
  end_date: stringValidation({ required: true }),
  moper_status: stringValidation({ required: true }),
  report_type: stringValidation({ required: true }),
  date_to_use: stringValidation({ required: true }),
  workgroup: stringValidation({ required: false }).when(
    "by_workgroup",
    {
      is: true,
      then: () => stringValidation({ required: true }),
    }
  ),
  worker: stringValidation({ required: false }).when(
    "by_worker",
    {
      is: true,
      then: () => stringValidation({ required: true }),
    }
  ),
});

const moperReportsAuthorizedSchema = yup.object().shape({
  start_date: stringValidation({ required: true }),
  end_date: stringValidation({ required: true }),
  moper_status: stringValidation({ required: true }),
});

const payrollConceptSchema = yup.object().shape({
  concept: yup.object().test(
    "oneRequired",
    t("validation:concept_required"),
    function (value) {
      return value && Object.values(value).some(v => v === true);
    }
  ),
});

/*General validation*/
export const validationSchema = {
  payrollReportConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
  }),
  payrollReportEmployerRegistrationConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...payrollSummarySchema.fields,
  }),
  payrollReportNetosConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...liquidPaymentSchema.fields,
  }),
  payrollReportDeduPercConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...perceptionsAndDeductionsSchema.fields,
  }),
  payrollReportDifferenceLinearConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...linealDifferenceSchema.fields,
  }),
  payrollReportCaptureNotAppliedConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
  }),
  payrollReportIncidencesConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
  }),
  payrollReportExcelGeneralConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
  }),
  payrollReportTaxesConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
  }),
  payrollReportConceptConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...payrollConceptSchema.fields,
  }),
  payrollReportPerceptionsDifferenceConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...linealDifferenceSchema.fields,
  }),
  payrollReportPaymentTransferConstant: yup.object().shape({
    ...commonPayrollSchema.fields,
    ...payrollReportPaymentTransferSchema.fields
  }),
  payrollReportLoansConstant: yup.object().shape({
    ...payrollLoansSchema.fields
  }),
  moperReportsConstant: yup.object().shape({
    ...moperReportSchema.fields
  }),
  moperReportsPendingConstant: yup.object().shape({
    ...moperReportsPending.fields
  }),
  moperReportsAuthorizedExcelConstant: yup.object().shape({
    ...moperReportsAuthorizedSchema.fields
  }),
};
