import PropTypes from "prop-types"
import React from "react";
import IconUse from "../../../core/common/IconUse";


function CloseButton({ closeToast }) {

    return (
        <button
            className="close-toast-button"
            onClick={closeToast}
        >
            <IconUse icon="SlimClose" />
        </button>
    );
}

CloseButton.propTypes = {
  closeToast: PropTypes.func
}

export default CloseButton;