import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { each, forEach, size, isFunction, first, omit } from 'lodash';
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid2";
import FormDialog from "../../dialog/FormDialog";
import { workersApi } from "../../../../core/services/worker";
import { parseRequest } from "../../../../core/common/parse-request";
import { pageable, selectedItem } from "../../GridCustomProps";
import { currentTenant, hasErrorTetant } from "../../../../core/common/validate-tenant";
import { showNotificationWarning } from "../../../../store/actions";
import { errorTenant } from "../../../../core/common/notification-messages";
import AdvanceFilters from "../../Buttons/AdvanceFilters";
import ButtonsContainer from "../../Buttons/ButtonsContainer";
import Export from "../../Buttons/Export";
import Refresh from "../../Buttons/Refresh";
import { getWorkerFilterFields } from "../../../../core/@components/employees/filterFunctions";
import Table from "../../../../core/@components/employees/Table";
import { tableFields, include, getPrefix, customColumnsOptions } from "../../../../core/@components/employees/constants";
import useController, { getTitles } from "../ComonControls";
import FormInput from "../../../../core/@components/form/Field";
import Actions from '../Actions';
import { Container } from '../Container';
import { hasValue, valueOrOption } from '../../../../core/common/GeneralUtilities';
import SearchField, { isLetter, isNumber, onlyLetters, onlyNumbers } from '../SearchField';
import ExportDialog from "../../Export/ExportDialog";
import { useRequestLoad } from "../../../../core/modules/MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { TooltipBody } from './TooltipBody';
import { columnsToExport, urlExport } from './constants';
import { ModalContentProps, ModalProps } from './propsDescriptions';
import { conditional } from '../../../../core/@components/grid/CommandCell';


export const ModalContent = ({
	onClose,
	handleCloseDialogForm,
	extraFilters,
	extraParams,
	initSearchValue,
	selectBy = "key",
	onlyActives = false,
	includeTo,
	forPayroll = false,
	extraFields = "",
	prefix = "",
	getApi,
	workers,
	multi,
	fromMopers = false,
	with_permission_user = true,
	includeData = false,
	setWorkersSelectedData = [],
	workersSelectedData = [],
	children,
	withoutActions = false,
	withFinishSelection = true,
	withAcceptButton = false,
	advanceFilterProps = {},
	height = "calc(100vh - 464px)",
	...others
}) => {

	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [init, setInit] = useState(false);
	const [filterFields, setFilterFields] = useState({});
	const [openDialogExport, setOpenDialogExport] = useState(false);
	const [fieldSelection, setFieldSelection] = useState([]);
	const searchOnChange = useRef(true);
	const searchValue = useRef(null);
	const dispatch = useDispatch();
	const [performRequest, loading] = useRequestLoad();

	const structure = useSelector((state) => state.configuration.steps);
	const cloneConfig = useSelector((state) => state.configuration.abrhil_package?.multi_employer_registration?.value);

	const handleClose = isFunction(onClose) ? onClose : handleCloseDialogForm;

	useEffect(() => {
		const fields = getWorkerFilterFields(!onlyActives, cloneConfig, structure);
		setFilterFields(fields);
		// eslint-disable-next-line
	}, [structure]);

	const getExtraFilters = () => {
		if (!hasValue(extraFilters)) {
			return {};
		}
		if (isFunction(extraFilters)) {
			return extraFilters();
		}
		return extraFilters;
	};

	const customFilters = {
		...getExtraFilters(),
		...(conditional(onlyActives, { active: true }, {})),
		...(conditional(fromMopers, { from_mopers: true }, {})),
		with_permission_user,
	};

	const getTableData = async (newPagination, newFilter) => {
		if (hasErrorTetant()) {
			dispatch(showNotificationWarning(errorTenant()));
			return;
		}
		setInit(true);

		let filters = newFilter?.filters ?? newFilter ?? {};

		const fieldPrefix = getPrefix(prefix).replace(".", "_");

		if (hasValue(prefix)) {
			let constructed = {};
			each(filters, (item, key) => {
				constructed[`${fieldPrefix}${key}`] = item;
			});
			filters = constructed;
		}

		if (!searchOnChange.current) {
			filters = {
				...omit(filters, ['active']),
				...(searchValue.current ? { search: searchValue.current, limit: 1 } : {})
			};
		}

		const includes = include(conditional(forPayroll, "", prefix)).join(",");
		const basicfields = tableFields(conditional(forPayroll, "", prefix)).join(",");
		extraFields += conditional(extraFields, ",", "");
		let workerIds = "";
		if (workers) {
			if (size(workers)) {
				forEach(workers, function (id) {
					workerIds += `${id},`;
				});
			}
			workerIds = workerIds.trim();
			workerIds = workerIds.slice(0, workerIds.length - 1);
		}

		const params = parseRequest(
			{
				...customFilters,
				fields: `${extraFields}${basicfields}`,
				...(conditional(workerIds !== "", { ids: workerIds }, {})),
				include: `${includes},${valueOrOption(includeTo, "")}`,
			},
			{ filters },
			newPagination,
			{
				[`${fieldPrefix}contract_start`]: `${fieldPrefix}contract_end_gte`,
				[`${fieldPrefix}contract_end`]: `${fieldPrefix}contract_end_lte`,
				[`${fieldPrefix}contract_switch`]: `${fieldPrefix}is_permanent`,
				[`${fieldPrefix}leave_start`]: `${fieldPrefix}leave_gte`,
				[`${fieldPrefix}leave_end`]: `${fieldPrefix}leave_lte`,
				...(conditional(!searchOnChange.current, { search: 'worker' }, {}))
			}
		);

		const getData = conditional(isFunction(getApi), getApi, workersApi.get);
		performRequest({ api: getData(params), callback: res => onSuccess(res, filters) });
	};

	const onSuccess = (response, filters) => {
		setTotal(response.count);
		setData(response.results);
		if (response.results?.length && !searchOnChange.current && filters?.limit) {
			onDblClick({ dataItem: first(response.results), isKeyPress: true });
		}
	};

	const {
		page,
		filter,
		selection,
		refresh,
		filterChange,
		pageChange,
		selectRow,
		finishSelection,
		selectionLength,
		onAdvanceFilter,
		onDblClick,
		onSelectPage,
		control,
		handleCustomColumns,
	} = useController({
		...others,
		with_permission_user,
		multi,
		data,
		getTableData,
		init,
		selectBy,
		onClose: handleClose,
		includeData,
		setWorkersSelectedData,
		workersSelectedData,
		extraParams,
		selectAndClose: searchOnChange.current,
	});

	const mapData = () => {
		if (hasValue(prefix)) {
			return (data ?? []).map(item => item[prefix]);
		}
		return data;
	};

	const tableProps = {
		style: { height },
		className: "principal-grid",
		data: selectedItem(mapData(), selection, selectBy),
		/* pagination */
		skip: page.skip,
		take: page.take,
		total: total,
		pageable: pageable(total),
		onPageChange: pageChange,
		/* filter */
		filter: filter,
		onFilterChange: filterChange,
		/* selection props */
		multiSelection: multi,
		selectedField: "selected",
		onRowClick: selectRow,
		onRowDoubleClick: onDblClick,
		onSelectPage,
	};

	const customColumn = useWatch({ control, name: 'custom_columns' });

	/**
	 *
	 * @param {Array} columns
	*/
	const filterValuesInColumns = (columns = []) => {
		let newColumns = [];

		columns.forEach((column = []) => {
			newColumns = [
				...newColumns,
				column.filter(field => field?.validation !== false)
			];
		});

		return newColumns;
	};

	let validateFields = () => filterValuesInColumns(columnsToExport());

	const handleOpenDialogExport = () => {

		if (hasErrorTetant()) {
			dispatch(showNotificationWarning(errorTenant()));
			return;
		}

		setFieldSelection(validateFields());
		setOpenDialogExport(true);
	};


	const closeHandleExport = () => {
		setOpenDialogExport(false);
	};

	const handleChange = value => {
		const firstChar = value[0];
		const hasLetter = isLetter(firstChar);
		const hasNumber = isNumber(firstChar);
		searchOnChange.current = hasLetter || value === '';
		if (hasLetter) value = onlyLetters(value);
		if (hasNumber) value = onlyNumbers(value);
		searchValue.current = value;
		return value;
	};

	const onSearchWorker = (value, keypress) => {
		if ((!searchOnChange.current && !keypress) || (searchOnChange.current && value?.filters?.search?.length < 2 && value?.filters?.search)) return;
		if (keypress) {
			value.filters.limit = 1;
		} else {
			delete value.filters.limit;
		}
		onAdvanceFilter(value);
	};

	return (
		<>
			<Container>
				{children}
				<SearchField
					control={control}
					initValue={initSearchValue}
					onAdvanceFilter={onSearchWorker}
					filters={filter}
					onChange={handleChange}
					label={t("worker")}
					helpIcon={true}
					titleHelp={<TooltipBody />}
				/>
				<Grid size={{ xs: 3, md: 3, lg: 2, }}>
					<FormInput
						fieldInput={"MultiSelect"}
						control={control}
						keyField="id"
						textField="name"
						value="value"
						name={"custom_columns"}
						label={`${t("view")} ${t("see-more-columns")}`}
						options={customColumnsOptions()}
						onChange={handleCustomColumns} />
				</Grid>
				<Grid size={"grow"}>
					<ButtonsContainer className={"mt-2"}>
						<Refresh onClick={() => refresh(filter)} />
						<AdvanceFilters
							{...advanceFilterProps}
							filters={filter}
							fields={filterFields}
							onFilter={onAdvanceFilter}
							withoutActive={onlyActives} />
						<Export onClick={handleOpenDialogExport} />
					</ButtonsContainer>
					<ExportDialog
						open={openDialogExport}
						handleClose={closeHandleExport}
						setFieldSelection={setFieldSelection}
						fieldSelection={fieldSelection}
						currentTenant={currentTenant()}
						filters={{ filters: { ...(filter?.filters ?? {}), ...customFilters } }}
						moduleFactory={urlExport} />
				</Grid>
				<Grid size={12}>
					<Table
						loading={loading}
						with_permission_user={with_permission_user}
						onlyActives={onlyActives}
						tableProps={tableProps}
						init={init}
						noRenderDescription={t("initial-search-modal-msg")}
						customColumn={customColumn} />
				</Grid>
			</Container>
			{!withoutActions && <Actions {...{ onClose, selectionLength, finishSelection, withFinishSelection, withAcceptButton, handleCloseDialogForm }} />}
		</>
	);
};

ModalContent.propTypes = ModalContentProps;

export const Modal = ({
	multi,
	openDialogForm,
	onClose,
	handleCloseDialogForm,
	...others
}) => {
	const { t } = useTranslation();

	const handleClose = handleCloseDialogForm ?? onClose;
	return (
		<FormDialog
			open={openDialogForm}
			title={`${t("select")} ${t(getTitles("worker", multi))}`}
			maxWidth="xl"
			handleClose={handleClose}
			dialogId={"worker-search-modal"}
		>
			<ModalContent {...others} onClose={handleClose} multi={multi} />
		</FormDialog>
	);
};

Modal.propTypes = ModalProps;

export default Modal;
