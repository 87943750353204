import PropTypes from "prop-types"
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { find } from "lodash";
import { dropdownProps as PropGen } from './utilities';
import FormInput from '../../../../form/Field';
import { valueOrOption } from '../../../../../common/GeneralUtilities';
import { useSelector } from "react-redux";

export const RolesDropdown = ({
	control,
	setValue,
	loading,
	getValues,
	roles = [],
	dropdownProps = null
}) => {

	const { t } = useTranslation();
	const currentRole = useSelector((state) => state.group?.select?.id);
	const isSuper = useSelector((state) => state.userConfigs?.is_superuser);

	const company = useWatch({ control, name: "company" });
	const data = find(roles, ["company", company])?.roles ?? [];

	useEffect(() => {
		// if (!size(data)) { return; }
		const id = getValues("role") || currentRole;
		const current = find(data, ["id", id]);
		const newRole = valueOrOption(current?.id, null);
		setValue("role", newRole);
		if (!newRole) {
			setValue('roleObj', null);
		}
		//eslint-disable-next-line
	}, [data, currentRole]);

	return (
		<FormInput
			{...dropdownProps || PropGen}
			data={data}
			loading={loading}
			control={control}
			name={"role"}
			noDefault={!isSuper}
			label={t("role")}
			onChange={e => {
				setValue("roleObj", e.objectValue);
				return e;
			}}
		/>
	);
};

RolesDropdown.propTypes = {
	control: PropTypes.any,
	loading: PropTypes.bool,
	dropdownProps: PropTypes.any,
	getValues: PropTypes.func,
	roles: PropTypes.array,
	setValue: PropTypes.func
}
