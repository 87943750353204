import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
    faPlus,
    faRotate,
} from "@fortawesome/pro-regular-svg-icons";
import PermissionsGate from "../../../../../../../../permissions/PermissionsGate";
import "./styles.scss";

export const ModernButton = ({
    className = "",
    onClick,
    variant = "",
    disabled,
    style = {},
    icon,
    label,
    color = "",
    size = "",
    children,
    iconPlacement = "start",
    ...others
}) => {
    const faIcon = icon?.prefix;

    const IconContainer = () => {
        return (
            <>
                {faIcon ?
                    <FontAwesomeIcon icon={icon} />
                    :
                    icon
                }
            </>
        )
    }

    return (
        <button
            className={`header-button-modern ${disabled ? 'disabled' : ''} ${size} ${variant} ${className} ${color}`}
            style={style}
            onClick={onClick}
            disabled={disabled}
            {...others}
        >
            {children ?
                children
                :
                <>
                    {
                        (icon && iconPlacement === "start") ?
                            <IconContainer />
                            :
                            null
                    }
                    <span>{label}</span>
                    {
                        (icon && iconPlacement === "end") ?
                            <IconContainer />
                            :
                            null
                    }
                </>
            }
        </button>
    );
};

export const Create = ({
    className = "",
    onClick,
    disabled,
    variant = "dark",
    size = "lg",
    style,
    ...others
}) => {
    const { t } = useTranslation();

    return (
        <PermissionsGate scopes={['write']}>
            <button
                className={`header-button-modern ${size} ${variant} ${className}`}
                style={style}
                onClick={onClick}
                disabled={disabled}
                {...others}
            >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('add-new')}</span>
            </button>
        </PermissionsGate>
    );
};

export const Refresh = ({
    className = "",
    onClick,
    disabled,
    variant = "",
    size = "lg",
    style,
    ...others
}) => {
    const { t } = useTranslation();

    return (
        <PermissionsGate scopes={['read']}>
            <button
                className={`header-button-modern ${size} ${variant} ${className}`}
                style={style}
                onClick={() => onClick ? onClick() : null}
                disabled={disabled}
                {...others}
            >
                <FontAwesomeIcon icon={faRotate} />
                <span>{t('consult')}</span>
            </button>
        </PermissionsGate>
    );
};