import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { companyCountryApi } from '../services/company';
import { currentTenant, currentUserInfo } from '../common/validate-tenant';
import { countryTenant, configuration, menu, groupInit, setDashboard, setUserConfigs, lockedWindow, unlockedWindow } from '../../store/actions';
import { mapStructure } from '../common/utilStructureRedux';
import { isAbrhil, logout } from '../common/auth';
import { resolveError } from '../common/resolve-error';
import { ApiAbrhiReduxSettings, ApiClientReduxSettings } from '../services/packages';
import { menuListAPI } from '../services/menu';
import { userGroupsListAPI } from '../services/administrator';
import { toast } from 'react-toastify';
import { userConfigAPI } from '../services/user';
import { useEffect } from 'react';
import { implementService } from '../services/implemet-service';
import { valueOrOption } from '../common/GeneralUtilities';
import { getTheme } from '../../App/components/Sockets/utilities';

const usePackages = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();


	useEffect(() => {
		const updateRedux = (e) => {
			if (e?.key !== 'tenantStorage') return;
			const newStorageObj = JSON.parse(localStorage.getItem('tenantStorage'))?.settings;
			dispatch(configuration(newStorageObj));
		};
		addEventListener('storage', updateRedux);
		return () => {
			removeEventListener('storage', updateRedux);
		};
	}, []);

	const getTenant = (tenant) => ({
		tenant: tenant || currentTenant(),
	});
	/**
	 *
	 * @param tenant number
	 */
	const loadPackages = async (tenant) => {
		const service = isAbrhil() ? ApiAbrhiReduxSettings(tenant).get : ApiClientReduxSettings.get;
		await implementService(
			service(),
			response => {
				const REDUX_CONFIG = mapStructure(response);
				const tenantStorage = localStorage.getItem("tenantStorage");
				if (tenantStorage) {
					const newStorage = JSON.parse(tenantStorage);
					newStorage.settings = REDUX_CONFIG;
					localStorage.setItem("tenantStorage", JSON.stringify(newStorage));
					window.dispatchEvent(new StorageEvent('storage', { key: 'tenantStorage' }));
					return;
				}
				dispatch(configuration(REDUX_CONFIG));
			},
			error => {
				if (error?.response?.status === 401) {
					setTimeout(() => {
						logout();
						navigate("/login");
					}, 2000);
				}
			}
		);
		loadCountry(getTenant(tenant));
	};

	const resetPackages = () => {
		dispatch(countryTenant('MX'));
	};

	const resetConfigs = (newConfigs) => {
		const newState = {
			...currentUserInfo(),
			configs: {
				...newConfigs,
			}
		};
		dispatch(setUserConfigs(newState));
	};

	const loadMenu = async (tenant, group, lock = false) => {
		const request = {
			...getTenant(tenant),
			current_user_group: group,
		};
		if (lock) dispatch(lockedWindow());
		try {
			const response = await menuListAPI.get(request);
			const menuStructure = response?.results;
			const path = (response?.default_path ?? '').split("__").join("/");
			dispatch(setDashboard(path));
			dispatch(menu(menuStructure));
			// return path;
		} catch (error) {
			resolveError(error);
		} finally {
			if (lock) {
				dispatch(unlockedWindow());
			}
		}
	};

	const getUserGroups = async (tenant, role) => {
		await implementService(
			userGroupsListAPI.get(getTenant(tenant)),
			response => {
				const resp = valueOrOption(role, response[0]);
				dispatch(groupInit(response));
				if (!resp) {
					toast.warn('No existen roles configurados', {
						position: 'top-center',
						autoClose: false,
						closeButton: true,
						draggableDirection: "y",
						theme: getTheme(),
					});
				}
			}
		);
	};

	const getUserConfigs = async (tenant) => {
		try {
			const response = await userConfigAPI.get(getTenant(tenant));
			const results = response?.result ?? [];
			delete response.result;
			response.configs = results;
			localStorage.setItem('isStaff', !response?.is_superuser);
			dispatch(setUserConfigs(response));
			return response;
		} catch (error) {
			resolveError(error);
		}
	};

	const loadCountry = async (request) => {
		try {
			const response = await companyCountryApi(request);
			dispatch(countryTenant(response.country));
		} catch (error) {
			resolveError(error);
		}
	};

	return {
		loadPackages,
		resetPackages,
		loadMenu,
		getUserGroups,
		getUserConfigs,
		resetConfigs
	};
};

export { usePackages };