import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import LottieUse from "../../../core/common/LottieUse";
/* styles */
import "./Styles.scss";
import { getCurrentPath } from "../../../core/@components/navigation/utilities";
import { dashboardPath } from "../../../core/common/validate-tenant";

const UnderConstruction = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentPath = getCurrentPath();

	return (
		<div className="warning-content">
			<div className="context">
				<h2>
					{t("page-in-construction.title")}
				</h2>
				<LottieUse
					loop
					icon={"page-constuction"}
					className="icon-page-constuction"
				/>
				<h4>
					{t("page-in-construction.text1")}
				</h4>
				<h4>
					{t("page-in-construction.text2")}
				</h4>

				{
					currentPath !== dashboardPath() &&
					<Button
						className={"mt-4"}
						variant="contained"
						size="large"
						onClick={() => navigate("/")}
					>
						{t("back-dashboad")}
					</Button>
				}
			</div>
		</div>
	);
}

export default UnderConstruction;