import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faRightLong } from '@fortawesome/pro-light-svg-icons'
import { CustomTooltip } from '../../../../../App/components/Templates/cells'

const styles = {
  reset: {
    p: '0',
    m: '0'
  },
  header: {
    fontSize: '18px',
    color: 'var(--textEmphasis)',
    fontWeight: 'bold'
  },
  card: {
    padding: '30px 30px 0px 30px',
    mb: '30px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    height: 'auto'
  },
  content: {
    p: '0',
    mt: '4px'
  }
}

function ChangeItem(props) {
  const { t } = useTranslation()
  return (<Card className='ChangeItem' sx={styles.card}>
        <CardHeader sx={styles.reset} title={<div style={styles.header}>{t(props.title)}</div>} />
        <CardContent sx={styles.content}>
          {props.changes.map((c, index) => {
            return (
              <React.Fragment key={index + 1}>
                <div className='row-change'>
                  <div className='text-desc'>{t("update-on")} <b>{c.title}</b></div>

                  <div className='changes'>
                    <div className='change-text'>{`${t("before")}:`}</div>

                    <CustomTooltip title={JSON.stringify(c.before)}>
                      <div className='change before'>{JSON.stringify(c.before)}</div>
                    </CustomTooltip>

                    <div className='change-icon'>
                      <FontAwesomeIcon icon={faRightLong} />
                    </div>

                    <div className='change-text'>{`${t("after")}:`}</div>
                    <CustomTooltip title={JSON.stringify(c.after)}>
                      <div className='change after'>{JSON.stringify(c.after)}</div>
                    </CustomTooltip>
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </CardContent>
    </Card>
  )
}

export default React.memo(ChangeItem)
