import React, { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  loadAttendance,
  showConfirmNotification,
  showNotificationWarning,
} from '../../../../store/actions';
import { useWatch } from 'react-hook-form';
import { getSelectedState } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { implementService } from '../../../services/implemet-service';
import { periodsApi } from '../../../services/payroll';
import { format, parseISO, isBefore, isAfter, isEqual } from 'date-fns';
import Alert from '@mui/material/Alert';
import es from 'date-fns/locale/es';
import { mapKeys, mapValues, size } from 'lodash';
import { hasValue } from '../../../common/GeneralUtilities';
import TriggerNotificationSocket from '../../../common/trigger-notification-socket';
import { store } from '../../../../store';
import useAttendance from './useController';

const DATA_ITEM_KEY = 'id';
const idGetter = getter(DATA_ITEM_KEY);

function getFormatDate(date) {
  const inputDate = new Date(date);
  return format(inputDate, 'dd/MM/yyyy');
}

function useApplyModule({ holydaysSource, setHolydaysSource }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openSelectWorkGroups, setOpenSelectWorkGroups] = useState(false);
  const { workgroups, control, filter } = useAttendance();
  const workgroupData = workgroups.filter(f => f.id !== 0);
  const actualValue = useRef(workgroupData);
  const [selectedState, setSelectedState] = useState({});
  const [title, setTitle] = useState('');
  const [absenceTypeStore, setAbsenceTypeStore] = useState(3);
  const period = useWatch({ control, name: 'period' });
  const typeModule = useRef({
    api: null,
    type: '',
  });

  function hanldeCancel() {
    setOpenSelectWorkGroups(false);
  }

  function validityData(selectInfo) {
    const workgroup = Object.keys(selectedState)
      .filter(key => selectedState[key] === true)
      .map(m => parseInt(m));
    const groupWorgroup = hasValue(workgroup) ? workgroup : [workgroupData[0]?.id];
    const params = {
      period,
      workgroup: selectInfo === 'individual' ? ([actualValue?.current[0]?.id ?? workgroupData[0]?.id]) : groupWorgroup,
    }

    if (typeModule.current.type === 'apply_holidays') {
      implementService(periodsApi.getOne(period), () => {
        // if (!response.list) {
        //   dispatch (showNotificationWarning ({message: t ('locked-period')}));
        //   return;
        // }
        //mostrar fechas que se van a aplicar festivos y cuales aun no aplica
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const higher = holydaysSource.filter(
          item =>
            isAfter(parseISO(item.calendar_day), now) ||
            isEqual(parseISO(item.calendar_day), now)
        );
        const less = holydaysSource.filter(item =>
          isBefore(parseISO(item.calendar_day), now)
        );
        if (!higher.length && !less.length) {
          dispatch(
            showNotificationWarning({
              message: t('No hay festivos en este periodo'),
            })
          );
          return;
        }
        const higherString = mapDates(higher);
        const lessString = mapDates(less);
        dispatch(
          showConfirmNotification({
            maxWidth: 'sm',
            message: '¿Desea continuar?',
            description: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px 15px',
                }}
              >
                {!!less.length &&
                  <Alert severity="info" className="mb-2">
                    <div>
                      Se aplicarán los festivos en las fechas de corte del periodo de lista actual (
                      {`${getFormatDate(filter?.initial_date)} - ${getFormatDate(filter?.end_date)}`}
                      )
                    </div>
                    <div style={{ fontWeight: 'bolder' }}> {lessString}</div>

                  </Alert>}
                {!!higher.length &&
                  <Alert severity="warning">
                    <div>
                      Los siguientes descansos festivos no pueden aplicar ya que deben ser aplicados un día posterior a los mismos
                    </div>
                    <div style={{ fontWeight: 'bolder' }}>{higherString}</div>
                  </Alert>}
              </div>
            ),
            onConfirm: () => {
              sendData(params);
            },
          })
        );
      });
    } else {
      sendData(params);
    }
    setHolydaysSource([]);
    setSelectedState({});
  }

  const capitalize = t => {
    return t[0].toUpperCase() + t.substr(1);
  };

  function mapDates(item) {
    return item
      .map(m => {
        const date = parseISO(m.calendar_day);
        return `${capitalize(format(date, 'eeee', {
          locale: es,
        }))} ${format(date, 'dd/MM/yyyy')}`;
      })
      .toString()
      .replace(',', ', ');
  }

  function sendData(params) {
    const filters = JSON.parse(JSON.stringify(filter));
    delete filters.workgroup;
    const request = {
      ...filter,
      ...params,
    };
    if (title === 'apply_faults') {
      request.absence_type_store = absenceTypeStore;
    }
    implementService(typeModule.current.api.post(request), (response) => {
      TriggerNotificationSocket(typeModule.current.type, response.task);
    });
  }

  function checkGroups(api, type) {
    setTitle(type);
    setSelectedState(mapValues(mapKeys(actualValue.current, 'id'), () => true));
    typeModule.current = { api, type };
    if ((size(actualValue.current.length) === 1 || size(workgroupData) === 1) && type !== 'apply_faults') {
      //aplica al unico grupo menos si se aplica faltas se tiene que mostrar el modal
      hanldeCancel();
      validityData('individual');
    } else {
      //se muestran los grupos y seleccionan a los que se desea aplicar
      setOpenSelectWorkGroups(true);
    }
  }

  const onSelectionChange = useCallback(
    event => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(event => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach(item => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
  }, []);

  function hanldeSaveMulti() {
    hanldeCancel();
    validityData('multi');
  }


  return {
    checkGroups,
    openSelectWorkGroups,
    hanldeCancel,
    onHeaderSelectionChange,
    onSelectionChange,
    selectedState,
    hanldeSaveMulti,
    title,
    setAbsenceTypeStore,
  };
}


export const OnSuccessApply = () => {
  store.dispatch(loadAttendance());
}

export default useApplyModule;
