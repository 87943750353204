import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import { isFunction } from 'lodash';
import { faX } from "@fortawesome/pro-regular-svg-icons";
import Button from "../../../core/styled/Button";
import PermissionsGate from '../../../permissions/PermissionsGate';
import IconUse from '../../../core/common/IconUse';

function SaveAndCancel({
	onCancel,
	className,
	disabled = false,
	hideCancel = false,
	onSave
}) {
	const { t } = useTranslation();
	const commons = {
		className: `${className ?? ""} systemButton`,
		design: "contained",
		style: { marginLeft: "10px" }
	};
	const saveProps = isFunction(onSave) ? { onClick: onSave } : { type: "submit" };
	return (
		<>
			{!hideCancel && <Cancel {...commons} onClick={onCancel} />}
			<PermissionsGate scopes={['write']}>
				<Button {...commons} disabled={disabled} {...saveProps}>
					<IconUse icon={"save"} />
					<span className="buttonLabel">{t("save")}</span>
				</Button>
			</PermissionsGate>
		</>
	);
};

SaveAndCancel.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	hideCancel: PropTypes.bool,
	onCancel: PropTypes.func,
	onSave: PropTypes.func
};

export default SaveAndCancel;

export function Cancel({
	className,
	style,
	...others
}) {
	const { t } = useTranslation();
	return (
		<Button
			color="error"
			type="button"
			className={`${className ?? ""} systemButton`}
			style={{
				marginLeft: "10px",
				...style
			}}
			{...others}
		>
			<IconUse icon={faX} />
			<span className="buttonLabel">{t("cancel")}</span>
		</Button>
	);
};

Cancel.propTypes = {
	className: PropTypes.string,
	style: PropTypes.any
};
