import { toLower } from "lodash";
import { downloadComparisonReport } from "../../../core/modules/EMA-EBA/hooks/useGrid";
import { downloadWorkerReport } from "../../../core/modules/WorkerMovementsReport/hooks/useForm";
import { downloadSuaReport } from "../../../core/modules/BSettlementComparasion/hooks/useSettlementComparasion";
import { downloadAttendanceList } from "../../../core/modules/Attendance/hooks/usePrintList";
import { downloadEntriesExits } from "../../../core/modules/Attendance/hooks/useCalendarChecks";
import { downloadReport } from "../../../core/modules/Reports/components/Modal/Payroll/hooks/usePayrollForm";
import { downloadContractReport } from "../../../core/modules/Worker/Contracts/hooks/usePrintFormats";
import { downloadFile } from './utilities';
import { OnCreatedTenant } from "../../../core/modules/AbrhilApp/Company";
import { valueOrOption } from '../../../core/common/GeneralUtilities';
import { OnSuccessApply } from "../../../core/modules/Attendance/hooks/useApplyModule";
import { formatTitleReport } from "../../../core/modules/Reports/utils/constants";

export const SocketsDictionary = (tenant) => {

	const getUrl = route => `${tenant}/${route}/`;

	const constructor = (route, filename, apiName, xlsx = false) => ({
		url: getUrl(route),
		cancel_api: 'task-report',
		callbackWS: async (taskID, repo_name) => {
			return await downloadFile(taskID, valueOrOption(filename, repo_name), apiName, xlsx);
		},
	});

	return {
		calculate_ptu_report: constructor("payroll-calculate-response/ptu_report", 'calculate-ptu-report'),
		bimonthly_variables_report: constructor("bimonthly-payroll-variable/report", 'bimonthly-payroll-variable'),
		delete_report: constructor("bimonthly-payroll-variable/delete-report", 'bimonthly-payroll-variable-delete'),
		worker_settlement_report: constructor("worker-settlement-report", 'worker-settlement-report'),
		payroll_policy_report: constructor("payroll-policy", 'payroll-policy'),
		payroll_provision_policy: constructor("payroll-provision-policy-vacations"),
		vacations_balance_report_pdf: constructor("vacation-balance/pdf-report", 'vacation-balance-report'),
		vacations_balance_report_xlsx: constructor("vacation-balance/xlsx-report", 'vacation-balance-report'),
		massive_increase_report: constructor("salary-movement-records/massive-increase-report", 'massive-increase-report'),
		massive_increase_apply: constructor("salary-movement-records/massive-increase-apply", 'massive-increase-apply'),
		massive_increase_delete: constructor("salary-movement-records/massive-increase-delete", 'massive-increase-delete'),
		worker_movements: {
			url: getUrl('worker-movement-report'),
			callbackWS: downloadWorkerReport,
		},
		ema_comparison: {
			url: getUrl('ema-comparison-report'),
			callbackWS: downloadComparisonReport,
		},
		eba_comparison: {
			url: getUrl('eba-comparison-report'),
			callbackWS: downloadComparisonReport,
		},
		idse_hiring_report: {
			url: getUrl('idse_hiring_transfer_report'),
			callbackWS: downloadReport,
		},
		idse_all_report: {
			url: getUrl('idse_transfer_all_movement_report'),
			callbackWS: downloadReport,
		},
		sua_infonavit: {
			url: getUrl('payroll_sua_comparison_report'),
			callbackWS: downloadSuaReport,
		},
		contracts_catalog: {
			url: getUrl('formats-render'),
			callbackWS: downloadContractReport,
		},
		payroll_dispersion_layout: constructor("payroll-report-dispersion-layout", "payroll-dispersion-layout"),
		payroll_dispersion_report: constructor("payroll-report-dispersion-report", "payroll-dispersion"),
		voucher_dispersion_layout: constructor("voucher-dispersion-layout", "voucher-dispersion-layout"),
		payroll_report: constructor('payroll-report', formatTitleReport("payroll_report")),
		payroll_report_incidences: constructor('payroll-report-incidences', formatTitleReport("payroll_report_incidences")),
		payroll_report_employer_registration: constructor('payroll-report-employer-registration', formatTitleReport("payroll_report_employer_registration")),
		payroll_report_netos: constructor('payroll-report-netos', formatTitleReport("payroll_report_netos")),
		payroll_report_dedu_perc: constructor('payroll-report-dedu-perc', formatTitleReport("payroll_report_dedu_perc")),
		payroll_report_difference_linear: constructor('payroll-report-difference-linear', formatTitleReport("payroll_report_difference_linear")),
		payroll_report_capture_not_applied: constructor('payroll-report-capture-not-applied', formatTitleReport("payroll_report_capture_not_applied")),
		payroll_report_excel_general: constructor('payroll-report-excel-general', formatTitleReport("payroll_report_excel_general")),
		payroll_report_taxes: constructor('payroll-report-taxes', formatTitleReport("payroll_report_taxes")),
		payroll_report_concept: constructor('payroll-report-concept', formatTitleReport("payroll_report_concept")),
		payroll_report_perceptions_difference: constructor('payroll-report-perceptions-difference',formatTitleReport("payroll_report_perceptions_difference")),
		payroll_report_loans: constructor('payroll-report-loans', formatTitleReport("payroll_report_loans")),
		report_birthday: constructor('report-birthday', formatTitleReport("report_birthday")),
		report_validity: constructor('report-validity', formatTitleReport("report_validity")),
		report_contract: constructor('report-contract', formatTitleReport("report_contract")),
		report_antiquity: constructor('report-antiquity', formatTitleReport("report_antiquity")),
		report_vacation_enjoyed: constructor('report-vacation-enjoyed', formatTitleReport("report_vacation_enjoyed")),
		report_document_expiration: constructor('report-document-expiration', formatTitleReport("report_document_expiration")),
		payroll_report_payment_transfer: constructor('payroll-report-payment-transfer', formatTitleReport("payroll_report_payment_transfer")),
		report_imss_movement: constructor('report-imss-movement', formatTitleReport("report_imss_movement")),
		report_personal_rotation: constructor('report-personal-rotation', formatTitleReport("report_personal_rotation")),
		report_worker_information: constructor('report-worker-information', formatTitleReport("report_worker_information")),
		moper_reports: constructor('moper-reports', formatTitleReport("moper_reports")),
		sua_transfer: constructor("sua_transfer"),
		worker_sua_transfer: constructor("worker_sua_transfer"),
		report_expired_contracts: constructor("report-expired-contracts", formatTitleReport("report_expired_contracts")),
		report_worker_current_active: constructor("report-worker-current-active", formatTitleReport("report_worker_current_active")),
		idse_missing_movements: constructor("missing-idse-report", formatTitleReport("idse_missing_movements")),
		moper_reports_pending: constructor("moper-reports-pending", formatTitleReport("moper_reports_pending")),
		moper_reports_authorized_excel: constructor("moper-reports-authorized-excel", formatTitleReport("moper_reports_authorized_excel")),
		vacation_audit: constructor("vacation-audit", formatTitleReport("vacation-audit")),
		worker_master_detail: constructor("worker-master-detail", formatTitleReport("worker-master-detail")),
		attendancelist_print: {
			url: getUrl('attendancelist-report'),
			callbackWS: downloadAttendanceList,
		},
		attendancelist_massive_report: {
			url: getUrl('attendancelist-massive-report'),
			callbackWS: downloadAttendanceList,
		},
		entries_exits_print: {
			url: getUrl('entriesexits-report'),
			callbackWS: downloadEntriesExits,
		},
		m_liquidation: {
			url: getUrl('monthly_liquidation'),
			callbackWS: null,
		},
		b_liquidation: {
			url: getUrl('bimonthly_liquidation'),
			callbackWS: null,
		},
		m_liquidation_report: {
			url: getUrl('monthly_liquidation_report'),
			callbackWS: downloadReport,
		},
		b_liquidation_report: {
			url: getUrl('bimonthly_liquidation_report'),
			callbackWS: downloadReport,
		},
		multi_create_users: {
			url: getUrl('multi-create-users'),
			callbackWS: null,
		},
		idse_confirmation: {
			url: getUrl('idse_confirmation'),
			callbackWS: downloadReport,
		},
		generic_export: {
			url: getUrl('export'),
			callbackWS: (taskID, filename) => downloadFile(taskID, `${toLower(filename?.split('_')?.[0])}`, "export-data", true),
		},
		create_tenant: {
			url: `tenant_creation/`,
			callbackWS: OnCreatedTenant,
		},
		apply_holidays: {
			url: getUrl('apply-holidays'),
			callbackWS: OnSuccessApply,
		},
		apply_faults: {
			url: getUrl('apply-absences'),
			callbackWS: OnSuccessApply,
		},
		apply_breaks: {
			url: getUrl('apply-days-off'),
			callbackWS: OnSuccessApply,
		},
		cirse_excel: constructor('cirse-excel', 'cirse', null, true),
		dc3_format: {
			url: getUrl('dc-3-format'),
			callbackWS: downloadReport,
		},
		list_course_report: {
			url: getUrl('list-course-report'),
			callbackWS: downloadReport,
		},
		replicate_flow_workgroups: {
			url: getUrl('copy-flow-signature'),
		},
	};
};