import PropTypes from "prop-types"
import React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import DialogContent from '../../../core/styled/DialogContent';

export const Container = ({ children }) => {
	return (
		<DialogContent dividers className="modal-content">
			<Card>
				<CardContent>
					<Grid container spacing={2}>
						{children}
					</Grid>
				</CardContent>
			</Card>
		</DialogContent>
	);
};

Container.propTypes = {
	children: PropTypes.any
}
