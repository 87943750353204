
import React from "react";
import StructureGeneral from "../StructureConfigs/General/StructureConfigs";
import StructureBreaks from "../StructureConfigs/Breaks";
import Overtime from "../StructureConfigs/Overtime";
import { uid } from 'uid';
import ConfigSettlement from "../../../ConfigSettlement";
import ConfigPayrollReport from "../../../ConfigPayrollReport";

const DATA_ENTERPRISE = {
  "GENERALES": <StructureGeneral />,
  "DESCANSOS": <StructureBreaks />,
  "TIEMPO EXTRAORDINARIO": <Overtime />,
}

export const setConfigEnterpriseStructure = (data) => {
  const search = data.classifications.find(f => f.key === "REPORT_002")

  if (search) {
    search.categories.forEach(f => {
      f.component = DATA_ENTERPRISE[f.name]
    })
  } else {
    const tab = {
      id: uid(),
      key: "REPORT_002",
      name: "CONFIGURACIONES ESTRUCTURA EMPRESARIAL",
      alias: "CONFIGURACIONES ESTRUCTURA EMPRESARIAL",
      categories: ["GENERALES","DESCANSOS","TIEMPO EXTRAORDINARIO"]
    }
    tab.categories = tab.categories.map(f => {
      const values = {}
      values.component = DATA_ENTERPRISE[f]
      values.id = uid()
      values.key = null
      values.name = f
      values.alias = f
      return values
    })
    data.classifications.push(tab)
  }
 
}

const DATA_PAYROLL = {
  "FINIQUITO": <ConfigSettlement />,
  "NÓMINA": <ConfigPayrollReport />,
}

export const setConfigPayroll = (data) => {
  const search = data.classifications.find(f => f.key === "REPORT_001")
  if (search) {
    search.categories.forEach(f => {
      f.component = DATA_PAYROLL[f.name]
    })
  } else {
    const tab = {
      id: uid(),
      key: "REPORT_001",
      name: "REPORTES",
      alias: "REPORTES",
      categories: ["FINIQUITO", "NÓMINA"]
    }
    tab.categories = tab.categories.map(f => {
      const values = {}
      values.component = DATA_PAYROLL[f]
      values.id = uid()
      values.key = null
      values.name = f
      values.alias = f
      return values
    })
    data.classifications.push(tab)
  }

}