import { format } from "date-fns";

//*** Data format for server ***//
export const serverDate = (dateToChange) => {
	if (dateToChange) {
		if (typeof dateToChange === "string") {
			return dateToChange
		} else {
			return format(dateToChange, "yyyy-MM-dd");
		}
	}
}
//*** End ***//