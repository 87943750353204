import React, { useState } from "react";
import { uid } from 'uid';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
/* form */
import Input from "@mui/material/TextField";
import { CustomTooltip } from "../Templates/cells";
import { objectValue } from "../../../core/@components/form/utilities";
import { MaterialLabelContainer } from '../Templates/SideLabelContainer';
import { materialSizeClasses } from '../sizesUtilities';
import { Loader } from "../../../core/modules/SignaturesByWorker/components/Shared";


const TextField = ({
	useTooltip,
	value,
	title,
	id,
	type,
	pasEye,
	inputProps,
	InputProps,
	onChange,
	label,
	loading,
	sideLabel = false,
	...others
}) => {


	const { size, className } = materialSizeClasses(others);

	const autocomplete = type === "password" ? "new-password" : "off";
	const [showPassword, setShowPassword] = useState(false);

	const inputType = type === "password" && showPassword ? "text" : type;


	const handleChange = (e) => {
		const value = objectValue(e, e.target.value);
		if (typeof onChange === "function") {
			onChange(value);
		}
	};

	const allProps = {
		fullWidth: true,
		...others,
		label: label,
		size,
		className: `${className}`,
		type: inputType,
		id: id || `TextField_${uid()}`,
		autoComplete: autocomplete,
		inputProps: {
			autoComplete: autocomplete,
			...(inputProps || {}),
		},
		InputProps: {
			endAdornment:
				<EndAdorment
					pasEye={pasEye}
					loading={loading}
					setShowPassword={setShowPassword}
					showPassword={showPassword}
				/>,
			...(InputProps || {}),
		},
		title: useTooltip ? "" : title,
		value: (value ?? ""),
		onChange: handleChange
	};

	if (useTooltip && type !== "password") {
		return (
			<MaterialLabelContainer sideLabel={sideLabel} label={label}>
				<CustomTooltip title={title || value || ""}>
					<Input {...allProps} />
				</CustomTooltip>
			</MaterialLabelContainer>
		);
	}

	return (
		<MaterialLabelContainer sideLabel={sideLabel} label={label}>
			<Input {...allProps} />
		</MaterialLabelContainer>
	);
};

export default TextField;

export const EndAdorment = ({
	pasEye,
	loading,
	showPassword,
	setShowPassword
}) => {

	const commonStyles = { marginRight: '6px' };

	if (loading) {
		return (
			<div style={commonStyles}>
				<Loader size={16} />
			</div>
		);
	}

	if (pasEye) {
		return (
			<InputAdornment position={"end"} sx={commonStyles}>
				<IconButton
					onClick={() => setShowPassword(!showPassword)}
				// edge="end"
				>
					<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
				</IconButton>
			</InputAdornment>
		);
	}

	return null;
};