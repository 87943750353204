import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { initialFilter } from '../../../../../../App/components/GridCustomProps';
import { resolveError } from '../../../../../common/resolve-error';
import { usePackages } from '../../../usePackages';
import { ServerAPI } from '../../../../../services/ServerAPI';

const useOvertime = () => {
  const { tenant } = usePackages()
  const [filters, setFilters] = useState(initialFilter());
  const [edit, setEdit] = useState(false);
  const [config, setConfig] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const {
    control,
    reset,
    setValue
  } = useForm({
    mode: "onChange",
  });

  useEffect(
    () => {
      setRefreshCounter(1);
      getConfig()
      setFilters(initialFilter());
      // eslint-disable-next-line
    },
    [tenant]
  );

  const refresh = () => {
    setRefreshCounter(counter => {
      return counter + 1;
    });
    getConfig()
  };

  async function getConfig() {
    const params = { tree: '', tenant }
    try {
      const result = await ServerAPI('multiples-default', false).get(params)
      result.push({
        key: "time_for_time_cancel",
        max_value:"99.00",
        min_value:"0.00"
      })
      setConfig(result)
    } catch (error) {
      resolveError(error)
    }
  }

  function cancel() {
    setEdit(false)
    reset()
  }

  return {
    filters,
    setFilters,
    refreshCounter,
    refresh,
    edit,
    setEdit,
    cancel,
    control,
    config,
    setValue
  };
};

export default useOvertime;
