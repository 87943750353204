import PropTypes from "prop-types"
import React, { Fragment, forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import { debounce, isArray, isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/pro-light-svg-icons";
import Divider from "@mui/material/Divider";
import { Button } from "@progress/kendo-react-buttons";
import Zoom from "@mui/material/Zoom";
import LottieUse from '../../../core/common/LottieUse';
import { uid } from "uid";

import "./ChangeItem.scss";
import IconUse from "../../../core/common/IconUse";

let lastFocus = null;

export const Skeleton = ({
	title,
	close,
	confirm,
	children,
	icon = null,
	lottieIcon = "error-icon",
	modern = false,
}) => {
	const autoID = uid();
	const notifRef = useRef();
	const dialogID = `notification_${autoID}`;

	let classStyle = "custom-player-class";
	if (title?.includes("ERROR")) {
		classStyle = "custom-player-class-error";
	};

	function handleEnter(e) {
		const keyCode = e.keyCode;
		if (keyCode !== 13) return;
		if (isFunction(confirm)) {
			confirm();
			return;
		}
		close();
	}

	useEffect(() => {
		document.addEventListener('keypress', handleEnter);
		return () => {
			document.removeEventListener('keypress', handleEnter);
		};
	}, []);

	useLayoutEffect(() => {
		saveFocus();
		return () => {
			restoreFocus();
		}
	}, [])

	const restoreFocus = useRef(debounce(() => {
		if (!lastFocus?.focus) return;
		lastFocus.focus();
	}, 200)).current;

	const saveFocus = () => {
		if (document.activeElement?.attributes?.tabindex?.value === "-1" || notifRef.current?.contains(document?.activeElement) || document?.activeElement instanceof HTMLBodyElement) return;
		lastFocus = document.activeElement;
	}

	return (
		<div ref={notifRef} id={dialogID}>
			{modern ?
				<div className="modern-icon-wrap">
					<div className="modern-icon">
						<IconUse icon={icon} />
					</div>
				</div>
				:
				<div className="iconContainer" >
					<LottieUse
						className={classStyle}
						icon={lottieIcon}
					/>
				</div>
			}
			<div className="dialogTitle" style={modern ? { paddingTop: "10px", paddingInline: "25px" } : {}}>{title}</div>
			{!modern && <Divider className={"dividerStyle"} />}
			{children}
		</div>
	);
};

Skeleton.propTypes = {
	children: PropTypes.any,
	close: PropTypes.func,
	confirm: PropTypes.func,
	icon: PropTypes.any,
	lottieIcon: PropTypes.string,
	modern: PropTypes.bool,
	title: PropTypes.any
}

export const Transition = forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} timeout={500} />;
});

export const MsgBody = ({
	message,
	description,
	isCustomChild,
	customChildren,
}) => {
	if (isCustomChild) {
		return <Fragment>{customChildren}</Fragment>;
	}

	return (
		<div className="textContainer">
			<div className={`dialogDescription`}>
				<span className={`dialogMessage`}>{message}</span>
				{description}
			</div>
		</div>
	);
};

MsgBody.propTypes = {
	customChildren: PropTypes.any,
	description: PropTypes.any,
	isCustomChild: PropTypes.any,
	message: PropTypes.any
}

const buttonProps = {
	size: "large",
	fillMode: "solid",
	rounded: "full",
	themeColor: "primary",
};

export const StatusSkeleton = (props) => {
	return (
		<Skeleton {...props}>
			<MsgBody {...props} />
			<Buttons {...props} />
		</Skeleton>
	);
};

export const DeleteSkeleton = ({
	confirm,
	close,
	...others
}) => (
	<Skeleton close={close} {...others}>
		<MsgBody {...others} />
		<Buttons
			{...others}
			confirm={confirm}
			close={close}
			hasConfirm={true}
			isDelete={true}
		/>
	</Skeleton>
);

DeleteSkeleton.propTypes = {
	close: PropTypes.func,
	confirm: PropTypes.func
}

export const ChangesSkeleton = ({
	changes,
	confirm,
	close,
	confirmChanges,
	title,
	message,
	...others
}) => {

	const { t } = useTranslation();

	return (
		<Skeleton lottieIcon={"info-icon"} title={title || t("changes-general-title")} close={close}>
			{!confirmChanges && <MsgBody {...others} message={message} />}

			{confirmChanges && (
				<span className={`dialogMessage`}>
					{message || t("exists-changes-in-row")}
				</span>
			)}

			{confirmChanges && (
				<div className="ChangeItem controlledContainer">
					{changes.map((item, index) => (
						<Fragment key={index + 1}>
							<div className="row-change">
								<div className="text-desc">
									{t("change-on.update")} :{" "}
									<span
										className={"k-text-inverse k-font-weight-bold blueData"}
									>
										{t(item.field)}
									</span>
								</div>
								<div className="changes">
									<div className="change-text">{t("change-on.before")} :</div>
									<ChangeValue value={item.old} className={"before"} />
									<div className="change-icon">
										<FontAwesomeIcon icon={faRightLong} />
									</div>
									<div className="change-text">{t("change-on.after")} :</div>
									<ChangeValue value={item.new} className={"after"} />
								</div>
							</div>
						</Fragment>
					))}
				</div>
			)}

			<Buttons confirm={confirm} close={close} hasConfirm={true} />
		</Skeleton>
	);
};

ChangesSkeleton.propTypes = {
	changes: PropTypes.any,
	close: PropTypes.func,
	confirm: PropTypes.func,
	confirmChanges: PropTypes.any,
	message: PropTypes.func,
	title: PropTypes.func
}

const ChangeValue = ({
	value,
	className
}) => {

	if (!isArray(value)) {
		return (
			<div className={`change ${className}`}>
				{value.toString()}
			</div>
		);
	}

	return value.map((item, index) => (
		<div key={index} className={`change ${className}`}>
			{item.toString()}
		</div>
	));

};

ChangeValue.propTypes = {
	className: PropTypes.any,
	value: PropTypes.any
}

const Buttons = ({
	confirm,
	close,
	hasConfirm,
	isDelete,
	title,
	cancelButtonText,
	confirmButtonText,
	customButtonProps,
	modern
}) => {

	const { t } = useTranslation();

	let cancelText, confirmText;

	switch (title) {
		case t("available-format"):
			cancelText = t("no");
			confirmText = t("yes");
			break;
		case t("reactivate-infonavit"):
			cancelText = t("no-reactivate");
			confirmText = t("yes-reactivate");
			break;
		default:
			cancelText = cancelButtonText ?? t("cancel");
			confirmText = confirmButtonText ?? t(isDelete ? "remove" : "accept");
			break;
	}

	return (
		<div className="buttonContainer" style={modern ? { paddingBlock: "20px" } : {}}>
			{!hasConfirm && (
				<Button {...buttonProps} onClick={close} style={modern ? {
					minWidth: "120px",
					minHeight: "30px",
					backgroundColor: "var(--blueBold)",
					fontWeight: "300",
					border: "none"
				} : {}}>
					{t("accept")}
				</Button>
			)}
			{hasConfirm && (
				<Fragment>
					<Button
						{...buttonProps}
						fillMode={"outline"}
						onClick={close}
						{...(!isDelete ? { themeColor: "error" } : {})}
						{...(customButtonProps ?? {})}
					>
						{cancelText}
					</Button>
					<Button
						{...buttonProps}
						onClick={confirm}
						{...(isDelete ? { themeColor: "error" } : {})}
						{...(customButtonProps ?? {})}
					>
						{confirmText}
					</Button>
				</Fragment>
			)}
		</div>
	);
};

Buttons.propTypes = {
	cancelButtonText: PropTypes.func,
	close: PropTypes.any,
	confirm: PropTypes.any,
	confirmButtonText: PropTypes.any,
	customButtonProps: PropTypes.object,
	hasConfirm: PropTypes.any,
	isDelete: PropTypes.any,
	modern: PropTypes.any,
	title: PropTypes.any
}
