import React, { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useForm as useReactForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema, bimesters, initValues } from "../util";
import {
  hideNotificationWarning,
  lockedWindow,
  showConfirmNotification,
  showNotificationError,
  showNotificationWarning,
  unlockedWindow,
} from "../../../../store/actions";
import FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import {
  initialFilter,
  initialPage,
} from "../../../../App/components/GridCustomProps";
import { implementService } from "../../../services/implemet-service";
import { parseRequest } from "../../../common/parse-request";
import { employerRegistrationAPI } from "../../../services/enterprise-structure";
import TemplateInfo from "../../../@components/modal/SettlementComparasionInfo";
import TriggerNotificationSocket from "../../../common/trigger-notification-socket";
import { settlementComparisonApi, suaSettlementComparisonApi } from "../../../services/IMSS";
import TemplateAlert from "../components/Grid/TemplateAlert";
import { socketsApi } from "../../../services/socket-report";
import { getNavItem } from "../../../@components/navigation/utilities";
import { scanFile } from "../../../services/ServerAntivirus";
import { resolveError } from "../../../common/resolve-error";
import useAbrhilNavigation from "../../../@components/navigation/contextsAndControllers/useAbrhilNavigation";

const DATA_ITEM_KEY = "key"

function useSettlementComparasion() {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tenant = useSelector((state) => state.tenant);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [total, setTotal] = useState(0);
  const [source, setSource] = useState([]);
  const [init, setInit] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [page, setPage] = useState(initialPage);
  const [disabledSua, setDisabledSua] = useState(true);
  const navigateStyle = {
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '8px',
    color: '#1E73AB',
  }

  const hiddenFileInput = useRef(null);
  const type_action = useRef("process");

  const settlement_type = "bimonthly-comparison";
  const path = getNavItem('modules@liquidation', false, true);
  const { openWindowItem } = useAbrhilNavigation();


  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors
  } = useReactForm({
    resolver: yupResolver(ValidationSchema),
    mode: "onChange",
    defaultValues: initValues,
  });

  const idGetter = getter(DATA_ITEM_KEY);

  useEffect(() => {
    if (!source.length && page.skip && init) {
      setPage(initialPage);
    }
    // eslint-disable-next-line
  }, [source]);

  useEffect(() => {
    setSource([]);
    setTotal(0);
    setInit(false);
    setPage(initialPage);
    setFilter(initialFilter);
    // eslint-disable-next-line
  }, [tenant]);

  useEffect(() => {
    if (!init) {
      return;
    }
    handleRefresh();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    handleRefresh();
  }, [])

  function handleRefresh() {
    getData(
      parseRequest(
        {
          distinct: "",
        },
        filter,
        page
      )
    );
  }

  function getData(request) {
    dispatch(lockedWindow());

    implementService(employerRegistrationAPI.get(request), (response) => {
      setTotal(response.count);
      setSource(response.results);
      setInit(true);
    }).finally(() => dispatch(unlockedWindow()));
  }

  function handleFilter(event) {
    setFilter(event.filter);
    handleRefresh();
  }

  function handlePage(event) {
    setPage({
      ...page,
      skip: event.page.skip,
      take: event.page.take,
    });
  }

  const setData = (objectSelected) => {
    let newRecords = Object.keys(objectSelected)
      .filter((key) => objectSelected[key])
      .map((key) => key);
    setValue("employer_registration", newRecords[0], { shouldValidate: true });
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedRecord = getSelectedState({
        event,
        selectedState: selectedRecord,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedRecord(newSelectedRecord);
      setData(newSelectedRecord);
      // eslint-disable-next-line
    },
    [selectedRecord, DATA_ITEM_KEY]
  );

  const handleInfo = () => {
    dispatch(
      showConfirmNotification({
        type: "info",
        title: t("general-information"),
        hasConfirm: false,
        message: (<TemplateInfo />),
      })
    );
  };
  const handleNavigate = () => {
    dispatch(hideNotificationWarning());
    openWindowItem(path);
  }

  const handleVerify = async (values) => {
    dispatch(lockedWindow());
    const request = {
      year: values.year,
      bimonthly: bimesters.find((f) => f.value === JSON.parse(JSON.stringify(values.bimester))).send,
      employer_registration: values.employer_registration,
      sua: values.sua
    }

    try {
      let response = await settlementComparisonApi.verifyData(settlement_type, request)
      if (response.is_difference) {
        dispatch(
          showConfirmNotification({
            type: "warning",
            title: "Reporte de diferencias de liquidación bimestral",
            message: (
              <TemplateAlert
                msg="Existe información con la fecha de aplicación, ¿Desea ver la información seleccionada?"
                type_action={type_action}
              />
            ),
            onConfirm: () => {
              type_action.current === "process" ? hiddenFileInput.current.click() : getReport(request);
              type_action.current = "process";
            },
          })
        );

        setDisabledSua(false);
      } else {
        hiddenFileInput.current.click()
      }
    } catch (error) {
      dispatch(
        showNotificationWarning({
          maxWidth: "sm",
          type: "error",
          title: "Reporte de diferencias de liquidación bimestral",
          message: `No se han generado datos de liquidación bimestral del año ${values.year}, bimestre ${values.bimester} y registro patronal “${values.employer_registration}”. Por favor verifique.`,
          description:
            <div
              style={navigateStyle}
              onClick={() => handleNavigate()}>
              {`${t("go-to")} ${t("liquidation-imss")}`}
            </div>
        })
      );
    } finally {
      dispatch(unlockedWindow());
    }

  };

  async function getReport(request) {
    dispatch(lockedWindow());
    try {
      let response = await settlementComparisonApi.downloadFile(settlement_type, request)
      FileSaver.saveAs(
        response,
        `${t("Reporte de comparación de liquidación bimestral")}_${request.employer_registration}_${format(
          new Date(),
          "yyyy-MM-dd hh:mm:ss"
        )}`
      );
    } catch (error) {
      dispatch(
        showNotificationWarning({
          message: t("no-results"),
        })
      );
    } finally {
      dispatch(unlockedWindow());
    }
  }

  const handleSUAInfonavit = async() => {
    dispatch(lockedWindow());

    const request = {
      year: getValues("year"),
      bimonthly: bimesters.find((f) => f.value === JSON.parse(JSON.stringify(getValues("bimester")))).send,
      key_employer: getValues("employer_registration"),
    }

    try {
      let response = await suaSettlementComparisonApi.verifyData(request);
      if (response.is_difference) {
        dispatch(
          showConfirmNotification({
            type: "warning",
            title: "Diferencias Infonavit calculo SUA menos descuentos en Nómina",
            message: (
              <TemplateAlert
                msg="Existen Periodos abiertos en el bimestre de comparación, ¿Desea ver la información seleccionada?"
                type_action={type_action}
              />
            ),
            onConfirm: () => {
              type_action.current === "process" ? getComparisonSua(true) : getComparisonSua(false);
              type_action.current = "process";
            },
          })
        );
      } else {
        getComparisonSua(true);
      }
    } catch (error) {
      showConfirmNotification(
        {
          maxWidth: "sm",
          message: t(`El archivo , no contiene información o no coincide con los parámetros dados. Por favor, verifique.`),
          onConfirm: () => {
            hiddenFileInput.current.click();
          },
        })
    } finally {
      dispatch(unlockedWindow());
    }
  };

  async function getComparisonSua(calculate) {

    const data = getValues();

    const body = parseRequest(
      {
        year: data.year,
        bimonthly: bimesters.find((f) => f.value === JSON.parse(JSON.stringify(data.bimester))).send,
        key_employer: data.employer_registration,
        is_recalculate: calculate
      }
    )

    try {
      let response = await suaSettlementComparisonApi.comparison(body);
      TriggerNotificationSocket('sua_infonavit', response.task_id);
    } catch (error) {
      dispatch(
        showNotificationError(
          { message: t(`El archivo, no contiene información del SUA o no coincide con los parámetros dados.`)}
        )
      );
    }
  }

  const readFileOnChange = async (event) => {
    if (!event.target.files[0]) return;
    try {
      await scanFile(event.target.files[0])
      getComparison(event.target.files[0]);
      event.target.value = null;
    } catch (error) {
      resolveError(error)
      event.target.value = null;
    }
  }

  async function getComparison(file) {
    dispatch(lockedWindow());

    const data = getValues();

    const request = parseRequest(
      {
        year: data.year,
        bimonthly: bimesters.find((f) => f.value === JSON.parse(JSON.stringify(data.bimester))).send,
        sua: data.sua,
        employer_registration: data.employer_registration,
        file: file
      }
    )

    try {
      await settlementComparisonApi.comparison(settlement_type, request).then((response) => {
        FileSaver.saveAs(
          response,
          `${t("Reporte de comparación de liquidación bimestral")}_${request.employer_registration}_${format(
            new Date(),
            "yyyy-MM-dd hh:mm:ss"
          )}`
        )
      })
    } catch (error) {
      dispatch(
        showConfirmNotification(
          {
            maxWidth: "sm",
            message: t(`El archivo "${file.name}", no contiene información del SUA o no coincide con los parámetros dados. Por favor, verifique.`),
            onConfirm: () => {
              hiddenFileInput.current.click();
            },
          })
      );
    } finally {
      dispatch(unlockedWindow());
    }
  }

  return {
    handleVerify,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    source,
    onSelectionChange,
    DATA_ITEM_KEY,
    selectedRecord,
    idGetter,
    filter,
    handleFilter,
    page,
    total,
    init,
    handlePage,
    handleRefresh,
    handleInfo,
    hiddenFileInput,
    readFileOnChange,
    handleSUAInfonavit,
    disabledSua
  };
}

export default useSettlementComparasion;

export const downloadSuaReport = async (taskID) => {
  try {
    let response = await socketsApi.downloadReport(taskID);
    FileSaver.saveAs(
      response,
      `${"payroll_sua_report"}_${format(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      )}`
    );
  } catch (error) {
    return error;
  }
}