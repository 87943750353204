import React from "react";
import Grid from '@mui/material/Grid2';
import { useDialogContext } from "../../../hooks/useDialog";
import { gridSize } from "..";
import Photo from "../../../../../Branch/components/Photo";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

function TabsHeader() {
    const { t } = useTranslation();
    const {
        isEdit,
        control,
    } = useDialogContext();

    const logo = useWatch({ control, name: 'logo' });
    const name = useWatch({ control, name: 'name' });

    return (
        <Grid {...gridSize(12)} container spacing={2}>
            <Grid container {...gridSize(12)}>
                <Grid>
                    <Photo
                        size={50}
                        withBorder={false}
                        showButton={false}
                        photo={logo?.full_size}
                    />
                </Grid>
                <Grid container spacing={0}>
                    <Grid className="title" {...gridSize(12)}>
                        {`${t(isEdit ? "edit" : "add")} ${t("company")}`}
                    </Grid>
                    <Grid {...gridSize(12)}>
                        {name}
                    </Grid>
                </Grid>
            </Grid>
            <Grid {...gridSize(12)}>
                Administra el nombre, la autenticación, y las credenciales de acceso, además de actualizar la imagen de perfil.
            </Grid>
        </Grid>
    );
};

export default TabsHeader;