import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { cloneDeep } from "lodash";
//-- mui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import SwitchItem from "./SwitchItem";
import Fieldset from '../../../../../core/@components/layout/Fieldset';
import StyledFormControlLabel from "../../../../../core/styled/StyledFormControlLabel";
import SwitchMuiCustom from "../../../Booleans/SwitchMui";
import { uid } from 'uid';

const styleContainer = {
  maxHeight: '540px',
  overflow: 'auto'
};

const Fields = (props) => {
  const {
    type,
    fieldSelection,
    setFieldSelection,
    setCheckedAll
  } = props;

  const renderByColumns = Array.isArray(fieldSelection.at(0));
  const [checkAll, setCheckAll] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    if (type !== "review") {
      handleSwitch(type);
    }
    // eslint-disable-next-line
  }, [type]);

  function handleCheckAll() {
    const newField = [...fieldSelection];
    setCheckAll(!checkAll)
    newField.map(f => {
      f.map(f => {
        f.isSelected = !checkAll
        return f
      })
      return f
    });
    setFieldSelection(newField);
  }

  const handleChangeSwitch = (fieldId, fieldState, columnIndex) => {
    const updatedField = renderByColumns
      ? fieldSelection[columnIndex][fieldId]
      : fieldSelection[fieldId];

    updatedField.isSelected = fieldState;

    const clone = cloneDeep(fieldSelection);
    clone[columnIndex][fieldId] = updatedField;

    setFieldSelection(clone);

  }

  const handleSwitch = (type) => {

    setCheckedAll(true);

    for (let prop in fieldSelection) {

      const updatedField = fieldSelection[prop];

      if (type !== "review") {
        updatedField.isSelected = true;
      }

      const newField = [...fieldSelection];
      newField[prop] = updatedField;

      setFieldSelection(newField);
    }
  };


  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item>
        <Card>
          <CardContent>
            <div disabled={type !== 'review'}>
              <Fieldset title={t('information-tab')} className={"export-fieldset"} container spacing={1} fieldsetStyle={styleContainer}>
                {renderByColumns && <Grid item xs={4}>
                  <StyledFormControlLabel
                    control={
                      <SwitchMuiCustom
                        checked={checkAll}
                        onChange={handleCheckAll}
                      />
                    }
                    label={checkAll ? t("uncheck-all") : t("check-all")}
                  />
                </Grid>}
                {renderByColumns && (
                  <RenderByColumns
                    columns={fieldSelection}
                    handleSelectedChange={handleChangeSwitch}
                  />
                )}

                {!renderByColumns && fieldSelection.map((field, index) => (
                  <Grid item xs={4} key={uid()}>
                    <SwitchItem
                      field={field}
                      fieldId={index}
                      type={type}
                      handleSelectedChange={handleChangeSwitch}
                    />
                  </Grid>
                ))}
              </Fieldset>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

function RenderByColumns(props) {
  const columns = props.columns;
  const type = props.type;
  const handleSelectedChange = props.handleSelectedChange;

  return (
    <Grid item container direction="row">
      {columns.map((column, index) => {
        return (
          <Grid item xs={4} key={index + 1}>
            {column.map((field, _index) => {
              return (
                <div key={_index}>
                  <SwitchItem
                    key={field?.key + 1}
                    field={field}
                    fieldId={_index}
                    type={type}
                    columnIndex={index}
                    handleSelectedChange={handleSelectedChange}
                  />
                </div>
              )
            })}
          </Grid>
        )
      })}
    </Grid>
  );
}

export default Fields;