export const kardexWorker = [
    "payroll_schemas.employer_registration_schema",
    "payroll_schemas.worker_payment_account",
    "last_entry,penultimate_infonavit_record.*",
    "last_infonavit_record.*",
    "worker_type,payroll_type",
    "integration_factor_type",
    "kiosco_configs.*",
    "kiosco_configs.custom_access.*",
    "enterprise_structure_organization.enterprise_structure.*",
    "enterprise_structure_organization.settings.*",
    "enterprise_structure_organization.employer_registration.*",
    "enterprise_structure_organization.employer_registration.schemas.bank_schema.*",
    "enterprise_structure_organization.employer_registration.schemas.voucher_bank_schema.*",
    "enterprise_structure_organization.employer_registration.schemas.payment_schema.*",
    "enterprise_structure_organization.enterprise_structure.job_position.*",
    "contract_type.*",
    "last_salary_movement.leave_movement.*",
    "last_payroll.payroll_type.*",
    "last_integration_factor.integration_factor_type.*",
    "geofences",
    "family",
    "workgroup",
    "exception_config",
    "scholarship",
    "zipcode.city.municipal.federal_entity.country.*",
    "voucher_schemas",
];

export const coursesWorker = [
    "worker_type",
    "payroll_type",
    "enterprise_structure_organization.enterprise_structure.*",
    "enterprise_structure_organization.enterprise_structure.job_position.*",
    "contract_type.*",
    "scholarship",
    "courses",
    "courses.course.*"
];

export const moperWorker = [
    "last_entry,penultimate_infonavit_record.*",
    "last_infonavit_record.*",
    "worker_type,payroll_type",
    "integration_factor_type",
    "enterprise_structure_organization.enterprise_structure.*",
    "enterprise_structure_organization.settings.*",
    "enterprise_structure_organization.employer_registration.*",
    "enterprise_structure_organization.enterprise_structure.job_position.*",
    "contract_type.*",
    "last_salary_movement.leave_movement.*",
    "last_payroll.payroll_type.*",
    "last_integration_factor.integration_factor_type.*",
    "workgroup",
    "voucher_schemas",
];


export const getFieldsRequest = (typeSearch) => {

    let urlWorker = "";

    switch (typeSearch) {
        case "kardex_worker":
            urlWorker = kardexWorker.join(",");
            break;
        case "courses_worker":
            urlWorker = coursesWorker.join(",");
            break;
        case "moper_worker":
            urlWorker = moperWorker.join(",");
            break;
        case "attendance_worker":
            urlWorker = [];
            break;
        case "alimony_worker":
            urlWorker = [];
            break;
        case "loans_worker":
            urlWorker = [];
            break;
        case "schedules_worker":
            urlWorker = [];
            break;
        default:
            urlWorker = [];
            break;
    }

    return urlWorker;
}