import { useEffect, useState } from "react";
import { extraActiveDaysAPI, extraCancelMopersAPI, extraMoperPermissionAPI, extraPermsAPI } from "../../../services/user";
import { branchEmpAPI } from "../../../services/enterprise-structure";
import { rolesApi } from "../../../services/roles-permissions";
import { companyApi } from "../../../services/company";
import { each, isFunction } from "lodash";
import { isAbrhil } from "../../../common/auth";
import { useSelector } from "react-redux";
import { useRequestLoad } from "../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";

const treeParam = { tree: true };

function useServerController({ selected, type, open, setValue } = {}) {
    const inAttendance = type === 'attendance';
    const hasSetValue = isFunction(setValue);
    const tenant = useSelector(state => state.tenant?.current?.id);
    const userRequest = { ...treeParam, user: selected?.id };

    const [extraPerms, setExtraPerms] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [activeDays, setActiveDays] = useState([]);
    const [cancelMopers, setCancelMopers] = useState([]);
    const [moperPermission, setMoperPermission] = useState([]);
    const [structureData, setStructureData] = useState([]);

    const [fetch, loading] = useRequestLoad();

    useEffect(() => {
        if (!open) return;
        if (!inAttendance) {
            getAllRoles();
            getStructureData();
            getExtraPerms();
            if (isAbrhil()) {
                getAllTenants();
            }
        } else {
            getActiveDay();
            getCancelMopers();
            getMoperPermission();
        }
    }, [open, type, tenant])

    async function getAllTenants() {
        const request = {
            ...treeParam,
            include: "*",
            fields: 'id,name,slug,country',
        }
        fetch({
            api: companyApi.get(request),
            callback: (resp) => {
                const actualTenant = resp?.findIndex(el => el.id === tenant);
                resp?.unshift(resp?.splice(actualTenant, 1)?.[0]);
                setTenants(resp);
            }
        });
    }

    async function getAllRoles() {
        fetch({
            api: rolesApi.get(treeParam),
            callback: (resp) => setRolesData(resp),
        });
    }

    async function getExtraPerms() {
        fetch({
            api: extraPermsAPI.get(treeParam),
            callback: (resp) => setExtraPerms(resp?.result),
        });
    }

    async function getStructureData() {
        fetch({
            api: branchEmpAPI.get(treeParam),
            callback: (resp) => {
                setStructureData(resp);
                if (hasSetValue) {
                    setValue('structure_data', resp);
                }
            }
        });
    }

    //* USER DATA

    async function getActiveDay() {
        fetch({
            api: extraActiveDaysAPI.get(userRequest),
            callback: (resp) => {
                setActiveDays(resp);
                if (hasSetValue) {
                    setValue('active_days', resp);
                }
            }
        });
    }

    async function getCancelMopers() {
        fetch({
            api: extraCancelMopersAPI.get(userRequest),
            callback: (resp) => {
                setCancelMopers(resp);
                if (hasSetValue) {
                    setValue('cancel_mopers', resp);
                }
            }
        });
    }

    async function getMoperPermission() {
        fetch({
            api: extraMoperPermissionAPI.get(userRequest),
            callback: (resp) => {
                if (!selected) {
                    each(resp?.filter(
                        el => el.permission_type === 1 && !['print_list'].includes(el.key)
                    ), field => {
                        field['has_perm'] = true;
                    });
                }
                setMoperPermission(resp);
                if (hasSetValue) {
                    setValue('moper_attendance', resp);
                }
            }
        });
    }

    return {
        loading,
        extraPerms,
        rolesData,
        tenants,
        activeDays,
        structureData,
        cancelMopers,
        moperPermission,
    };
};

export default useServerController;