import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFloppyDisk, faBan } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from "react-redux";
import Grid from '@mui/material/Grid';
import { useWatch } from "react-hook-form";
import { mapData } from '../util';
import { successUpdated } from '../../../../../common/notification-messages';
import { showNotificationSuccess } from '../../../../../../store/actions';
import { implementService } from '../../../../../services/implemet-service';
import { enterpriseStructureSettingsMultiAPI, enterpriseStructureSettingsMultiUpdateAPI } from '../../../../../services/enterprise-structure';
import { parseRequest } from '../../../../../common/parse-request';
import { usePackages } from '../../../usePackages';

function ButtonEdit({ edit, setEdit, cancel, control, refresh, filters, setValue, locked }) {
  const { tenant } = usePackages()

  const { t } = useTranslation()

  const data = useWatch({ control, name: 'data' });
  const all = useWatch({ control, name: 'all' });

  const dispatch = useDispatch();

  function hanldeEdit() {
    setEdit(true)
  }

  async function hanldeSave() {

    const resultData = mapData(data, true)
    const resultAll = mapData(all, false)
    
    if (resultData.length) {
      await implementService(enterpriseStructureSettingsMultiAPI.multi({ multiples: resultData, tenant},parseRequest({}, filters, {}), false), () => saveMultiplos());
    }
    
    if(Object.keys(resultAll).length) {
      await implementService(enterpriseStructureSettingsMultiUpdateAPI.putConcatName({...resultAll, tenant},parseRequest({},filters,{}),false), () => saveMultiplos());
    }

    if (!resultData.length && Object.keys(resultAll).length) {
      hanldeCancel()
    }
    setValue("data",[])
    setValue("all",{})
  }

  function saveMultiplos() {
    refresh()
    setEdit(false)
    dispatch(showNotificationSuccess(successUpdated()));
  }

  function hanldeCancel() {
    cancel()
  }

  if (edit) {
    return (
      <Fragment>
        <Grid item className="mt-2">
          <Button style={{ width: 'fit-content', float: 'right', marginLeft: '10px' }}
            fullWidth
            size="small"
            variant="contained"
            onClick={hanldeSave}
            endIcon={
              <FontAwesomeIcon className="icon-button" icon={faFloppyDisk} />
            }
          >
            {t('save')}
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ width: 'fit-content', float: 'right' }}
            fullWidth
            variant="contained"
            className="mr-3"
            size="small"
            onClick={hanldeCancel}
            endIcon={<FontAwesomeIcon className="icon-button" icon={faBan} />}
          >
            {t('cancel')}
          </Button>
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {locked && <Grid item className="mt-2" sx={{ width: 'fit-content', float: 'right' }}>
          <Button
            variant="contained"
            fullWidth={true}
            size="small"
            onClick={hanldeEdit}
            endIcon={<FontAwesomeIcon className="icon-button" icon={faPen} />}
          >
            {t('edit')}
          </Button>
        </Grid>}
      </Fragment>
    );
  }
}

export default ButtonEdit;
