import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleSideMenu } from "../../../../../store/triggers/navigate";
import HeaderMenuLogo from "./HeaderMenuLogo";
import Breadcrumb from "./Breadcrumb";
import HeaderRight from "../NavRight/Index";
import useAbrhilNavigation from '../../contextsAndControllers/useAbrhilNavigation';

const TopBar = () => {

	const { isMobile, menuCollapsed } = useAbrhilNavigation();

	const dispatch = useDispatch();
	const [toolsOpen, setToolsOpen] = useState(false);

	const toggleTools = () => {
		setToolsOpen(!toolsOpen);
		if(isMobile && !menuCollapsed){
			dispatch(toggleSideMenu());
		}
	};

	useEffect(()=>{
		if(isMobile && !menuCollapsed){
			setToolsOpen(false);
		}
		//eslint-disable-next-line
	},[menuCollapsed])

	return (
		<div className={`app-header`}>
			<HeaderMenuLogo toggleTools={toggleTools} />
			<div className={`app-header__content ${toolsOpen ? "header-mobile-open" : ""}`}>
				<div className="app-header-left">
					<Breadcrumb />
				</div>
				<HeaderRight />
			</div>
		</div >
	)
}

export default TopBar;