import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import {
    faTrash,
    faCheck,
    faXmark,
    faLayerGroup,
    faCube
} from "@fortawesome/pro-solid-svg-icons";
import FormInput from "../../../../../../@components/form/Field";
import {
    baseRole,
    initializeRole,
    transition2,
    validateShowItem,
    variants2,
} from "../../../../utils/utilities";
import { isString, trim } from "lodash";
import { useDispatch } from "react-redux";
import { showNotificationRemove } from "../../../../../../../store/actions";
import { questionDelete } from "../../../../../../common/notification-messages";
import { CustomTooltip } from "../../../../../../../App/components/Templates/cells";
import PropTypes from "prop-types";
import { customAnimation } from "../../../../../../@components/form/Permission/Utils";

const RoleItem = ({
    keyItem,
    control,
    item,
    dataInput,
    setEditing,
    errors,
    setValue,
    roleIndex,
    setRoleIndex,
    setCurrentInfo,
    setCurrentGroup,
    handleConfigAccess,
    getValues,
}) => {

    RoleItem.propTypes = {
        keyItem: PropTypes.number,
        control: PropTypes.object,
        item: PropTypes.object,
        dataInput: PropTypes.array,
        setEditing: PropTypes.func,
        errors: PropTypes.object,
        setValue: PropTypes.func,
        roleIndex: PropTypes.number,
        setRoleIndex: PropTypes.func,
        setCurrentInfo: PropTypes.func,
        setCurrentGroup: PropTypes.func,
        handleConfigAccess: PropTypes.func,
        getValues: PropTypes.func,
    };

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectedConfig, setSelectedConfig] = useState(false);
    const permErrors = errors?.groups_perms?.[keyItem];
    const menuErrors = permErrors?.rolePerms || permErrors?.dashboard;
    const structureError = permErrors?.branches_employer;
    const groups = getValues('groups_perms');

    useEffect(() => {
        if (selectedConfig) {
            const info = {
                info: `Módulos`,
                class: 'modules-perms',
                icon: faCube,
            }
            setCurrentInfo(info);
            return;
        }
        setCurrentInfo(null);
    }, [selectedConfig])

    const handleSave = () => {
        let updateRoles = groups;
        const index = updateRoles.findIndex(el => el.saved === false);
        updateRoles[index] = initializeRole(updateRoles[index], getValues);
        const newItem = updateRoles[index];
        newItem.saved = true;
        handleConfig(newItem);
        setValue('groups_perms', updateRoles, { shouldValidate: true });
        setEditing(false);
    }

    const handleCancel = (item) => {
        const updateRoles = groups.filter(el => el.saved !== item.saved);
        unselectRole(item);
        setValue('groups_perms', updateRoles, { shouldValidate: true });
        setEditing(false);
    }

    const handleConfig = (item) => {
        const select = unselectRole(item);
        setSelectedConfig(!select);
        if (!select) {
            const index = groups.findIndex(el => el?.group?.id === item?.group?.id);
            setRoleIndex(() => (index));
            setCurrentGroup(groups[index]);
            return;
        };
        setCurrentGroup(null);
    }

    const handleDelete = (item) => {
        const context = `${t("role")} ${item.group.name}`;
        dispatch(showNotificationRemove(questionDelete(context, confirmDelete, item)));
    }

    const confirmDelete = (item) => {
        const updateRoles = groups.filter(el => el?.group?.id !== item.group.id);
        unselectRole(item);
        if (!updateRoles?.length) {
            updateRoles?.push(baseRole);
        }
        setValue('groups_perms', updateRoles, { shouldValidate: true });
        setEditing(false);
    }

    function unselectRole(item) {
        const index = groups.findIndex(el => el.group.id === item?.group?.id);
        if (index === roleIndex) {
            setRoleIndex(null);
            setCurrentGroup(null);
            setCurrentInfo(null);
            return true;
        };
        return false;
    }

    const validateSelected = () => {
        return selectedConfig && item?.group?.id === validateShowItem(roleIndex, groups, true);
    }

    function itemValidation(value, item) {

        const handleSelect = (e) => {
            let updateRoles = groups;
            let index = updateRoles.findIndex(el => el.saved === false);
            if (e) {
                const groupInfo = {
                    id: e?.id,
                    name: e?.name,
                    config: e?.config,
                }
                const newItem = { ...item, ...e, group: groupInfo };
                updateRoles[index] = newItem;
            } else {
                updateRoles[index] = baseRole;
                setValue(`role_select`, null);
            }
            setValue('groups_perms', updateRoles);
            return e;
        };

        const data = {
            [true]: {
                onClick1: handleConfig,
                onClick2: handleDelete,
                onClick3: handleConfigAccess,
                name: item?.group?.name,
                icon1: faCube,
                icon2: faTrash,
                icon3: faLayerGroup,
            },
            [false]: {
                onClick1: handleSave,
                onClick2: handleCancel,
                name:
                    <FormInput
                        control={control}
                        ListboxProps={{ style: { maxHeight: "17rem" }, position: "bottom-start" }}
                        className="list-permissions-select"
                        fieldInput="CustomSelect"
                        returnObject={true}
                        name={`role_select`}
                        label={t("select")}
                        options={dataInput}
                        onChange={handleSelect}
                    />,
                icon1: faCheck,
                icon2: faXmark,
            },
        }
        return data[value];
    }

    const itemName = itemValidation(item.saved, item).name;

    const getBranchTooltip = () => {
        const branches = item?.branches_employer;
        if (branches?.length) {
            const basicKey = item => `${item.branch_key} - ${item.branch_name}`;
            const getExtraKey = item => item.employer_id ? ` / ${item.employer_key} - ${item.employer_name}` : '';
            return (
                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '200px', overflow: 'auto', paddingRight: '5px' }}>
                    {branches.map(el => <span key={`branch_emp_${el.id}`}>{trim(`${basicKey(el)}${getExtraKey(el)}`)}</span>)}
                </div>
            );
        }
        return 'Configurar Suc. / R. Social / Reg. Pat';
    }

    return (
        <AnimatePresence mode='wait'>
            <motion.div
                className={`role-item ${!item.saved || item?.group?.id === validateShowItem(roleIndex, groups, true) ? 'unsaved' : ''} ${permErrors ? 'error' : ''}`}
                key={keyItem}
                {...customAnimation(variants2, transition2)}
            >
                <div className={`role-info`}>
                    <div className="role-content-wrapper">
                        {
                            isString(itemName) ?
                                <span className="role-item-text">
                                    {itemName}
                                </span>
                                :
                                <div className="role-item-input-wrap">
                                    {itemName}
                                </div>
                        }
                    </div>
                    <div className="role-item-buttons-container">
                        <CustomTooltip title={item.saved ? 'Configurar permisos módulos' : 'Guardar'}>
                            <div>
                                <button
                                    className={`role-action-button config ${validateSelected() ? 'selected' : ''} ${item?.group?.id === undefined ? 'disabled' : ''} ${menuErrors ? 'error' : ''}`}
                                    onClick={() => itemValidation(item.saved).onClick1(item)}
                                    disabled={!item?.group?.id}
                                >
                                    <FontAwesomeIcon icon={itemValidation(item.saved).icon1} />
                                </button>
                            </div>
                        </CustomTooltip>
                        {
                            itemValidation(item.saved)?.icon3 ?
                                <CustomTooltip title={getBranchTooltip()} disableInteractive={false}>
                                    <button
                                        className={`role-action-button config-access ${structureError ? 'error' : ''}`}
                                        onClick={() => itemValidation(item.saved).onClick3(item, keyItem)}
                                    >
                                        <FontAwesomeIcon icon={itemValidation(item.saved).icon3} />
                                    </button>
                                </CustomTooltip>
                                :
                                null
                        }
                        <CustomTooltip title={item.saved ? 'Eliminar' : 'Cancelar'}>
                            <button
                                className={`role-action-button delete`}
                                onClick={() => itemValidation(item.saved).onClick2(item)}
                            >
                                <FontAwesomeIcon icon={itemValidation(item.saved).icon2} />
                            </button>
                        </CustomTooltip>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

export default RoleItem;