import { store } from '../../store';
import { isAbrhil } from './auth';

/**
 *
 * @returns {number | null}
 */
export function currentTenant() {
  return store.getState().tenant.current?.id;
}

export function currentGroup() {
  const group = store.getState().group;
  if (group?.data.length === 0) {
    return 0;
  }
  return group?.select?.id ?? null;
}

export function hasErrorTetant() {
  const tenant = store.getState().tenant;
  return isAbrhil() && !tenant.current?.id;
}

export function tenantCountry() {
  return store.getState().country?.country ?? "MX";
}

export function dashboardPath() {
  return store.getState().userDashboard?.path;
}

export function currentUserInfo() {
  return store.getState().userConfigs;
}