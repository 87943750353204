import { useState, useEffect, useMemo } from "react";
import { implementService } from "../../../../services/implemet-service";
import { packagesV2Api } from "../../../../services/packages";
import { usePackages } from "../../usePackages";
import { debounce, includes, filter } from "lodash";
import { setConfigEnterpriseStructure, setConfigPayroll } from "./util";

function useBarList() {
  const { tenant, selectPackage, setSelectCategory, setClassifications, hideSettings, persistSettings, chill, setChill } = usePackages()
  const [data, setData] = useState({})
  const [search, setSearch] = useState(null)
  const [load, setLoad] = useState(false)
  const [refreshList, setRefreshList] = useState(false)
  const [filterData, setFilterData] = useState({})

  useEffect(() => {
    if (!selectPackage) return
    setSearch(null)
    setRefreshList(true)
    if (chill && selectPackage.sub_packages) {
      setLoad(true)
      const result = selectPackage.sub_packages.find(f => f.key === chill.key)
      selectTab({},result)
    } else {
      setChill(null)
    }
    if (selectPackage.id) {
      getData()
    } else {
      setSubPackages()
    }

  }, [selectPackage])

  const debounceSearch = useMemo(() => {
    return debounce(handleSetSearch, 200);
    //eslint-disable-next-line
  }, [data]);

  function verify(result) {
    if (persistSettings.length) {
      const classifications = filter(result.classifications, item => includes(persistSettings, item.key));
      result.classifications = classifications
    } else if (hideSettings.length) {
      const classifications = filter(result.classifications, item => !includes(hideSettings, item.key));
      result.classifications = classifications
    }
  }

  function setSubPackages() {
    const copyData = JSON.parse(JSON.stringify(selectPackage))
    copyData.classifications = copyData.sub_packages
    copyData.chill = true
    setData(copyData)
    setFilterData(copyData)
    setRefreshList(false)
    setSelectCategory(null)
  }

  function getData() {
    const params = {
      package: selectPackage.id
    }
    implementService(packagesV2Api(tenant).packages.get(params), result => {
      setRefreshList(false)
      if (result.key === "ATTENDANCE_LIST") {
        setConfigEnterpriseStructure(result)
      } else if (result.key === "payroll_package") {
        setConfigPayroll(result)
      }
      verify(result)
      setData(result)
      setFilterData(result)
      setSelectCategory(result?.classifications[0]?.categories[0])
      setClassifications(result?.classifications[0])
    })
  }

  function handleSelectCategory(category, classification) {
    setSelectCategory(category)
    setClassifications(classification)
  }

  function handleSearchChill(value) {
    const copyData = JSON.parse(JSON.stringify(data))
    copyData.classifications = copyData.classifications.map(m => {
      return (includes(m.alias.toLowerCase(), value.toLowerCase())) ? m : null
    }).filter(f => f)
    setFilterData(copyData)
    setLoad(false)
  }

  function handleSetSearch({ value }) {
    if (data.chill) {
      handleSearchChill(value)
      return
    }
    const copyData = JSON.parse(JSON.stringify(data))
    if (copyData.key === "ATTENDANCE_LIST") {
      setConfigEnterpriseStructure(copyData)
    } else if (copyData.key === "payroll_package") {
      setConfigPayroll(copyData)
    }
    verify(copyData)

    copyData.classifications = copyData.classifications.map(m => {
      m.categories = m.categories.map(f => {
        return (includes(f.alias.toLowerCase(), value.toLowerCase())) ? f : null
      }).filter(f => f)
      return m.categories.length ? m : null
    }).filter(f => f)
    setFilterData(copyData)
    setLoad(false)
  }

  async function selectTab(e, item) {
    if (e?.target?.type === 'checkbox') return
    const params = {
      package: item.id
    }
    implementService(packagesV2Api(tenant).packages.get(params), result => {
      setSearch(null)
      setChill(result)
      setFilterData(result)
      setData(result)
      setLoad(false)
      setSelectCategory(result?.classifications[0]?.categories[0])
      setClassifications(result?.classifications[0])
    })
  }

  function backTabs() {
    setChill(null)
    setClassifications(null)
    setSelectCategory(null)
    setSubPackages()
  }

  return {
    filterData,
    handleSelectCategory,
    search,
    debounceSearch,
    setSearch,
    load,
    setLoad,
    refreshList,
    selectTab,
    chill,
    backTabs,
  };
}

export default useBarList;
