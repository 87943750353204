
import Money from "../../../../icons/money.svg";
import TxT from "../../../../icons/txt.svg";
import Check from "../../../../icons/check.svg";
import PD from "../../../../icons/prim_dom.svg";
import TD from "../../../../icons/double_shift.svg";
import DT from "../../../../icons/worked_rest.svg";
import Holiday from "../../../../icons/holiday.svg";
import Rest from "../../../../icons/rest.svg";
import { forEach, isDate, isEmpty, isNumber, isString, size, toLower } from "lodash";
import { workerFullName } from "../../../../../../@components/employees/constants";
import { toTitleCase } from '../../../../../../@components/navigation/components/userSection/utilities';
import { store } from "../../../../../../../store";
import { parseDatetime } from "../../../../../../common/GeneralUtilities";
import { showNotificationWarning } from "../../../../../../../store/actions";
import { currentUserInfo } from "../../../../../../common/validate-tenant";

const DEFAULT_CHECK = "--:--";

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.removeDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() - days);
    return date;
};

export const initValues = {
    selected_calendar_filter: 0,
};

export const paymentInitValues = {
    cover_reason: 2,
    money_request_time: 0,
    txt_request_time: 0,
    pay_type: 1,
    comment: null,
    isDT: false,
    payment_type: null,
    request_time: null,
    worker: null,
};

export const paymentTypes = [
    { value: 1, name: 'PD', desc: 'Prima Dominical', auto: true },
    { value: 2, name: 'FT', desc: 'Festivo Trabajado', auto: true },
    { value: 3, name: 'DT', desc: 'Descanso Trabajado' },
    { value: 4, name: 'TD', desc: 'Turno Doble' },
    { value: 5, name: 'BN', desc: 'Bono nocturno', auto: true },
    { value: 6, name: 'IN', desc: 'Implicita nocturna', auto: true },
    { value: 7, name: 'IM', desc: 'Implicita mixta', auto: true },
];

export const paymentTypesInput = [
    { value: 1, name: 'Dinero', keyname: 'money_request_time' },
    { value: 2, name: 'Tiempo por tiempo', keyname: 'txt_request_time' },
];

export const allMTypes = [
    [
        { id: 1, label: 'Prima dominical' },
        { id: 2, label: 'Festivo trabajado' },
        { id: 3, label: 'Descanso trabajado' },
        { id: 4, label: 'Turno doble' },
    ],
    [
        { id: 1, label: 'Dinero' },
        { id: 2, label: 'Tiempo por tiempo' },
        { id: 3, label: 'Implicita' },
    ]
];

export const coverReasonsInput = [
    { value: 1, name: 'Cubrir a un colaborador' },
    { value: 2, name: 'Otro' },
];

export const workdayTypes = [
    { label: 'Descanso', icon: Rest },
    { label: 'Festivo', icon: Holiday },
];

export const omitValues = (list, omit) => list?.filter(el => !omit?.includes(el?.key));

export const intToHour = number => {
    let numb = number ?? 0;
    const hour = numb.toString().padStart(2, '0');
    return `${hour}:00`;
};

export const jobPoFilter = [
    [{ key: 'key', label: 'Clave' }, { key: 'name', label: 'Nombre' }],
    'key',
    'label'
];

export const paymentTypeIcons = [
    [
        { value: 1, icon: PD },
        { value: 2, icon: Holiday },
        { value: 3, icon: DT },
        { value: 4, icon: TD },
    ],
    [
        { value: 1, icon: Money },
        { value: 2, icon: TxT }
    ],
];

export const signatureStatus = {
    [false]: { class: 'indeterminate', icon: null },
    [true]: { class: 'success', icon: Check },
};

export const filterOptions = [
    { label: 'Periodo Actual', value: 0 },
    { label: 'Hoy', value: 1 },
    { label: 'Ayer', value: 2 },
    { label: 'Está semana', value: 3 },
    { label: 'Semana pasada', value: 4 },
    { label: 'Este mes', value: 5 },
];

export const Actions = [
    { value: 1, label: 'Cancelar solicitud' },
    { value: 2, label: 'Restaurar solicitud' },
    { value: 3, label: 'Cancelar TxT' },
];

export const getMoperPerm = (perm) => store.getState().userConfigs?.user_info?.moper_attendance?.find(el => el.key === perm)?.has_perm;

export const genericPaymentRequest = (
    params,
    worker,
    selected,
    pay_type,
    dt_pay_type,
    in_minutes,
) => ({
    moper: {
        moper_catalog: 6,
        worker: worker?.id,
        cover_worker: {
            worker: params?.worker,
            type_absenteeism: params?.cover_reason,
        },
    },
    ...(params?.money_request_time ? { minutes_requested: in_minutes ? params?.money_request_time : params.money_request_time * 60 } : {}),
    ...(params?.txt_request_time ? { minutes_requested_txt: in_minutes ? params?.txt_request_time : params.txt_request_time * 60 } : {}),
    origin_day: selected?.origin_day,
    request_comment: params.comment,
    pay_type: pay_type,
    ...(isNumber(dt_pay_type) ? { payment_type_rest_worked: dt_pay_type } : {}),
});

export const toHours = value => (value ?? 0) / 60;

export const validateCreateCheck = (item, isMyWorkgroup, canCreateChecks) => {
    const dispatch = store.dispatch;
    const checks = item?.checks;
    const body = { message: null, description: null, maxWidth: 'sm' };
    if (isEmpty(checks) && !canCreateChecks) {
        body.message = 'Sin permisos para crear checadas';
        body.description = 'Su usuario no cuenta con permisos para crear checadas';
    }
    if (!isMyWorkgroup) {
        body.message = 'No tiene asignado este grupo de trabajo';
        body.description = 'Solicite la asignación de este grupo a su administrador';
    }
    if (body.message || body.description) {
        dispatch(showNotificationWarning(body));
        return;
    }
    return true;
}

export const validateDayCalculate = worker => {
    const dispatch = store.dispatch;
    const body = { message: null, description: null, maxWidth: 'sm' };
    if (!workerHasHorary(worker)) {
        body.message = 'La estructura del colaborador no tiene horarios asignados';
        body.description = 'Asigne los horarios correspondientes, creélos o contacte a su administrador';
    }
    if (body.message || body.description) {
        dispatch(showNotificationWarning(body));
        return;
    }
    return true;
}

export const validateRequestOvertime = () => {
    const dispatch = store.dispatch;
    const body = { message: null, description: null, maxWidth: 'sm' };
    const userPerms = currentUserInfo()?.user_info?.moper_attendance?.filter(el => el.has_perm)?.map(el => el.description);
    const canReq = userPerms?.includes(7) || userPerms?.includes(8);
    if (!canReq) {
        body.message = 'Sin permisos de solicitar TxT y Dinero';
        body.description = 'Su usuario no cuenta con permisos para solicitar TxT y Dinero';
    }
    if (body.message || body.description) {
        dispatch(showNotificationWarning(body));
        return;
    }
    return true;
}

export const validateRequestOvPayment = (item) => {
    const dispatch = store.dispatch;
    const body = { message: null, description: null, maxWidth: 'sm' };
    const canRequest = item?.has_perm_for_this;
    const type = item?.modal;
    if (!canRequest) {
        body.message = `Sin permisos de solicitar ${type}`;
        body.description = `Su usuario no cuenta con permisos para solicitar ${type}`;
    }
    if (body.message || body.description) {
        dispatch(showNotificationWarning(body));
        return;
    }
    return true;
}

export function buildHours(minutes) {
    const hours = parseFloat(((minutes ?? 0) / 60).toFixed(2));
    return `${hours} horas`;
}

export function buildHour(minutes) {
    let min = minutes ?? 0;
    const hours = Math.floor(min / 60);
    const minutesR = min % 60;
    const fHour = hours.toString().padStart(2, '0');
    const fMinutes = minutesR.toString().padStart(2, '0');
    return `${fHour}:${fMinutes}`;
}

export function buildCheck(check) {
    if (isDate(check)) {
        return `${(check.getHours()).toString().padStart(2, '0')}:${(check.getMinutes()).toString().padStart(2, '0')}`;
    }
    if (isString(check)) {
        return check.slice(0, -3);
    }
    return DEFAULT_CHECK;
}

export const validChecks = checks => {
    if (!size(checks)) return false;
    let value = true;
    forEach(checks, (check) => {
        if (!check.check_in || !check.check_out) {
            value = false;
        }
    });
    return value;
};

export const isMoperAuthorized = moper => {
    const signatures = moper?.flow_signature?.filter(el => el.name !== 'cancel');
    if (size(signatures)) return signatures?.at(-1)?.is_signed;
    return true;
};

export const workerHasHorary = (worker) => size(worker?.enterprise_structure_organization?.settings?.enterprise_structure_horary) > 0;

export const findAbsen = (item, key) => item?.absenteeisms?.find(el => toLower(el.key) === toLower(key));

export const isMoperProcess = moper => moper?.flow_signature?.filter(el => el.name !== 'cancel')?.some(el => !el?.is_signed) || moper?.status === 1;

export const isMoperCanceled = moper => moper?.flow_signature?.find(el => el.name === 'cancel')?.is_signed || moper?.status === 3 || moper?.moper?.status === 3;

export const isAutoMoper = moper => !moper?.flow_signature?.filter(el => el.name !== 'cancel')?.length;

export const hasAssistMopers = item => size(item?.assist_adjust?.filter(el => !isMoperAuthorized(el))) > 0;

export const hasCompleteShift = item => size(item?.complete_shift) > 0 && (!isMoperAuthorized(item?.complete_shift) || item?.complete_shift?.can_request);

export const hasAbsenteeismMopers = item => size(item?.absenteeisms) > 0;

export const isVacationMoper = item => findAbsen(item, 'v');

export const isInactive = item => !!findAbsen(item, 'b');

export const isNonAttendance = item => !!findAbsen(item, 'f');

export const isAbsenteeismMoper = item => [1, 2].includes(item?.absenteeisms?.[0]?.moper?.moper_catalog);

export const isWorkerRestMoper = item => findAbsen(item, 'd');

export const isHolidayRest = item => findAbsen(item, 'df');

export const isRestVacation = item => findAbsen(item, 'dv');

export const hasHoliday = item => size(item?.holiday_record) > 0;

export const isPeriodLocked = period => period ? parseDatetime(period?.locked_at, 'asDate') < new Date() : false;

export const getMoperNextSign = moper => {
    const flowSignature = moper?.flow_signature?.filter(el => el.name !== 'cancel');
    let foundLastSign = null;
    for (const field in flowSignature) {
        const sign = flowSignature[field];
        if (foundLastSign) {
            return sign;
        }
        if (sign) {
            foundLastSign = sign;
        }
    }
    return null;
}

export const canSignMoper = moper => {
    const next = getMoperNextSign(moper);
    return !next?.is_signed && next?.can_sign && next?.user_sign;
};

export const isMoperSigned = item => {
    const signatures = item?.flow_signature?.filter(el => el.name !== 'cancel');
    if (!signatures?.length) return true;
    const onlySigned = signatures.filter(el => el.is_signed);
    return !!((onlySigned.length > 1 && signatures.length > 1) || (onlySigned.length === 1 && signatures.length === 1));
}

export const getUserName = (item) => {
    const worker = item?.moper?.user?.profile?.worker;
    const externalName = item?.moper?.user?.profile?.external_name;
    const hasWorker = size(worker) > 0;
    if (hasWorker) {
        return toTitleCase(workerFullName(worker));
    }
    return toTitleCase(externalName);
};

export const statusOptions = [
    { label: 'Firmados', value: 2, classname: 'auth' },
    { label: 'En proceso', value: 1, classname: 'in-process' },
    { label: 'Cancelados', value: 3, classname: 'canceled' },
];

export const paymentOptions = [
    { label: 'Prima dominical', value: 5 },
    { label: 'Festivo trabajado', value: 6 },
    { label: 'Descanso trabajado', value: 3 },
    { label: 'Turno doble', value: 4 },
    { label: 'Dinero', value: 1 },
    { label: 'TxT', value: 2 },
    { label: 'Implicitas', value: 7 },
];

export const typeOptions = [
    { label: 'Calculados', value: false },
];

// ANIMATIONS

export const variants = {
    enter: () => {
        return {
            opacity: 0,
            y: -20,
        };
    },
    center: {
        opacity: 1,
        y: 0,
    },
    exit: () => {
        return {
            opacity: 0,
            y: -20,
        };
    }
};

export const transition = {
    duration: 0.5,
    ease: [0, 0.71, 0.2, 1.01],
};


export const variants2 = {
    enter: {
        // opacity: 0,
        scale: 0,
    },
    center: {
        // opacity: 1,
        scale: 1,
    },
    exit: {
        // opacity: 0,
        scale: 0,
    }
};

export const transition2 = {
    duration: 0.4,
    ease: [0, 0.71, 0.2, 1.01],
};

export const variants3 = {
    enter: {
        opacity: 0,
    },
    center: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    }
};

export const transition3 = {
    duration: 0.2,
    // ease: [0, 0.71, 0.2, 1.01],
};
