import React from "react";
import GridNoRecordsRender from "../../../../../../App/components/Templates/GridNoRecordsRender";
import { useGridContext } from "../../hooks/useGrid";
import List from "./components/List";


function GridList() {
    const { data, init, filters } = useGridContext();

    return (
        <>
            {
                data.total > 0 ?
                    <List />
                    :
                    <GridNoRecordsRender init={init} filter={filters} />
            }
        </>
    );
};

export default GridList;