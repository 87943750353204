import React, { useEffect, useRef } from "react";
import { Popover } from "@mui/material";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import Grid from "@mui/material/Grid2";
import IconUse from "../../../../../../common/IconUse";
import { gridSize } from "../../../../../AbrhilApp/Company/Components/AddEdit";
import { SolidDivider } from "../../../../../MOPERSByWorker/components/container/Overtime/components/Modals/TimePayment";
import "./style.scss";

function ModernPopup({
    target,
    title,
    handleClose,
    content: Content,
    children,
    className = "",
    withBackdrop = true,
    anchorOrigin = {
        vertical: 'top',
        horizontal: 'left',
    },
    transformOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    ...others
}) {

    ModernPopup.propTypes = {
        target: PropTypes.any,
        anchorOrigin: PropTypes.object,
        transformOrigin: PropTypes.object,
        children: PropTypes.any,
        withBackdrop: PropTypes.bool,
        title: PropTypes.string,
        handleClose: PropTypes.func,
        content: PropTypes.any,
        className: PropTypes.string,
    };

    const popActions = useRef();

    const open = Boolean(target);
    const id = open ? 'simple-modern-popover' : undefined;
    const body = isFunction(Content) ? <Content /> : Content;


    useEffect(() => {
        if (!popActions.current) return;
        popActions.current.updatePosition();
    }, [open])

    return (
        <Popover
            id={id}
            open={open}
            action={popActions}
            anchorEl={target}
            onClose={handleClose}
            className={`modern-popup-component ${className} ${!withBackdrop ? 'no-backdrop' : ''}`}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...others}
        >
            <PopHeader title={title} handleClose={handleClose} />
            <div className="modern-popup-body">
                {body ?? children}
            </div>
        </Popover>
    );
};

export default ModernPopup;

const PopHeader = ({
    title,
    handleClose,
}) => {

    PopHeader.propTypes = {
        title: PropTypes.string,
        handleClose: PropTypes.func,
    };

    return (
        <Grid container columnSpacing={4} rowSpacing={1.1} className="modern-popup-header">
            {!!title && <Grid {...gridSize('auto')} sx={{ alignSelf: 'flex-end' }}>{title}</Grid>}
            <Grid sx={{ marginLeft: 'auto', alignSelf: 'flex-start' }}>
                <button onClick={handleClose}>
                    <IconUse icon="SlimClose" />
                </button>
            </Grid>
            <SolidDivider />
        </Grid>
    );
};