import { isBefore, isSameDay, isToday } from 'date-fns';
import i18next from "i18next";
import { concat, first, isBoolean, isNil, size, sortBy, upperCase } from 'lodash';
import { current_date } from "../../../../../App/components/Dates/utilities";
import { hasValue, valueOrOption } from "../../../../common/GeneralUtilities";
import { getRestDayBg, parseDate } from "../attendanceGrid/utilities";
import { ChecksElements, DaysFlags, HierarchicalLevels, ImssKeys, buttonsMopers } from "./constants";
import { formatOvertimeResponse } from '../../../MOPERSByWorker/components/container/Overtime/hooks/useGrid';
import { getAssistAdjust, getCompleteShift } from '../../components/Table/common/utilities';
import { status } from '../constants';
import { statusOptions } from '../attendanceGrid/constants';
import { getCheck } from '../../../MOPERSByWorker/components/container/Overtime/components/Cards/WorkingDay';
import { getPaymentsToShow } from '../../../MOPERSByWorker/components/container/Overtime/components/Cards/OvertimeContent/utils/utils';
const { t } = i18next;

export const getDayCellContent = ({
    currentDate,
    worker,
    view,
    userCountry,
    currentData
}) => {

    const {
        absenteeisms,
        holiday_record,
        group_date,
        checks,
        assist_adjust
    } = currentData ?? {};

    //Rest and holiday
    const holidayData = holiday_record?.[0] || {};

    let holidays = [{
        attendance_key: 'holiday',
        ...holidayData
    }];

    if (absenteeisms?.some(element => element?.attendance_key === "holiday")) {
        holidays = [];
    }

    const hasDefaultRest = getRestDayBg(currentDate, holiday_record);

    // Flags info
    const checksInfo = getDayFlags({
        daysFilter: "",
        currentDate,
        worker,
        ...currentData
    });

    // Overtime item format
    const overtimeItem = valueOrOption(first(formatOvertimeResponse([{
        ...currentData,
        worker,
        origin_day: currentDate,
    }])), []);

    //Groups data
    const groupInfo = handleGroups({ ...currentData });

    // Symbols and content
    const checkSymbol = checksInfo?.symbol;
    const withNewCheck = withFaultRegister({ currentDate, worker, ...currentData });

    const principalSymbol = getCellKey({ ...currentData, checkSymbol, withNewCheck });

    const showContent = validItems(groupInfo);

    const isSameSymbol = principalSymbol === checkSymbol;
    const isCheckSymbolNil = isNil(checkSymbol);
    const isGrouped = groupInfo?.isGroupped;
    const hasPar = groupInfo?.par;

    let secondarySymbol = null;

    if (!isSameSymbol && !isCheckSymbolNil) {
        if ((isGrouped && !showContent) || hasPar) {
            secondarySymbol = checkSymbol;
        } else {
            secondarySymbol = "?";
        }
    }

    // Obtenemos el contenido principal
    const principalContent = generalMopAndAttIconAndLabel(principalSymbol, userCountry);
    let faultWithFlows = '';
    if (principalSymbol === "fault" && hasValue(first(absenteeisms)?.flow_signature)) {
        principalContent.withFlows = true;
        faultWithFlows = 'with-flow';
    }

    const secondaryContent = generalMopAndAttIconAndLabel(withNewCheck, userCountry);

    const pairIconSide = hasPar ? group_date?.is_middle : null;

    // ScrollView
    let scrollViewdata = concat(assist_adjust, absenteeisms, holidays?.filter(element => element?.is_applied))?.filter(element => element?.key || element?.id);

    const hasChecks = hasValue(checks);
    const shouldShowPrincipalContent = showContent && (isGrouped || (!isGrouped && !hasChecks));
    const principalContentResult = shouldShowPrincipalContent ? principalContent : [];

    //Footer Buttons
    const buttonsToShow = getButtonsActive({
        ...currentData,
        overtimeItem,
        userCountry,
        principalContent: principalContentResult,
        currentDate,
        group_date
    });

    // Get initial card bg
    const defaultRestClass = hasDefaultRest ? 'default-rest' : '';
    const principalContentKeyClass = principalContent?.key || '';
    const groupInfoClassName = groupInfo?.className || '';

    // Unimos todas las clases en una sola cadena
    let initialCardClassName = `days-cell-wrap ${defaultRestClass} ${view} general-mop-att bg ${principalContentKeyClass} ${faultWithFlows} ${groupInfoClassName}`;

    const bgContentCard = principalContent?.withFlows ? (absenteeisms[0]?.moper
        ?.status === 1 ? 'process' : principalContent?.key) : principalContent?.key;

    //Get content card bg
    const viewCellWrapClass = `${view}-cell-wrap`;
    const flagsClassName = checksInfo?.flagsClassName || '';

    let contentCardClassName = `${viewCellWrapClass} ${flagsClassName} general-mop-att bg ${bgContentCard} ${faultWithFlows} ${groupInfoClassName}`;

    // Tooltip
    const tooltipMessage = getTooltip(principalContent, absenteeisms, holiday_record?.[0]);

    return {
        initialCardClassName,
        contentCardClassName,
        principalSymbol,
        secondarySymbol,
        principalContent,
        secondaryContent,
        showContent,
        pairIconSide,
        worker,
        currentDate,
        overtimeItem,
        buttonsToShow,
        scrollViewdata,
        tooltipMessage,
        groupInfo,
        holidays,
        ...checksInfo,
        ...currentData
    }
}

const getTooltip = (content, data, holiday) => {
    if (!hasValue(content)) {
        return;
    }
    let message = {
        title: t(content?.title)
    };
    if (content?.withFlows) {
        const item = data?.find(element => element?.attendance_key === content?.key);
        const title = `Moper de ${t(content?.title)}`;
        const status = statusOptions.find(status => status?.id === item?.moper?.status);
        message = {
            title,
            status: status,
            initial_date: item?.start_date,
            end_date: item?.end_date,
        }
    }
    if (content?.key === 'holiday' && (holiday?.is_applied || data?.find(element => element?.attendance_key === 'holiday'))) {
        const title = `${t(content?.title)}: `;
        message.title = title;
        message.description = holiday?.description;
    }

    if (content?.key === 'inabilities' || content?.key === "imss") {
        const item = data?.[0];
        const title = `${t(content?.title)}: `;
        message = {
            title,
            description: item?.description,
            initial_date: item?.initial_date,
            end_date: item?.end_date,
        }
    }

    return message;
}

export const validItems = (groupData) => {
    if (!groupData?.isGroupped) {
        return true;
    }
    return groupData?.withContent;
}

export const getCellKey = ({ absenteeisms, checkSymbol, withNewCheck, group_date }) => {
    if (!hasValue(absenteeisms)) {
        return isNil(checkSymbol) && !isNil(withNewCheck) ? "register-fault" : checkSymbol;
    };

    const sortedArr = sortBy(absenteeisms, (element) => HierarchicalLevels.indexOf(element?.attendance_key));
    let key = sortedArr[0]?.attendance_key;
    if (hasValue(group_date) && group_date?.attendance_key !== key) {
        key = group_date?.attendance_key;
    }
    return key;
}

export const getDayFlags = ({ daysFilter, checks, assist_adjust, ...others }) => {
    let flagsCheck = DaysFlags;
    let symbol = null;
    let cornerClasses = '';
    let isComplete = true;
    if (daysFilter === 'adjust') {
        flagsCheck = ['adjust'];
    } else if (daysFilter === 'over') {
        flagsCheck = ['over', 'less',];
    }
    if (hasValue(checks)) {
        //verify checks
        checks.forEach(f => {
            if (!f.is_complete) {
                isComplete = false;
            }
        });
        //verify flags
        flagsCheck.forEach(f => {
            if (checks[0][f]) {
                cornerClasses += `${f} `;
            }
        });

        symbol = getCheckSymbol({ ...others, checks });
    }

    const realListAdjust = assist_adjust?.filter(element => element?.moper?.status !== 3);

    if (hasValue(realListAdjust) && !cornerClasses.includes('adjust') && daysFilter !== 'over') {
        cornerClasses = `${cornerClasses} adjust`;
    }

    const flagsClassName = `corner-marks ${cornerClasses}`;
    return {
        symbol,
        flagsCheck,
        flagsClassName,
        isComplete,
        cornerClasses
    }
}

export const getCheckSymbol = ({ checks, overtime, currentDate }) => {

    const {
        type_calculation,
    } = overtime ?? {};

    const byCheck = ((type_calculation ?? 1) === 1) || isToday(parseDate(currentDate, "", 'date'));
    let checksData = [];
    ChecksElements.forEach((element) => {
        checks.forEach((check) => {
            const checkItem = getCheck(check, byCheck, element?.id, false, true);
            checksData.push(checkItem?.value);
        });
    });
    const hasIncidence = checksData?.filter(element => isNil(element));
    const symbol = size(hasIncidence) > 0 ? '/' : '•';

    return symbol;
}

export const getButtonsActive = ({
    assist_adjust,
    overtime,
    overtimeItem,
    absenteeisms,
    holiday_record,
    complete_shift,
    principalContent,
    userCountry,
    currentDate,
    group_date,
    checks
}) => {

    //Overtime
    let overtimeInfo = [];
    let overtimeButtons = [];
    const paymentsWithPerms = getPaymentsToShow({ item: overtimeItem }).map(element => element?.name);

    if (hasValue(overtime)) {
        overtimeInfo = Object.entries(overtimeItem?.overtime).map(([key, value]) => [
            key,
            ((paymentsWithPerms?.includes(upperCase(key)) && (value === true || value?.can_request || value?.request)) || (upperCase(key) === 'IN' && paymentsWithPerms?.includes('IM') && value === true)) ? value : null,
        ]).filter(element => !isNil(element?.[1]));

        overtimeButtons = overtimeInfo?.map(payment => {
            const key = upperCase(payment?.[0]);
            const paymentStatus = payment?.[1];
            let status = paymentStatus?.status ?? 4;
            const item = buttonsMopers?.find(element => element?.prefix === key);

            if (isBoolean(paymentStatus) && paymentStatus) {
                status = 2;
            } else if (paymentStatus?.can_request) {
                status = 4;
            }

            return {
                status: status,
                ...item
            };
        })?.filter(element => element?.key);
    }

    //Absenteeims
    const absenteeismsButtons = absenteeisms.map(element => {
        if (element?.attendance_key === 'inabilities' || element?.attendance_key === 'imss') {
            element.attendance_key = (ImssKeys.includes(principalContent?.key)) ? element?.attendance_key : null;
        }
        if (userCountry === "MX" && element?.attendance_key === "inabilities") {
            element.attendance_key = "imss";
        }
        const foundAbsen = buttonsMopers?.find(button => button?.key === element?.attendance_key);
        return hasValue(foundAbsen) ? foundAbsen : null;
    });

    //ExtraHours
    const byCheck = ((overtime?.type_calculation ?? 1) === 1) || isToday(parseDate(currentDate, "", 'date'));
    const exceed = (!byCheck ? overtime?.overtime_calculated : overtime?.overtime_real) ?? 0;
    const balance = overtime?.balance ?? 0;
    const requestedTime = exceed - balance;
    const extraHours = overtime?.balance > 0 || requestedTime > 0;

    //Others
    const hasAssistAdjust = getAssistAdjust({ assist_adjust });
    let hasCompleteTurn = getCompleteShift({ complete_shift });

    const auxButtons = buttonsMopers.map(element => {
        const key = element?.key;
        if (hasAssistAdjust?.show && key === 'assist-adjust') {
            return {
                status: hasAssistAdjust?.status,
                ...element
            };
        }
        if (hasCompleteTurn?.show && key === 'complete-turn') {
            return {
                status: hasCompleteTurn?.status,
                ...element
            };
        }
        if (holiday_record?.[0]?.is_applied && key == 'holiday' && !absenteeisms?.find(element => element?.attendance_key === "holiday") && !ImssKeys.includes(group_date?.attendance_key)) {
            return element;
        }
        if (extraHours && key === "request-hours") {
            return {
                status: requestedTime > 0 ? 2 : 4,
                ...element
            };
        }
    })?.filter(element => !isNil(element));

    const allButtons = concat(absenteeismsButtons?.filter(element => !isNil(element)), auxButtons, overtimeButtons)?.filter(element => (element?.key !== principalContent?.key && element?.key !== group_date?.attendance_key) || ((ImssKeys.includes(element?.key)) && ImssKeys?.includes(principalContent?.key) && hasValue(checks)));

    return allButtons;


}

export const generalMopAndAttIconAndLabel = (key, country) => {

    if (key === 'inabilities' && country === 'MX') {
        key = "imss";
    }

    const item = buttonsMopers?.find(element => element?.key === key);
    return valueOrOption(item, {});
}

export const withFaultRegister = ({ currentDate, worker, checks, absenteeisms, holiday_record }) => {
    const isBeforeActualDate = isBefore(parseDate(currentDate, "", "date"), parseDate(current_date(), "", "date"));
    const isSame = isSameDay(parseDate(currentDate, "", "date"), parseDate(current_date(), "", "date"));
    if ((hasValue(checks) || hasValue(absenteeisms) || !worker?.with_required_check || (!isBeforeActualDate && !isSame) || holiday_record?.[0]?.is_applied)) {
        return null;
    };
    return "new-check";
}

export const handleGroups = ({ group_date }) => {

    if (!hasValue(group_date)) {
        return { isGroupped: false, className: "ungroup" };
    }

    let className = `att-groupped-cells`;

    const isInitial = group_date?.is_initial;
    const middleValue = group_date?.is_middle;
    const isEnding = group_date?.is_ending;
    let showContent = false;

    if (isInitial) {
        className = `${className} ${"is-initial"}`;
    }

    if ((middleValue || middleValue === 0) && !isInitial && !isEnding) {
        className = `${className} ${"is-middle"}`;
    }

    if (isEnding) {
        className = `${className} ${"is-ending"}`;
    }

    if (!isNil(middleValue) && middleValue < 3) {
        showContent = true;
    }

    if (middleValue > 0 && !isNil(middleValue)) {
        className = `${className} ${middleValue === 1 ? "first" : "second"}-${"middle"}`;
    }

    return {
        isGroupped: true,
        className: className,
        withContent: showContent,
        par: middleValue > 0 && !isNil(middleValue),
    };
}

export const checkColorByFlag = ({
    assist_adjust,
    value,
    field,
    cornerClasses,
    index,
    checks
}) => {

    const classes = cornerClasses?.split(' ');
    let className = "";

    classes.forEach(element => {
        const totalChecks = size(checks);
        const isCheckIn = field?.includes('check_in');
        const currentAdjust = first(assist_adjust);

        switch (element) {
            case 'late':
                className = `${className} ${(index === 0 && isCheckIn) ? 'is-late' : ''}`;
                break;
            case 'incidence':
                break;
            case 'over':
                className = `${className} ${(index + 1 === totalChecks && !isCheckIn) ? 'is-over' : ''}`;
                break;
            case 'less':
                className = `${className} ${(index + 1 === totalChecks && !isCheckIn) ? 'is-less' : ''}`;
                break;
            case 'adjust':
                if (!hasValue(currentAdjust)) {
                    return;
                }
                className = `${className} ${isSameDay(parseDate(currentAdjust?.[field], '', 'date'), parseDate(value, '', 'date')) ? 'is-adjust' : ''}`;
                break;
            default:
                break;
        }
    });
    return className;
}

// RestWorker ClassName
export const getRestWorkerClassName = (item) => {
    const restItem = item?.buttonsToShow?.find(element => element?.key === 'overtime-wb');
    return hasValue(restItem) ? status[restItem?.status] : "";
}

// Define groups IconStyle
export const getIconGroupStyle = (item, data) => {
    const attendanceItemData = data?.find(element => element?.worker?.id === item?.worker?.id)?.attendance_list;
    const findItemIndex = attendanceItemData?.findIndex(element => element?.currentDate === item?.currentDate);
    const findIndex = item?.pairIconSide === 1 ? (findItemIndex + 1) : (findItemIndex - 1);
    const newItemInfo = attendanceItemData[findIndex];
    const hasChecks = hasValue(newItemInfo?.checks) || hasValue(newItemInfo?.holiday_record);

    return hasChecks;
}