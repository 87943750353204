import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { mapData } from '../util';
import { successUpdated } from '../../../../../common/notification-messages';
import { showNotificationSuccess } from '../../../../../../store/actions';
import { enterpriseStructureSettingsMultiAPI } from '../../../../../services/enterprise-structure';
import { implementService } from '../../../../../services/implemet-service';
import { usePackages } from '../../../usePackages';

const useConfig = ({refresh}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { tenant } = usePackages()

  const {
    control,
    handleSubmit
  } = useForm({
    mode: "onChange",
  });

  function hanldeOpen() {
    setOpen(true)
  }

  function hanldeCancel() {
    setOpen(false)
  }

  async function onSubmit({data}) {
    const config = mapData(data, true)
    implementService(enterpriseStructureSettingsMultiAPI.multiples_default({config, tenant},false),() => {
      dispatch(showNotificationSuccess(successUpdated()));
      setOpen(false)
      refresh()
    })
  }

  return {
    open,
    hanldeOpen,
    hanldeCancel,
    handleSubmit,
    onSubmit,
    control
  };
};

export default useConfig;
