import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BaseButton } from '../../../../../App/components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../form/Field';
import { CompaniesDropdown } from '../windows/EnterpriseTab/CompaniesDropdown';
import { RolesDropdown } from '../windows/EnterpriseTab/RolesDropdown';
import { currentUserInfo } from '../../../../common/validate-tenant';
import { ValidationSchema, defaultValues, setObjStorage } from '../windows/EnterpriseTab/utilities';
import { isAbrhil } from '../../../../common/auth';
import { hasValue, valueOrOption } from '../../../../common/GeneralUtilities';
import { Form } from '../../../form/Container';
import { userOptionsApi } from '../../../../services/administrator';
import { usePackages } from '../../../../@hooks/usePackages';
import { closeAllWindows } from '../../../../../store/triggers/navigate';
import { changeGroup, changeTenant, groupInit, lockedWindow, menu, showNotificationWarning, unlockedWindow } from '../../../../../store/actions';
import { useRequestLoad } from "../../../../modules/MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";

const dropdownProps = {
	size: "small",
	noDefault: true,
	valueField: "id",
	textField: "name",
	fieldInput: "Dropdown",
};

const ModalContent = ({ onClose }) => {

	const { t } = useTranslation();

	const {
		user_info: {
			id,
		} = {}
	} = currentUserInfo() ?? {};

	const { select } = useSelector((state) => state.group);

	const {
		loadPackages,
		resetPackages,
		loadMenu,
		// getUserGroups,
		getUserConfigs,
	} = usePackages();

	const dispatch = useDispatch();
	const tenant = useSelector((state) => state.tenant);
	const isSuper = useSelector((state) => state.userConfigs?.is_superuser);
	const [corporates, setCorporates] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [roles, setRoles] = useState([]);
	const [fetch, loading] = useRequestLoad(true);

	const loadData = () => {
		const api = userOptionsApi();
		fetch({
			api: api.get(),
			callback: response => {
				setCorporates(valueOrOption(response?.corporates, []));
				setCompanies(valueOrOption(response?.companies, []));
				setRoles(valueOrOption(response?.roles, []));
			}
		});
	};

	const {
		reset,
		control,
		setValue,
		getValues,
		handleSubmit,
		clearErrors
	} = useForm({
		resolver: yupResolver(ValidationSchema(isSuper)),
		mode: "onSubmit",
		defaultValues
	});

	useEffect(() => {
		loadData();
		const current = tenant?.current;
		const newValues = {
			corporate: valueOrOption(current?.corporate?.id, current?.corporate),
			company: current?.id,
			companyObj: current,
			role: select?.id,
		};
		reset(newValues);
		//eslint-disable-next-line
	}, [id]);

	const onSubmit = async (data) => {
		onClose();
		const current = tenant?.current;
		dispatch(closeAllWindows());
		dispatch(lockedWindow());
		if (data.company !== current?.id) {
			dispatch(menu({}));
			onChangeTenant(data.companyObj);
			dispatch(groupInit(roles));
		}
		if (!roles?.length || !hasValue(data.role)) {
			dispatch(menu({}));
			const userData = await getUserConfigs(current?.id);
			const isSuper = userData?.is_superuser;
			if (isSuper) onChangeGroup(current?.id, data.roleObj, true);
			dispatch(showNotificationWarning({
				maxWidth: "sm",
				title: t("warning-general-title"),
				message: t(isSuper ? "not-configured-roles" : "not-configured-roles2")
			}));
		} else if (data.role !== select?.id && hasValue(data.role)) {
			dispatch(menu({}));
			await onChangeGroup(current?.id, data.roleObj, true);
		}
		dispatch(unlockedWindow());
	};

	const onChangeTenant = (newValue) => {
		dispatch(changeTenant(newValue));
		if (newValue) {
			localStorage.setItem("tenantStorage", JSON.stringify(newValue));
			loadPackages(newValue.id);
			return;
		}
		localStorage.removeItem("tenantStorage");
		resetPackages();
	};

	const onChangeGroup = async (tenant, newValue, lock = false) => {
		dispatch(changeGroup(newValue));
		setObjStorage("roleStorage", newValue);
		await loadMenu(tenant, newValue?.id, lock);
		// return dispatch(unlockedWindow());
	};

	return (
		<div className='p-2' style={{ width: "300px" }}>
			<Grid container spacing={1} justifyContent={"center"} alignItems={"center"}>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormInput
								control={control}
								{...dropdownProps}
								data={valueOrOption(corporates, [])}
								name={"corporate"}
								loading={loading}
								label={t("corporate")}
								disabled={!isAbrhil()}
							/>
						</Grid>
						<Grid item xs={12}>
							<CompaniesDropdown
								control={control}
								loading={loading}
								dropdownProps={dropdownProps}
								companies={companies}
								setValue={setValue}
								clearErrors={clearErrors}
								getValues={getValues}
							/>
						</Grid>
						<Grid item xs={12}>
							<RolesDropdown
								control={control}
								roles={roles}
								loading={loading}
								dropdownProps={dropdownProps}
								setValue={setValue}
								getValues={getValues}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={"auto"}>
					<Form
						onSubmit={onSubmit}
						handleSubmit={handleSubmit}
						defaultValues={defaultValues}
					>
						<BaseButton
							label={t("accept")}
							design={"contained"}
							disabled={loading}
							type="submit"
						/>
					</Form>
				</Grid>
			</Grid>
		</div>
	);
};

ModalContent.propTypes = {
	onClose: PropTypes.func
}

export default ModalContent;
