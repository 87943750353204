import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import { each } from 'lodash';
import { useWatch } from "react-hook-form";
import { getStructureApi } from '../../../common/StructureUtilities';
import FormInput from '../Field';
import { hasValue, valueOrOption } from '../../../common/GeneralUtilities';

export function DropdownInput({
	field,
	control,
	setValue,
	extraFilters = {},
	sequencialItems = ["none"],
	...others
}) {

	const prevFieldsValues = useWatch({ control, name: sequencialItems });
	const changed = prevFieldsValues.join("-");

	useEffect(() => {
		setValue(field, null);
		//eslint-disable-next-line
	}, [changed]);

	const getExtras = () => {
		let extras = valueOrOption(extraFilters, {});
		each(prevFieldsValues, (fieldValue, index) => {
			if (hasValue(fieldValue)) {
				extras[sequencialItems[index]] = fieldValue;
			}
		});
		extras["select"] = true;
		return extras;
	};

	return (
		<FormInput
			{...others}
			control={control}
			fieldInput={"ServerDropdown"}
			serverApi={getStructureApi(field)}
			valueField={"id"}
			textField={"name"}
			searchBy={"search"}
			filterEfields={"key|name"}
			filterSplit={"-"}
			format={"key|rfc|name"}
			fields={"id,key,name,rfc"}
			extraFilters={getExtras()} />
	);
}

DropdownInput.propTypes = {
	control: PropTypes.any,
	extraFilters: PropTypes.object,
	field: PropTypes.string,
	sequencialItems: PropTypes.array,
	setValue: PropTypes.func
};
