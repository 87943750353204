import * as yup from "yup";
import { stringValidation } from "../../../common/validations/stringValidation";
import { validateNumeric } from "../../../common/validations/numeric";
import fileValidation from "../../../common/validations/fileValidation";

export const validationSchema = yup.object().shape({
    branch: validateNumeric({ required: true }),
    employer_registration: validateNumeric({ required: true }),
    stps_key: stringValidation({ required: true, max: 10, type: "alphaspecials" }),
    company_representative: stringValidation({ required: true, max: 200, type: "alphanumericPC" }),
    worker_representative: stringValidation({ required: true, max: 200, type: "alphanumericPC" }),
    worker_signature: fileValidation({ required: true, allowedExtensions: ['.png', '.jpg', '.jpeg'] }),
    company_signature: fileValidation({ required: true, allowedExtensions: ['.png', '.jpg', '.jpeg'] }),
});