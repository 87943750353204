import { createContext } from 'react'
import * as yup from "yup";
import i18next from 'i18next';
const { t } = i18next;
import { stringValidation } from '../../common/validations/stringValidation';
import arrayValidation from '../../common/validations/array';
export const ModuleContext = createContext()


export const validationSchema = yup.object().shape({
  footer_description: stringValidation({ required: true, min: 10, max: 1500 }),
  signatures: arrayValidation({ required: true, min: 1, max: 4 }).of(
    yup.object().shape({
      signature: arrayValidation({ required: true, min: 1, max: 4 }).of(
        yup.object().shape({
          name: stringValidation({ required: true, }),
        })),
    })
  )
});

export const workerPreviewInfo = [
  { fieldName: "RFC", numberFormat: 13, column: 1 },
  { fieldName: "NSS", numberFormat: 11, column: 1 },
  { fieldName: t("bank"), column: 2 },
  { fieldName: t("bank-acount"), column: 2 },
  { fieldName: t("standardized_bank_code"), column: 2 },
  { fieldName: t("branch"), column: 3, format: "XXX (XXXXX)" },
  { fieldName: t("admission-date"), column: 3 },
  { fieldName: t("daily-salary"), column: 3, format: "$XXX" },
  { fieldName: t("area"), column: 4, format: "XXX - XXXXX" },
  { fieldName: t("date-discharge"), column: 4, },
  { fieldName: t("integrated-salary"), column: 4, format: "$XXX" },
  { fieldName: t("department"), column: 5, format: "XXX - XXXXX" },
  { fieldName: t("antiquity"), column: 5 },
  { fieldName: t("job"), column: 6, format: "XXX - XXXXX" },
]
