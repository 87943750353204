import React, { lazy } from "react";
import {
    faScrewdriverWrench,
} from "@fortawesome/pro-light-svg-icons";
import abrilItems from "./abrhil";
import moduleItems from "./modules";
import usersRolesItems from "./roles-users";
import kioskoItems from "./kiosko";
import generalsItems from './generals';

const Dashboard = lazy(() => import("../../../../Demo/Dashboard"));
const Reports = lazy(() => import("../../../modules/Reports"));
const Clock = lazy(() => import('../../../modules/ClockApp/Clock'));
const ClockWorker = lazy(() => import('../../../modules/ClockApp/ClockWorker'));
const Binnacle = lazy(() => import('../../../modules/WorkerLog'));
const Welcome = lazy(() => import('../../../modules/Welcome'));
const FileExplorer = lazy(() => import('../../../modules/FileExplorer'));

//* Al agregar o actualizar elementos es de suma importancia que el id se construya
//* de la siguiente forma: '{padre raiz}@{item id del backend}'

//* Al agregar un nuevo componente el backend debe de haber hecho el previo registro

//* En caso de actualizar el ID de un componente al que se navegue mediante navigate en el sistema
//* por ejemplo: const path = getNavItem(padre@hijoID) -> navigate(path)
//* se debe actualizar su referencia (padre@nuevoID) al llamar a la funcion getNavItem la cual se encarga
//* de recuperar el path del elemento en el menu de REDUX dinamico mediante su ID

const menuElements = [
    //* ABRHIL *//
    ...abrilItems,

    //* KPIS *//
    {
        id: 'kpis',
        type: 'item',
        index: true, // Indica que está a nivel raíz del menú
        icon: 'kpis',
        componentElm: <Dashboard />
    },

    //* FILE EXPLORER *//
    {
        id: 'modules@file-explorer',
        type: 'item',
        icon: "fileexplorer",
        componentElm: <FileExplorer />
    },

    //* Welcome *//
    {
        id: 'welcome',
        type: 'item',
        index: true, // Indica que está a nivel raíz del menú
        icon: 'home',
        componentElm: <Welcome />
    },

    //* REPORTES *//
    {
        id: "main-reports",
        type: "item",
        index: true, // Indica que está a nivel raíz del menú
        icon: 'reports',
        componentElm: <Reports />,
    },

    //* MODULOS *//
    ...moduleItems,

    //* CONTROL DE ACCESO *//
    {
        id: 'access-control@clocks',
        type: 'item',
        icon: 'clock',
        componentElm: <Clock />
    },
    {
        id: 'access-control@clock-workers',
        type: 'item',
        icon: 'assign-clocks',
        componentElm: <ClockWorker />
    },

    //* USUARIOS Y ROLES
    ...usersRolesItems,

    //* KISKO *//
    ...kioskoItems,

    //* BITACORA *//
    {
        id: 'modules@audit',
        type: 'item',
        icon: 'audit',
        componentElm: <Binnacle />
    },

    //* UTILERIAS *//
    {
        id: "utils",
        icon: 'config-icon',
        type: "item",
        inConstruction: true,
        componentElm: true
    },

    //* HERRAMIENTAS *//
    {
        id: "tools",
        icon: faScrewdriverWrench,
        type: "item",
        inConstruction: true,
        componentElm: true
    },

    ...generalsItems,
    // //* AYUDA *//
    // {
    //     id: "help",
    //     icon: faCircleInfo,
    //     type: "item",
    //     index: true, // Indica que está a nivel raíz del menú
    //     inConstruction: true,
    //     componentElm: true
    // },

    // //* ABRHIL HELPDESK *//
    // {
    //     id: "abrhilhelpdesk",
    //     icon: faHeadphones,
    //     type: "item",
    //     index: true, // Indica que está a nivel raíz del menú
    //     inConstruction: true,
    //     componentElm: true
    // }
];

export default menuElements;