import { parse, differenceInDays } from "date-fns";
import { isNil, omitBy, forEach, isDate, isString, size } from "lodash";
import i18next from 'i18next';
const { t } = i18next;
import { backDateFormat } from "../../../../App/components/Dates/utilities";
import { attendanceValues, counterInputs } from "./constants";
import { parseDatetime } from "../../../common/GeneralUtilities";
import { store } from "../../../../store";

// Filters
export const filtersCounter = (filter) => {
  const filters = filter ?? filter?.filter;
  let realCounter = size(omitBy(Object.fromEntries(
    Object.entries(filters).map(([key, value]) => [
      key,
      counterInputs.includes(key) ? value : null,
    ])
  ), isNil));
  if (filters?.active_date) {
    realCounter += 3;
  }
  if (filters?.indicate_days !== 'ALL' && filters?.indicate_days) {
    realCounter = realCounter + 1;
  }

  return realCounter;
}

// Modals

export const getCatalog = (catalogsConfig, catalogRef) => {
  const {
    area,
    sub_area,
    sub_branch,
    department,
    sub_department,
  } = catalogsConfig ?? {};

  const catalogs = {
    sub_department,
    department,
    sub_area,
    area,
    sub_branch,
  }

  for (let option in catalogs) {
    if (catalogs[option]?.value) {
      catalogRef.current = option;
    }
  }

  return catalogRef.current;
}

export const getPrintOptions = ({ catalog, workerTypes }) => {
  let wTypes = []
  if (size(workerTypes)) {
    forEach(workerTypes, function (type) {
      const wType = {};
      wType.label = type.name;
      wType.name = type.name;
      wType.type = 'switch';
      wType.id = type.id;
      wTypes.push(wType);
    });
  }

  return {
    print_options_1: {
      print_type: [
        { label: t('Lista completa'), name: 'complete_list', type: 'switch' },
        { label: t('Lista visualizada'), name: 'visualized_list', type: 'switch' },
        { label: t('Lista personalizada'), name: 'personalized_list', type: 'switch' },
      ],
      list_content: [
        { label: t('Con datos'), name: 'with_data', type: 'switch' },
      ],
      worker_signature: [
        { label: t('Imprimir linea'), name: 'print_signature', type: 'switch' },
      ],
      worker_type: [
        { label: t('Todos'), name: 'all', type: 'switch' },
        ...wTypes,
      ]
    },
    print_options_2: {
      dates: [
        { label: t('initial_date'), name: 'initial_date', type: 'date' },
        { label: t('end_date'), name: 'final_date', type: 'date' },
      ],
      structure: [
        { label: t('employer-registration'), name: 'employer_registration', type: 'structuresearch', api: 'employer-registration' },
        { label: t('branches'), name: 'branch', type: 'structuresearch', api: 'branch' },
        { ...(catalog ? { label: t(catalog), name: catalog, type: 'structuresearch', api: catalog } : {}) },
      ],
      separate_by: [
        { label: t('employer-registration'), name: 'employer_registration', type: 'switch' },
        { label: t('branch'), name: 'branch', type: 'switch' },
        { ...(catalog ? { label: t(catalog), name: catalog, type: 'switch' } : {}) },
      ],
      order: [
        { label: t('employer-registration'), name: 'employer_registration', type: 'switch' },
        { label: `${t('employer-registration')}-${t('branch')}`, name: 'employer_registration_branch', type: 'switch' },
        { ...(catalog ? { label: `${t('employer-registration')}-${t('branch')}-${t(catalog)}`, name: `employer_registration_branch_${catalog}`, type: 'switch' } : {}) },
      ]
    }
  }
}

// Perms

export const getAttendanceListPermission = (key) => {
  return store.getState().userConfigs.user_info.moper_attendance?.find(f => f.key === attendanceValues[key]?.value)?.has_perm;
}
export const getAttendanceListName = (key) => {
  return store.getState().userConfigs.user_info.moper_attendance?.find(f => f.key === attendanceValues[key]?.value)?.name;
}

// DateFormat

export const formatDate = (date, useIso = false) => {
  let newDate = null;
  if (isDate(date)) {
    return date;
  }
  if (isString(date)) {
    newDate = parseDatetime(date, 'asDate', useIso);
  }
  return newDate;
}

export const daysDiff = ({ initialDate, endDate }) => {
  const startDate = parse(initialDate, backDateFormat, new Date())
  const lastDate = parse(endDate, backDateFormat, new Date())
  const difference = differenceInDays(lastDate, startDate) + 4;
  return difference;
}
