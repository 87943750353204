import React, { Fragment } from 'react'
import {useTranslation} from "react-i18next";
import { BaseButton } from '../../../../../../App/components/Buttons'
/* Components */
import { faGear } from "@fortawesome/pro-light-svg-icons";
import useConfig from './useConfig';
import DialogForm from '../../../../../@components/form/Dialog';
import KendoTable from '../../../../../@components/grid/KendoTable';
import { GridColumn } from '@progress/kendo-react-grid';
import { columnProps } from '../../../../../../App/components/GridCustomProps';
import FormInput from '../../../../../@components/form/Field';
import { ServerAPI } from '../../../../../services/ServerAPI';
import { usePackages } from '../../../usePackages';

function ButtonConfig({refresh, locked}) {
	const {
		hanldeOpen,
		hanldeCancel,
		open,
		handleSubmit,
		onSubmit,
		control
	} = useConfig({refresh})

	return (
		<Fragment>
			{locked && <BaseButton className={"mb-2"} style={{ float: 'right' }}
				label={"Configuraciones de múltiplos"}
				icon={faGear}
				onClick={hanldeOpen}
			/>}
			{open && <DialogForm
				simpleTitle="Configuraciones de múltiplos"
				open={open}
				onClose={hanldeCancel}
				handleSubmit={handleSubmit}
				maxWidth={"sm"}
				onSubmit={onSubmit}
			>
				<DynamicComponent control={control}/>
			</DialogForm >}
		</Fragment>
	)
}

export default ButtonConfig;


function DynamicComponent({control}) {
	const { tenant } = usePackages()

	const { t } = useTranslation();

	function ComponentCell({dataItem, field}) {
		return <td>
		<FormInput
          name={`data.${field}_${dataItem.id}`}
		  value={dataItem[field]}
          fieldInput="numeric"
          control={control}
          max={100}
		  min={0}
		  disabled={field === "min_value" ? true :false}
        />
		</td>
	}

	return <KendoTable
		style={{
			height: "100%",
		}}
		Api={ServerAPI('multiples-default', false)}
		refreshCounter={1}
		is_active={false}
		actions={false}
		pageable={undefined}
		extraOptions={{  tree : true, tenant }}
	>
		<GridColumn
			{...columnProps("name", "none")}
			title={t("name")}
			width={180}
		/>
			<GridColumn
			{...columnProps("value", "none")}
			title={t("is-default")}
			cell={ComponentCell}
		/>
			<GridColumn
			{...columnProps("max_value", "none")}
			title={t("Valor máximo")}
			cell={ComponentCell}
		/>
			<GridColumn
			{...columnProps("min_value", "none")}
			title={t("Valor mínimo")}
			cell={ComponentCell}
		/>

	</KendoTable>
}