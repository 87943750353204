import React from "react";
import { isFunction } from "lodash";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideNotificationError } from '../../../store/actions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import { StatusSkeleton, Transition } from "./DialogSkeleton";

const ErrorNotification = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		title,
		message,
		description,
		maxWidth,
		onConfirm,
		...others
	} = useSelector(state => state.notification.errorNotification);

	const onConfirmClick = () => {
		dispatch(hideNotificationError());
		if (isFunction(onConfirm)) {
			onConfirm();
		}
	};

	return <Dialog
		open={show}
		TransitionComponent={Transition}
		className="myDialog errorDialog"
		maxWidth={maxWidth ?? "lg"}
		onClose={onConfirmClick}
	>
		<StatusSkeleton
			title={title || t("error-general-title")}
			lottieIcon={"error-icon"}
			message={message || ""}
			description={description || ""}
			close={onConfirmClick}
			{...others}
		/>
	</Dialog>;
};

export default ErrorNotification;