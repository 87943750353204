import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { materialSwitchProps } from './PropTypes';
import { valueOrOption } from '../../../core/common/GeneralUtilities';
import { MaterialLabelContainer } from '../Templates/SideLabelContainer';
import { materialSizeClasses } from '../sizesUtilities';

/**
 * Hook que regresa el componente switch basado en MUi
 *
 * @returns {JSX.Component}
 */
const SwitchMui = (
	{
		checked,
		onChange,
		disabled = false,
		name = "",
		label = "",
		inactiveLabel = "",
		labelPlacement = "end",
		sx = {},
		switchSx = {},
		sideLabel = false,
		modernDesign = false,
		error,
		icon,
		...others
	}: materialSwitchProps
) => {

	const { t } = useTranslation();
	const { size, className } = materialSizeClasses(others);

	checked = valueOrOption(checked, false);
	disabled = valueOrOption(disabled, false);
	label = checked ? label : valueOrOption(inactiveLabel, label);
	if (label === "switchActiveInactiveLabel") {
		label = t(checked ? "active" : "inactive");
	}

	const handleChange = (event) => {
		const isCheck = event.target.checked;
		const value = {
			type: "change",
			value: isCheck,
			target: { value: isCheck }
		};
		if (typeof onChange === "function") {
			onChange(value);
		}
	};

	const switchProps = {
		size: size ?? "",
		checked,
		onChange: handleChange,
		className: `${className} customSwitch mx-1`,
		sx: switchSx,
		disabled: disabled,
		error: valueOrOption(error, "").toString(),
	};

	if ((label ?? "") === "") {
		return (
			<Switch {...switchProps} />
		);
	}

	if (sideLabel) {
		return (
			<MaterialLabelContainer
				modernDesign={modernDesign}
				sideLabel={sideLabel}
				label={label}
				icon={icon}
			>
				<Switch {...switchProps} name={name} />
			</MaterialLabelContainer>
		);
	}

	return (
		<FormControlLabel
			disabled={disabled}
			name={name ?? ""}
			label={label ?? ""}
			labelPlacement={labelPlacement ?? "end"}
			control={<Switch {...switchProps} />}
			sx={sx}
		/>
	);
};
export default SwitchMui;
