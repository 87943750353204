import PropTypes from "prop-types"
import React from 'react';
import { useWatch } from "react-hook-form";
import KendoTable from '../../../../../@components/grid/KendoTable';
import { GridColumn } from '@progress/kendo-react-grid';
import { structurePackagesAPI } from '../../../../../services/enterprise-structure';
import useStructureConfigs from '../useStructureConfigs';
import { columnProps } from '../../../../../../App/components/GridCustomProps';
import { useSelector } from "react-redux";
import { structureLabel, structureSteps } from '../../../../../common/StructureUtilities';
import FormInput from '../../../../../@components/form/Field';
import { CustomTooltip } from '../../../../../../App/components/Templates/cells';
import ButtonEdit from '../Multiples/ButtonEdit';
import { usePackages } from '../../../usePackages';

const PAYMENT_TYPE = [
  { id: 1, name: "Domingo" },
  { id: 2, name: "Fin de semana" },
  { id: 3, name: "Libre" },
]

function StructureBreaks() {
  const { selectPackage, tenant } = usePackages()
  const locked = selectPackage?.value
  const providerValues = useStructureConfigs()
  const {
    filters,
    setFilters,
    refreshCounter,
    refresh,
  } = providerValues ?? {};

  const configuration = useSelector((state) => state.configuration.steps) ?? [];
  const employer_registration = configuration.find(f => f.step === 'employer_registration');
  const steps = employer_registration ? [...structureSteps(configuration, ['employer_registration']), employer_registration] : [...structureSteps(configuration)];

  function HeaderTitle({ title }) {
    const value = title.split('|')
    return <CustomTooltip title={value[1]}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: '700' }}>
        {value[0]}
      </div>
    </CustomTooltip>
  }

  return (
    <div>
      <KendoTable
        pageSizes={[20, 30, 40, 50]}
        style={{
          maxHeight: "calc(100vh - 178px)",
        }}
        Api={structurePackagesAPI}
        extraOptions={{
          include: "enterprise_structure.*,employer_registration,settings",
          tenant
        }}
        filters={filters}
        setFilters={setFilters}
        refreshCounter={refreshCounter}
        onRefresh={refresh}
        is_active={false}
        actions={false}
      >
        {steps.map((item, index) => (
          <GridColumn
            key={index}
            width={"auto"}
            {...columnProps(item?.step === "employer_registration" ? item?.step : `enterprise_structure.${[item?.step]}`, filters, [(item?.step !== "employer_registration" ? "keyName" : "keyNameRfc"), item?.step])}
            format={item?.step === "employer_registration" ? "withRFC" : ""}
            title={structureLabel(item)}
          />
        ))}
        <GridColumn title="T. Descanso|Tipo de Descanso" headerCell={HeaderTitle}>
          <GridColumn
            width={140}
            field="day_off_mode"
            headerCell={(kProps) => <HeaderCellRender {...providerValues} type="dropdown" data={PAYMENT_TYPE} {...kProps} />}
            cell={(kProps) => <CellInput {...providerValues} defaultValue={kProps.dataItem.settings.config[kProps.field]} type="dropdown" data={PAYMENT_TYPE} {...kProps} />}
          />
        </GridColumn>
        <GridColumn title="D. Descanso|Dias de Descanso" headerCell={HeaderTitle}>
          <GridColumn
            width={100}
            field="days_off"
            headerCell={(kProps) => <HeaderCellRender {...providerValues} type="numeric" {...kProps} />}
            cell={(kProps) => <CellInput {...providerValues} defaultValue={kProps.dataItem.settings.config[kProps.field]} type="numeric" {...kProps} />}
          />
        </GridColumn>
      </KendoTable>
      <ButtonEdit {...providerValues} locked={locked} />
    </div>
  );
}

export default StructureBreaks;

const valuesDefault = {
  1: 1,
  2: 2,
  3: 1
}

function HeaderCellRender({ type, data, field, edit, control, setValue }) {
  const day_off_mode = useWatch({ control, name: "all.day_off_mode" });

  function hanldeOnChange(e) {
    if (field === 'day_off_mode') {
      setValue('all.days_off', valuesDefault[e.value])
    }
    return e
  }

  if (!edit) {
    return (
      <div style={{ height: '35px' }}></div>
    );
  }

  const typeProps = {
    dropdown: {
      textField: "name",
      noDefault: true,
      dataItemKey: "id",
      valueField: "id"
    }
  }
  const props = typeProps[type]
  return <div>
    <FormInput
      className='numeric_input'
      name={`all.${field}`}
      fieldInput={type}
      data={data}
      {...props}
      control={control}
      min={1}
      max={2}
      disabled={field === "days_off" ? day_off_mode !== 3 : false}
      onChange={hanldeOnChange}
    />
  </div>
}

HeaderCellRender.propTypes = {
  control: PropTypes.any,
  data: PropTypes.any,
  edit: PropTypes.any,
  field: PropTypes.string,
  setValue: PropTypes.func,
  type: PropTypes.any
}

function CellInput({ dataItem, field, type, data, edit, control, setValue, defaultValue }) {
  const results = {
    dropdown: ({ value }) => PAYMENT_TYPE.find(f => f.id === value)?.name,
    numeric: ({ value }) => value
  }
  const day_off_mode = useWatch({ control, name: `data.day_off_mode_${dataItem.config_id}` });
  const day_off_mode2 = dataItem.settings.config.day_off_mode
  const valueWatch = useWatch({ control, name: `data.${field}_${dataItem.config_id}` });

  function hanldeOnChange(e) {
    if (field === 'day_off_mode') {
      setValue(`data.days_off_${dataItem.config_id}`, valuesDefault[e.value])
    }
    return e
  }

  const value = dataItem.settings.config[field]
  function ViewInput() {
    const typeProps = {
      dropdown: {
        textField: "name",
        noDefault: true,
        dataItemKey: "id",
        valueField: "id",
      }
    }
    const props = typeProps[type]
    return <FormInput
      name={`data.${field}_${dataItem.config_id}`}
      fieldInput={type}
      data={data}
      control={control}
      min={1}
      max={2}
      value={defaultValue != null ? defaultValue : valueWatch}
      {...props}
      onChange={hanldeOnChange}
      disabled={field === "days_off" ? (day_off_mode || day_off_mode2) !== 3 : false}
    />
  }


  if (edit) {
    return <td><ViewInput/></td>
  } else {
    return <td >
      {results[type]({ value })}
    </td>
  }
}

CellInput.propTypes = {
  control: PropTypes.any,
  data: PropTypes.any,
  dataItem: PropTypes.shape({
    config_id: PropTypes.any,
    settings: PropTypes.shape({
      config: PropTypes.shape({
        day_off_mode: PropTypes.any
      })
    })
  }),
  defaultValue: PropTypes.any,
  edit: PropTypes.any,
  field: PropTypes.string,
  setValue: PropTypes.func,
  type: PropTypes.any
}
