import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import Button from "../../../core/styled/Button";
import PermissionsGate from '../../../permissions/PermissionsGate';
import IconUse from '../../../core/common/IconUse';
import { valueOrOption } from '../../../core/common/GeneralUtilities';

function Refresh({
	sx,
	onClick,
	className,
	label,
	disabled = false,
	...others
}) {
	const { t } = useTranslation();
	return (
		<PermissionsGate scopes={['read']}>
			<Button {...others} disabled={disabled} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy="refresh">
				<IconUse icon={'refresh'} size={sx || "1x"} />
				<span className="buttonLabel">
					{valueOrOption(label, t('consult'))}
				</span>
			</Button>
		</PermissionsGate>
	);
}

Refresh.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.func,
	onClick: PropTypes.func,
	sx: PropTypes.any
};

export default Refresh;
