import React from 'react';
import { size } from 'lodash';
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import useAbrhilSideMenu from '../../../contextsAndControllers/useSideMenu';

const Tabs = () => {
	const { t } = useTranslation();
	const {
		subMenu,
		isSubmenu,
		forceHide,
		tab,
		setTab,
		groupsAtFirstLevel,
		elements,
	} = useMegaMenu();

	const { openWindowItem } = useAbrhilSideMenu();

	if (!groupsAtFirstLevel && (!isSubmenu || !size(subMenu?.children))) {
		return null;
	}
	const items = groupsAtFirstLevel ? elements : subMenu.children;

	const onClick = (item) => {
		if (item?.key?.includes('report')) {
			openWindowItem(item);
			forceHide();
			return;
		}
		setTab(item.name);
	}

	return (
		<Grid item xs={"auto"} >
			<Grid container alignItems={"center"}>
				<ButtonGroup aria-label="tabs button group" className={`header-tab-container`}>
					{items.map(item => (
						<Button
							key={item.name}
							onClick={() => onClick(item)}
							className={`header-tab ${tab === item.name ? "selected" : ""}`}
						>
							{t(item.name)}
						</Button>
					))}
				</ButtonGroup>
			</Grid>
		</Grid>
	);
};

export default Tabs;