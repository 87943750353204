import { forEach, isArray, isObject, size } from "lodash";
import { getNavItem } from "../../../../core/@components/navigation/utilities";
import { store } from "../../../../store";
import { AFTER_NAVIGATE_KEYS, KARDEX_KEYS, WORKER_KEYS } from "./keys";
import { clearAccents } from "./commands";
import { setSearch, setSpeech } from "../../../../store/actions";
import i18next from 'i18next';
const { t } = i18next;

const WORKER_REGEX = /(colaborador|trabajador|worker|employeer|talento|asociado)\D*(\d+)/i;
const CALCULATE_REGEX = /\bcalcula(?:te|te\smelo|lo)?\b/i;
const PERIOD_REGEX = /period(?:o)\s+(?:\w+\s+)*(\b[A-Za-z]\s*\d+(?:\s*\d+)*)\b/i;

export const GoPayroll = (transcript, navigate) => {
    const periodKey = 'period';
    const workerKey = 'worker';
    const calculateKey = 'calculate';
    const calculate = { key: calculateKey, regex: CALCULATE_REGEX };
    const period = { key: periodKey, regex: PERIOD_REGEX };
    const worker = { key: workerKey, regex: WORKER_REGEX };
    const route = getNavItem('modules@calculate', false);

    const params = {
        worker: worker,
        employeer: worker,
        colaborador: worker,
        trabajador: worker,
        talento: worker,
        asociado: worker,
        periodo: period,
        period,
        calcula: calculate,
        calculate,
        calculamelo: calculate,
    }

    function validatePattern(str, item) {
        const fnSelected = options(str, item);
        return fnSelected();
    }

    const options = (str, item) => {
        const options = {
            calculate: () => {
                const tempStr = str;
                const match = tempStr.match(item.regex);
                return !!match?.[0];
            },
            worker: () => {
                const tempStr = str;
                const match = tempStr.match(item.regex);
                return match ? match[2] : null;
            },
            period: () => {
                const tempStr = str;
                const match = tempStr.match(item.regex);
                if (match) {
                    const noSpaces = match[1].replace(/\s/g, '');
                    return noSpaces;
                }
                return null;
            },
        }
        return options[item.key];
    }

    let finalParams = {};
    for (let name in params) {
        if (transcript.includes(name)) {
            let filterString = transcript.substring(transcript.indexOf(name), transcript.length);
            let value = validatePattern(filterString, params[name]);
            if (isArray(value)) {
                value = value[0];
            }
            finalParams[params[name].key] = value;
        }
    }
    navigate(route, { state: { ...finalParams } });
    window.history.replaceState(null, null, window.location.pathname);
}

export const LoadWorker = (transcript, navigate, location) => {
    const worker = extractWorker(transcript) ?? transcript.replace(/\D/g, '');
    const currentPath = window.location.pathname.replace('/app/', '');
    let searchInput = document.querySelector('#search-key-input');
    const state = location?.state;
    if (searchInput) {
        navigate(`${currentPath}?worker=${worker}`, { state: { ...state, worker: worker } });
    } else {
        navigate(currentPath, { state: { ...state, worker } });
    }
}

export const genericNavigate = (transcript, navigate, dispatch, openWindow) => {
    const options = findBestMatches(transcript);
    if (!options) {
        dispatch(setSpeech('Menciona un módulo'));
        return;
    }
    const worker = extractWorker(transcript);
    if (size(options)) {
        const item = options[0];
        const moreThanOne = validateDuplicate(options);
        if (moreThanOne) {
            dispatch(setSearch(item?.title));
            dispatch(setSpeech(`Hay múltiples módulos de ${item?.title}`));
            return;
        }
        if (item.perms?.find(el => el?.action === 'read')?.has_perm) {
            const message = ('Bienvenido' === item.title) ? t('welcome') : `${t('welcome')} a ${item.title}`;
            dispatch(setSpeech(message));
            const pathNavigate = worker ? `${item.path}?worker=${worker}` : item.path;
            const stateParam = worker ? { state: { worker } } : {};
            navigate(pathNavigate, stateParam);
            openWindow(item);
        }
    }
}

export const verifyCalculate = (transcript, navigate, location) => {
    const currentPath = window.location.pathname.replace('/app/', '');
    const payrollCalculatePath = getNavItem('modules@calculate', false);
    const worker = extractWorker(transcript) ?? transcript.replace(/\D/g, '');
    if (currentPath === payrollCalculatePath) {
        const state = location.state;
        if (worker) {
            navigate(payrollCalculatePath, { state: { ...state, worker, calculate: true } });
            window.history.replaceState(null, null, window.location.pathname);
            return;
        }
        navigate(payrollCalculatePath, { state: { ...state, calculate: true } });
        window.history.replaceState(null, null, window.location.pathname);
    }
}

const extractWorker = (transcript) => {
    const match = transcript.match(WORKER_REGEX);
    return match ? match[2] : null;
}

function levenshteinDistance(input, item) {
    const title = clearAccents(item?.title?.toLowerCase());
    const dp = Array.from(Array(input.length + 1), () => Array(title.length + 1).fill(0));

    for (let i = 0; i <= input.length; i++) {
        for (let j = 0; j <= title.length; j++) {
            if (i === 0) {
                dp[i][j] = j;
            } else if (j === 0) {
                dp[i][j] = i;
            } else if (input[i - 1] === title[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1];
            } else {
                dp[i][j] = 1 + Math.min(
                    dp[i - 1][j],
                    dp[i][j - 1],
                    dp[i - 1][j - 1] + 1
                );
            }
        }
    }

    return dp[input.length][title.length];
}

function findBestMatches(input) {
    const matches = [];
    const items = getItemsArray();
    const clearInput = treatInput(input);
    if (!clearInput) return;
    const similar = findSimilar(clearInput, items);
    for (const item of items) {
        const distance = levenshteinDistance(similar ?? clearInput, item);
        matches.push({ item: item, distance });
    }
    matches.sort((a, b) => a.distance - b.distance);
    return matches.map(match => match.item);
}

function findSimilar(input, items) {
    let foundItem = null;
    forEach(items, (item) => {
        const itemTitle = clearAccents(item?.title?.toLowerCase());
        if (itemTitle === input) {
            foundItem = item.title;
            return;
        }
        if (itemTitle.includes(input)) {
            foundItem = item.title;
        }
    });
    return foundItem;
}

function getItemsArray() {
    const result = [];
    const menu = store.getState().navigate?.routes;

    function traverse(node) {
        if (isArray(node)) {
            node.forEach(item => traverse(item));
        }
        if (isObject(node)) {
            if (node?.path && node?.path !== 'no-path') {
                result.push(node);
            }
            if (isArray(node.children)) {
                node.children.forEach(child => traverse(child));
            }
        }
    }

    traverse(menu);
    return result;
}

function treatInput(input) {
    const hasKardex = KARDEX_KEYS.some(key => input?.includes(key));
    let KEYS_TO_USE = AFTER_NAVIGATE_KEYS;
    if (!hasKardex) {
        KEYS_TO_USE = KEYS_TO_USE.concat(WORKER_KEYS);
    }
    const regex = new RegExp('\\b(?:' + KEYS_TO_USE.join('|') + ')\\b|\\d+', 'gi');
    return input.replace(regex, '').replace(/\s+/g, ' ').trim();
}

function validateDuplicate(array) {
    const firstTitle = array[0].title;
    for (let i = 1; i < Math.min(5, array.length); i++) {
        if (array[i].title === firstTitle) {
            return true;
        }
    }
    return false;
};