import React from "react";
import Grid from "@mui/material/Grid";
import image from "../../../assets/images/help.png"

function TemplateInfo() {

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>{"Ejemplo de formato del reporte a descargar"}</Grid>
      <Grid item>
        <img src={image}  alt="info" />
      </Grid>
    </Grid>
  );
}

export default TemplateInfo;
