import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, useWatch } from 'react-hook-form';
import { size } from "lodash";
import { implementService } from "../../../services/implemet-service";
import { attendanceListAPI } from "../../../services/RRHH";
import { resolveError } from "../../../common/resolve-error";
import { format, getMonth, getYear } from "date-fns";
import { useDispatch } from "react-redux";
import { lockedWindow, unlockedWindow } from "../../../../store/actions";
import TriggerNotificationSocket from "../../../common/trigger-notification-socket";
import { socketsApi } from "../../../services/socket-report";
import FileSaver from "file-saver";
import { calendarChecksInitValues } from "../ConstantAndUtilities/constants";
import { currentGroup } from "../../../common/validate-tenant";
import { hasValue } from "../../../common/GeneralUtilities";
import { parseDate } from "../ConstantAndUtilities/attendanceGrid/utilities";

export const useCalendarMenu = (props) => {

    const dispatch = useDispatch();

    const {
        initialWorker,
        open,
        initialDate,
        showNavControls
    } = props;

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({
        // resolver: yupResolver(ValidationSchema),
        defaultValues: calendarChecksInitValues,
        mode: 'onChange',
    });

    const [worker, setWorker] = useState([]);
    const [workersData, setWorkersData] = useState([]);
    const [workgroup, setWorkgroup] = useState(null);

    const currentMonth = useWatch({ control, name: 'calendar_month' });
    const currentYear = useWatch({ control, name: 'calendar_year' });

    const [workerIndex, setWorkerIndex] = useState(0);
    const [preview, setPreview] = useState(false);
    const [next, setNext] = useState(false);

    const initializeData = () => {
        setWorker(initialWorker);
        setWorkgroup(initialWorker?.workgroup?.id);
        const month = getMonth(parseDate(initialDate, '', 'date'));
        const year = getYear(parseDate(initialDate, '', 'date'));
        setValue('worker_loading', false);
        setValue('calendar_loading', false);
        setValue('start_date', initialDate);
        setValue('calendar_month', month + 1);
        setValue('calendar_year', year);
    }

    const handleScrollView = () => {
        const totalWorkers = size(workersData);
        const findItemIndex = workersData?.findIndex(element => element?.id === worker?.id);
        setWorkerIndex(findItemIndex);
        const hasPreview = findItemIndex > 0;
        const hasNext = findItemIndex < (totalWorkers - 1);
        setPreview(hasPreview);
        setNext(hasNext);
    }

    const handleChangeWorker = async (id) => {
        setValue('worker_loading', true);
        setValue('calendar_loading', true);
        const request = {
            tree: "",
            include:
                "absenteeisms,enterprise_structure_organization.enterprise_structure.*,enterprise_structure_organization.settings.*",
            month: currentMonth,
            year: currentYear,
            workgroup: initialWorker?.workgroup?.id,
            current_user_group: currentGroup(),
        };
        try {
            const response = await attendanceListAPI.getOne(id, request);
            setWorker(response);
            setValue('worker_loading', false);
            setValue('calendar_loading', false);
        } catch (error) {
            setValue('worker_loading', false);
            setValue('calendar_loading', false);
            resolveError(error);
        }
    }

    const onPrev = () => {
        const newWorker = workersData[workerIndex - 1];
        handleChangeWorker(newWorker?.id);
    }

    const onNext = () => {
        const newWorker = workersData[workerIndex + 1];
        handleChangeWorker(newWorker?.id);
    }

    function getWorkers() {
        setWorkersData(null);
        const request = {
            tree: "",
            fields: 'id',
            month: currentMonth,
            year: currentYear,
            workgroup: initialWorker?.workgroup?.id,
            current_user_group: currentGroup(),
        };
        implementService(attendanceListAPI.get(request), (response) => {
            setWorkersData(response);
        }, null, false, {}, false);
    }

    async function handlePrint() {
        dispatch(lockedWindow());
        const request = {
            month: currentMonth,
            year: currentYear,
            workgroup: workgroup,
            worker: worker?.id,
        }
        try {
            const response = await attendanceListAPI.exportEntriesExits(request);
            TriggerNotificationSocket(`entries_exits_print`, response.task);
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    }

    useEffect(() => {
        if (hasValue(workersData)) {
            handleScrollView();
        }
    }, [workersData, worker]);

    useEffect(() => {
        if (hasValue(initialWorker) && open) {
            initializeData();
            if (showNavControls) {
                getWorkers();
            }
        }
    }, [initialWorker, open]);

    return {
        ...props,
        control,
        setValue,
        handleSubmit,
        worker,
        handlePrint,
        workersData,
        handleChangeWorker,
        preview,
        next,
        onNext,
        onPrev
    }
}

export const CalendarMenuControllerContext = createContext();

export const CalendarMenuControllerProvider = ({ children, ...others }) => {
    const values = useCalendarMenu({ children, ...others });
    return <CalendarMenuControllerContext.Provider value={values}>
        {children}
    </CalendarMenuControllerContext.Provider>
};

CalendarMenuControllerProvider.propTypes = {
    children: PropTypes.any,
}

export const useCalendarMenuController = () => useContext(CalendarMenuControllerContext);

export default useCalendarMenuController;

export const downloadEntriesExits = async (taskID) => {
    try {
        let response = await socketsApi.downloadReport(taskID);
        FileSaver.saveAs(
            response,
            `${"Entradas y salidas"}_${format(
                new Date(),
                "yyyy-MM-dd hh:mm:ss"
            )}`
        );
    } catch (error) {
        return error;
    }
}