import React, { lazy } from "react";

const PayVacations = lazy(() => import('../../../../modules/PayVacations'));
const InitialVacationsProvisions = lazy(() => import('../../../../modules/InitialVacationsProvisions'));

const generalsItems = [
    {
        id: 'modules@pay-vacations',
        type: 'item',
        componentElm: <PayVacations />
    },
    {
        id: 'modules@vacations-initial-provisions',
        type: 'item',
        componentElm: <InitialVacationsProvisions />
    },
];

export default generalsItems;
