import React from 'react';
import { useWatch } from "react-hook-form";
import KendoTable from '../../../../../@components/grid/KendoTable';
import { GridColumn } from '@progress/kendo-react-grid';
import { structurePackagesAPI } from '../../../../../services/enterprise-structure';
import useStructureConfigs from '../useStructureConfigs';
import { columnProps } from '../../../../../../App/components/GridCustomProps';
import { useSelector } from "react-redux";
import { structureLabel, structureSteps } from '../../../../../common/StructureUtilities';
import FormInput from '../../../../../@components/form/Field';
import { CustomTooltip } from '../../../../../../App/components/Templates/cells';
import ButtonEdit from '../Multiples/ButtonEdit';
import { statusLabel } from '../../../../../../App/components/StatusColumn';
import { usePackages } from '../../../usePackages';

function StructureGeneral() {
  const { selectPackage, tenant } = usePackages()
  const locked = selectPackage?.value
  const providerValues = useStructureConfigs()
  const {
    filters,
    setFilters,
    refreshCounter,
    edit,
    refresh,
    control,
  } = providerValues ?? {};

  const configuration = useSelector((state) => state.configuration.steps) ?? [];
  const employer_registration = configuration.find(f => f.step === 'employer_registration');
  const steps = employer_registration ? [...structureSteps(configuration, ['employer_registration']), employer_registration] : [...structureSteps(configuration)];


  function HeaderCellRender({type, data, field}) {
    if(!edit) return <div style={{height:'35px'}}></div>
    return <div>
      <FormInput
        className='numeric_input'
        name={`all.${field}`}
        fieldInput={type}
        data={data}
        textField="name"
        noDefault={true}
        dataItemKey="id"
        valueField="id"
        control={control}
      />
    </div>
  }

  function HeaderTitle({title}) {
    const value = title.split('|')
    return <CustomTooltip title={value[1]}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: '700' }}>
        {value[0]}
      </div>
    </CustomTooltip>
  }

  const results = {
    dropdown:(data, value) => data.find(f => f.id === value)?.name,
    switch:({dataItem, field}) => {
      const value = dataItem.settings.config[field]
      return statusLabel(value)
    }
  }


  function CellInput({dataItem, field, type, data}) {
    function ViewInput({dataItem, field, type, data}) {
      const defaultValue = dataItem.settings.config[field]
      const valueWatch = useWatch({ control, name: `data.${field}_${dataItem.config_id}` });
      return  <FormInput
        name={`data.${field}_${dataItem.config_id}`}
        fieldInput={type}
        data={data}
        textField="name"
        value={defaultValue != null ? defaultValue : valueWatch}
        noDefault={true}
        dataItemKey="id"
        valueField="id"
        control={control}
      />
    }
    if (edit) {
      return <td>
       <ViewInput dataItem={dataItem} field={field} type={type} data={data}/>
      </td>
    } else {
      return <td >
        {type === "dropdown" ?
          results.dropdown(data, dataItem[field]) :
          results[type]({dataItem, field}) }
      </td>
    }
  }

  return (
    <div>
      <KendoTable
        style={{
          maxHeight: "calc(100vh - 178px)",
        }}
        pageSizes={[20,30,40,50]}
        Api={structurePackagesAPI}
        extraOptions={{
          include: "enterprise_structure.*,employer_registration,settings",
          tenant
        }}
        filters={filters}
        setFilters={setFilters}
        refreshCounter={refreshCounter}
        onRefresh={refresh}
        is_active={false}
        actions={false}
      >
        {steps.map((item, index) => (
          <GridColumn
            key={index}
            width={"auto"}
            {...columnProps(item?.step === "employer_registration" ? item?.step : `enterprise_structure.${[item?.step]}`, filters, [(item?.step !== "employer_registration" ? "keyName" : "keyNameRfc"), item?.step])}
            format={item?.step === "employer_registration" ? "withRFC" : ""}
            title={structureLabel(item)}
          />
        ))}
        <GridColumn title="Req. checar|Requiere checar" headerCell={HeaderTitle}>
          <GridColumn
            width={105}
            field="required_check"
            headerCell={(kProps) => <HeaderCellRender type="switch"  {...kProps} />}
            cell={(kProps) => <CellInput type="switch"  {...kProps} />}
            />
        </GridColumn>
        <GridColumn title="Perm. checar|Permitir checar" headerCell={HeaderTitle}>
          <GridColumn
            width={105}
            field="allow_check"
            headerCell={(kProps) => <HeaderCellRender type="switch"  {...kProps} />}
            cell={(kProps) => <CellInput type="switch"  {...kProps} />}
            />
        </GridColumn>
        <GridColumn title="Días Fijos|Días Fijos" headerCell={HeaderTitle}>
          <GridColumn
            width={105}
            field="fixed_days"
            headerCell={(kProps) => <HeaderCellRender type="switch"  {...kProps} />}
            cell={(kProps) => <CellInput type="switch"  {...kProps} />}
            />
        </GridColumn>
        <GridColumn title="Pri. Aniversario|Pago prima vacacional por aniversario" headerCell={HeaderTitle}>
          <GridColumn
            width={105}
            field="anniversary_vacation_bonus"
            headerCell={(kProps) => <HeaderCellRender type="switch"  {...kProps} />}
            cell={(kProps) => <CellInput type="switch"  {...kProps} />}
            />
        </GridColumn>
        <GridColumn title="Prorrateo|Prorrateo" headerCell={HeaderTitle}>
          <GridColumn
            width={105}
            field="apportionment"
            headerCell={(kProps) => <HeaderCellRender type="switch"  {...kProps} />}
            cell={(kProps) => <CellInput type="switch"  {...kProps} />}
            />
        </GridColumn>
      </KendoTable>
      <ButtonEdit {...providerValues} locked={locked}/>
    </div>
  );
}

export default StructureGeneral;
