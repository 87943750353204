import React, {
	useContext,
	createContext,
	useState,
	useEffect,
	useMemo,
} from 'react';
import { debounce, first, size } from 'lodash';
import { hasValue, valueOrOption } from '../../../common/GeneralUtilities';
import useAbrhilNavigation from './useAbrhilNavigation';

const useController = ({
	title,
	elements,
	groupsAtFirstLevel,
}) => {

	let initialTab = groupsAtFirstLevel ? elements?.[0]?.name : null;
	const [search, setSearch] = useState("");
	const [tab, setTab] = useState(valueOrOption(initialTab, null));
	const [subMenu, setSubMenu] = useState(null);
	const { setShowed } = useAbrhilNavigation();
	const [withBackToMain, setWithBackToMain] = useState(true);

	const debouncedSearch = useMemo(() => {
		return debounce(value => setSearch(value), 500);
	}, []);

	const debouncedShow = useMemo(() => {
		return debounce(value => setShowed(value), 300);
	}, []);

	useEffect(() => debouncedSearch.cancel());
	useEffect(() => debouncedShow.cancel());

	const navigateInside = (item) => {
		setSubMenu(item);
		setTab(first(item?.children)?.name ?? null);
	};

	const onlyInsideItems = () => {
		if (size(elements) === 1 && elements?.[0]?.is_subitem) {
			navigateInside(elements?.[0]);
			setWithBackToMain(false);
		}
	}

	const forceHide = () => setShowed(null);
	const forceOpen = (item) => setShowed(item);

	useEffect(() => {
		onlyInsideItems();
	}, [elements]);

	return {
		title,
		tab,
		setTab,
		elements,
		search,
		debouncedSearch,
		subMenu,
		navigateInside,
		isSubmenu: hasValue(subMenu?.title),
		groupsAtFirstLevel,
		debouncedShow,
		forceOpen,
		forceHide,
		withBackToMain
	};

};

const MegaMenuContext = createContext();

export const MegaMenuProvider = ({ children, ...others }) => (
	<MegaMenuContext.Provider value={useController(others)}>
		{children}
	</MegaMenuContext.Provider>
);

/**
 * Controlador de contexto para los conceptos de nomina
 * @returns {useController}
 * */
export const useMegaMenu = () => useContext(MegaMenuContext);

export default useMegaMenu;