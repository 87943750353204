import PropTypes from "prop-types"
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import FileSaver from "file-saver";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Pager } from "@progress/kendo-react-data-tools";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import { capitalize } from 'lodash';
import { useGridContext } from "../../../hooks/useGrid";
import { pageable } from "../../../../../../../App/components/GridCustomProps";
import { lockedWindow, set_window_packages, showNotificationRemove, unlockedWindow } from "../../../../../../../store/actions";
import { questionDelete, successDeleted, successUpdated } from "../../../../../../common/notification-messages";
import { companyApi } from "../../../../../../services/company";
import { showSuccessNotification } from "../../../../../../../App/components/Notifications";
import { resolveError } from "../../../../../../common/resolve-error";
import { securityTypes } from "../../constants";
import { implementService } from "../../../../../../services/implemet-service";
import Photo from "../../../../../Branch/components/Photo";
import { CustomTooltip } from "../../../../../../../App/components/Templates/cells";
import IconUse from "../../../../../../common/IconUse";
import WindowPackages from "../../WindowPackages";
import ModalsController, { CONTRACTS_KEY, PAYROLL_KEY, SETTINGS_KEY, TOKEN_KEY, useModalsContext } from "../../Dialogs/hooks/useModals";
import PermissionsGate from "../../../../../../../permissions/PermissionsGate";
import { usePackages } from "../../../../../../@hooks/usePackages";
import Modals from "../../Dialogs";
import { statusLabel } from "../../../../../../../App/components/StatusColumn";
//-- components

const MIGRATION_STATUS = [
  { title: "SIN MIGRACION", css: "initial" },
  { title: "MIGRACION EN PROCESO", css: "processing" },
  { title: "MIGRACION COMPLETADA", css: "completed" },
  { title: "MIGRACION FALLIDA", css: "failed" },
];

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data,
    page,
    setPage,
    filters,
    getCompanies,
  } = useGridContext();

  const onPageChange = (event) => {
    if (page.skip !== event.skip) {
      setPage(event);
    }
  };

  /* Delete data */
  const onDelete = (item) => {
    const context = `${t("company")} ${item.id} - ${item.name}`;
    dispatch(showNotificationRemove(questionDelete(context, handleRemove, item)));
  };

  const handleRemove = async (deleteItem) => {
    dispatch(lockedWindow());
    companyApi
      .destroy(deleteItem.id, {})
      .then(() => {
        getCompanies(filters, page);
        showSuccessNotification(successDeleted());
      })
      .catch((error) => {
        resolveError(error);
      })
      .finally(() => {
        dispatch(unlockedWindow());
      });
  };

  return (
    <ModalsController>
      <Modals />
      <div className="company-grid-wrapper">
        <div className="list-items-wrap">
          <div>
            {data.data?.map(el =>
              <ItemTemplate
                key={`company-${el.slug}-${el.id}`}
                item={el}
                onDelete={onDelete}
              />
            )}
          </div>
        </div>
        <Pager
          skip={page.skip}
          take={page.take}
          onPageChange={onPageChange}
          total={data.total}
          previousNext
          {...pageable(data.total)}
        />
      </div>
    </ModalsController>
  );
};

export default List;

const ItemTemplate = ({ item, onDelete }) => {
  const {
    id,
    logo,
    corporate,
    is_active,
    name,
    slug,
    securitytype,
    migration_status,
  } = item;

  const {
    data,
    addEdit,
    loadCompanies,
    corporatives,
  } = useGridContext();

  const stateWindow = useSelector(state => state.window_packages[id]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loadPackages } = usePackages();
  const { handleGeneralState } = useModalsContext();

  // const onDownloadType = async (item) => {
  //   const { openWindow, value } = item;
  //   if (value.migration_status) return;

  //   try {
  //     let response = await companyApi.downloadConfigXML(value.id);
  //     const source = new Blob([response.data], { type: "application/xml" });
  //     FileSaver.saveAs(source, `${value.schema_name}.xml`);
  //     if (openWindow) {
  //       window.open(
  //         "https://hris-cloud-backend.s3.us-east-2.amazonaws.com/public/media/Abrhil+Cloud+Migration+Tool.7z"
  //       );
  //     }
  //   } catch (error) {
  //     resolveError(error);
  //   }
  // };

  const corporateValue = corporatives.find((item) => item.id === corporate);
  const securitytypeVal = securityTypes.find(
    (item) => item.id === (securitytype || 1)
  );

  const onSuccess = () => {
    showSuccessNotification(successUpdated());
    loadCompanies();
  }

  const onSave = (file) => {
    const data = { logo: file };
    implementService(companyApi.updateLogo(id, data), onSuccess);
  }

  function hanldeOpenWindow() {
    dispatch(set_window_packages({ [id]: true }));
  }

  function onClose() {
    loadPackages(id)
    dispatch(set_window_packages({ [id]: false }));
  }

  const onOpenConfig = ({ value }) => {
    handleGeneralState(value, true, item);
  }

  return (
    <Card className="companyCard" >
      <div className="k-card-image">
        <Photo photo={logo?.full_size} onSave={onSave} size={100} showButton={false} />
      </div>
      <CardBody className="divders">
        <div className="status-container">
          {statusLabel(is_active)}
          <div className={`status ${MIGRATION_STATUS[migration_status].css}`}>
            {capitalize(MIGRATION_STATUS[migration_status].title)}
            {migration_status === 1 && (
              <div className="abrhil-progress-bar">
                <div className="abrhil-progress-bar-value"></div>
              </div>
            )}
          </div>
        </div>

        <div className="data-container">
          <CustomTooltip title={name}>
            <div className="companie-name">{name}</div>
          </CustomTooltip>
          <div className="companie-info">
            <div className="info-label">Corporativo:
              <CustomTooltip title={corporateValue?.name}>
                <span >
                  {corporateValue?.name}
                </span>
              </CustomTooltip>
            </div>
            <div className="info-label">Subdominio:
              <CustomTooltip title={slug}>
                <span >
                  {slug}
                </span>
              </CustomTooltip>
            </div>
            <div className="info-label">Tipo de seguridad:
              <CustomTooltip title={securitytypeVal?.name}>
                <span >
                  {securitytypeVal?.name}
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>

        <div className="actions-row">
          <ActionButton
            cy="packages"
            scope="read"
            title={t("company-packages")}
            icon={<IconUse icon={"package"} />}
            onClick={hanldeOpenWindow}
          />
          {/* <ActionButton
            cy="export"
            scope="read"
            title={t("company-settings")}
            items={[
              {
                icon: <IconUse icon={"configicon"} />,
                text: t("XML_config"),
                openWindow: false,
                value: item,
              },
              {
                icon: <IconUse icon={"configicon"} />,
                text: t("XML_migration"),
                openWindow: true,
                value: item,
              },
            ]}
            icon={<IconUse icon={"configicon"} />}
            disabled={migration_status !== 0}
            onItemClick={onDownloadType}
          /> */}

          <ActionButton
            cy="edit"
            scope="write"
            title={t("edit")}
            icon={<IconUse icon={"edit"} />}
            onClick={() => addEdit(item)}
          />
          <ActionButton
            cy="delete"
            scope="write"
            title={t("remove")}
            icon={<IconUse icon={"delete"} />}
            disabled={(migration_status === 1 || data.total <= 1)}
            onClick={() => onDelete(item)}
          />
          {stateWindow &&
            <WindowPackages
              tenant={item.id}
              open={stateWindow}
              onClose={onClose}
              title={`${t("company-packages")} | ${name}`}
            />}
          <ActionButton
            cy="more-options"
            scope="read"
            title={t("more-options")}
            items={[
              {
                icon: <IconUse icon={"Copy"} />,
                text: t("Copiar paquete desde otra empresa"),
                value: SETTINGS_KEY,
              },
              {
                icon: <IconUse icon={"Copy"} />,
                text: t("Copiar contratos y formatos desde otra empresa"),
                value: CONTRACTS_KEY,
              },
              {
                icon: <IconUse icon={"Token"} />,
                text: t("Generar token para migración"),
                value: TOKEN_KEY,
              },
              {
                icon: <IconUse icon={"configicon"} />,
                text: t("Asignar configuraciones de nómina"),
                value: PAYROLL_KEY,
              },
            ]}
            icon={<FontAwesomeIcon icon={faEllipsis} />}
            disabled={migration_status !== 0}
            onItemClick={onOpenConfig}
          />
        </div>
      </CardBody>
    </Card>
  );
};

ItemTemplate.propTypes = {
  item: PropTypes.any,
  onDelete: PropTypes.func
}

const ActionButton = ({
  onClick,
  onItemClick,
  icon,
  title,
  items,
  disabled,
  scope
}) => {

  return (
    <PermissionsGate scopes={[scope]}>
      <CustomTooltip title={title}>
        {items ?
          <span>
            <ButtonDropDawn items={items} icon={icon} onItemClick={onItemClick} />
          </span>
          :
          <IconButton aria-label={title} onClick={onClick} disabled={disabled}>
            {icon}
          </IconButton>}
      </CustomTooltip>
    </PermissionsGate>
  );
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  items: PropTypes.any,
  onClick: PropTypes.func,
  onItemClick: PropTypes.func,
  scope: PropTypes.any,
  title: PropTypes.any
}

const ButtonDropDawn = ({ items, icon, onItemClick }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((option) => (
          <MenuItem
            key={option.text}
            onClick={() => {
              onItemClick(option);
              handleClose();
            }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ButtonDropDawn.propTypes = {
  icon: PropTypes.any,
  items: PropTypes.any,
  onItemClick: PropTypes.func
}