import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGridContext } from "./useGridController";
import { initialDialog } from "../../../../App/components/dialog/FormDialog";
import { useRequestLoad } from "../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { trainingModeApi } from "../../../services/training";
import { showSuccessNotification } from "../../../../App/components/Notifications";
import { successCreated, successUpdated } from "../../../common/notification-messages";
import { pick } from "lodash";
import { ValidationSchema } from "../utilities/validations";

const initValues = {
    id: null,
    key: null,
    name: null,
    is_active: true,
    is_default: false,
};

function useDialogController() {
    const { dialog, setDialog, onRefresh } = useGridContext();
    const [fetch, loading] = useRequestLoad();
    const { open, isEdit, data } = dialog;

    const {
        control,
        reset,
        setValue,
        handleSubmit
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(ValidationSchema),
        defaultValues: initValues,
    });

    useEffect(() => {
        if (!open) return;
        reset(pick(data, Object.keys(initValues)));
    }, [open]);

    const handleClose = () => {
        setDialog(initialDialog);
        reset(initValues);
    };

    const getApi = (data) => {
        if (isEdit) {
            return trainingModeApi.put(data.id, data);
        }
        return trainingModeApi.post(data);
    };

    const onSubmit = (data) => {
        fetch({
            api: getApi(data),
            callback: onSuccess
        });
    };

    const onSuccess = () => {
        handleClose();
        onRefresh();
        showSuccessNotification(isEdit ? successUpdated() : successCreated());
    };

    return {
        open,
        data,
        isEdit,
        control,
        loading,
        setValue,
        reset,
        onSubmit,
        handleSubmit,
        handleClose,
    };
};

export const DialogContext = createContext({});

export const useDialogContext = () => useContext(DialogContext);

export default function DialogController({
    children,
}) {
    const values = useDialogController();

    return (
        <DialogContext.Provider value={values}>
            {children}
        </DialogContext.Provider>
    );
};

DialogController.propTypes = {
    children: PropTypes.any,
};