import { valueOrOption } from '../../core/common/GeneralUtilities';

export const materialSizeClasses = ({ size, className }) => {
    className = valueOrOption(className, "");
    size = valueOrOption(size, "medium");
    let extraClass = "";

    if (["xs", "extra-small", "medium", "md"].includes(size)) {
        extraClass = "extra-small";
    }

    if (["2xs", "extra-small-2", "small", "sm"].includes(size)) {
        extraClass = "extra-small-2";
    }

    return { size: "small", className: `${className} ${extraClass}` };
};

export const kendoSizeClasses = ({ size, className }) => {
    className = valueOrOption(className, "");
    size = valueOrOption(size, "medium");
    let extraClass = "";

    if (["xs", "extra-small", "medium", "md"].includes(size)) {
        size = "medium";
    }

    if (["2xs", "extra-small-2", "small", "sm"].includes(size)) {
        size = "small";
    }

    return { size: size, className: `${className} ${extraClass}` };
};

export const bootstrapSizeClasses = ({ size, className }, valid = false, disabled = false) => {
    className = valueOrOption(className, "");
    size = valueOrOption(size, "medium");
    let extraClass = "";

    if (["lg", "large"].includes(size)) {
        size = "large";
        extraClass = "boostrap-large-input";
    }

    if (["xs", "extra-small", "medium", "md"].includes(size)) {
        size = "medium";
        extraClass = "boostrap-medium-input";
    }

    if (["2xs", "extra-small-2", "small", "sm"].includes(size)) {
        size = "small";
        extraClass = "boostrap-small-input";
    }

    const containerClasses = [
        className,
        extraClass,
        "abrhil-bootstrap-container",
        size,
        valid ? "" : "invalid",
        disabled ? "disabled" : "",
    ].join(" ");

    return {
        size: size,
        containerClasses: containerClasses,
        className: `${className} ${extraClass}`,
    };
};