import { configFile } from "./config";
import { ParseParams, ServerAPI, getUrl } from "./ServerAPI";
import axios from 'axios';

export const directoryManagerAPI = ServerAPI('directory-manager');

const FileManagerAPI = () => {

  const baseName = "file-manager";
  const baseApi = ServerAPI(baseName);

  const downloadFile = async (data) => {
    const url = getUrl(`${baseName}/download`, true);

    const params = ParseParams({ params: data, include_config: true });
    return axios
      .get(url, { ...params, responseType: 'blob' })
      .then(response => window.URL.createObjectURL(response.data));
  }

  // TODO: REVISAR QUE SE CONFIGURE EL CORS O IMPLEMENTAR API CUSTOM PARA RECUPERAR ARCHIVOS
  const fetchFile = async (url) => axios.get(url, configFile()).then(resp => resp.data);

  return {
    ...baseApi,
    downloadFile,
    fetchFile,
  };
};

export const fileManagerAPI = FileManagerAPI();