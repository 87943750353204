import React, { forwardRef } from "react";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid2";
import { hasValue, valueOrOption } from '../../../../common/GeneralUtilities';
import { isObject, omit } from 'lodash';
import { statusesArray } from '../../utilities';
import { backDateTimeFormat } from '../../../../../App/components/Dates/utilities';

export const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

export const getItemKey = (groupIndex, fieldIndex) => {
	let key = `key_group${groupIndex}`;
	if (!hasValue(fieldIndex)) {
		return key;
	}

	return `${key}_field${fieldIndex}`;
};

export const filterProps = (item) => {

	let allProps = valueOrOption(item?.[2], { fieldInput: "TextField" });
	if (["statusd", "status"].includes(allProps)) {
		allProps = {
			fieldInput: "Dropdown",
			data: statusesArray(),
			valueField: "value",
			textField: "label",
		};
	}
	if (!isObject(allProps)) {
		allProps = {
			fieldInput: "TextField"
		};
	}

	const fieldInput = (allProps?.fieldInput ?? "TextField").toLowerCase();

	const sizes = {
		xs: 12, md: 6, lg: 6, xl: 6,
		...valueOrOption(allProps?.size, {}),
		...valueOrOption(allProps?.colProps, {}),
	};

	if (fieldInput === "date") {
		allProps["useBackFormats"] = true;
	} else if (fieldInput === "datetime") {
		allProps["valueFormat"] = backDateTimeFormat;
		allProps["outFormat"] = backDateTimeFormat;
	} else if (fieldInput === "daterange") {
		allProps["startProps"] = {
			...allProps?.startProps,
			fieldInput: "Date",
			useBackFormats: true
		};
		allProps["endProps"] = {
			...allProps?.endProps,
			fieldInput: "Date",
			useBackFormats: true
		};
	}

	return {
		...omit(allProps, ["colProps", "size", "validation"]),
		fieldInput: fieldInput,
		name: item[0],
		label: item[1],
		Container: Grid,
		containerProps: { size: sizes },
	};
};