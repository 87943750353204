import { useForm } from "react-hook-form";
import { resolveError } from "../../../../../../common/resolve-error";
import { overtimeMopersAPI, shiftsAPI } from "../../../../../../services/mopers";
import React, { useEffect, useRef, useState } from "react";
import { isFunction, size } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validateNumeric } from "../../../../../../common/validations/numeric";
import { getModulePerm, getNavItem } from "../../../../../../@components/navigation/utilities";
import useAbrhilNavigation from "../../../../../../@components/navigation/contextsAndControllers/useAbrhilNavigation";
import { implementService } from "../../../../../../services/implemet-service";
import { currentUserInfo } from "../../../../../../common/validate-tenant";
import { showNotification } from "../../../../../../../App/components/Notifications";

export const MODAL_KEY = 3;

const validationSchema = yup.object().shape({
    shift: validateNumeric({ required: true }),
});

function useResolveIncidence({
    modalValues,
    handleClose,
    handleRefresh,
    worker,
    selected,
}) {
    const { openWindowItem } = useAbrhilNavigation();

    const { value: open } = modalValues[MODAL_KEY];
    const modulePerm = getModulePerm('modules@schedules', 'write') || currentUserInfo()?.is_superuser;
    const [fetch, loading] = useRequestLoad();
    const [performResolve, loadingResolve] = useRequestLoad();
    const [shifts, setShifts] = useState([]);
    const [checks, setChecks] = useState([]);
    const [step, setStep] = useState(0);
    const currentCheck = checks?.[step];
    const someResolved = checks?.some(el => el?.resolved);
    const resolvedShift = currentCheck?.clock_process?.[0]?.resolved_shift;
    const currentShift = currentCheck?.clock_process?.[0]?.shift;
    const currentIndex = checks?.findIndex(el => el?.id === currentCheck?.id);
    const initValues = { shift: resolvedShift ?? currentShift };
    const direction = useRef(1);

    const {
        control,
        reset,
        getValues,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: initValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (!open || !selected?.checks) return;
        const itemChecks = selected?.checks?.filter(el => el?.incidence);
        setChecks(itemChecks);
    }, [selected?.checks, open])

    useEffect(() => {
        if (!open || !size(worker)) return;
        reset({ ...initValues });
        getSchedules();
    }, [open, worker, step, currentShift])

    const getSchedules = async () => {
        const params = {
            tree: true,
            worker_shift: worker?.id,
        };
        try {
            const resp = await fetch({ api: shiftsAPI.get(params) });
            setShifts(resp);
        } catch (error) {
            resolveError(error);
        }
    }

    const resolveIncidence = async (data) => {
        if (!shifts.length) return;
        const params = {
            check: data ? currentCheck?.id : undefined,
            worker: worker?.id,
            origin_day: selected?.origin_day,
            shift: data?.shift ?? getValues('shift'),
        }
        try {
            const resp = await performResolve({ api: overtimeMopersAPI.resolveIncidence(params) });
            let newChecks = [...checks];
            const unresolveIndex = newChecks.findIndex(el => !el.resolved);
            if (data) {
                newChecks[currentIndex].resolved = true;
                newChecks[currentIndex].clock_process = newChecks?.[currentIndex]?.clock_process ?? [{}];
                newChecks[currentIndex].clock_process[0].resolved_shift = data?.shift;
            } else {
                newChecks = newChecks.map(el => {
                    el.resolved = true;
                    el.clock_process = el?.clock_process ?? [{}];
                    el.clock_process[0].resolved_shift = data?.shift;
                    return el;
                });
            }
            setChecks(newChecks);
            const direction = unresolveIndex < currentIndex;
            const pendings = newChecks.filter(el => !el.resolved).length;
            setTimeout(() => {
                if (!pendings) return;
                if (!direction) {
                    onClickRight();
                } else if (direction) {
                    onClickLeft();
                }
            }, 500);
            showNotification({ message: resp?.detail, showTitle: false });
            if (!pendings) {
                setTimeout(() => {
                    onClose();
                    if (isFunction(handleRefresh)) {
                        handleRefresh(false, selected);
                    }
                }, 1000);
            }
        } catch (error) {
            resolveError(error);
        }
    }

    const onClickLeft = () => {
        direction.current = -1;
        setStep(prev => prev - 1);
    };

    const onClickRight = () => {
        direction.current = 1;
        setStep(prev => prev + 1);
    };

    const onClose = () => {
        handleClose(MODAL_KEY, reset);
        setStep(0);
    };

    const handleClick = () => {
        if (shifts.length) return;
        const gsModule = getNavItem('modules@schedules', false, true);
        if (modulePerm) openWindowItem(gsModule);
        onClose();
    }

    const handleResolveAll = () => resolveIncidence();

    const handleNavigate = (newIndex) => {
        direction.current = -1;
        if (newIndex > step) {
            direction.current = 1;
        }
        setStep(newIndex);
    };

    return {
        open,
        step,
        checks,
        shifts,
        worker,
        control,
        loading,
        selected,
        direction,
        modulePerm,
        currentIndex,
        loadingResolve,
        someResolved,
        currentCheck,
        handleRefresh,
        setStep,
        onClose,
        handleClick,
        handleSubmit,
        onClickLeft,
        onClickRight,
        handleNavigate,
        handleResolveAll,
        resolveIncidence,
    };
};

export default useResolveIncidence;

type FetchFunction = (options: {
    api: Promise<any>;
    callback: (response: any) => void;
    onFailed?: (error: any) => void;
    paramLabels?: any;
    waitCallback?: boolean;
    load?: boolean;
}) => Promise<void>;

/**
 * Hook personalizado para manejar la carga de datos y el estado de carga.
 *
 * @param {boolean} initialState - El estado inicial del indicador de carga.
 * @returns {[Function, boolean, Function]} - [Función para hacer el request, State de la carga de la petición, setState de el estado de carga manual]
 */
export const useRequestLoad = (
    initialState: boolean = false
): [FetchFunction, boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [loading, setLoading] = useState(initialState);

    const getData: FetchFunction = async ({ api, callback, onFailed, paramLabels, waitCallback = true, load = true }) => {
        if (load) setLoading(true);
        return await implementService(
            api,
            (resp) => onCallback(resp, callback, waitCallback),
            (err) => onError(err, onFailed),
            true,
            paramLabels,
            false
        );
    }

    const onCallback = async (resp, customCall, waitCallback) => {
        if (isFunction(customCall)) {
            if (waitCallback) {
                await customCall(resp);
            } else {
                customCall(resp);
            }
        }
        setLoading(false);
        return resp;
    }

    const onError = (error, customError) => {
        if (isFunction(customError)) {
            customError(error);
        } else {
            resolveError(error);
        }
        setLoading(false);
    }

    return [getData, loading, setLoading];
};

export const removeDatasFailed = err => {
    if (err.response?.data?.datas_failed) {
        delete err.response?.data?.datas_failed;
        delete err.response?.data?.data;
        delete err.response?.data?.type;
    }
    return err;
}