import PropTypes from "prop-types";
import React, { useRef, useEffect } from 'react';
import { each } from "lodash";
import { WindowActionsBar, Window as KendoWindow } from "@progress/kendo-react-dialogs";
import Grid from "@mui/material/Grid2";
import { hasValue } from '../../common/GeneralUtilities';

const Window = ({
	title,
	subTitle,
	children,
	onClose,
	open,
	Actions,
	className,
	...others
}) => {

	const thisWindow = useRef(null);

	useEffect(() => {
		if (!open) {
			return;
		}
		const elements = document.getElementsByClassName("abrhil-kendo-window");
		each(elements, (element) => {
			const eId = element.id;
			const thisId = thisWindow?.current?.windowElement?.id;
			if (eId !== thisId && thisId) {
				element.classList.remove('k-window-focused');
			}
		});
	}, [open]);

	if (!open) { return null; }

	return (
		<KendoWindow
			ref={thisWindow}
			title={<WindowTitle title={title} subTitle={subTitle} />}
			onClose={onClose}
			initialWidth={800}
			appendTo={document.getElementById("root")}
			className={`${className} abrhil-kendo-window`}
			{...others}
		>
			{children}
			{Actions && (
				<WindowActionsBar layout={"end"}>
					{Actions}
				</WindowActionsBar>
			)}
		</KendoWindow>
	);
};


Window.propTypes = {
	Actions: PropTypes.any,
	children: PropTypes.any,
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	subTitle: PropTypes.any,
	title: PropTypes.any
};

export default Window;

const WindowTitle = ({
	title,
	subTitle,
}) => (
	<Grid container spacing={0}>
		<Grid size={{ xs: 12, md: "auto" }}>
			{title}
		</Grid>
		{hasValue(subTitle) && (
			<Grid className={"window-subtitle"} size={{ xs: 12, md: "grow" }}>
				{subTitle}
			</Grid>
		)}
	</Grid>
);

WindowTitle.propTypes = {
	subTitle: PropTypes.any,
	title: PropTypes.any
};
