import React from "react";
import PropTypes from "prop-types";
import "../../../../../styles/style.scss";
import { CustomTooltip } from "../../../../../../../../App/components/Templates/cells";

function Flag({ position = '', color = '', title }) {

    return (
        <CustomTooltip title={title}>
            <div className={`mopers-flag-indicator ${position} ${color}`} />
        </CustomTooltip>
    );
}

export default Flag;

Flag.propTypes = {
    position: PropTypes.string,
    color: PropTypes.string,
};