import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { Default } from './Default';
import { Scanning } from './Scanning';
import Calculating from './Calculating';
import SocketMessages from './SocketMessages';
import { valueOrOption } from '../../../core/common/GeneralUtilities';
import useSocketsLocked from './utilities/useSockedLocked';

const item = {
	calculating: <Calculating />,
	scanning: <Scanning />,
	default: <Default />,
};

const Locked = () => {

	const {
		lockedWindow,
		type,
	} = useSelector(state => state.locked) ?? {};

	const { openLock, messages, } = useSocketsLocked();

	if (openLock) {
		return (
			<Dialog open={true} className={`loaderContainer calculating`} maxWidth={'lg'}>
				<SocketMessages {...messages} />
			</Dialog>
		);
	}

	if (!lockedWindow) return;

	const loaderType = valueOrOption(type, "default");

	return (
		<Dialog open={lockedWindow} className={`loaderContainer ${loaderType}`} maxWidth={'lg'}>
			{item[loaderType]}
		</Dialog>
	);
};

export default memo(Locked);