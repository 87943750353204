import { usePackages } from "../usePackages";

function useHeader() {
  const { setSelectPackage } = usePackages()


  function handleSelectPackage(e, item) {
    if (e.target.type === 'checkbox') return
    setSelectPackage(item)
  }

  return {
    handleSelectPackage
  };
}

export default useHeader;
