import React, { useContext } from 'react'
import { isArray } from "lodash";
import { useController } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import LottieUse from '../../../../common/LottieUse'
import { ModuleContext } from '../../util';

function SortableEmptyItemUI({ findIndex }) {
  const { t } = useTranslation()
  const { control } = useContext(ModuleContext);

  const {
    fieldState: { error }
  } = useController({ name: "signatures", control });

  function ValidateMsg() {
    if (!error) return
    if (isArray(error)) {
      if (isArray(error[findIndex]?.signature)) return
      return <p className="form-error">{error[findIndex]?.signature?.message}</p>
    } else {
      return <p className="form-error">{error.message}</p>
    }
  }

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <div className='emptyRow' style={{ width: "100%" }}>
        <div className='animations-container' >
          <LottieUse style={{ height: "100px" }} loop icon={"laptop"} className={`player-laptop`} />
          <LottieUse style={{ height: "60px" }} loop icon={"no-records-icon"} className={`player-no-results`} />
        </div>
        <div className='description' style={{ fontSize: "12px" }}>{t("no-results")}</div>
      </div>
      <ValidateMsg />
    </div>

  )
}

export default SortableEmptyItemUI