import { createContext } from 'react'
import * as yup from "yup";
import { stringValidation } from '../../common/validations/stringValidation';
import arrayValidation from '../../common/validations/array';
export const ModuleContext = createContext()


export const validationSchema = yup.object().shape({
  footer_description: stringValidation({ required: true, min: 10, max: 1500 }),
  signatures: arrayValidation({ required: true, min: 1, max: 4 }).of(
    yup.object().shape({
      signature: arrayValidation({ required: true, min: 1, max: 4 }).of(
        yup.object().shape({
          name: stringValidation({ required: true, }),
        })),
    })
  )
});
