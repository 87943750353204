import { useEffect, useRef, useState } from "react";
import {
	each,
	isEmpty,
	isFunction,
	isNumber,
	omit,
	size,
	some,
} from "lodash";

// Util imports
import { API_WS } from "../../../../core/services/config";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Sockets registered in system
import { SocketsDictionary } from "./SocketsDictionary";

// General utilities
import { hasValue, valueOrOption } from '../../../../core/common/GeneralUtilities';
import { resolveError } from "../../../../core/common/resolve-error";
import { socketsApi } from "../../../../core/services/socket-report";
import { playError, playSuccess } from '../../sounds';
import { showControlledError, showNotificationError, showNotificationSuccess } from '../../../../store/actions';
import { lockedSockedEmitter } from "../../../../core/common/trigger-notification-socket";


const errorStatus = ['FAILURE', 400];
const errorCodes = [400, 404];

const getApiUrl = (name) => {
	const token = localStorage.getItem('access');
	return `${API_WS}/${name}?token=${token}`;
};

const useSocketsLocked = () => {

	const { t } = useTranslation('notifications');
	const dispatch = useDispatch();
	const nullValues = [null, 'null', undefined, 'undefined'];

	const tenant = useSelector((state) => state.userConfigs?.current_tenant);
	const socketDictionary = SocketsDictionary(tenant);

	const isReconnectLock = useRef(false);
	const socket_lock_connections = useRef([]);
	const sockets_lock_queue = useRef([]);
	const [openLock, setOpenLock] = useState(false);
	const [messages, setMessages] = useState({});

	const getSocketByName = (socketName) => socketDictionary[socketName] ?? {};

	const definedSocket = (socketName, taskID, callback) => {
		const foundSocket = getSocketByName(socketName);
		if (isEmpty(foundSocket) || !foundSocket) {
			setOpenLock(false);
			return;
		};
		const { url, cancel_api } = foundSocket;
		const alreadyConnected = sockets_lock_queue.current.some(socket => socket.url === url);
		sockets_lock_queue.current.push({ taskID, socketName, cancel_api, url, callback, dimissed: false });

		if (!alreadyConnected) {
			connect(url);
		}
	};

	const getActiveSocket = (url) => socket_lock_connections.current?.find(el => el?.url?.includes(url));

	const buildMessage = (message) => {
		if (!hasValue(message)) {
			return "";
		}
		return t(message);
	};

	const getActualTask = (taskId) => {
		return sockets_lock_queue.current?.find(el => el?.taskID === taskId) ?? {};
	};

	const massiveDisconnect = () => {
		each(socket_lock_connections.current, (socket) => socket?.close());
		socket_lock_connections.current.length = 0;
		sockets_lock_queue.current.length = 0;
		isReconnectLock.current = false;
		localStorage.removeItem('sockets_lock_queue');
		localStorage.removeItem('isReconnectLock');
	};

	const handleSocket = ({ name, taskID, callback }) => {
		if (!name || !taskID) return;
		definedSocket(name, taskID, callback);
	};

	const handleMassiveAbort = () => massiveDisconnect();

	const initialize = (queue_backup) => {
		sockets_lock_queue.current = JSON.parse(queue_backup);
		if (sockets_lock_queue.current.length > 0 && isReconnectLock.current) {
			each(sockets_lock_queue.current, (task) => {
				task.dimissed = false;
				const alreadyOpen = socket_lock_connections.current?.some(el => el?.url?.includes(task?.url));
				if (!alreadyOpen) {
					connect(task.url);
				}
			});
		}
		localStorage.removeItem('sockets_lock_queue');
		localStorage.removeItem('isReconnectLock');
	};

	useEffect(() => {
		if (!isNumber(tenant)) {
			setOpenLock(false);
			return;
		};
		const sockets_lock_queue_backup = localStorage.getItem('sockets_lock_queue');
		isReconnectLock.current = !nullValues.includes(localStorage.getItem('isReconnectLock'));
		if (!nullValues.includes(sockets_lock_queue_backup) && isReconnectLock) {
			initialize(sockets_lock_queue_backup);
		}
	}, [tenant]);

	useEffect(() => {
		if (!isNumber(tenant)) return;
		lockedSockedEmitter.on('socket_lock_connection', handleSocket);
		lockedSockedEmitter.on('massive_lock_socket_abort', handleMassiveAbort);
		return () => {
			lockedSockedEmitter.removeListener('socket_lock_connection', handleSocket);
			lockedSockedEmitter.removeListener('massive_lock_socket_abort', handleMassiveAbort);
		};
	}, [tenant]);

	const handleUnload = () => {
		if (sockets_lock_queue.current.length) {
			isReconnectLock.current = true;
			localStorage.setItem("sockets_lock_queue", JSON.stringify(sockets_lock_queue.current?.map(el => omit(el, ['callback']))));
			localStorage.setItem('isReconnectLock', isReconnectLock.current);
		}
	};

	const handleOffline = () => {
		if (sockets_lock_queue.current.length) {
			isReconnectLock.current = true;
			localStorage.setItem('isReconnectLock', isReconnectLock.current);
			each(socket_lock_connections.current, (socket) => socket?.close());
			socket_lock_connections.current.length = 0;
		};
	};

	const handleOnline = () => {
		const sockets_lock_queue_backup = localStorage.getItem('sockets_lock_queue');
		isReconnectLock.current = !nullValues.includes(localStorage.getItem('isReconnectLock'));
		if (!nullValues.includes(sockets_lock_queue_backup) && isNumber(tenant) && isReconnectLock.current) {
			initialize(sockets_lock_queue_backup);
		}
	};

	const loadListeners = () => {
		window.addEventListener('beforeunload', handleUnload);
		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);
	};

	const unloadListeners = () => {
		window.removeEventListener('beforeunload', handleUnload);
		window.removeEventListener('offline', handleOffline);
		window.removeEventListener('online', handleOnline);
	};

	useEffect(() => {
		if (!isNumber(tenant)) return;
		loadListeners();
		return () => unloadListeners();
	}, [tenant]);

	const handleCancel = async (taskID, socket) => {
		try {
			const apiName = getActualTask(taskID)?.cancel_api;
			if (apiName) {
				await socketsApi.cancelTask(taskID, apiName);
			}
			disconnect(socket, taskID);
		} catch (error) {
			resolveError(error);
		}
	};

	const finishTask = (message, sound) => {
		setOpenLock(false);
		const msgType = message?.isError ? showNotificationError : showNotificationSuccess;
		dispatch(msgType(message));
		if (!isReconnectLock.current) {
			sound();
		}
	};

	const showError = (message) => finishTask({ title: t("task-error"), message: t(message), isError: true }, playError);
	const showSuccess = (message) => finishTask({ title: t("task-success"), message: t(message), }, playSuccess);

	const onReconnect = (socket) => {
		each(sockets_lock_queue.current, (task) => {
			if (socket.url.includes(task.url)) {
				const body = {
					"type": "task_status_message",
					"task": task.taskID,
				};
				if (task.percentage < 1) {
					setOpenLock(true);
					socket.send(JSON.stringify(body));
				}
			}
		});
	};

	const onSocketError = (socket) => {
		each(sockets_lock_queue.current, (task) => {
			if (socket.url.includes(task.url)) {
				disconnect(socket, task.taskID);
			}
		});
		showError(t("socket-conection-error"));
	};

	const onFinalMessage = (message, response) => {
		const finalMessage = message?.filename ? t('success') : message.message;
		if (response) {
			return showSuccess(finalMessage);
		}
		showError(t("download-error"));
	};

	const onFinish = async (message_obj) => {
		const finalMessage = valueOrOption(message_obj, {});
		const { task, /* message, */ filename } = finalMessage;
		const socketTask = sockets_lock_queue.current?.find(queue => queue?.taskID === task);
		const callback = socketDictionary[socketTask?.socketName]?.callbackWS ?? socketTask?.callback;

		if (isFunction(callback)) {
			const response = await callback(task, filename);
			return onFinalMessage(message_obj, response);
		}
		return onFinalMessage(message_obj, true);
	};

	const onUpdateMessage = (message_obj, socket) => {
		const { step, message, task, percentage } = valueOrOption(message_obj, {});
		const actTask = getActualTask(task);
		if (actTask) actTask.percentage = percentage;
		setMessages({
			taskId: task,
			tasks: sockets_lock_queue.current,
			percentage: percentage,
			onCancel: (id) => handleCancel(id, socket),
			message: buildMessage(valueOrOption(step, message)),
			progress: percentage,
			isLoading: true,
		});
	};

	const onMessageError = (message) => showError(valueOrOption(t(message.message), 'failure'));

	const onInitMessage = (message) => {
		setOpenLock(true);
		setMessages({
			taskId: message.task,
			tasks: null,
			percentage: message.percentage * 0.01,
			message: "",
			progress: message.percentage * 0.01,
			isLoading: true,
		});
	};

	const handleFailureMsg = (task, details, message) => {
		if (!isReconnectLock.current) {
			playError();

		};
		const isUncontroled = size(details) && details?.detail_code !== "unespected-task-error";

		if (isUncontroled) {
			setOpenLock(false);
			return dispatch(showControlledError({ fields: details }));
		}

		return onMessageError(message);
	};

	const handleSocketMessage = async (message, socket) => {
		const { status, code, task, details } = valueOrOption(message, {});
		const { dimissed } = getActualTask(task);
		const hasStatus = [...errorStatus, 'SUCCESS'].includes(status);
		const errorCode = errorCodes.includes(code);

		if (!hasStatus && !errorCode && !dimissed) {
			onInitMessage(message);
		}

		if (errorStatus.includes(status) || errorCode) {
			handleFailureMsg(task, details, message);
		}

		if (!errorStatus.includes(status) && !dimissed) {
			onUpdateMessage(message, socket);
		}

		if (status === 'SUCCESS') {
			await onFinish(message);
		}

		if (hasStatus || errorCode) {
			disconnect(socket, task);
		}
	};

	const connect = (path) => {
		const url = getApiUrl(path);
		socket_lock_connections.current.push(new WebSocket(url));

		each(socket_lock_connections.current, (socket) => {
			socket.onopen = () => {
				onReconnect(socket);
			};

			socket.onmessage = (e) => {
				const message = JSON.parse(e.data);
				handleSocketMessage(message, socket);
			};

			socket.onerror = () => {
				onSocketError(socket);
			};
		});
	};

	const disconnect = (socket, task_id) => {
		setOpenLock(false);
		const existsTasks = some(sockets_lock_queue.current, (task) => socket.url.includes(task.url));
		if (!existsTasks) {
			socket?.close();
		}
		sockets_lock_queue.current = sockets_lock_queue.current.filter(task => task.taskID !== task_id);
		if (!sockets_lock_queue.current.length) {
			socket_lock_connections.current.length = 0;
			isReconnectLock.current = false;
		}
	};

	return {
		messages,
		openLock,
		connect,
		onFinish,
		disconnect,
		initialize,
		onReconnect,
		handleCancel,
		handleSocket,
		buildMessage,
		onSocketError,
		onInitMessage,
		definedSocket,
		getActualTask,
		onFinalMessage,
		onMessageError,
		onUpdateMessage,
		getSocketByName,
		getActiveSocket,
		massiveDisconnect,
		handleMassiveAbort,
		handleSocketMessage,
	};

};

export default useSocketsLocked;