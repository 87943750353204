import {
	isObject,
	isArray,
	each,
	size,
	isUndefined
} from 'lodash';
import { trim } from './validations/validationFilter';

export function parseFilter(filter, filterChanges) {
	filterChanges = filterChanges ?? {};
	if (!isObject(filter) && !isArray(filter)) { return {}; }

	if (isArray(filter)) {
		return getFilters({}, filter, filterChanges);
	}

	const filters = filter?.filters ?? filter;
	if (!size(filters)) { return {}; }

	if (isObject(filters) && !isArray(filters)) {
		return mapFilters({}, filters, filterChanges);
	}

	return getFilters({}, filters, filterChanges);;
}

export function getFilters(newFilters, filters, filterChanges) {

	let value;
	let arValue = [];
	let field = "";

	filters.forEach((filter) => {
		field = filter?.field;
		if (field) {
			value = !isUndefined(filter.trueValue) ? filter.trueValue : filter.value;
			field = filterChanges[field] ?? field;
			arValue.push(value);
			if (!isUndefined(newFilters[field])) {
				value = arValue;
			}

			if (!isUndefined(value) && value !== "" && value !== null) {
				newFilters[field] = value;
			}
		}
		if (filter.filters) {
			getFilters(newFilters, filter.filters, filterChanges);
		}
	});

	return newFilters;
}

export const mapFilters = (newFilters, filters, filterChanges) => {
	each(filters, (value, key) => {
		key = key.replace("|", "_");
		if (isArray(value)) {
			if (value.length > 0) {
				if (filterChanges["multiselectBy"]) {
					value = value
						.map((element) => { return element[filterChanges["multiselectBy"]] })
						.filter((v) => v !== undefined);
				}
				value = value.join(",");
			} else {
				value = undefined;
			}
		} else if (isObject(value)) {
			value = (!isUndefined(value.trueValue) ? value.trueValue : value.value);
		}

		if (!isUndefined(value) && value !== "" && value !== null) {
			newFilters[filterChanges[key] ?? key] = trim(value);
		}
	});

	return newFilters;
}

function parsePage(page) {
	if (!isObject(page)) { return {}; }

	return {
		limit: page.take,
		offset: page.skip
	}
}

/**
 *
 * @param {object} request
 * @param {object} filter
 * @param {object} page
 * @param {object} filterChanges : Se manda si algun filtro quiere ser cambiado de nombre
 * un ejemplo seria cuando en los filtros o formulario se maneja con uno diferente que recibe el back
 ** como este : { key: "profession_id", name: "profession", minimum_salaries: "year" }
 * en lugar de que se mande a buscar por key se mandara a buscar por profession_id y asi con cada 1
 * el formato es se pasa la llave utilizada y el valor es por el cual se va a cambiar al momento de filtrar
 */
export function parseRequest(request, filter, page, filterChanges) {
	return {
		...request,
		...parsePage(page),
		...parseFilter(filter, filterChanges)
	}
}

/**
 * Está función es olo para llmar al parse request, pero los datos se pueden ivocar con el formato json, cuando no necesariamente dse necesitan enviar todos los parametros
 * @param {*} params
 * @returns
 */
export const makeParseRequest = ({
	request, filter, page, filterChanges
}) => {
	return parseRequest(request, filter, page, filterChanges);
}