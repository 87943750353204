import { isArray, isString } from "lodash";
import {
    GoPayroll,
    LoadWorker,
    genericNavigate,
    verifyCalculate,
} from "./functions";
import {
    ACTIVATE_KEYS,
    CALCULATE_KEYS,
    NAVIGATE_KEYS,
    NUMBERS_DICTIONARY,
    PAYROLL_KEYS,
    SHOW_KEYS,
    WORKER_KEYS,
} from "./keys";
import { setSpeech } from "../../../../store/actions";

const MIN_COINCIDENCES = 2;
const NUMBERS = /\d+/;

function Commands(transcript, navigate, handleListen, dispatch, location, openWindow) {
    const dictionary = commandsDictionary(handleListen, dispatch, openWindow);
    let selection = null;
    if (transcript) {
        const speech = replaceNumbers(clearAccents(transcript.toLowerCase()));
        const test = [];
        for (let i = 0; i < dictionary.length; i++) {
            const item = dictionary[i];
            const coincidences = item?.coincidences ?? MIN_COINCIDENCES;
            const count = validateMatch(item.command, speech);
            test.push({ index: i, coincidences: count, min_coincidences: coincidences });
        }
        const result = test.reduce((prev, current) => {
            if (current.coincidences >= current.min_coincidences && current.coincidences > (prev ? prev.coincidences : -1)) {
                return current;
            } else {
                return prev;
            }
        }, null);
        const index = result?.index;
        const bestMatch = dictionary[index];
        selection = bestMatch?.callback;
        if (typeof selection === 'function') {
            return selection({ speech, navigate, location, dispatch });
        }
        dispatch(setSpeech("Disculpa, no puedo hacer eso"));
    } else {
        return false;
    }
};

export default Commands;

const commandsDictionary = (handleListen, dispatch, openWindow) => ([
    {
        command: [ACTIVATE_KEYS],
        callback: () => handleListen(),
        coincidences: 1,
    },
    {
        command: [CALCULATE_KEYS, PAYROLL_KEYS],
        callback: ({ speech, navigate }) => GoPayroll(speech, navigate),
    },
    {
        command: [SHOW_KEYS, NUMBERS],
        callback: ({ speech, navigate, location }) => LoadWorker(speech, navigate, location),
    },
    {
        command: [CALCULATE_KEYS, NUMBERS],
        callback: ({ speech, navigate, location }) => verifyCalculate(speech, navigate, location),
        coincidences: 1,
    },
    {
        command: [NAVIGATE_KEYS, WORKER_KEYS],
        callback: ({ speech, navigate, dispatch }) => genericNavigate(speech, navigate, dispatch, openWindow),
        coincidences: 1,
    },
    {
        command: ["hola"],
        callback: () => dispatch(setSpeech("Hola!!")),
        coincidences: 1,
    }
]);

export function clearAccents(string) {
    if (!string) return;
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const validateMatch = (commands, speech) => {
    let count = 0;

    const checkSubtree = (subtree) => {
        if (isArray(subtree)) {
            return subtree.some(word => {
                if (isString(word)) {
                    return speech.includes(word);
                }
                return word.test(speech);
            });
        }
        if (isString(subtree)) {
            return speech.includes(subtree);
        }
        return subtree.test(speech);
    };

    commands.forEach(subtree => {
        if (checkSubtree(subtree)) {
            count++;
        }
    });

    return count;
};

function replaceNumbers(speech) {
    const regex = new RegExp(`\\b(?:${Object.keys(NUMBERS_DICTIONARY).join('|')})\\b`, 'gi');
    return speech.replace(regex, match => NUMBERS_DICTIONARY[match.toLowerCase()] || match);
}