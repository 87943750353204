import React, {
	// useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import LottieUse from "../../../core/common/LottieUse";

/* styles */
import "./Styles.scss";
import { dashboardPath } from "../../../core/common/validate-tenant";
import { getCurrentPath } from "../../../core/@components/navigation/utilities";

const NoPermision = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentPath = getCurrentPath();

	return (
		<div className="restricted-container">
			<div className="context">
				<div className="lottie-container-403">
					<LottieUse
						loop
						icon={"403Error"}
						className="icon-403-error"
					/>
				</div>
				<h2 className="h2-403">{t("access-restricted.title2")}</h2>
				<span className="span-403">
					<p>
						{t("access-restricted.text1")}
					</p>
					<p>
						{t("access-restricted.text2")}
					</p>
				</span>

				{
					currentPath !== dashboardPath() &&
					<Button
						className={"mt-4"}
						variant="contained"
						size="large"
						onClick={() => navigate("/")}
					>
						{t("back-dashboad")}
					</Button>
				}
			</div>
		</div>
	);
};

export default NoPermision;