import React, { cloneElement } from 'react';
import { each, isObject, isUndefined } from 'lodash';
import { CustomTooltip } from '../Templates/cells';

export const forFormat = (format) => {
	let extraValues = {};
	each(format.split("|"), field => {
		extraValues[field] = "";
	});
	return extraValues;
};

export const itemRender = (li, itemProps, format) => {
	const item = itemProps?.dataItem;
	if (!item) {
		return;
	}
	const label = formatLabel(format, item);
	return cloneElement(li, li.props, label);
};

export const valueRender = (element, item, format, valueField) => {
	item = item?.dataItem || item;
	const value = item?.[valueField];
	if (value === undefined || value === null || value === "") {
		return element;
	}
	const label = formatLabel(format, item);
	return cloneElement(element, { ...element.props }, (
		<CustomTooltip title={label || ""} className="dd-internal-elem">
			<span className="dd-internal-elem"> {label} </span>
		</CustomTooltip>
	));
};

export const formatLabel = (format, item) => {
	if (!isObject(item)) {
		return item;
	}

	let label = "";

	each(format.split("|"), field => {
		let auxLabel = null;
		if (field.includes(".")) {
			auxLabel = item;
			each(field.split("."), splited => {
				auxLabel = auxLabel?.[splited];
			});
		}

		auxLabel = auxLabel ?? item[field] ?? "";
		if (!isUndefined(auxLabel) && auxLabel !== "") {
			label += `${label === "" ? "" : " - "}${auxLabel}`;
		}
	});

	return label;
};