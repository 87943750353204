import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import Locked from "./App/components/Locked";
import NoConnection from './App/layout/NoConnection';
import App from './App/index';

import * as serviceWorker from './serviceWorker';
import { store } from './store';
import './core/language/i18n';

import "./assets/scss/abrhil/index.scss";
import NotificationSocket from './App/components/Sockets/NotificationSocket';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<Locked />
		<NoConnection />
		<NotificationSocket />
		<App />
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
