// --language
import i18next from "i18next";
const { t } = i18next;
import * as yup from "yup";
import { currentTenant } from "../../../common/validate-tenant";
import { stringValidation } from "../../../common/validations/stringValidation";
import arrayValidation from "../../../common/validations/array";
import { moperConstructor } from "../components/Details/components/models";
import { toUpper } from "lodash";

export const basicData = {
    tenant: currentTenant(),
    name: "",
    description: "",
    is_active: true,
    permissions: []
}

export const ValidationSchema = yup.object().shape({
    name: stringValidation({ required: true, max: 200 }),
    description: stringValidation({ required: false, max: 500 }),
    permissions: arrayValidation({ required: true, min: 1 }),
});

export const mopersTabs = {
    1: { id: 1, key: 'moper_absenteeism', label: t("absenteeisms"), type: "absenteeism" },
    2: { id: 1, key: 'moper_absenteeism', label: t("absenteeisms"), type: "absenteeism" },
    3: { id: 3, key: 'moper_absenteeism', label: t("vacation"), type: "vacation" },
    4: { id: 4, key: 'moper_clock', label: t("assist-adjustment") },
    5: { id: 5, key: 'complete_turn', label: t("complete-turn") },
    6: { id: 6, key: 'txt', label: t("overtime") },
    7: { id: 7, key: 'contracts', label: t("Contrataciones") },
    8: { id: 8, key: 'moper_concept', label: t("special-concepts") },
    9: { id: 9, key: 'promotions', label: t("Promociones") },
}

export const getStructure = item => item?.enterprise_structure_organization?.enterprise_structure;

export const keyName = (item, upper = true) => {
    const str = `${item?.key} - ${item?.name}`;
    if (!item) return null;
    if (upper) return toUpper(str);
    return str;
};

export const moperGroups = {
    1: { title: 'Ausentismos', catalogs: '1,2,3' },
    2: { title: 'Asistencia', catalogs: '4,5' },
    3: { title: 'Tiempo', catalogs: '6' },
    4: { title: 'Contratacion', catalogs: '7' },
    5: { title: 'Conceptos especiales', catalogs: '8' },
    6: { title: 'Promociones', catalogs: '9' },
    7: { title: 'Firmar lista', catalogs: '10' },
};

export const getTotals = mopers => {
    if (!mopers) return;
    const values = { total_hours: ['minutes_requested'], total_amount: ['amount', 'total_amount'] };
    const foundMoper = mopers.map(el => moperConstructor(el)).map(el => el.exactMoper);
    return buildTotals(foundMoper, values);
};

const buildTotals = (mopers, fields) => {
    return Object.keys(fields).reduce((result, key) => {
        result[key] = mopers.reduce((total, item) => {
            return total + fields[key].reduce((sum, prop) => {
                return sum + (parseFloat(item[prop]) || 0);
            }, 0);
        }, 0);
        return result;
    }, {});
}