import React, { lazy } from "react";

const KioskUsers = lazy(() => import('../../../../modules/KioskUsers'));
const KioskPermissions = lazy(() => import('../../../../modules/KioskPermissions'));
const KioskAccessControl = lazy(() => import('../../../../modules/KioskAccessControl'));
const KioskAssignStructures = lazy(() => import('../../../../modules/KioskAssignStructure'));

const kioskoItems = [
    {
        id: 'kiosk@kiosk-users',
        type: 'item',
        icon: 'users',
        package_permissions: ["kiosk"],
        componentElm: <KioskUsers />
    },
    {
        id: 'kiosk@permissions',
        type: 'item',
        icon: 'permissions',
        package_permissions: ["kiosk"],
        componentElm: <KioskPermissions />
    },
    {
        id: 'kiosk@kiosk-access-control',
        type: 'item',
        icon: 'clock',
        package_permissions: ["kiosk"],
        componentElm: <KioskAccessControl />
    },
    {
        id: 'kiosk@assign-to-structures',
        type: 'item',
        icon: 'asignestructure',
        package_permissions: ["kiosk"],
        componentElm: <KioskAssignStructures />,
    },
];

export default kioskoItems;