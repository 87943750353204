import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form';
import { faTrash, faUpDownLeftRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//kendo and MUI
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
//components
import FormInput from '../../../../@components/form/Field';
import { ModuleContext } from '../../util';

function List(props) {
  const { style, attributes, forwardRef, isActive, dataItem } = props;
  const { control, setValue } = useContext(ModuleContext);
  const signatures = useWatch({ control, name: `signatures` })
  const findIndexParent = signatures.findIndex(f => f.order === dataItem.idParent);
  const findIndexChill = signatures[findIndexParent].signature.findIndex(f => f.id === dataItem.id);

  function removeList() {
    const list = signatures[findIndexParent].signature
    list.splice(findIndexChill, 1)
    setValue(`signatures[${findIndexParent}].signature`, list)
  }

  return (
    <div
      className="m-1"
      ref={forwardRef}
      {...attributes}
      style={{
        ...style,
        border: isActive ? "1px dashed black" : 0,
      }}
    >
      {/* {JSON.stringify(signatures[findIndexParent].signature[findIndexChill]?.name)} */}
      <Grid container >
        <Grid item xs={10}>
          <FormInput
            uppercase={false}
            control={control}
            fieldInput="textfield"
            name={`signatures[${findIndexParent}].signature[${findIndexChill}].name`}
          />
        </Grid>
        <Grid item xs={1} className="pt-2">
          <FontAwesomeIcon icon={faUpDownLeftRight} style={{ fontSize: "14px", paddingLeft: "2px" }} />
        </Grid>
        <Grid item xs={1} >
          <IconButton aria-label="del" style={{ fontSize: "17px", padding: "12px 3px", color: "var(--red)" }} onClick={removeList}>
            <FontAwesomeIcon icon={faTrash} size="xs" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default List