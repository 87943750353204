import { useEffect, useRef, useState } from 'react';
import Avatar2 from '../../../../../assets/images/user/avatar-2.jpg';
import { store } from '../../../../../store';
import { valueOrOption } from '../../../../common/GeneralUtilities';
import { logout } from '../../../../common/auth';
import { currentUserInfo } from '../../../../common/validate-tenant';

export const getUsername = () => {
    const userData = store.getState().userConfigs?.user_info;
    if (userData?.internal_user) {
        const worker = userData?.worker;
        return `${worker?.name} ${worker?.first_surname}`;
    }
    return valueOrOption(userData?.external_name, userData?.email);
};

export const toTitleCase = str => str?.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());

export const useController = () => {

    const anchor = useRef(null);
    const [open, setOpen] = useState(false);
    const userInfo = currentUserInfo()?.user_info;
    const username = getUsername();
    const systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    const initialTheme = localStorage.getItem('theme') || (systemDark.matches ? "dark" : "light");
    const photo = valueOrOption(userInfo?.photo, Avatar2);
    const jobPosition = userInfo?.user_info?.worker?.enterprise_structure_organization?.enterprise_structure?.job_position?.name;

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const handleLogOut = () => logout();

    const changeTheme = (theme) => {
        localStorage.setItem('theme', theme);
        window.dispatchEvent(new Event("storage"));
        document.querySelector("body").className = theme;
    };

    useEffect(() => {
        const handleChange = (event) => changeTheme(event.matches ? "dark" : "light");
        systemDark.addEventListener('change', handleChange);
        document.querySelector("body").className = initialTheme;
        localStorage.setItem('theme', initialTheme);

        return () => systemDark.removeEventListener('change', handleChange);
    }, []);

    return {
        anchor,
        open,
        userInfo,
        username: toTitleCase(username),
        jobPosition,
        photo,
        onOpen,
        onClose,
        handleLogOut,
        changeTheme,
    };
};


export const bottomMenuProps = {
    className: "user-menu-options",
    PaperProps: {
        elevation: 7,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px var(--shadow,rgba(0,0,0,0.32)) )',
            ml: `10px`,
            mb: "30px",
            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translate(-50%, -50%) rotate(45deg)',
                zIndex: 0,
            },
        },
    },
    transformOrigin: { horizontal: 'left', vertical: 'top' },
    anchorOrigin: { horizontal: 'right', vertical: 'top' },
};