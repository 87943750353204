import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import FormInput from "../../../../../@components/form/Field";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { each } from "lodash";
import { useDialogContext } from "../../../hooks/DialogController";
import "../../../styles/style.scss";
import { GridWrapper } from "./Tenants";

function CancelMopers() {

  const {
    control,
    getValues,
  } = useDialogContext();

  const cancel_mopers = getValues('cancel_mopers');

  return (
    <GridWrapper>
      <CheckAll name='cancel_mopers' />
      {cancel_mopers?.map((el, index) => (
        <div key={`cancel-moper-${el.key}-${el.id}`}>
          <CustomItem
            item={el}
            itemIndex={index}
            control={control}
          />
        </div>
      ))}
    </GridWrapper>
  );
};

export default CancelMopers;

const CustomItem = ({
  item,
  itemIndex,
  control,
}) => {

  CustomItem.propTypes = {
    item: PropTypes.object,
    itemIndex: PropTypes.number,
    control: PropTypes.object,
  };

  const valueField = `cancel_mopers[${itemIndex}].has_perm`;

  return (
    <div className="cancel-moper-item">
      <FormInput
        fieldInput="switch"
        control={control}
        label={item?.description}
        name={valueField}
      />
    </div>
  );
};

export const CheckAll = ({ name }) => {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    getValues,
  } = useDialogContext();

  const checkName = `check_all_${name}`;
  const check_all = useWatch({ control, name: checkName });
  const checked = useWatch({ control, name: name });

  useEffect(() => {
    const all = checked?.every(el => el?.has_perm);
    setValue(checkName, all);
  }, [checked])

  const onCheckAll = (e) => {
    const cancelMopers = getValues(name);
    each(cancelMopers, (f, i) => {
      setValue(`${name}[${i}].has_perm`, e.value);
    });
    return e;
  }

  return (
    <div className="cancel-moper-item">
      <FormInput
        fieldInput="switch"
        control={control}
        label={t(check_all ? "uncheck-all" : "check-all")}
        name={checkName}
        onChange={onCheckAll}
      />
    </div>
  );
};

CheckAll.propTypes = {
  name: PropTypes.string,
};
