import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { addDays, format, isToday, parse, subDays } from "date-fns";
import { each, isDate, isEqual, isFunction, isNumber, isString, last, omit, pick, size } from "lodash";
import { useForm } from "react-hook-form";
import { DragAndDrop } from "@progress/kendo-react-common";
import { uid } from "uid";
import { mopersClockWorkerApi, mopersComments } from "../../../../../../../../../services/mopers";
import { resolveError } from "../../../../../../../../../common/resolve-error";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stringValidation } from "../../../../../../../../../common/validations/stringValidation";
import arrayValidation from "../../../../../../../../../common/validations/array";
import { showNotificationWarning } from "../../../../../../../../../../store/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { hasValue, parseDatetime } from "../../../../../../../../../common/GeneralUtilities";
import { implementService } from "../../../../../../../../../services/implemet-service";
import { successUpdated } from "../../../../../../../../../common/notification-messages";
import { showSuccessNotification } from "../../../../../../../../../../App/components/Notifications";
import { copyObject } from "../../../../../../../../User/utils/utilities";
import { useModalsContext } from "../../../../hooks/useModals";

export const MODAL_KEY = 5;

const validationSchema = () => yupResolver(yup.object().shape({
    checks: arrayValidation({ required: true }).of(
        yup.object().shape({
            check_in_date: stringValidation({ required: false }).when('check_in_time', {
                is: (value) => value,
                then: () => stringValidation({ required: true }),
            }),
            check_in_time: stringValidation({ required: false }).when('check_in_date', {
                is: (value) => value,
                then: () => stringValidation({ required: true }),
            }),
            check_out_date: stringValidation({ required: false }).when('check_out_time', {
                is: (value) => value,
                then: () => stringValidation({ required: true }),
            }),
            check_out_time: stringValidation({ required: false }).when('check_out_date', {
                is: (value) => value,
                then: () => stringValidation({ required: true }),
            }),
        }, [['check_in_date', 'check_in_time'], ['check_out_date', 'check_out_time']]),
    ),
    comment: stringValidation({ required: true, min: 10, max: 100 }).when('require_comment', {
        is: false,
        then: () => stringValidation({ required: false }).nullable(),
    }),
}));

function useAssistSetting({
    modalValues,
    handleClose,
    handleRefresh,
    selected,
    worker,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { deleteCheckPerm, createCheckPerm } = useModalsContext();
    const fieldsPickIn = ['check_in_date', 'check_in_time'];
    const fieldsPickOut = ['check_out_date', 'check_out_time'];

    const { value: open } = modalValues[MODAL_KEY];

    const selectedItem = copyObject(selected);
    const fCheck = selectedItem?.origin_day ? parse(selectedItem?.origin_day, 'yyyy-MM-dd', new Date()) : null;
    const selectedIsToday = isToday(fCheck);

    const defaultCheck = (customDate) => {
        return {
            unique_id: uid(),
            type_check_in: 1,
            type_check_out: 1,
            type_movement_check_in: 1,
            type_movement_check_out: 2,
            check_in_date: (customDate ?? selectedItem?.origin_day)?.split('-')?.toReversed()?.join('/'),
            check_out_date: !selectedIsToday ? (customDate ?? selectedItem?.origin_day)?.split('-')?.toReversed()?.join('/') : null,
            check_in_time: null,
            check_out_time: null
        };
    }

    const initValues = { checks: [defaultCheck()], comment: null, require_comment: false };

    const {
        control,
        reset,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        defaultValues: initValues,
        resolver: validationSchema()
    });

    const [pressed, setPressed] = useState(false);
    const [dragged, setDragged] = useState(false);
    const [comments, setComments] = useState([]);
    const [checksData, setChecksData] = useState({});
    const dragElement = useRef(null);
    const initialState = useRef([]);

    useEffect(() => {
        if (!open) {
            setDragged(false);
            setPressed(false);
            setChecksData({});
            reset({ ...initValues });
            dragElement.current = null;
            initialState.current = [];
            return;
        };
        if (!size(selectedItem?.checks)) {
            initialState.current = [];
            reset({ ...initValues });
            initChecksDatasources(null, null, 0);
            return;
        };

        const itemChecks = [...(selectedItem?.checks ?? [])];
        initialState.current = initializeValues(itemChecks) ?? [];
        const initChecks = initDefaults(initializeValues(itemChecks) ?? []);
        initChecksDatasources(itemChecks);
        setValue('checks', initChecks);
    }, [open])

    useEffect(() => {
        if (!open) return;
        getCommentList();
    }, [open])

    const getCommentList = async () => {
        try {
            const response = await mopersComments.get({ tree: "" });
            setComments(response.map(item => item.comment));
        } catch (error) {
            resolveError(error);
        }
    };

    const sortedDates = dates => dates?.sort((a, b) => {
        const [dayA, monthA, yearA] = a.split('/').map(Number);
        const [dayB, monthB, yearB] = b.split('/').map(Number);
        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);
        return dateA - dateB;
    });

    const initChecksDatasources = (checks, date, index) => {
        let newCheckData = {};
        if (checks) {
            each(checks, (el, ind) => {
                const checkIn = el.check_in ? initCheck(el.check_in, false, 'yyyy-MM-dd') : null;
                const checkOut = el.check_out ? initCheck(el.check_out, false, 'yyyy-MM-dd') : null;
                newCheckData[ind] = generateDates(checkOut ?? checkIn);
            });
        }
        if (isNumber(index)) {
            const checkData = generateDates(date);
            newCheckData = { [index]: checkData };
        }
        setChecksData(prev => ({ ...prev, ...newCheckData }));
    }

    const generateDates = (date) => {
        const splitOrigin = (date ?? selectedItem?.origin_day ?? format(new Date(), 'yyyy-MM-dd'))?.split('-');
        const originDate = new Date(splitOrigin?.join('/'));
        const firstDate = splitOrigin?.toReversed()?.join('/');
        const originalData = ['', firstDate];
        const checkIns = sortedDates(originalData.concat(format(subDays(originDate, 1), 'dd/MM/yyyy')));
        let checkOuts = originalData;
        if (!selectedIsToday) {
            checkOuts = sortedDates(checkOuts.concat(format(addDays(originDate, 1), 'dd/MM/yyyy')));
        }
        return { check_ins: checkIns, check_outs: checkOuts };
    }

    const initializeValues = (checks = []) => {
        return [...checks].map(el => {
            const newCheck = {};
            newCheck.item_id = el.id;
            newCheck.unique_id = uid();
            newCheck.type_movement_check_in = 1;
            newCheck.type_movement_check_out = 2;
            newCheck.type_check_in = 2;
            newCheck.type_check_out = 2;
            newCheck.original_check_in = el.id;
            newCheck.original_check_out = el.id;
            newCheck.initial_check_in = el.check_in ? el.id : null;
            newCheck.initial_check_out = el.check_out ? el.id : null;
            newCheck.check_in_date = initCheck(el.check_in);
            newCheck.check_in_time = initCheck(el.check_in, true);
            newCheck.check_out_date = initCheck(el.check_out);
            newCheck.check_out_time = initCheck(el.check_out, true);
            newCheck.disabled_in = hasValue(newCheck.check_in_time);
            newCheck.disabled_out = hasValue(newCheck.check_out_time);
            return newCheck;
        })
    }

    const initCheck = (value, onlyHour, dateFormat = "dd/MM/yyyy") => {
        const stringFormat = !onlyHour ? dateFormat : 'HH:mm';
        if (isDate(value)) {
            return parseDatetime(value, stringFormat);
        }
        if (isString(value)) {
            const date = parseDatetime(value, "asDate", true);
            return initCheck(date, onlyHour, dateFormat);
        }
        return null;
    }

    const initDefaults = (data = []) => {
        return [...data].map(el => {
            let checkOutInit = el.check_in_date;
            let checkInInit = el.check_out_date;
            if (isTimeGreater(el.check_in_time, '20:00') && el.check_in_date) {
                const checkInDate = new Date(el.check_in_date.split('/').toReversed().join('/'));
                const iniDate = format(addDays(checkInDate, 1), 'dd/MM/yyyy');
                checkOutInit = iniDate;
            }
            if (!isTimeGreater(el.check_out_time, '10:00') && el.check_out_date) {
                const checkOutDate = new Date(el.check_out_date.split('/').toReversed().join('/'));
                const fDate = format(subDays(checkOutDate, 1), 'dd/MM/yyyy');
                checkInInit = fDate;
            }
            if (!el.check_out_date) {
                el.check_out_date = checkOutInit;
            }
            if (!el.check_in_date) {
                el.check_in_date = checkInInit;
            }
            return el;
        });
    }

    const isTimeGreater = (time, referenceTime) => {
        return time >= referenceTime;
    };

    const buildPayload = data => data.map(item => {
        const newCheck = {};
        newCheck.original_check = item.item_id;
        const hasCheckIn = item.check_in_date && item.check_in_time;
        const hasCheckOut = item.check_out_date && item.check_out_time;
        if (!item.delete_check_in) {
            newCheck.check_in = {
                type_initial: item.type_movement_check_in,
                check_initial: item.initial_check_in,
                check_time: hasCheckIn ? `${item.check_in_date?.split('/')?.toReversed()?.join('-')} ${item.check_in_time}` : null,
                type_movement: item.type_check_in,
            };
        }
        if (!item.delete_check_out) {
            newCheck.check_out = {
                type_initial: item.type_movement_check_out,
                check_initial: item.initial_check_out,
                check_time: hasCheckOut ? `${item.check_out_date?.split('/')?.toReversed()?.join('-')} ${item.check_out_time}` : null,
                type_movement: item.type_check_out,
            };
        }
        if (newCheck.check_in || newCheck.check_out) {
            return newCheck;
        }
    }).filter(el => el);

    const validateSubmit = data => {
        const filteredData = data.filter(el => el.check_in_date || el.check_out_date);
        const sameSize = size(filteredData) === size(initialState.current);
        const allEqual = filteredData.every(el => !!initialState.current.find(li => isEqual(omit(el, ['unique_id']), omit(li, ['unique_id']))));
        return sameSize && allEqual;
    }

    const validation = (obj1, obj2) => {
        if (obj2) return obj1 !== obj2;
        return !!obj1;
    };

    const validateEmpty = (item, type) => {
        const hasCheckIn = item?.check_in_date && item?.check_in_time;
        const hasCheckOut = item?.check_out_date && item?.check_out_time;
        if (type === 1) {
            return !(!hasCheckIn && !item?.initial_check_in);
        }
        return !(!hasCheckOut && !item?.initial_check_out);
    }

    const markEqual = (arr1, arr2) => {
        return arr1.map((obj1) => {
            const foundObj = arr2?.find(el => el?.item_id === obj1.item_id);
            let deleteValueIn = true;
            let deleteValueOut = true;
            for (const key in pick(obj1, fieldsPickIn.concat(fieldsPickOut))) {
                const isCheckIn = key.includes('in');
                const obj2 = pick(foundObj, isCheckIn ? fieldsPickIn : fieldsPickOut);
                if (validation(obj1[key], obj2[key]) && isCheckIn && validateEmpty(obj1, 1)) {
                    deleteValueIn = false;
                }
                if (validation(obj1[key], obj2[key]) && !isCheckIn && validateEmpty(obj1, 2)) {
                    deleteValueOut = false;
                }
            }
            obj1['delete_check_in'] = deleteValueIn;
            obj1['delete_check_out'] = deleteValueOut;
            return obj1;
        });
    };

    const checksPayload = (checks) => {
        const fChecks = [...checks];
        const markedChecks = markEqual(fChecks, initialState.current);
        return buildPayload(markedChecks);
    }

    const onSubmit = data => {
        if (validateSubmit(data.checks)) {
            dispatch(showNotificationWarning({
                maxWidth: 'sm',
                title: t('warning-general-title'),
                message: 'Información inicial igual a la información final',
                description: 'Las checadas que intenta guardar dan como resultado las checadas iniciales',
            }));
            return;
        }
        const newData = JSON.parse(JSON.stringify(data));
        newData.checks = checksPayload(newData.checks);
        sendServer(omit(newData, ['require_comment']));
    }

    const sendServer = async (data) => implementService(
        mopersClockWorkerApi.saveChecks({
            ...data,
            origin_day: selectedItem.origin_day,
            worker: worker?.id ?? worker,
        }),
        onSuccessSave,
        (err) => {
            delete err.response.data.data;
            delete err.response.data.datas_failed;
            resolveError(err);
        }
    );

    const onSuccessSave = () => {
        handleClose(MODAL_KEY, reset);
        if (isFunction(handleRefresh)) {
            handleRefresh(false, selectedItem);
        }
        showSuccessNotification(successUpdated());
    }

    const handleDeleteCheck = (item, type) => {
        const disabled = item?.[type === 1 ? 'disabled_in' : 'disabled_out'];
        if (!deleteCheckPerm && disabled) {
            dispatch(showNotificationWarning({
                message: 'Sin permisos de eliminar checadas',
                description: 'Su usuario no cuenta con permisos para eliminar checadas'
            }));
            return;
        }
        const newFields = [...getValues('checks')];
        const currentIndex = newFields.findIndex(el => el.unique_id === item.unique_id);
        const currentItem = newFields[currentIndex];
        if (type === 1) {
            currentItem.disabled_in = false;
            currentItem.check_in_date = null;
            currentItem.check_in_time = null;
            currentItem.type_check_in = 2;
        }

        if (type === 2) {
            currentItem.disabled_out = false;
            currentItem.check_out_date = null;
            currentItem.check_out_time = null;
            currentItem.type_check_out = 2;
        }
        dragElement.current = null;
        setValue('checks', newFields, { shouldValidate: true });
    }

    const onDrop = (finalItem, type) => {
        const newFields = [...getValues('checks')];
        const finalIndex = newFields.findIndex(el => el.unique_id === finalItem.unique_id);
        const initialIndex = newFields.findIndex(el => el.unique_id === dragElement.current.item.unique_id);
        if (initialIndex === finalIndex && type === dragElement.current?.type) return;

        const finalTemp = { ...newFields[finalIndex] };
        const initialTemp = { ...newFields[initialIndex] };

        const initialIsCheckIn = dragElement.current.type === 1;
        const finalIsCheckIn = type === 1;

        const finalFieldDate = finalIsCheckIn ? 'check_in_date' : 'check_out_date';
        const finalFieldTime = finalIsCheckIn ? 'check_in_time' : 'check_out_time';
        const finalDisabled = finalIsCheckIn ? 'disabled_in' : 'disabled_out';
        const finalFinal = finalIsCheckIn ? 'initial_check_in' : 'initial_check_out';
        const finalTypeMov = finalIsCheckIn ? 'type_movement_check_in' : 'type_movement_check_out';
        const finalType = finalIsCheckIn ? 'type_check_in' : 'type_check_out';

        const initialFieldDate = initialIsCheckIn ? 'check_in_date' : 'check_out_date';
        const initialFieldTime = initialIsCheckIn ? 'check_in_time' : 'check_out_time';
        const initialDisabled = initialIsCheckIn ? 'disabled_in' : 'disabled_out';
        const initialOriginal = initialIsCheckIn ? 'original_check_in' : 'original_check_out';
        const initialFinal = initialIsCheckIn ? 'initial_check_in' : 'initial_check_out';
        const initialType = initialIsCheckIn ? 'type_check_in' : 'type_check_out';
        const initialTypeMov = initialIsCheckIn ? 'type_movement_check_in' : 'type_movement_check_out';

        newFields[initialIndex][initialFieldDate] = finalTemp[finalFieldDate];
        newFields[initialIndex][initialFieldTime] = finalTemp[finalFieldTime];
        newFields[initialIndex][initialDisabled] = finalTemp[finalDisabled];
        newFields[initialIndex][initialType] = !newFields[initialIndex][initialFieldDate] ? finalTemp[finalType] : initialTemp[initialType];

        newFields[finalIndex][finalFieldDate] = initialTemp[initialFieldDate];
        newFields[finalIndex][finalFieldTime] = initialTemp[initialFieldTime];
        newFields[finalIndex][finalDisabled] = initialTemp[initialDisabled];
        newFields[finalIndex][finalFinal] = initialTemp[initialFinal] ?? initialTemp[initialOriginal];
        newFields[finalIndex][finalTypeMov] = initialTemp[initialTypeMov];
        newFields[finalIndex][finalType] = initialTemp[initialType]

        dragElement.current = null;
        setValue('checks', newFields, { shouldValidate: true });
    }

    const onDropDelete = () => handleDeleteCheck(dragElement.current.item, dragElement.current.type);

    const onNew = () => {
        if (!createCheckPerm) {
            dispatch(showNotificationWarning({
                message: 'Sin permisos para crear checadas',
                description: 'Su usuario no cuenta con permisos para crear checadas',
            }));
            return;
        }
        const currentChecks = getValues('checks');
        const newChecks = [...currentChecks, defaultCheck(last(currentChecks)?.check_out_date)];
        initChecksDatasources(newChecks);
        setValue('checks', newChecks);
    };

    const onHourChange = (value, checkItem, type, index, nameHour, field) => {
        const currentIn = checkItem?.check_in_date;
        const currentOut = checkItem?.check_out_date;
        const newChecks = [...(getValues('checks') ?? [])];
        const disabledOut = newChecks[index]?.disabled_out;
        const disabledIn = newChecks[index]?.disabled_in;
        newChecks[index][`type_${field}`] = 1;
        newChecks[index][nameHour] = value;
        if (type === 1 && value) {
            checksData[index].type_check_in = 1;
            if (isTimeGreater(value, '20:00') && currentIn && !selectedIsToday) {
                const checkInDate = new Date(currentIn?.split('/').toReversed().join('/'));
                const iniDate = format(addDays(checkInDate, 1), 'dd/MM/yyyy');
                if (checksData[index]?.check_outs?.includes(iniDate) && !disabledOut) newChecks[index].check_out_date = iniDate;
            } else if (!disabledOut && !selectedIsToday) {
                newChecks[index].check_out_date = newChecks?.[index]?.check_in_date;
            }
            if (isTimeGreater(value, checkItem?.check_out_time) && checkItem?.check_out_time && !disabledOut) {
                newChecks[index].check_out_time = null;
            }
        } else {
            checksData[index].type_check_in = 2;
        }
        if (type === 2 && value) {
            checksData[index].type_check_out = 1;
            if (!isTimeGreater(value, '10:00') && currentOut && !selectedIsToday) {
                const checkOutDate = new Date(currentOut.split('/').toReversed().join('/'));
                const fDate = format(subDays(checkOutDate, 1), 'dd/MM/yyyy');
                if (checksData[index]?.check_ins?.includes(fDate) && !disabledIn) newChecks[index].check_in_date = fDate;
            } else if (!disabledIn && !selectedIsToday) {
                newChecks[index].check_in_date = newChecks?.[index]?.check_out_date;
            }
        } else {
            checksData[index].type_check_out = 2;
        }
        setValue('checks', newChecks);
    };

    const onDateChange = (value, nameHour, nameDate, field, index) => {
        const newChecks = [...(getValues('checks') ?? [])];
        newChecks[index][`type_${field}`] = 1;
        newChecks[index][nameHour] = null;
        newChecks[index][nameDate] = value;
        const nextCheck = newChecks[index + 1];
        if (nextCheck) {
            const { check_in_date, check_out_date } = newChecks[index];
            const checkInF = check_in_date?.split('/')?.toReversed()?.join('-');
            const checkOutF = check_out_date?.split('/')?.toReversed()?.join('-');
            initChecksDatasources(null, checkOutF ?? checkInF, index);
        }
        setValue('checks', newChecks, { shouldValidate: true });
    }

    return {
        comments,
        dragged,
        dragElement,
        control,
        errors,
        open,
        worker,
        pressed,
        selected: selectedItem,
        checksData,
        handleClose,
        selectedIsToday,
        onHourChange,
        isTimeGreater,
        onDateChange,
        initChecksDatasources,
        setDragged,
        setValue,
        reset,
        onNew,
        onDrop,
        onSubmit,
        setPressed,
        handleSubmit,
        onDropDelete,
        handleDeleteCheck,
    };
};

const AssistSettingContext = createContext({});

export const useAssistSettingContext = () => useContext(AssistSettingContext);

export default function AssistSettingController({
    children,
    ...others
}) {
    const values = useAssistSetting(others);

    return (
        <DragAndDrop>
            <AssistSettingContext.Provider value={values}>
                {children}
            </AssistSettingContext.Provider>
        </DragAndDrop>
    );
};

AssistSettingController.propTypes = {
    children: PropTypes.any
};

export const rightClickMessage = [
    { value: 'Click derecho o arrastre para' },
    { value: 'eliminar', color: 'red' },
];

export const maintainDragMessage = [
    { value: 'Mantenga presionado el elemento para' },
    { value: 'activar el arrastre', color: 'primary' },
];