import React from "react";
import Grid from '@mui/material/Grid';
import useConfig from "./hooks/useConfig";
import { ModuleContext } from "./util";
import Configuration from "./Config";
import File from "./File";
import "./styles.scss";

function ConfigPayrollReport() {
  const providerValues = useConfig();
  return (
    <ModuleContext.Provider value={providerValues}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} id="config-payroll-report">
          <Configuration />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} id="config-payroll-report">
          <File />
        </Grid>
      </Grid>
    </ModuleContext.Provider>

  );
}

export default React.memo(ConfigPayrollReport);
