import React from "react";
//--components
import Card from "../../../../App/components/MainCard";
import { successCreated } from "../../../common/notification-messages";
import Filters from "./Components/Header/Filters";

/* Styles */
import Grid from '@mui/material/Grid2';
import GridController from "./hooks/useGrid";
import GridList from "./Components/Grid";
import { showSuccessNotification } from "../../../../App/components/Notifications";
import AddEdit from "./Components/AddEdit";
import "./styles/style.scss";
import "./styles/_dialog.scss";

const Container = () => {

	return (
		<GridController>
			<AddEdit />
			<Card principal className='company-container'>
				<Grid container spacing={1}>
					<Grid xs={12}>
						<Filters />
					</Grid>
					<Grid xs={12}>
						<GridList />
					</Grid>
				</Grid>
			</Card>
		</GridController>
	);
};

export default Container;

export const OnCreatedTenant = () => {
	showSuccessNotification(successCreated());
	setTimeout(() => {
		location.reload();
	}, 2000);
}