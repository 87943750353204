import { TENANT_INIT, TENANT_CURRENT } from './reducers/tenant';
// import { MENU_ACTIVE } from "./reducers/menu";
import { CONFIG } from './reducers/config';
import { MENU_STRUCTURE, USER_DASHBOARD } from './reducers/navigation-menu';
import { GROUP_CURRENT, GROUP_INIT } from './reducers/groups';
import { USER_CONFIGS } from './reducers/userConfigs';
import { WINDOW_PACKAGES } from './reducers/window_packages';

export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const SHOW_ADVANCE_FILTERS = 'SHOW_ADVANCE_FILTERS';
export const HIDE_ADVANCE_FILTERS = 'HIDE_ADVANCE_FILTERS';
export const SHOW_NOTIFICATION_SUCCESS = 'SHOW_NOTIFICATION_SUCCESS';
export const HIDE_NOTIFICATION_SUCCESS = 'HIDE_NOTIFICATION_SUCCESS';
export const SHOW_NOTIFICATION_WARNING = 'SHOW_NOTIFICATION_WARNING';
export const HIDE_NOTIFICATION_WARNING = 'HIDE_NOTIFICATION_WARNING';
export const SHOW_NOTIFICATION_ERROR = 'SHOW_NOTIFICATION_ERROR';
export const HIDE_NOTIFICATION_ERROR = 'HIDE_NOTIFICATION_ERROR';
export const SHOW_CONTROLLED_ERROR = 'SHOW_CONTROLLED_ERROR';
export const HIDE_CONTROLLED_ERROR = 'HIDE_CONTROLLED_ERROR';
export const SHOW_NOTIFICATION_REMOVE = 'SHOW_NOTIFICATION_REMOVE';
export const HIDE_NOTIFICATION_REMOVE = 'HIDE_NOTIFICATION_REMOVE';
export const SHOW_NOTIFICATION_IMPORT = 'SHOW_NOTIFICATION_IMPORT';
export const HIDE_NOTIFICATION_IMPORT = 'HIDE_NOTIFICATION_IMPORT';
export const SHOW_NOTIFICATION_DIALOG = 'SHOW_NOTIFICATION_DIALOG';
export const HIDE_NOTIFICATION_DIALOG = 'HIDE_NOTIFICATION_DIALOG';
export const SHOW_CUSTOM_NOTIFICATION_DIALOG = 'SHOW_CUSTOM_NOTIFICATION_DIALOG';
export const HIDE_CUSTOM_NOTIFICATION_DIALOG = 'HIDE_CUSTOM_NOTIFICATION_DIALOG';
export const SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG';
export const HIDE_CONFIRM_DIALOG = 'HIDE_CONFIRM_DIALOG';
export const SHOW_EXPORT_DIALOG = 'SHOW_EXPORT_DIALOG';
export const HIDE_EXPORT_DIALOG = 'HIDE_EXPORT_DIALOG';
export const LOCKED_WINDOW = 'LOCKED_WINDOW';
export const UNLOCKED_WINDOW = 'UNLOCKED_WINDOW';
export const LOCKED_WINDOW_MESSAGE = 'LOCKED_WINDOW_MESSAGE';
export const ADD_EDIT_USER = 'ADD_EDIT_USER';
export const ACTIVE = 'ACTIVE';
export const COUNTRY = 'COUNTRY';
export const THEME = 'THEME';
export const SPEECH = 'SPEECH';
export const SEARCH = 'SEARCH';
export const ICONS = 'ICONS';
export const ENABLE_FOOD_LIMIT = 'ENABLE_FOOD_LIMIT';
export const DISABLE_FOOD_LIMIT = 'DISABLE_FOOD_LIMIT';
export const LOAD_ATTENDANCE = 'LOAD_ATTENDANCE';
export const STAMP = 'STAMP';

export function configuration(payload) {
  return {
    type: CONFIG,
    payload
  };
}

export function setSearch(payload) {
  return {
    type: SEARCH,
    payload,
  };
}

export function setIcons(payload) {
  return {
    type: ICONS,
    payload,
  };
}

export function setSpeech(payload) {
  return {
    type: SPEECH,
    payload,
  };
}

export function menu(payload) {
  return {
    type: MENU_STRUCTURE,
    payload
  };
}

export function showExport(payload) {
  return {
    type: SHOW_EXPORT_DIALOG,
    payload
  };
}

export function hideExport() {
  return {
    type: HIDE_EXPORT_DIALOG
  };
}

export function showAdvanceFilters(payload) {
  return {
    type: SHOW_ADVANCE_FILTERS,
    payload
  };
}

export function hideAdvanceFilters() {
  return {
    type: HIDE_ADVANCE_FILTERS
  };
}

/**
 * Función para mostrar el diálogo de éxito en el sistema
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {?function} payload.onConfirm - La acción que sera aplicada al persionar aceptar, no es necesaria
 * @returns {object}
 */
export function showNotificationSuccess(payload) {
  return {
    type: SHOW_NOTIFICATION_SUCCESS,
    payload
  };
}

export function hideNotificationSuccess() {
  return {
    type: HIDE_NOTIFICATION_SUCCESS
  };
}

/**
 * Función para mostrar el diálogo de advertencia en el sistema
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {?function} payload.onConfirm - La acción que sera aplicada al persionar aceptar, no es necesaria
 * @returns {payload}
 */
export function showNotificationWarning(payload) {
  return {
    type: SHOW_NOTIFICATION_WARNING,
    payload
  };
}

export function hideNotificationWarning() {
  return {
    type: HIDE_NOTIFICATION_WARNING
  };
}

// Import notification
export function showNotificationImport(payload) {
  return {
    type: SHOW_NOTIFICATION_IMPORT,
    payload
  };
}

export function hideNotificationImport(payload) {
  return {
    type: HIDE_NOTIFICATION_IMPORT,
    payload
  };
}

/**
 * Función para mostrar el diálogo de error en el sistema
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {?function} payload.onConfirm - La acción que sera aplicada al persionar aceptar, ¡ NO ES NECESARIA !
 * @returns {payload}
 */
export function showNotificationError(payload) {
  return {
    type: SHOW_NOTIFICATION_ERROR,
    payload
  };
}

export function hideNotificationError() {
  return {
    type: HIDE_NOTIFICATION_ERROR
  };
}
export function showControlledError(payload) {
  return {
    type: SHOW_CONTROLLED_ERROR,
    payload
  };
}

export function hideControlledError() {
  return {
    type: HIDE_CONTROLLED_ERROR
  };
}

/**
 * Función para mostrar el diálogo de confirmación antes de eliminar un elemento
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {function} payload.onConfirm - La acción que sera aplicada al persionar aceptar
 * @param {?string} payload.confirmButtonText - Texto custom para el botón de aceptar
 * @param {?string} payload.cancelButtonText - Texto custom para el botón de cancelar
 * @returns {object}
 */
export function showNotificationRemove(payload) {
  return {
    type: SHOW_NOTIFICATION_REMOVE,
    payload
  };
}

export function hideNotificationRemove() {
  return {
    type: HIDE_NOTIFICATION_REMOVE
  };
}


/**
 *
 * @param {?"calculating","scanning","default"} lock_type
 * @param {function} callback
 */
export function lockedWindow(lock_type, callback) {
  return {
    type: LOCKED_WINDOW,
    lock_type,
    callback
  };
}


export const lockedWindowMessages = (message) => ({
  type: LOCKED_WINDOW_MESSAGE,
  message
});

export function unlockedWindow() {
  return {
    type: UNLOCKED_WINDOW
  };
}

export function tenantInit(payload) {
  return {
    type: TENANT_INIT,
    payload
  };
}

export function groupInit(payload) {
  return {
    type: GROUP_INIT,
    payload
  };
}

export function changeGroup(payload) {
  return {
    type: GROUP_CURRENT,
    payload
  };
}

export function setDashboard(payload) {
  return {
    type: USER_DASHBOARD,
    payload
  };
}

export function setUserConfigs(payload) {
  return {
    type: USER_CONFIGS,
    payload
  };
}

export function menuActive(payload) {
  return {
    type: ACTIVE,
    payload
  };
}

export function countryTenant(payload) {
  return {
    type: COUNTRY,
    payload
  };
}

export function changeTenant(payload) {
  return {
    type: TENANT_CURRENT,
    payload
  };
}

export function addEditUser(payload) {
  return {
    type: ADD_EDIT_USER,
    payload
  };
}


export function systemTheme(payload) {
  return {
    type: THEME,
    payload
  };
}

/**
 * Función para mostrar el diálogo de notificación general en el sistema
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {?function} payload.onConfirm - La acción que sera aplicada al persionar aceptar, ¡ NO ES NECESARIA !
 */
export function showNotificationDialog(payload) {
  return {
    type: SHOW_NOTIFICATION_DIALOG,
    payload
  };
}

export function hideNotificationDialog() {
  return {
    type: HIDE_NOTIFICATION_DIALOG
  };
}

/**
 * Función para mostrar el diálogo de notificación custom en el sistema
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado
 * @param {?string|JSX.Element} payload.icon -El icono que tendra el modal al ser mostrado
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {?function} payload.onConfirm - La acción que sera aplicada al persionar aceptar, ¡ NO ES NECESARIA !
 * @param {?function} payload.iconStyle - Estilo del icono a colocar, ¡ NO ES NECESARIA !
 */
export function showCustomNotificationDialog(payload) {
  return {
    type: SHOW_CUSTOM_NOTIFICATION_DIALOG,
    payload
  };
}

export function hideCustomNotificationDialog() {
  return {
    type: HIDE_CUSTOM_NOTIFICATION_DIALOG
  };
}

/**
 * Función para mostrar el diálogo de confirmación del sistema
 * @param {object} payload
 * @param {?string} payload.maxWidth - El tamaño máximo que tendra la ventana de notificación
 * @param {?string|JSX.Element} payload.title -El titulo que tendra el modal al ser mostrado, por default es ADVERTENCIA
 * @param {string|JSX.Element} payload.message - El mensaje que se intenta dar al usuario, puede ser un componente tambien
 * @param {?string|JSX.Element} payload.description - Una descripción mas detallada del mensaje por default está en blanco
 * @param {?"info"|"warning"} payload.type - El tipo de advertencia que se muestra, por default es un warning
 * @param {Function} payload.onConfirm - La acción que sera aplicada al persionar aceptar
 * @param {?Function} payload.onCancel - La acción que sera aplicada al precionar cancelar
 * @param {?string} payload.confirmButtonText - Texto custom para el botón de aceptar
 * @param {?string} payload.cancelButtonText - Texto custom para el botón de cancelar
 */
export function showConfirmNotification(payload) {
  return {
    type: SHOW_CONFIRM_DIALOG,
    payload
  };
}

export function hideConfirmNotification() {
  return {
    type: HIDE_CONFIRM_DIALOG
  };
}

export function enableFoodLimit() {
  return {
    type: ENABLE_FOOD_LIMIT
  };
}

export function disableFoodLimit() {
  return {
    type: DISABLE_FOOD_LIMIT
  };
}


export const loadAttendance = () => ({
  type: LOAD_ATTENDANCE,
})

export function set_window_packages(payload) {
  return {
    type: WINDOW_PACKAGES,
    payload
  };
}

export function stampType(payload) {
  return {
    type: STAMP,
    payload
  };
}
