import React, { lazy } from "react";

const Users = lazy(() => import('../../../../modules/User'));
const RolesPermission = lazy(() => import('../../../../modules/Roles'));
const OrgChart = lazy(() => import('../../../../modules/OrganizationChart'));

const usersRolesItems = [
    {
        id: 'user-and-roles@users',
        type: 'item',
        icon: 'users',
        componentElm: <Users />
    },
    {
        id: 'user-and-roles@roles-permissions',
        type: 'item',
        icon: 'roles-and-permissions',
        componentElm: <RolesPermission />
    },
    {
        id: 'user-and-roles@org-chart',
        type: 'item',
        icon: 'organization-chart',
        componentElm: <OrgChart />
    },
];

export default usersRolesItems;