import i18next from 'i18next';
import { es, enUS } from 'date-fns/locale';
import * as yup from "yup";
import { stringDateValidation } from "../../../../common/validations/dateValidation";
import { idValidations } from '../../../../common/validations/idValidations';
import { stringValidation } from '../../../../common/validations/stringValidation';
import { validateNumeric } from '../../../../common/validations/numeric';
import arrayValidation from "../../../../common/validations/array";
import { current_date } from '../../../../../App/components/Dates/utilities';
const { t } = i18next;

export const defaultValues = {
	'is_active': true,
}

export const formDefault = {
	open: false,
	item: {},
	type: ""
};

export const previewDefault = {
	open: false,
	item: {},
	type: "preview",
	request: {}
};

export const muenuItems = [
	// { id: 1, type: "versions", text: t("versions") },
	{ id: 2, type: "edit-configs", text: `${t("edit")} ${t("configuration")}` },
	{ id: 3, type: "edit-format", text: `${t("edit")} ${t("format")}` },
	{ id: 4, type: "draft-document", text: `${t("edit")} ${t("draft-content")}` },
	{ id: 6, type: "remove", text: t("remove") },
];

export const md = {
	fontSize: "60px",
	marginRight: "10px",
};

export const sx = {
	fontSize: "12px",
	marginRight: "10px",
};

export const optionIconsStyle = {
	fontSize: "20px",
	marginRight: "5px",
};

export const datefnOptions = {
	addSuffix: true,
	locale: t("systemLanguage") === "es" ? es : enUS
};

export const buttonProps = {
	size: "large",
	fillMode: "solid",
	rounded: "full",
	//themeColor: "info",
};

// Opciones del dropdownbutton "guardar"
export const saveOptions = [
	{ id: 1, filter: "production", name: t("production") },
	{ id: 2, filter: "draft", name: t("draft-content") },
];

export const impresionsKitItems = [
	{ id: 0, key: 0, name: t("subscribe") },
	{ id: 1, key: 1, name: t("re-entry") },
	{ id: 2, key: 2, name: t("unsubscribe") },
];

export const dataDocTypes = [
	{ id: 1, key: 1, name: t("formats.indefinite-contract"), classColor: 'green', lottie: 'indeterminate-contract' },
	{ id: 2, key: 2, name: t("formats.eventual-contract"), classColor: 'purple', lottie: 'eventual-contract' },
	{ id: 3, key: 3, name: t("formats.other-document"), classColor: 'orange', lottie: 'other-document' },
	{ id: 4, key: 4, name: t("formats.evaluation-contract"), classColor: 'blue', lottie: 'evaluation-contract' },
];

export const orderTypes = [
	{
		id: 1,
		key: 1,
		name: `${t("key")} - ${t("worker")}`,
		value: "key",
	},
	{
		id: 2,
		key: 2,
		name: t("structure-enterprise"),
		value: "enterprise_structure_organization",

	},
	// {
	// 	id: 3,
	// 	key: 3,
	// 	name: `${t("date-discharge")} - ${t("worker")}`,
	// },
];

export const printTypes = [
	{ id: 0, key: 0, name: t("Inicial"), value: "I" },
	{ id: 1, key: 1, name: t("Subsecuente"), value: "S" }
]

export const initialDataState = {
	skip: 0,
	take: 15,
};

export const defaultPrint = {
	ordering: "key",
	permanent_date: current_date()
}

export const ValidationSchema = yup.object().shape({
	// workers: idValidations({ type: "multiKey", required: true }),
	ordering: stringValidation({ required: true }),

	print_type: yup.string().when(["my_type"], {
		is: (my_type) => my_type === 2,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
		},
		otherwise: () => stringValidation({ required: false })
	}),

	print_days: yup.number().when(["my_type"], {
		is: (my_type) => my_type === 2,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
				.min(1, t("validation:digits", { digits: 1 }));
		},
		otherwise: () => validateNumeric({ required: false })
	}),

	authorized: yup.string().when(["my_type"], {
		is: (my_type) => my_type === 1,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
		},
		otherwise: () => stringValidation({ required: false })
	}),

	permanent_date: yup.string().when(["my_type"], {
		is: (my_type) => my_type === 1,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
		},
		otherwise: () => stringValidation({ required: false })
	}),

	observations: yup.string().when(["my_type"], {
		is: (my_type) => my_type === 1,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
		},
		otherwise: () => stringValidation({ required: false })
	}),

});

export const customButtonList = [
	{
		id: 1,
		name: "signature-line",
		customValue: "_________________________________________"
	},
	{
		id: 2,
		name: "tab",
		customValue: "        "
	},
	{
		id: 2,
		name: "upload-image",
		customValue: null
	},
];

export const defaultImageValues = {
	width: 100,
	height: 100
};

export const ValidationConfigSchema = yup.object().shape({
	key: stringValidation({ required: true, max: 10, type: "alphanumericKey" }),
	title: stringValidation({ required: true, max: 300 }),
	//description: stringValidation({ required: true, max: 300 }),
	my_type: validateNumeric({ required: true }),
	job_position_type: yup.array().when(["my_type"], {
		is: (my_type) => my_type != 3,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
				.min(1, t("required-contract-type"));
		},
		otherwise: () => arrayValidation({ required: false })
	}),
	kit: yup.array().when(["my_type"], {
		is: (my_type) => my_type === 2,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
				.min(1, t("required-kit"));
		},
		otherwise: () => arrayValidation({ required: false })
	}),
	default_days: yup.number().when(["my_type"], {
		is: (my_type) => my_type < 3 && my_type != 1,
		then: (schema) => {
			return schema
				.required(t("validation:required"))
				// (				.min(1, t("validation:digits", { digits: 1 })))
				.max(186, t("validation:digits", { digits: 186 }));
		},
		otherwise: () => stringValidation({ required: false, max: 30, type: "onlyNumericPositive" })
	}),
});

export const structureList = [
	'branch',
	'sub_branch',
	'area',
	'sub_area',
	'department',
	'sub_department',
	'job_position',
	'employer_registration'
]

export const ValidationSchemaWorkerContracts = yup.object().shape({

	workers: idValidations({ type: "multiKey" }),
	branch: idValidations({ type: "multiKey" }),
	sub_branch: idValidations({ type: "multiKey" }),
	area: idValidations({ type: "multiKey" }),
	sub_area: idValidations({ type: "multiKey" }),
	department: idValidations({ type: "multiKey" }),
	sub_department: idValidations({ type: "multiKey" }),
	job_position: idValidations({ type: "multiKey" }),
	employer_registration: idValidations({ type: "multiKey" }),
	initial_date: stringDateValidation({ required: true }),
	end_date: stringDateValidation({ required: true }),
});

export const signedFilter = [
	{ value: true, label: t("signed") },
	{ value: false, label: t("not-signed") },
];