import i18next from "i18next";
import * as yup from "yup";
import arrayValidation from "../../../common/validations/array";
import { stringDateValidation } from "../../../common/validations/dateValidation";
const { t } = i18next;
import { format } from "date-fns";
import { stringValidation } from "../../../common/validations/stringValidation";

export const ValidationSchema = yup.object().shape({

  keys: yup.array().when(["action"], {
    is: (action) => parseInt(action) === 1,
    then: (schema) => {
      return schema
        .required(t("validation:required"))
        .min(1, "Es necesario seleccionar al menos un colaborador");
    },
    otherwise: () => arrayValidation({ required: false })
  }),
  initial_date: stringDateValidation({ required: true }),
  end_date: stringDateValidation({ required: true }),
  movement_type: arrayValidation({ required: true }),
  movement_by_sua: arrayValidation({ required: true }),
  worker_type: arrayValidation({ required: true }),
  ordenation_type: stringValidation({ required: true }),
});

export const initValues = {
  action: 0,
  initial_date: format(new Date(), "yyyy-MM-dd"),
  end_date: format(new Date(), "yyyy-MM-dd"),
  activate_start_date: false,
  check_all_keys: false,
  check_all_enterprise: false,
};

export const actions = [
  { id: 0, name: t("search-by-date"), option: false },
  { id: 1, name: t("search-by-key-worker"), option: true },
];

export const movementsType = [
  { id: 0, name: t("subscribes"), option: "A" },
  { id: 1, name: t("unsubscribes"), option: "B" },
  { id: 2, name: t("readmissions"), option: "R" },
  { id: 3, name: t("wage-changes"), option: "M" },
  { id: 4, name: t("IDSE"), option: "IDSE" },
];

export const ordenationType = [
  { id: 0, name: t("movement"), option: "M" },
  { id: 1, name: t("key-worker"), option: "W" },
];

export const suaMovements = [
  { id: 0, name: t("Enviados al IMSS"), option: true },
  { id: 1, name: t("No enviados al IMSS"), option: false },
];