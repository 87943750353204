
import React, {
	Suspense,
	cloneElement,
	isValidElement,
	useEffect,
	useState,
} from 'react';
import useAbrhilWindow from '../../contextsAndControllers/useWindow';
import PageSkeleton from '../PageSkeleton';

const Window = ({ id }) => {
	const { open, isFocused } = useAbrhilWindow();
	const focusClass = isFocused ? "isFocused" : "";

	return (
		<>
			{open &&
				<div
					id={id}
					key={id}
					className={`module-window ${focusClass}`}
				>
					<div className='module-window-container'>
						<WindowContent />
					</div>
				</div>}
		</>
	);
};

export default Window;

export const WindowContent = () => {

	const [loaded, setLoaded] = useState(false);

	const {
		open,
		item: { element, path },
	} = useAbrhilWindow();

	useEffect(() => {
		setTimeout(() => {
			setLoaded(!!element);
		}, 400);
	}, [open])

	if (!open || !element) {
		return null;
	}

	if (!isValidElement(element) || !loaded) {
		return <PageSkeleton />;
	}

	return (
		<Suspense fallback={<PageSkeleton />}>
			{cloneElement(element, {
				path,
				inWindow: true,
			})}
		</Suspense>
	);
}