import React from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { CustomTooltip } from '../../../../../../App/components/Templates/cells';

const ExternalOpen = ({
    onClick,
    path,
    show
}) => {

    const { t } = useTranslation();

    if (!show) {
        return null;
    }

    return (
        <CustomTooltip title={t("open-new-tab")} className="external-open menu-item-tooltip" placement="top">
            <NavLink
                target="_blank"
                rel="noopener noreferrer"
                to={path}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </NavLink>
        </CustomTooltip>
    );
};

export default ExternalOpen;