import React from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../App/components/dialog/FormDialog";
import { useTranslation } from "react-i18next";
import DialogContent from "../../../../styled/DialogContent";
import DialogActions from "../../../../styled/DialogActions";
import Button from "../../../../styled/Button";
import { Grid } from "@mui/material";
import DialogController, { useDialogContext } from "../../hooks/useDialogController";
import FormInput from "../../../../@components/form/Field";
import { keyFormat } from "../../../../@components/form/utilities";
import DefaultField from "../../../../@components/form/DefaultField";
import { isFunction } from "lodash";

function Dialog() {

    return (
        <DialogController>
            <TrainingModalitiesDialog />
        </DialogController>
    );
};

export default Dialog;

function TrainingModalitiesDialog() {
    const { t } = useTranslation();

    const {
        open,
        isEdit,
        control,
        loading,
        onSubmit,
        setValue,
        handleClose,
        handleSubmit
    } = useDialogContext();

    return (
        <FormDialog
            open={open}
            handleClose={handleClose}
            maxWidth='md'
            title={`${t(isEdit ? 'edit' : 'add')} ${t("training-modalities")}`}
        >
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={2.5}>
                        <FormInput
                            control={control}
                            fieldInput="TextField"
                            label={t('key')}
                            name="key"
                            isRequired
                            disabled={isEdit}
                            onChange={keyFormat}
                        />
                    </Grid>
                    <Grid item xs={5.5}>
                        <FormInput
                            control={control}
                            fieldInput="TextField"
                            label={t('description')}
                            name="name"
                            isRequired
                            autoFocus={isEdit}
                        />
                    </Grid>
                    <Grid item>
                        <FormInput
                            control={control}
                            fieldInput="Switch"
                            label={t('active')}
                            name="is_active"
                        />
                    </Grid>
                    <Grid item>
                        <DefaultField
                            control={control}
                            setValue={setValue}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <BasicDialogActions
                    onSubmit={onSubmit}
                    loading={loading}
                    onCancel={handleClose}
                    handleSubmit={handleSubmit}
                />
            </DialogActions>
        </FormDialog>
    );
};

export const BasicDialogActions = ({
    onCancel,
    onSubmit,
    loading,
    saveLabel,
    handleSubmit,
}) => {
    const { t } = useTranslation();

    return (
        <DialogActions>
            <Button onClick={onCancel} design='text' disabled={loading}>
                {t('cancel')}
            </Button>
            {isFunction(onSubmit) &&
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
                    <Button type='submit' design='contained' loading={loading}>
                        {t(saveLabel ?? 'save')}
                    </Button>
                </form>}
        </DialogActions>
    );
};

BasicDialogActions.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
    saveLabel: PropTypes.string,
    handleSubmit: PropTypes.func,
};