import React, { useContext } from 'react';
import { maxBy } from "lodash";
import { useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
//kendo and MUI
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
//components
import Drag from './Drag';
import { ModuleContext } from '../util';

function Firms() {
  const { control, setValue } = useContext(ModuleContext);
  const signatures = useWatch({ control, name: `signatures` })

  function addCard() {
    const idParent = (maxBy(signatures, "order")?.order || 0) + 1
    signatures.push({
      signature: [{
        idParent,
        id: 0,
        name: ""
      }],
      key:"payroll_general",
      order: idParent,
      is_hidden: false
    })
    setValue("signatures", signatures)
  }

  return (
    <Grid className="mb-3">
      <Grid className="card-title p-2">
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}>
          <div>
            Firmas del documento
            {/* <FontAwesomeIcon
              icon={faCircleQuestion}
              size="lg"
              style={{
                color: 'var(--textAndIcons)',
                marginLeft: '8px',
                cursor: 'pointer',
              }}
            /> */}
          </div>
          {signatures.length != 4 && <div>
            <IconButton aria-label="add" style={{ color: "var(--tableHeadbg)", padding: "0px" }} onClick={addCard}>
              <FontAwesomeIcon icon={faCirclePlus} size="sm" />
            </IconButton>
          </div>}
        </div>

      </Grid>
      <Grid
        style={{ height: 'calc(100vh - 677px)' }}
        className="card-container pt-2 p-1"
      >
        <Drag />
      </Grid>
    </Grid>
  );
}

export default Firms;
