import i18next from 'i18next';
import { string } from "yup";
import { hasValue, valueOrOption } from '../GeneralUtilities';
/**
 *
 * @param {object} params
 * @param {boolean} params.required - Inidca si el campo es requerido
 * @param {?"key"|"multiKey"|"id"|"multiId"|"custom"} params.type - El tipo de validación a aplicar
 * @param {boolean} params.required - Inidca si el parametro es requerido o no
 * @param {RegExp} params.custom_regex - Un custom regex para la clave introducida
 * @param {number} params.max - Indica el numero maximo de parametros que deberia tener el input, separados por coma
 * @returns
 */
export const idValidations = ({
	required = false,
	type = "key",
	custom_regex = null,
	max
}) => {

	const { t } = i18next;

	max = ["key", "id"].includes(type) ? 1 : max;

	const values = {
		key: { msg: "key", extra: "", regex: /^[a-zA-Z0-9]*$/ },
		multiKey: { msg: "regex", extra: " ej : A01,A02,003", regex: /^([a-zA-Z\d]+,?)*([a-zA-Z\d]+)?$|\*/ },
		id: { msg: "numeric", extra: "", regex: /^[0-9]*$/ },
		multiId: { msg: "regex", extra: " ej : 1,2,3", regex: /^\d+(,\d+)*(,)?$|\*/ },
	}[type] ?? { msg: "key", extra: "", regex: custom_regex ?? /^[a-zA-Z0-9]*$/ };

	let message = t(`validation:${values.msg}`) + values.extra;

	let validation = string()
		.nullable()
		.matches(values.regex, { message: message });

	if (required) {
		validation = validation.required(t('validation:required'));
	}

	if (max) {
		validation = validation.test(
			'max-items-validation',
			t('validation:max.array', { max: max }),
			value => {
				if (!hasValue(value)) {
					return true;
				}
				const items = valueOrOption(value, "").split(",").filter(item => hasValue(item));
				return items.length <= max;
			}
		);
	}

	return validation;
};