import PropTypes from "prop-types"
import React from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '../../../../styled/Dialog'
import DialogTitle from '../../../../styled/DialogTitle'
import DialogContent from '../../../../styled/DialogContent'
import DialogActions from '../../../../styled/DialogActions'
import Grid from '@mui/material/Grid'
import Button from '../../../../styled/Button'
import ChangeItem from './ChangeItem'

function ModalComponent({ toggle, modalDiff, changes, updatePackages }) {
  const { t } = useTranslation()

  let col1 = []
  let col2 = []

  if (changes.length === 1) {
    col1 = changes
  } else {
    const first = Math.ceil(changes.length / 2) - 1

    col1 = changes.filter((f, index) => index <= first)
    col2 = changes.filter((f, index) => index > first)
  }

  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={modalDiff} aria-labelledby='customized-dialog-title'>
      <DialogTitle title={t('editing-settings')} onClose={toggle} />
      <DialogContent>
        <div className='warning-message'> {t("message-edit-settings")} </div>
        <div className='my-changes'>
          <Grid container spacing={6}>
            <Grid item md={changes.length === 1 ? 12 : 6}>
              {col1.map((c, index) => {
                return <ChangeItem key={index} {...c} />
              })}
            </Grid>

            {!!col2.length && (
              <Grid item md={6}>
                {col2.map((c, index) => {
                  return <ChangeItem key={index} {...c} />
                })}
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>

      <DialogActions>
        <Button design='text' type='button' onClick={toggle}>
          {t('cancel')}
        </Button>
        <Button design='contained' type='submit' onClick={updatePackages}>
          {t('continue')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalComponent.propTypes = {
  changes: PropTypes.any,
  modalDiff: PropTypes.any,
  toggle: PropTypes.any,
  updatePackages: PropTypes.any
}

export default ModalComponent
