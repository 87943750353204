import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { hasValue, valueOrOption } from '../../../../core/common/GeneralUtilities';
import Textbox from '../../textInputs/Textbox';
import { debounce, isFunction } from 'lodash';
import { SufixIcon } from './SufixIcon';

const numExp = /^\d$/;
const letExp = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ]$/;

export const isNumber = (value) => numExp.test(value);
export const isLetter = (value) => letExp.test(value);

export const onlyNumbers = (value) => value?.replace(/\D/g, '');
export const onlyLetters = (value) => value?.replace(/\d/g, '')?.replace(/\s{2,}/g, ' ');

const SearchField = ({
	onAdvanceFilter,
	filters,
	initValue,
	onChange,
	label = "",
	filterName = "search",
	helpIcon = false,
	titleHelp = "",
	size,
}) => {

	const { t } = useTranslation();
	const [text, setText] = useState(initValue ?? "");

	useEffect(() => {
		if (!initValue) return;
		handleChange({ value: initValue });
	}, []);

	const setAdvanceFiltersSearch = (value, keypress, filter) => {
		if (!isFunction(onAdvanceFilter)) return;
		onAdvanceFilter({
			filters: {
				...filter?.filters,
				[filterName]: value
			}
		}, keypress);
	};

	const debouncedResults = useRef(debounce(setAdvanceFiltersSearch, 500)).current;

	const onTyping = ({ charCode, key, target }) => {
		if ((charCode === 13 || (key ?? "").toString().toLowerCase() === "enter")) {
			if (hasValue(target?.value) && isFunction(onAdvanceFilter)) {
				setAdvanceFiltersSearch(target?.value, true, filters);
			}
		}
	};

	const handleChange = ({ value }) => {
		if (isFunction(onChange)) {
			value = onChange(value) ?? value;
		}
		setText(value);
		debouncedResults(value, false, filters);
	};

	size = valueOrOption(size, { xs: 3.5, md: 3.5, lg: 2.5, });

	return (
		<Grid size={size}>
			<Textbox
				autoComplete={"off"}
				autoFocus
				name={"search"}
				label={valueOrOption(label, t("search"))}
				onKeyPress={onTyping}
				size={"medium"}
				value={text}
				onChange={handleChange}
				suffix={() => <SufixIcon helpIcon={helpIcon} titleHelp={titleHelp} />}
			/>
		</Grid>
	);
};

SearchField.propTypes = {
	filterName: PropTypes.string,
	filters: PropTypes.any,
	helpIcon: PropTypes.bool,
	initValue: PropTypes.any,
	label: PropTypes.string,
	onAdvanceFilter: PropTypes.func,
	onChange: PropTypes.func,
	titleHelp: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	size: PropTypes.any
};

export default SearchField;