import PropTypes from "prop-types"
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { filter, find, size } from "lodash";
import { dropdownProps as PropGen } from './utilities';
import FormInput from '../../../../form/Field';
import { valueOrOption } from '../../../../../common/GeneralUtilities';
import { isAbrhil } from '../../../../../common/auth';

export const CompaniesDropdown = ({
	control,
	setValue,
	loading,
	getValues,
	clearErrors,
	companies = [],
	dropdownProps = null
}) => {

	const { t } = useTranslation();
	const corporate = useWatch({ control, name: "corporate" });
	const data = filter(companies, ["corporate", corporate]);

	useEffect(() => {
		if (!size(data)) { return; }
		const id = getValues("company");
		const current = find(data, ["id", id]);
		setValue("company", valueOrOption(current?.id, null));
		setValue("companyObj", valueOrOption(current, {}));
		//eslint-disable-next-line
	}, [data, corporate]);

	const finalData = valueOrOption(data, []);
	return (
		<FormInput
			{...dropdownProps || PropGen}
			data={finalData}
			control={control}
			loading={loading}
			name={"company"}
			label={t("company")}
			onChange={e => {
				clearErrors();
				setValue("companyObj", e.objectValue);
				return e;
			}}
			disabled={!isAbrhil()} />
	);
};

CompaniesDropdown.propTypes = {
	companies: PropTypes.array,
	control: PropTypes.any,
	loading: PropTypes.bool,
	dropdownProps: PropTypes.any,
	getValues: PropTypes.func,
	setValue: PropTypes.func,
	clearErrors: PropTypes.any,
}
