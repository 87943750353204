import React from "react";
import { size, filter, isFunction } from "lodash";
import { useTranslation } from "react-i18next";
/* Own components */
import KendoCheckbox from "../../../App/components/Booleans/KendoCheckbox";
import { valueOrOption } from '../../common/GeneralUtilities';
import { CustomTooltip } from '../../../App/components/Templates/cells';

/* Font awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faOctagonCheck,
	faOctagonXmark,
} from "@fortawesome/pro-solid-svg-icons";

export const CustomSelectHeader = ({
	// generalSelect = false,
	data,
	onSelectPage,
	multiSelection = false,
}) => {

	const isAllSelected = () => {
		const pageSelecteds = filter(data, item => item.selected === true);
		return size(pageSelecteds) === size(data);
	};

	if (!size(data) || !multiSelection || !isFunction(onSelectPage)) {
		return null;
	}

	return (
		<KendoCheckbox
			checked={isAllSelected()}
			onChange={onSelectPage}
		/>
	);
};

export const CustomSelectCell = ({
	dataItem,
	onRowClick,
	onClick,
	selectionChange,
	className,
	style,
	persistedSelection,
	disabledSelection,
	selectBy,
}) => {

	const { t } = useTranslation();

	selectBy = valueOrOption(selectBy, "id");
	persistedSelection = valueOrOption(persistedSelection, []);
	disabledSelection = valueOrOption(disabledSelection, []);

	if (persistedSelection.includes(dataItem[selectBy])) {
		return (
			<CustomTooltip title={t("selection-restrict-uncheck")}>
				<td className={`${className} center icon-select-cell restrict-uncheck`} style={style}>
					<FontAwesomeIcon icon={faOctagonCheck} />
				</td>
			</CustomTooltip>
		);
	}

	if (disabledSelection.includes(dataItem[selectBy])) {
		return (
			<CustomTooltip title={t("selection-restrict-check")}>
				<td className={`${className} center icon-select-cell restrict-check`} style={style}>
					<FontAwesomeIcon icon={faOctagonXmark} />
				</td>
			</CustomTooltip>
		);
	}

	return (
		<td onClick={onClick ?? selectionChange} className={`${className} center`} style={style}>
			<KendoCheckbox
				checked={dataItem?.selected}
				onChange={() => {
					if (isFunction(onRowClick)) {
						onRowClick({ dataItem: dataItem });
					}
				}}
			/>
		</td>
	);
};