import PropTypes from "prop-types";
import React from 'react';
import {
	structureLabel,
	structureSteps
} from '../../../common/StructureUtilities';
import { hasValue } from '../../../common/GeneralUtilities';
import { DropdownInput } from './DropdownInput';
import { ModalSelectionInput } from './ModalSelectionInput';

function StructureFields({
	structure,
	Container,
	containerProps,
	fieldsPrefix = "",
	sequencial = true,
	asDropdown = true,
	...others
}) {

	if (!hasValue(structure)) { return null; }

	let organizatedStructure = structureSteps(structure);

	const getSequelncialItems = (index) => {
		if (!sequencial) {
			return ["none"];
		}

		let items = organizatedStructure.map((item, stepIndex) => (
			stepIndex < index ? `${fieldsPrefix}${item?.step}` : null
		));
		return ["none", ...items.filter(item => item !== null)];
	};

	const getCommons = (item, index, separatedLabel) => ({
		field: `${fieldsPrefix}${item?.step}`,
		name: `${fieldsPrefix}${item?.step}`,
		label: structureLabel(item, false, separatedLabel),
		Container: Container,
		containerProps: containerProps,
		sequencialItems: getSequelncialItems(index),
	});

	const prevSequencialItem = (index) => {
		if (index === 0) {
			return "none";
		}
		let prevField = organizatedStructure[index - 1]?.step ?? "none";
		return `${fieldsPrefix}${prevField}`;
	};

	return (
		<>
			{organizatedStructure.map((item, index) => {
				if (!hasValue(item)) { return null; }

				if (asDropdown) {
					return <DropdownInput key={item.step} {...others} {...getCommons(item, index, true)} />;
				}

				return (
					<ModalSelectionInput
						key={item.step} {...others}
						{...getCommons(item, index, false)}
						fieldsPrefix={fieldsPrefix}
						prevSequencialItem={prevSequencialItem(index)}
					/>
				);
			})}
		</>
	);
}

StructureFields.propTypes = {
	Container: PropTypes.any,
	asDropdown: PropTypes.bool,
	containerProps: PropTypes.object,
	sequencial: PropTypes.bool,
	structure: PropTypes.array,
	fieldsPrefix: PropTypes.any
};

export default StructureFields;