
import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useAdvanceFilters from '../useAdvanceFilters';
import SubTitle from '../../../layout/Subtitle';
import { Transition, filterProps, getItemKey } from '../ConstantsAndUtilities/utilities';
import { hasValue, valueOrOption } from '../../../../common/GeneralUtilities';
import FormInput from '../../../form/Field';
import MuiAlert from '../../../general/MuiAlert';

import Title from './Title';
import Buttons from './Buttons';
import DateRange from './DateRange';
import StructureFields from '../../../form/Structure';

const Content = () => {

	const { t } = useTranslation();
	const {
		open,
		control,
		onClose,
		fields,
		setValue,
		getValues,
	} = useAdvanceFilters();

	const filtersKeys = Object.keys(fields || {});

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			// keepMounted
			onClose={onClose}
			aria-describedby="advancefilter-slide-description"
			className="advanceFilters"
		>
			<Title />

			<DialogContent >
				<Grid id="advance-filters-form-container" container rowSpacing={1} columnSpacing={2}>
					{filtersKeys.map((keyName, groupIndex) => (
						<Fragment key={getItemKey(groupIndex)}  >
							<SubTitle title={t(keyName)} asV2 asDivider className='mb-1' />
							{valueOrOption(fields[keyName], [])
								.filter(item => hasValue(item))
								.map((item, fieldIndex) => {
									const key = getItemKey(groupIndex, fieldIndex);
									if (item[0] === "custom-element") {
										const Filter = item[1];
										return <Filter key={key} id={key} control={control} setValue={setValue} getValues={getValues} {...item?.[2]} />;
									}

									if (item[0] === "structure-filters") {
										return (
											<StructureFields
												key={key}
												id={key}
												Container={Grid}
												containerProps={{ size: { xs: 12, md: 6, lg: 6, xl: 6 } }}
												{...item[1]}
												control={control}
												setValue={setValue}
											/>
										);
									}
									const props = filterProps(item) ?? {};
									const fieldInput = props?.fieldInput ?? "textfield";
									if (fieldInput === "info") {
										return (
											<MuiAlert key={key} id={key} asV2 size={props?.containerProps?.size}>
												{t(props.label)}
											</MuiAlert>
										);
									}
									if (fieldInput === "daterange") {
										return (
											<DateRange key={key} {...props} id={key} control={control} />
										);
									}
									return <FormInput {...props} key={key} id={key} control={control} label={t(props.label)} />;
								})}
						</Fragment>
					))}
				</Grid>
			</DialogContent>

			<Buttons />
		</Dialog>
	);
};

export default Content;
