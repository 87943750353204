import { useEffect, useState } from "react"
import { implementService } from "../../../../services/implemet-service"
import { packagesV2Api } from "../../../../services/packages"
import { usePackages } from "../../usePackages"
import { isNumber } from "lodash";

function useConfigurations() {
  const { tenant, selectPackage, selectCategory, classifications, chill } = usePackages()
  const [data, setData] = useState([])
  const [viewComponent, setViewComponent] = useState(false)

  useEffect(() => {
    if (!selectCategory) {
      setData([])
    } else {
      getData()
    }
  }, [selectCategory])

  function getData() {
    if (isNumber(selectCategory.id)) {
      setViewComponent(false)
      const params = {
        package: selectPackage.id || chill?.id,
        classification: classifications.id,
        category: selectCategory.id,
      }
      implementService(packagesV2Api(tenant).settings.get(params), result => {
        setData(result.groups)
      })
    } else {
      setViewComponent(true)
    }
  }



  return {
    data,
    getData,
    viewComponent
  };
}

export default useConfigurations;
