import React from "react";
import PropTypes from "prop-types";
import { uid } from "uid";
import { toString } from "lodash";
import { motion } from "framer-motion";

const uniqueId = uid();

function RichText({ words, fontSize, wrap = false, ...others }) {

    return (
        <motion.div
            key={`rich-text-${uniqueId}`}
            className={`mopers-rich-text-wrap ${wrap ? 'wrap' : ''}`}
            {...others}
        >
            {words.map(w => toString(w?.value)?.split(' ')?.map(el =>
                <span
                    key={`rich-text-child-${uid()}`}
                    style={{ fontSize, ...(others?.style ?? {}), ...(w.style ?? {}) }}
                    className={w.color ?? ''}
                >{el}</span>
            ))}
        </motion.div>
    );
};

export default RichText;

RichText.propTypes = {
    fontSize: PropTypes.any,
    words: PropTypes.array,
    wrap: PropTypes.bool
}