import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';
import { gridSize } from "..";
import { useDialogContext } from "../../../hooks/useDialog";
import { titleView } from "../../constants";
import { AnimatePresence, motion } from "framer-motion";
import { opacityAnimation } from "../../../../../../@components/form/Permission/Utils";

function GenericContent({ children }) {
    const { currentTab: { key } } = useDialogContext();
    const titleData = titleView[key];

    return (
        <Grid
            {...gridSize(12)}
            container
            spacing={5}
        >
            <AnimatePresence mode="wait">
                <Grid
                    key={`current-view-${titleData.title}`}
                    {...gridSize(12)}
                    component={motion.div}
                    container
                    {...opacityAnimation}
                >
                    <Grid {...gridSize(12)} className="title">
                        {titleData.title}
                    </Grid>
                    <Grid {...gridSize(12)}>
                        {titleData.subtitle}
                    </Grid>
                </Grid>
            </AnimatePresence>
            <Grid
                {...gridSize(12)}
                container
                spacing={3}
                direction="column"
            >
                {children}
            </Grid>
        </Grid>
    );
};

export default GenericContent;

GenericContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
    ])
};