import React from 'react'
import Window from '../../../../@components/modal/Window';
import PackagesV2 from '../../../PackagesV2';

function WindowPackages({ open, onClose, title, tenant}) {
  return (
    <Window
      initialTop={0}
      initialLeft={0}
      initialWidth={1910}
			initialHeight={930}
      minHeight={850}
      minWidth={1600}
      title={title}
      onClose={onClose}
      open={open}
    >
      <PackagesV2 tenant={tenant}/>
    </Window>
  )
}

export default WindowPackages