import React, { useContext } from "react";
import { useWatch } from 'react-hook-form';
import { Sortable } from "@progress/kendo-react-sortable";
import Card from "./Card";
import { ModuleContext } from '../../util';
import SortableEmptyItemUI from "./SortableEmptyItemUI";

const Drag = () => {

  const { control, setValue } = useContext(ModuleContext);
  const signatures = useWatch({ control, name: 'signatures' });

  const onDragOver = (event) => {
    setValue('signatures', event.newState)
  };

  return (
    <Sortable
      style={{ height: "100%", display: "flex" }}
      idField={"order"}
      data={signatures}
      navigation={false}
      itemUI={Card}
      disabledField={"is_hidden"}
      onDragOver={onDragOver}
      emptyItemUI={SortableEmptyItemUI}
    />
  );
};

export default Drag
