import React from "react";
import lang from "i18next";
import { size, isArray, isObject, filter as _filter, includes, toUpper, isEmpty, slice } from "lodash";
import { Checkbox } from "@progress/kendo-react-inputs";
import { getter, setter } from '@progress/kendo-react-common';

/* components */
import { formatLabel } from '../Select/utilities.jsx';
import { hasValue } from '../../../core/common/GeneralUtilities.jsx';

const { t } = lang;

/*
	--------------------- TREE MULTI SELECT UTILITIES ---------------------
*/

/**
 * Mps de element values to get the value
 */
export const getValueMap = (value, idGetter) => {
	const map = {};

	if (value && value.length) {
		value.forEach(item => {
			map[idGetter(item)] = true;
		});
	}

	return map;
};

/**
 * control the expand state
 */
export const expandedState = (item, dataItemKey, expanded) => {
	const nextExpanded = expanded.slice();
	const keyGetter = getter(dataItemKey);
	const itemKey = keyGetter(item);
	const index = expanded.findIndex(currentKey => {
		return currentKey === itemKey;
	});
	index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
	return nextExpanded;
};

/**
 * Maps and construct element data
 */
const mapMultiSelectTreeData = (data, options) => {
	const {
		keyGetter,
		subItemGetter,
		subItemSetter,
		checkSetter,
		expandedSetter,
		checkIndeterminateSetter,
		valueMap,
		expandedMap,
	} = options;

	if (!data || !data.length) {
		return [data, false];
	}

	let hasChecked = false;
	const newData = [...data].map(dataItem => {
		const [children, hasCheckedChildren] = mapMultiSelectTreeData(subItemGetter(dataItem), options);
		const isChecked = valueMap[keyGetter(dataItem)];

		if (isChecked || hasCheckedChildren) {
			hasChecked = true;
		}

		const newItem = {
			...dataItem
		};
		expandedSetter(newItem, expandedMap[keyGetter(newItem)]);
		subItemSetter(newItem, children);
		checkSetter(newItem, isChecked);
		checkIndeterminateSetter(newItem, !isChecked && hasCheckedChildren);
		return newItem;
	});
	return [newData, hasChecked];
};

/**
 * Creates a new array with the results of calling the provided callback function
 * on every element in the provided data tree. The new tree items have their `check` and `checkIndeterminate` fields set.
 */
export const processMultiSelectTreeData = (tree, options) => {
	const {
		subItemsField = 'items',
		checkField = 'checkField',
		checkIndeterminateField = 'checkIndeterminateField',
		expandField = 'expanded',
		dataItemKey,
		value,
		filter,
		expanded,
	} = options;

	const keyGetter = getter(dataItemKey);
	const filtering = Boolean(filter && filter.value);
	const expandedMap = {};
	expanded.forEach(id => expandedMap[id] = true);

	const [result] = mapMultiSelectTreeData(tree, {
		valueMap: getValueMap(value, keyGetter),
		expandedMap: expandedMap,
		keyGetter,
		expandedSetter: setter(expandField),
		subItemGetter: getter(subItemsField),
		subItemSetter: setter(subItemsField),
		checkSetter: setter(checkField),
		checkIndeterminateSetter: setter(checkIndeterminateField)
	});

	return filtering ? filterTree(filter.value, result) : result;
};

const filterTree = (filter, list) => {
	return _filter(list, (item) => {
		if (includes(textSearch(item.title), textSearch(filter))) {
			return true;
		} else if (item.children) {
			item.children = filterTree(filter, item.children);
			return !isEmpty(item.children);
		}
	});
};

const textSearch = (title) => {
	return toUpper(title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
};


/*
	--------------------- MULTI SELECT UTILITIES ---------------------
*/

/**
 * Get the real array value of the multiselect based provided data and configurations
 */
export const getValue = (options, value, data, keyField) => {
	if (size(options) === 0 || size(value) === 0) {
		return [];
	}

	if (size(options) === size(value)) {
		return data;
	}

	let selection = realValue(value, true, keyField);
	return data.filter(item => selection.includes(item?.[keyField] ?? item));
};

/**
 * transform the data to the configured value options
 */
export const realValue = (selected, byKey, keyField) => {
	if (byKey && isObject(selected?.[0])) {
		return selected.map((item) => item[keyField]);
	}
	return selected ?? [];
};

/**
 * Get the correspondant text for multiselect tags
 */
export const getTagText = (item, format) => {
	if (!isObject(item)) {
		return item.toString();
	}

	return item.renderLabelField ?? formatLabel(format, item);
};

/**
 * Makes a custom render element for multiselect input
 */
export const itemRender = (li, itemProps, format) => {

	const onChecked = (e) => {
		e.stopPropagation = () => null;
		itemProps.onClick(itemProps.index, e);
		return e;
	};

	const itemChildren = (
		<span>
			<Checkbox
				value={itemProps.selected}
				onChange={onChecked}
				className="checkbox-custom-list"
			/>
			&nbsp; {formatLabel(format, itemProps.dataItem)}
		</span>
	);
	return React.cloneElement(li, li.props, itemChildren);
};

/**
 * Construct an array data based on multiselect options for easy manipulate
*/
export const constructData = (data, keyField, textField, format, withAllSelectionLabel = true, childrenField = null) => {
	if (!isArray(data) || !size(data)) {
		return [];
	}

	const finalData = format ?
		data.map(item => {
			item["renderLabelField"] = formatLabel(format ?? textField, item);
			if (hasValue(childrenField)) {
				const subData = item?.[childrenField];
				item[childrenField] = constructData(subData, keyField, textField, format, withAllSelectionLabel, childrenField);
			}
			return item;
		}) : data;

	if (hasValue(childrenField)) {
		return finalData;
	}

	let newData = [
		{ renderLabelField: t("all-items"), [keyField]: t("all-items"), [textField]: t("all-items") },
		...finalData
	];

	newData = withAllSelectionLabel ? newData : slice(newData, 1);
	return newData;
};
