import React from "react";
import Token from "./components/Token";
import Settings from "./components/Settings";
import Payroll from "./components/Payroll";
import Contracts from "./components/Contracts";
import "./styles/style.scss";

function Modals() {


    return (
        <>
            <Token />
            <Settings />
            <Payroll />
            <Contracts />
        </>
    );
};

export default Modals;