import React, { createContext } from "react";
import {
    faUser,
    faPeopleGroup,
    faCubes,
    faUserSlash,
    faUserGroup,
    faLockKeyholeOpen,
    faLockKeyhole,
    faHandHoldingDollar,
    faLayerGroup,
    faGear,
    faUserShield,
    faCalendarCheck,
    faMobileNotch,
} from "@fortawesome/pro-light-svg-icons";
import UserView from "../components/Modal/Views/User";
import RolesView from "../components/Modal/Views/Roles";
import i18next from 'i18next';
import { each, isArray, isBoolean, isNumber, size, unionBy, xorBy } from "lodash";
import Tenants from "../components/Modal/Views/Tenants";
import { isAbrhil } from "../../../common/auth";
import { trim } from '../../../common/validations/validationFilter';
import Workgroups from "../components/Modal/Views/Workgroups";
import Payroll from "../components/Modal/Views/Payroll";
import Settings from "../components/Modal/Views/Settings";
import ActivateDays from "../components/Modal/Views/ActivateDays";
import MoperConfiguration from "../components/Modal/Views/MoperConfiguration";
import AccessSU from "../components/Modal/Views/AccessSU";
import CancelMopers from "../components/Modal/Views/CancelMopers";
const { t } = i18next;

export const GENERIC_DOMAIN = '.abrhilcloud.com';
export const PERMISSION_KEY = 'module_permission';
export const UserContext = createContext({});
export const FlowContext = createContext({});
export const DialogContext = createContext({});

export const emptyWorker = {
    key: "",
    first_surname: "",
    second_surname: "",
    name_worker: "",
    internal_user: false,
    worker: null,
    external_name: "",
};

export const typeFilterConfig = [
    [{ key: 2, label: t('workgroup-admin2') }, { key: 1, label: t('workgroup-admin3') }],
    'key',
    'label'
];

export const baseRole = {
    group: {
        name: '',
        config: {
            description: null,
            only_branch: null,
        },
    },
    user_group_permissions: [],
    modules_perms: [],
    rolePerms: [],
    branches_employer: [],
    saved: false,
};

export const isDevMode = process.env.REACT_APP_ABR_MODE === "localhost" || process.env.REACT_APP_ABR_MODE === "development";

export const initialValues = (
    attendance,
) => ({
    id: null,
    username: "",
    email: "",
    password: "",
    password2: "",
    user_type: 1,
    is_active: true,
    internal_user: true,
    external_name: "",
    initial_email: null,
    existingEmail: '',
    groups_perms: [baseRole],
    extra_perms: {},
    cancel_mopers: [],
    moper_attendance: [],
    payroll_permissions: !isAbrhil() ? [
        {
            "payroll_access": 1,
            "calculate_permissions": [
                1
            ]
        }
    ] : [],
    admin_workgroups: [],
    tenants: [],
    worker: null,
    roles_permissions: {},
    attendance,
});

export const mainItems = (attendance) => {
    return (
        [
            {
                name: 'Usuario',
                description: 'Informacion básica del usuario',
                icon: faUser,
                classname: 'blueBg',
                step: 0,
                menu_order: 0,
                errorClass: '',
                fields: ['worker', 'external_name', 'password', 'password2', 'username', 'email'],
                open: <UserView />
            }, {
                name: 'Nómina',
                description: 'Configuración de cálculos, nóminas, etc.',
                icon: faHandHoldingDollar,
                classname: 'greenBg',
                step: 2,
                menu_order: 2,
                errorClass: '',
                attendance: false,
                fields: ['payroll_permissions'],
                // validate: (params) => !validateSU(params) && (!isAbrhil() || isDevMode),
                open: <Payroll />
            },
            {
                name: 'Roles asignados',
                description: 'Gestión de roles ligados',
                icon: faPeopleGroup,
                classname: 'yellowBg',
                step: 1,
                menu_order: 1,
                errorClass: '',
                attendance: false,
                fields: ['groups_perms'],
                // validate: (params) => !validateSU(params) && (!isAbrhil() || isDevMode),
                open: <RolesView />
            },
            {
                name: 'Grupos de trabajo',
                description: 'Asignación de grupos de trabajo',
                icon: faUserGroup,
                classname: 'blueBg',
                step: 1,
                menu_order: 4,
                attendance: true,
                errorClass: '',
                fields: ['admin_workgroups'],
                open: <Workgroups />
            },
            {
                name: 'Empresas',
                description: 'Configuración de usuario en diferentes empresas',
                icon: faCubes,
                classname: 'violetBg',
                step: 4,
                menu_order: 7,
                errorClass: '',
                attendance: false,
                fields: ['tenants'],
                validate: (params) => validateTenants(params),
                open: <Tenants />
            },
            {
                name: 'Cancelar Mopers',
                description: 'Configuración de cancelación de mopers',
                icon: faUserShield,
                classname: 'blueBg',
                attendance: true,
                step: attendance ? 2 : 4,
                menu_order: 6,
                errorClass: '',
                fields: [],
                open: <CancelMopers />
            },
            {
                name: 'Configuración',
                description: 'Configuración de preferencias del usuario',
                icon: faGear,
                classname: 'blueBg',
                step: 3,
                menu_order: 5,
                attendance: false,
                errorClass: '',
                fields: ['extra_perms'],
                // validate: () => !isAbrhil() || isDevMode,
                open: <Settings />
            },
            {
                name: 'Suc. / R. Social / Reg. Pat',
                description: 'Visualiza los registros disponibles',
                icon: faLayerGroup,
                classname: 'greenBg',
                menu_order: 6,
                errorClass: '',
                attendance: false,
                fields: [],
                open: <AccessSU />
            },
            {
                name: 'Configuración de mopers',
                description: 'Configuración de preferencias de los mopers',
                icon: faGear,
                classname: 'blueBg',
                attendance: true,
                step: 3,
                menu_order: 7,
                errorClass: '',
                fields: [],
                open: <MoperConfiguration />
            },
            {
                name: 'Activar días',
                description: 'Configuración de activar días',
                icon: faCalendarCheck,
                classname: 'blueBg',
                attendance: true,
                step: 4,
                menu_order: 8,
                errorClass: '',
                fields: [],
                open: <ActivateDays />
            },
        ]
    )
};

export const payrollChoices = [
    { name: 'Nóminas públicas', description: 'Acceso a tipos de nóminas normales', id: 1, icon: faLockKeyholeOpen },
    { name: 'Nóminas privadas', description: 'Acceso a tipos de nóminas confidenciales', id: 2, icon: faLockKeyhole },
];

export const payrollCalculateChoices = [
    { name: 'Normal', value: 1 },
    { name: 'Finiquito', value: 2 },
    { name: 'Especial', value: 3 },
];

export const basePayroll = (id, calc) => ({
    payroll_access: id,
    calculate_permissions: calc ? [...calc] : [1, 2, 3],
});

export const accessTypes = [
    { id: 0, name: 'Todos' },
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Confidencial' },
];

const validateTenants = ({ datasource }) => {
    if (isArray(datasource) && size(datasource) <= 1) {
        return false;
    }
    return isAbrhil();
};

// const validateSU = ({ user }) => {
//     return !!user?.is_superuser;
// };

export const variants = {
    enter: () => {
        return {
            opacity: 0,
            scale: 0.4,
        };
    },
    center: {
        opacity: 1,
        scale: 1,
    },
    exit: () => {
        return {
            opacity: 0,
            scale: 0.4,
        };
    }
};

export const transition = {
    duration: 0.3,
    ease: [0, 0.71, 0.2, 1.01],
};

export const transition2 = {
    duration: 0.2,
};

export const variants2 = {
    enter: () => {
        return {
            x: '100%',
            opacity: 0,
        };
    },
    center: {
        x: '0%',
        opacity: 1,
    },
    exit: () => {
        return {
            x: '100%',
            opacity: 0,
        };
    }
};

export function initializeRole(role, getValues) {
    const { id, name, config } = role ?? {};
    const basePerms = role.modules_perms;
    const extraPerms = role.user_group_permissions;
    const exceptions = role.user_group_exceptions;
    const totalPerms = unionBy(basePerms, extraPerms, PERMISSION_KEY);
    role.user_group_configs = formatDashboard(role?.user_group_configs);
    role.rolePerms = xorBy(totalPerms, exceptions, PERMISSION_KEY).map(el => el.module_permission);
    role.structure_data = getValues('structure_data');
    if (!role.group) {
        role.group = { id, name, config };
    }
    return role;
}

const formatDashboard = (dashboard) => {
    dashboard = dashboard ?? {};
    if (isNumber(dashboard?.default_sub_item)) {
        dashboard.element_id = dashboard.default_sub_item;
        dashboard.is_subitem = true;
        return dashboard;
    }
    dashboard.is_subitem = false;
    dashboard.element_id = dashboard?.default_item;
    return dashboard;
}

export const validateShowItem = (index, groups, forSelection = false) => {
    const selectedRole = groups[index];
    if (!forSelection) {
        return size(selectedRole) && selectedRole?.saved;
    }
    return selectedRole?.group?.id;
}

export const validateShowMessage = (groups) => {
    return groups.some(el => el.saved);
}

export const stepItemsBase = (items) => {
    return items.map((item) => ({
        label: t(item.name),
        isValid: undefined,
        validate: item?.validate,
        step: item.step,
        fields: item?.fields,
        ...(isBoolean(item?.attendance) ? { attendance: item.attendance } : {}),
    }));
}

export function validateComponentShow(item, datasource, attendance, user) {
    if (item.attendance && !attendance) return false;
    if (item.attendance === false && attendance) return false;
    if (item?.validate) {
        if (typeof item.validate === 'function') {
            const params = {
                datasource: datasource[item.step],
                user,
            };
            return item.validate(params);
        }
        return item.validate;
    }
    return true;
};

export const treatAccessData = (data, config) => {
    if (!isArray(data)) return;
    if (!config) {
        return data.map(item => {
            item.comp_key = `${item.branch_id}-${item.branch_key}-${item.employer_id}-${item.employer_key}-${item.rfc}`;
            item.selected = false;
            return item;
        });
    }
    return data.map(item => {
        item.comp_key = `${item.branch_id}-${item.branch_key}`;
        item.selected = false;
        return item;
    });
};

export const handleInitialState = (item, config) => {
    const dataResponse = {};
    if (size(item?.branches_employer)) {
        each(item.branches_employer, el => {
            let comp_key = `${el.branch_id}-${el.branch_key}`;
            if (!config) {
                comp_key += `-${el.employer_id}-${el.employer_key}-${el.rfc}`;
            }
            dataResponse[comp_key] = true;
        });
    }
    return dataResponse;
}

export const actualIndex = (dataItem, userItem, config) => {
    const userData = userItem ? [...userItem] : [];
    if (!userData) return;
    let index = null;
    if (config) {
        index = userData.findIndex(el => (el.branch_id === dataItem.branch_id && el.branch_key === dataItem.branch_key));
    } else {
        index = userData.findIndex(el =>
            el.branch_id === dataItem.branch_id
            && el.branch_key === dataItem.branch_key
            && el.employer_id === dataItem?.employer_id
            && el.employer_key === dataItem?.employer_key
            && el.rfc === dataItem?.rfc);
    }
    return index >= 0 ? index : undefined;
}

export function getItemsData(state, config, userData) {
    const dataSave = [];
    for (let props in state) {
        const value = state[props];
        const info = props.split('-');
        const index = userData.findIndex(
            el => (el?.branch?.id === parseInt(info[0])
                && (el?.branch?.key === info[1]) && config)
                || (!config && (el?.employer_registration?.id === parseInt(info[2])
                    && el?.employer_registration?.rfc === info[4]
                    && el?.branch?.id === parseInt(info[0])
                    && (el?.branch?.key === info[1])))
        );
        const confidential = userData[index]?.confidential ?? 0;
        const attendancePerm = userData[index]?.attendance_access ?? false;
        if (value) {
            let newObj = {
                attendance_access: attendancePerm,
                confidential,
                branch: {
                    id: parseInt(info[0]),
                    key: info[1],
                },
            }
            if (!config) {
                newObj = {
                    ...newObj,
                    employer_registration: {
                        id: parseInt(info[2]),
                        key: info[3],
                        rfc: info[4],
                    },
                }
            }
            dataSave.push(newObj);
        }
    }
    return dataSave;
};

export const internalUserValues = {
    icons: [faUserSlash, faUser],
    title: ["external", "internal"],
    style: ["orangeActionLabel", "blueActionLabel"],
};

export const userTypeValues = {
    icons: [faUser, faMobileNotch],
    title: ["normal", "kiosk"],
    style: ["blueActionLabel", "purpleActionLabel"],
};

export const workerName = (props) => {

    const {
        second_surname,
        first_surname,
        name,
        key,
        include_key
    } = (props ?? {});

    const keyField = key ? `${key} - ` : "";

    const workerName = trim([
        include_key ? keyField : "",
        first_surname ?? "",
        second_surname ?? "",
        name ?? ""
    ].join(" "));

    return workerName;
};

export const copyObject = (object) => (JSON.parse(JSON.stringify(object ?? {})));

export const USER_FIELDS_EXCLUDE = [
    'is_verified',
    'last_login',
    'force_change_password',
    'active_days',
    'admin_workgroups',
    'cancel_mopers',
    'moper_attendance',
    'cancel_moper_permissions',
].join(',');

export const USER_FIELDS_EXCLUDE_ATTENDANCE = [
    'is_verified',
    'last_login',
    'force_change_password',
    // 'groups_perms',
    'payroll_permissions',
].join(',');