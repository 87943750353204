import PropTypes from "prop-types";
import React from 'react';
import { useTranslation } from "react-i18next";
import Button from "../../../core/styled/Button";
import PermissionsGate from '../../../permissions/PermissionsGate';
import IconUse from '../../../core/common/IconUse';
import { valueOrOption } from '../../../core/common/GeneralUtilities';

function Create({
	sx,
	onClick,
	style = {},
	className,
	disabled = false,
	title,
	scopeProps = {},
}) {
	const { t } = useTranslation();
	return (
		<PermissionsGate scopes={['write']} {...scopeProps}>
			<Button design='contained' style={style} disabled={disabled} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy="create">
				<IconUse icon={"new"} size={sx || "1x"} />
				<span className="buttonLabel">
					{valueOrOption(title, t('add-new'))}
				</span>
			</Button>
		</PermissionsGate>
	);
};

Create.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	scopeProps: PropTypes.object,
	style: PropTypes.object,
	sx: PropTypes.string,
	title: PropTypes.any
};

export default Create;
