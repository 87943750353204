import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { initialFilter, initialPage } from "../../../../../App/components/GridCustomProps";
import { initialForm } from "../Components/constants";
import { lockedWindow, unlockedWindow } from "../../../../../store/actions";
import { companyApi, corporateAPI } from "../../../../services/company";
import { countriesAPI } from "../../../../services/countries";
import { resolveError } from "../../../../common/resolve-error";
import { parseRequest } from "../../../../common/parse-request";

function useGrid() {

    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [data, setData] = useState({ data: [], total: 0 });
    const [page, setPage] = useState(initialPage);
    const [dataCountry, setDataCountry] = useState([]);
    const [filters, setFilters] = useState(initialFilter);
    const [corporatives, setCorporatives] = useState(initialFilter);
    const [form, setForm] = useState(initialForm);

    useEffect(() => {
        getCorporative();
    }, []);

    useEffect(() => {
        if (data.total === 0 && page.skip && init) {
            setPage(initialPage);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (init) {
            getCompanies(filters, page);
        }
        // eslint-disable-next-line
    }, [page]);

    /* Refresh */
    const refresh = (newFilters) => getCompanies(newFilters, initialPage);

    const getCorporative = async () => {
        dispatch(lockedWindow());
        try {
            let response = await corporateAPI.get({});
            setCorporatives(response?.results);
            getCompanies(filters, page);
            let countries = await countriesAPI.get({ tree: '' })
            setDataCountry(countries)
        } catch (error) {
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    };

    const getCompanies = async (newFilter, newPagination) => {
        dispatch(lockedWindow());
        setFilters(newFilter);
        setInit(true);
        const params = parseRequest({}, newFilter, newPagination,);
        try {
            let response = await companyApi.get(params);
            setData({
                data: response.results,
                total: response.count
            });
        } catch (error) {
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    };

    const addEdit = (item) => {
        const itemToEdit = { ...item };
        setForm({
            open: true,
            isEdit: !!item?.id,
            item: itemToEdit
        });
    };

    const onClose = () => {
        setForm(initialForm);
    }

    const loadCompanies = () => {
        getCompanies(filters, page);
    }

    return {
        onClose,
        loadCompanies,
        getCompanies,
        addEdit,
        refresh,
        setPage,
        setForm,
        page,
        data,
        form,
        filters,
        dataCountry,
        corporatives,
    };
};

const GridContext = createContext({});

export const useGridContext = () => useContext(GridContext);

export default function GridController({
    children,
}) {
    const values = useGrid();

    return (
        <GridContext.Provider value={values}>
            {children}
        </GridContext.Provider>
    );
};

GridController.propTypes = {
    children: PropTypes.any
};