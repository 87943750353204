import React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import LottieUse from '../../../core/common/LottieUse';

export const Scanning = () => {

	const { t } = useTranslation();
	return (
		<div>
			<LottieUse loop icon={'scanning'} style={{ height: '250px' }} />
			<Typography sx={{ color: "var(--MuiBlue)" }} variant="h5" gutterBottom>
				{t('analyzing-content')}
			</Typography>
		</div>
	);
};
