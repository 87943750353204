import { remove } from "lodash";
import {
  faUserSlash,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import lang from "i18next";
import { trim } from '../../common/validations/validationFilter';
import { store } from "../../../store";
import { stringValidation } from '../../common/validations/stringValidation';

const { t } = lang;

export const structureFields = () => store.getState()?.configuration?.structure?.filter(el => el?.value)?.map(el => el?.key) ?? [];

export const workgroupFilterFields = (structure) => {
  return {
    "Filtrar por grupo de trabajo": [
      ["name", "name"],
      ["structure-filters", { structure: structure }]
    ],
    "Filtrar por Colaborador": [
      ["worker_key", "key"],
      ["worker_first_surname", "first-surname", { validation: () => stringValidation({ min: 3, max: 50, type: "capital" }) }],
      ["worker_second_surname", "second-surname", { validation: () => stringValidation({ min: 3, max: 50, type: "capital" }) }],
      ["worker_name", "name", { validation: () => stringValidation({ min: 3, max: 50, type: "capital" }) }],
    ],
    "Filtrar por usuarios": [
      ["username", "username", "input"],
      ["email", "email", "input"],
    ]
  };
};

function workerObject(params) {
  const { id, first_surname, second_surname, name, key } = params;
  return {
    id,
    first_surname,
    second_surname,
    name,
    key,
  };
}

function jobPositionObject(params) {
  const { id, key, name } = params;
  return {
    id,
    key,
    name,
    workers: [],
  };
}

export const sortKey = data => {
  return data.sort((item1, item2) => {
    const key1 = item1?.key;
    const key2 = item2?.key;
    const matchA = key1?.match(/^([A-Z])(\d+)$/);
    const matchB = key2?.match(/^([A-Z])(\d+)$/);
    if (matchA && matchB) {
      const [charA, numA] = [matchA[1], parseInt(matchA[2], 10)];
      const [charB, numB] = [matchB[1], parseInt(matchB[2], 10)];
      if (charA < charB) return -1;
      if (charA > charB) return 1;
      return numA - numB;
    }
    return 0;
  });
};

export const mapJobPositions = (value) => {
  value.forEach((element) => {
    const data = [];
    const workers = element.workers;

    if (workers.length) {
      workers.forEach((worker) => {

        const job_position =
          worker.worker.enterprise_structure_organization.enterprise_structure
            .job_position;
        const index = data.findIndex((i) => i.key === job_position.key);

        if (index !== -1) {
          data[index].workers.push(workerObject(worker.worker));
        } else {
          const object = {
            id: job_position.id,
            key: job_position.key,
            name: job_position.name,
            workers: [workerObject(worker.worker)],
          };
          data.push(object);
        }
      });
    }

    element.enterprise_structures.forEach((f) => {
      const already = data.some(el => el?.key === f?.job_position?.key);
      if (!already) {
        data.push(jobPositionObject(f.job_position));
      }
    });

    // if (!data.length) {
    //   element.enterprise_structures.forEach((f) => {
    //     data.push(jobPositionObject(f.job_position));
    //   });
    // } else {
    //   const result = element.enterprise_structures.filter((o1) => {
    //     return !data.some((o2) => {
    //       return o1?.job_position?.key === o2?.key;
    //     });
    //   });
    //   if (result?.job_position) {
    //     result.forEach((f) => {
    //       data.push(jobPositionObject(f.job_position));
    //     });
    //   }
    // }

    element.job_positions = sortKey(data);
  });
  return value;
};

export const mapFlows = (data) => {
  const flow_signature = JSON.parse(JSON.stringify(data));
  flow_signature.map(m => {
    m.is_signed = true;
    m.can_sign = true;
    m.user_sign = true;
    m.workgroup_id = m.workgroup?.id;
    m.name = m.administrator?.name;
    m.signed_by = "User";
    return m;
  });
  return { flow_signature };
};

export const removeItems = (request) => {
  //Se eliminan trabajadores de las estructuras que primero se seleccionaron y despues se quitaron
  const options_job_position = request.options_job_position;
  const copyWorkers = [...(request.workers ?? [])];
  const copyOptionsWorker = { ...request.options_worker };
  Object.keys(options_job_position ?? {}).forEach((f) => {
    if (!options_job_position[f]) {
      remove(copyWorkers, (item) => {
        const validation =
          item.worker?.enterprise_structure_organization?.enterprise_structure
            ?.id === parseInt(f);
        if (validation) {
          copyOptionsWorker[item.worker?.id] = false;
        }
        return validation;
      });
    }
  });
  request.workers = request?.workers ? copyWorkers : undefined;
  request.options_worker = request?.options_worker ? copyOptionsWorker : undefined;
  return request;
};

export const CATALOGS_APIS = {
  branch: "branches",
  sub_branch: "sub_branches",
  area: "areas",
  sub_area: "sub_areas",
  department: "departments",
  sub_department: "sub_departments",
  job_position: "job-positions",
  employer_registration: "employer-registration"
};

export const myStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  borderBottom: "14px solid transparent",
  borderLeft: "14px solid #0092c1",
};

export const internalUserValues = {
  icons: [faUserSlash, faUser],
  title: ["external", "internal"],
  style: ["orangeActionLabel", "blueActionLabel"],
};

export const userTypes = () => {
  return (
    [
      { value: true, label: t("internal") },
      { value: false, label: t("external") }
    ]
  )
};

export const workerName = (props) => {

  const {
    second_surname,
    first_surname,
    name,
    key,
    include_key
  } = (props ?? {});

  const keyField = key ? `${key} - ` : "";

  const workerName = trim([
    include_key ? keyField : "",
    second_surname ?? "",
    first_surname ?? "",
    name ?? ""
  ].join(" "));

  return workerName;
};