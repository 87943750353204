import axios from "axios";
import { isFunction } from "lodash";
import { store } from "../../store";
import { lockedWindow, unlockedWindow } from "../../store/actions";
import { resolveError } from "../common/resolve-error";

/**
 * Funcion general para implementar los servicios del sistema
 * @param {Api} httpService - Es el servició axios junto a los parametros que se va a ejecutar
 * @param {Function} callback - La función a ejecutar al finalizar le implementación
 * @param {?boolean} unlockWhenFinally - Define si se desbloquea la pantalla cuando termina la ejecución, Muy util en serviciós en cadena, por default en true
 * @param {?Function} onFailed - Función custom cuando falle la ejecución del servicio
 * @param {?object} paramsLabels - Es un objeto que contiene eldiccionario de los parametros que se están enviando,
 * este parametro es útil en caso de que el request falle y el parametro no corresponda a una traduccion literal
 * ejemplo: {payroll_period : "periodo"}
 * @returns {promise}
 */
export const implementService = async (
	httpService,
	callback,
	onFailed,
	unlockWhenFinally = true,
	paramsLabels = {},
	lock = true,
	lockType = "default"
) => {

	/* Desbloquando pantalla de carga */
	const lockWindow = () => {
		store.dispatch(lockedWindow(lockType));
	};

	/* Desbloquando pantalla de carga */
	const unlockWindow = () => {
		store.dispatch(unlockedWindow());
	};

	/* Resolviendo el error en advertencia o error no controlado */
	const failure = (error) => {
		unlockWindow();
		if (axios.isCancel(error)) { return; }
		if (onFailed && isFunction(onFailed)) {
			return onFailed(error);
		}
		resolveError(error, paramsLabels);
	};

	const success = async (response) => {
		if (isFunction(callback)) {
			await callback(response);
		}

		if (unlockWhenFinally) {
			unlockWindow();
		}
		return response;
	};

	/* Metodo princpal a ejecutar */
	const execute = async () => {
		if (lock) lockWindow();
		return await httpService
			.then(success)
			.catch(failure);
	};

	return execute();
};

/**
 * Función para implementar el servició por medio de la parametrización por un objeto, por si solo se quieren usar algunas de
 * las propiedades del implementService sin tener que declar en null o undefinded las que no se requieran
 * @param {object} args
 * @param {int} args.id - El id del elemento que se va a cambiar para apis PUT
 * @param {axiosService} args.apiMethod - Es el servició que se va a ejecutar
 * @param {object} args.params - Los parametros que se van a incluir al momento de ejectura el servició
 * @param {function} args.onSuccess - La función a ejecutar al finalizar le implementación
 * @param {function} args.onFailed - Define si se desbloquea la pantalla cuando termina la ejecución, Muy util en serviciós en cadena, por default en true
 * @param {string} args.route_extension - La ruta extensiva para el serviscio que se está usando
 * @param {boolean} args.unlockWhenFinally - Función custom cuando falle la ejecución del servicio
 * @param {object} args.paramsLabels - Es un objeto que contiene eldiccionario de los parametros que se están enviando,
 * @param {boolean} args.lock - Indica si la vista se va a bloquear o no
 * @param {?"calculating"|"scanning"|"default"} args.lockType - Indica el tipo de bloqueo de pantalla que se va a utilizar
 * este parametro es útil en caso de que el request falle y el parametro no corresponda a una traduccion literal
 * ejemplo: {payroll_period : "periodo"}
 * @returns {implementService}
 */

export const loadService = async ({
	id,
	apiMethod,
	params = {},
	onSuccess,
	onFailed,
	route_extension,
	unlockWhenFinally = true,
	paramsLabels = {},
	lock = true,
	lockType = "default"
}) => await implementService(
	id ? apiMethod(id, params, route_extension) : apiMethod(params, route_extension),
	onSuccess,
	onFailed,
	unlockWhenFinally,
	paramsLabels,
	lock,
	lockType
);