
var saludate = '';

export const getSaludate = () => {
  const currentTime = new Date().getHours();
  if (currentTime < 12) {
    saludate = "¡Buenos dias!"
    return saludateToList(saludate)
  } else if (currentTime < 18) {
    saludate = "¡Buenas tardes!"
    return saludateToList(saludate)
  } else {
    saludate = "¡Buenas noches!"
    return saludateToList(saludate)
  }
}

const saludateToList = (saludate) => {
  var letterList = [];
  let i = 0;
  while (i < saludate.length) {
    letterList.push(saludate[i]);
    i = i + 1;
  }
  return letterList
}

export const formatWorkerName = (worker) => {
  let name = worker?.name?.split(' ')
  let firstSurname = worker?.first_surname
  name = `${name[0]} ${firstSurname}` 
  let workerName = name.split(' ')
  return workerName;
};