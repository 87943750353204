import PropTypes from "prop-types";
import React, {
	useContext,
	createContext,
	useEffect,
	useState
} from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import { hasValue, valueOrOption } from '../../../common/GeneralUtilities';
import { hideAdvanceFilters } from '../../../../store/actions';
import { each, pick, size } from 'lodash';
import { defaultValidSchema } from './ConstantsAndUtilities/validations';

const useAdvanceFiltersController = () => {

	const {
		show,
		onFilter,
		fields,
		initialFilters,
		filters,
		ValidationSchema,
	} = useSelector(state => state.filter);

	const cleaned = initialFilters?.filters ?? initialFilters ?? {};

	const dispatch = useDispatch();
	const [fieldsLabels, setFieldsLabels] = useState({});
	const [defaults, setDefaults] = useState({});

	const {
		reset,
		handleSubmit,
		control,
		setValue,
		getValues,
	} = useForm({
		resolver: yupResolver(valueOrOption(ValidationSchema, defaultValidSchema(show, fields))),
		mode: "onSubmit",
		defaultValues: cleaned
	});

	useEffect(() => {
		if (!show) { return; }
		setFormParameters(fields);
		//eslint-disable-next-line
	}, [show]);

	useEffect(() => {
		mapAndReset(filters?.filters ?? filters ?? {});
		//eslint-disable-next-line
	}, [defaults]);

	const mapAndReset = (filters) => {
		let toReset = {};
		each(Object.keys(defaults), field => {
			toReset[field] = filters?.[field] ?? defaults[field] ?? null;
		});
		reset(toReset);
	};

	const setFormParameters = (groups) => {
		if (!hasValue(fields)) {
			setFieldsLabels({});
			return;
		}
		let tempFieldsLabels = {};
		let tempDefaults = {};
		each(groups, groupFields => {
			each(groupFields, field => {
				if (hasValue(field)) {
					const name = field[0];
					const label = field[1];
					tempFieldsLabels[name] = label;

					tempDefaults[name] = valueOrOption(cleaned[name], null);
				}
			});
		});
		setFieldsLabels(tempFieldsLabels);
		setDefaults(tempDefaults);
	};

	const onClose = () => dispatch(hideAdvanceFilters());

	const onSubmit = (newFilters) => {
		const values = Object.keys(newFilters).filter(key => key !== "filters" && hasValue(newFilters[key]));
		let toSet = {};
		if (size(values)) {
			toSet = valueOrOption(newFilters, {});
			toSet = pick(toSet, values);
		}
		onFilter({ filters: toSet });
		onClose();
	};

	const onClean = () => {
		reset(defaults);
		onFilter(valueOrOption({ filters: cleaned }));
		onClose();
	};

	return {
		/* hook form attributes */
		handleSubmit,
		control,
		setValue,
		getValues,
		/* Advance filters attributes */
		open: show,
		defaultValues: cleaned,
		fields,
		onClose,
		onSubmit,
		onClean,
		fieldsLabels,
	};
};

const AdvanceFiltersContext = createContext();
/**
 * Contextualizador para los filtros avanzados
 * @returns {useAdvanceFiltersController()}
 */
export const useAdvanceFilters = () => useContext(AdvanceFiltersContext);

export default useAdvanceFilters;

export const AdvanceFiltersProvider = ({ children }) => (
	<AdvanceFiltersContext.Provider value={useAdvanceFiltersController()}>
		{children}
	</AdvanceFiltersContext.Provider>
);

AdvanceFiltersProvider.propTypes = {
	children: PropTypes.any
};
