import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import { isNumber } from "lodash";
import Message from './Message';
import { NotificationMessage } from "../../../core/modules/MOPERSByWorker/components/container/Overtime/components/Signatures";
import IconUse from "../../../core/common/IconUse";

const BodyMessage = ({
	message,
	taskId,
	currentTask,
	percentage,
	onCancel,
}) => {

	const [cancel, setCancel] = useState(false);
	const handleCancel = () => {
		setCancel(!cancel);
	};

	const handleConfirmCancel = () => {
		onCancel(taskId);
		toast.dismiss(taskId);
		setCancel(false);
	};

	return (
		<div className="body-message-socket">
			<div
				className='socket-message'
				key={`body-socket-${taskId}`}
			>
				<NotificationMessage
					title={'Tarea en proceso'}
					subtitle={currentTask?.module}
					message={!cancel ?
						message
						:
						<Message
							taskId={taskId}
							handleCancel={handleCancel}
							handleConfirmCancel={handleConfirmCancel}
						/>}
				/>
			</div>

			{isNumber(percentage) ?
				<span className="percentage">
					{`${parseInt(percentage)}%`}
				</span>
				:
				null
			}

			{!cancel ?
				<div className="socket-icons-wrapper">
					<button
						className="cancel-socket"
						onClick={handleCancel}
					>
						<IconUse icon="SlimClose" />
					</button>
				</div>
				:
				null
			}
		</div>
	);
};

export default BodyMessage;

BodyMessage.propTypes = {
	message: PropTypes.string,
	taskId: PropTypes.string,
	currentTask: PropTypes.object,
	percentage: PropTypes.number,
	onCancel: PropTypes.func,
};