import {
	ServerAPI,
	getUrl,
	ParseParams,
} from "./ServerAPI";
import axios from 'axios';
import { config, configMedia } from "./config";

const CompanyAPI = () => {

	const baseName = "companies";
	const baseApi = ServerAPI(baseName, false);

	function downloadConfigXML(idCompany) {
		const url = getUrl(`${baseName}/${idCompany}/check-config-file`);
		const params = ParseParams({
			params: {
				observe: 'response',
				responseType: 'json'
			},
			include_tenant: false,
			include_config: true
		});

		return axios.get(url, params)
	}

	const updateLogo = (id, data) => {
		const url = getUrl(`${baseName}/${id}`, true);
		return axios.patch(url, data, data?.logo ? configMedia() : config())
			.then((response) => response.data);
	}

	const updateSsoConfigs = (params) => {
		const url = getUrl(`sso-settings/generate-configs`, true);
		return axios.post(url, params, config())
			.then((response) => response.data);
	}

	const generateToken = (id, params) => {
		const url = getUrl(`${baseName}/${id}/token-migration`);
		return axios.post(url, params, config()).then(resp => resp.data);
	}

	const assignPayrollSettings = (id, params) => {
		const url = getUrl(`${baseName}/${id}/payroll-settings`);
		return axios.put(url, params, config()).then(resp => resp.data);
	}

	const cloneSettings = (id, params) => {
		const url = getUrl(`${baseName}/${id}/clone-settings`);
		return axios.put(url, params, config()).then(resp => resp.data);
	}

	const cloneFormats = (id, params) => {
		const url = getUrl(`${baseName}/${id}/clone-formats`);
		return axios.put(url, params, config()).then(resp => resp.data);
	}

	return {
		...baseApi,
		generateToken,
		cloneFormats,
		cloneSettings,
		assignPayrollSettings,
		downloadConfigXML,
		updateLogo,
		updateSsoConfigs,
	};
};
// TODO: Check company settings use of tenant in URL
export const companyApi = CompanyAPI();
export const companyCountryApi = ServerAPI("get-tenant-country").get;
export const corporateAPI = ServerAPI('corporate', false);