import React, { useRef } from "react";
import { useWatch } from "react-hook-form";
import FormInput from "../../../../../@components/form/Field";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import UserAvatar from "../../Avatar";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useDialogContext } from "../../../hooks/DialogController";

function UserView() {
    const { t } = useTranslation();

    const {
        control,
        handleSelectWorker,
        handleKeyPress,
        handleInternalUser,
        inAttendance,
        isEdit,
    } = useDialogContext();

    const internalUser = useWatch({ control, name: `internal_user` });
    const nameField = !internalUser ? 'external_name' : 'worker.full_name';

    return (
        <Grid container sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <Grid item container xs={12} md={3}>
                <UserAvatar />
            </Grid>
            <Grid item container xs={12} md={9} spacing={isEdit ? 3 : 2.5}>
                {(inAttendance && !isEdit) &&
                    <Grid item container xs={12} className="user-type-wrap">
                        <UserTypeSelection />
                    </Grid>}
                <Grid item xs={6}>
                    <FormInput
                        fieldInput="switch"
                        control={control}
                        name={`internal_user`}
                        onChange={handleInternalUser}
                        label={t('internal-user')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormInput
                        autoFocus
                        selectBy={null}
                        fieldInput="WorkerSearch"
                        with_permission_user={false}
                        control={control}
                        name={`worker.key`}
                        label={t("key")}
                        inputProps={{ maxLength: 11 }}
                        handleSelect={handleSelectWorker}
                        onKeyPress={handleKeyPress}
                        onBlur={handleKeyPress}
                        multi={false}
                        isRequired={internalUser}
                        disabled={!internalUser}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormInput
                        fieldInput="textfield"
                        control={control}
                        name={nameField}
                        autoFocus={!internalUser}
                        label={t(internalUser ? 'full-name' : 'external-name')}
                        isRequired={!internalUser}
                        disabled={internalUser}
                        inputProps={{ maxLength: 101 }}
                    />
                </Grid>
                <BasicFields />
                <Grid item xs={6}>
                    <FormInput
                        fieldInput="switch"
                        control={control}
                        name="is_active"
                        label={t('is-active')}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default UserView;

export const UserTypeSelection = () => {
    const { t } = useTranslation();
    const { control } = useDialogContext();

    UserTypeSelection.propTypes = {
        validateDisabled: PropTypes.bool,
    };

    return (
        <FormInput
            fieldInput="Radiogroup"
            control={control}
            name="user_type"
            className="user-type-input"
            options={[
                { id: 1, name: t('workgroup-admin3') },
                { id: 2, name: t('workgroup-admin2') },
            ]}
        />
    );
}

const BasicFields = () => {
    const { t } = useTranslation();

    const {
        control,
        trigger,
        getValues,
        required,
        emailLoading,
        usernameLoading,
        passwordValidation,
        onUsernameChange,
        onEmailChange,
        loginMode,
        isSSO,
    } = useDialogContext();

    const debounceValidation = useRef(debounce(validateTrigger, 300)).current;
    const password = useWatch({ control, name: 'password' });

    function validateTrigger(fields) {
        fields = fields.filter(el => {
            const value = getValues(el);
            return value;
        });
        trigger(fields);
    }

    return (
        <>
            <Grid item xs={6}>
                <FormInput
                    fieldInput="textfield"
                    control={control}
                    name="username"
                    label={t('username')}
                    loading={usernameLoading}
                    isRequired={loginMode !== 1 && !isSSO}
                    onChange={onUsernameChange}
                    uppercase={false}
                    inputProps={{ maxLength: 51 }}
                    triggerChange={() => debounceValidation(["password"])}
                />
            </Grid>
            <Grid item xs={6}>
                <FormInput
                    fieldInput="textfield"
                    control={control}
                    name="email"
                    loading={emailLoading}
                    label={t('email')}
                    isRequired={loginMode === 1 || isSSO}
                    onChange={onEmailChange}
                    inputProps={{ maxLength: 101 }}
                />
            </Grid>
            {!isSSO &&
                <>
                    <Grid item xs={6}>
                        <FormInput
                            fieldInput="textfield"
                            control={control}
                            name="password"
                            type="password"
                            label={t('password')}
                            isRequired={required}
                            pasEye={true}
                            onChange={passwordValidation}
                            inputProps={{ maxLength: 51 }}
                            triggerChange={() => debounceValidation(["password2", "username"])}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormInput
                            fieldInput="textfield"
                            control={control}
                            name="password2"
                            type="password"
                            label={t('repeat-password')}
                            isRequired={password}
                            disabled={!password}
                            pasEye={true}
                            onChange={passwordValidation}
                            inputProps={{ maxLength: 51 }}
                        />
                    </Grid>
                </>
            }
        </>
    );
};