import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useForm as useReactForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  lockedWindow,
  showConfirmNotification,
  showNotificationError,
  unlockedWindow,
} from "../../../../store/actions";
import { idseReportApi } from "../../../services/idse";
import FileSaver from "file-saver";
import { ValidationSchema, actions, initValues } from "../utils/Constants";
import { enterpriseStructureOrganizationAPI, getJobPositionsTypeApi } from "../../../services/enterprise-structure";
import { workersApi } from "../../../services/worker";
import { resolveError } from "../../../common/resolve-error";
import { hasValue } from "../../../common/GeneralUtilities";
import { socketsApi } from "../../../services/socket-report";
import TriggerNotificationSocket from "../../../common/trigger-notification-socket";
import {errorGet} from "../../../common/notification-messages";
import {isAbrhil} from "../../../common/auth";

function useForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [workerType, setWorkerType] = useState([]);
  const [workerFull, setWorkerFull] = useState([]);
  const [enterpriseFull, setEnterpriseFull] = useState([]);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState();
  const tenant = useSelector((state) => state.tenant);

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    trigger,
  } = useReactForm({
    resolver: yupResolver(ValidationSchema),
    mode: "onChange",
    defaultValues: initValues,
  });

  const handleLoading = () => {
    if (hasValue(localStorage.getItem("taskID"))) {
      setLoading(true);
      return;
    }
    setLoading(false);
  }

  useEffect(() => {
    window.addEventListener('storage', handleLoading);

    return () => {
      window.removeEventListener('storage', handleLoading);
    }
  }, [])

  useEffect(() => {
    if (isAbrhil()) { return; }

    handleLoading();
    listsRequests();
    getWorkerData();
    getEnterpriseData();
  }, []);

  useEffect(() => {
    if (tenant.current?.id) {
      handleLoading();
      listsRequests();
      getWorkerData();
      getEnterpriseData();
    }

    setInit(false);
  }, [tenant]);

  function handleRefresh() {
    setInit(true);
  }

  const listsRequests = async () => {
    try {
      let params = {
        tree: "",
        select: true,
        distinct: "",
      };

      let response = await getJobPositionsTypeApi.get(params);
      setWorkerType(response);
    } catch (error) {
      /* nothing to do */
    }
  };

  async function getWorkerData() {
    dispatch(lockedWindow());

    let params = {
      fields: "key",
      tree: ""
    };

    try {
      let response = await workersApi.get(params);
      let cloneResponse = response.map((item) => item.key);
      setWorkerFull(cloneResponse);
    } catch (error) {
      dispatch(showNotificationError(errorGet(error)));
    } finally {
      dispatch(unlockedWindow());
    }
  }

  async function getEnterpriseData() {
    dispatch(lockedWindow());

    let params = {
      fields: "enterprise_structure.id",
      tree: ""
    };

    try {
      let response = await enterpriseStructureOrganizationAPI.get(params);
      let cloneResponse = response.map((item) => item.enterprise_structure);
      setEnterpriseFull(cloneResponse);
    } catch (error) {
      resolveError(error);
    } finally {
      dispatch(unlockedWindow());
    }
  }

  const handleDownload = (values) => {
    dispatch(
      showConfirmNotification({
        type: "info",
        maxWidth: "sm",
        message:
          "Se generará el reporte de los movimientos de todos los colaboradores en el rango de fecha seleccionado",
        onConfirm: () => {
          getReport(values);
        },
      })
    );
  };

  async function getReport(values) {

    let keys = values.keys;
    let enterprise = values.enterprise;

    if (values.action === undefined || parseInt(values.action) === 0) {
      values.action = actions[0].option;
      keys = null;
    } else {
      values.action = actions[1].option;
    }

    const mov_sua =
      values.movement_by_sua.lenght > 1 ? values.movement_by_sua.option : null;
    const workerType =
      values.worker_type.lenght > 1 ? values.worker_type.option : null;

    let movementTypes = [];

    for (const element of values.movement_type) {
      movementTypes.push(element.option);
    }

    const body = {
      search_worker: values.action,
      initial_date: values.initial_date,
      end_date: values.end_date,
      keys: values.check_all_keys ? workerFull : keys,
      enterprise_structures: values.check_all_enterprise ? enterpriseFull : enterprise,
      capture_date: values.use_capture_date,
      summary: values.resume,
      movement_type: movementTypes,
      group_by: values.ordenation_type,
      worker_type: workerType,
      sua_movements: mov_sua,
    };

    try {
      let response = await idseReportApi.workersReport(body);
      TriggerNotificationSocket('worker_movements', response.task);
    } catch (error) {
      dispatch(
        showNotificationError({
          message: t("no-results"),
        })
      );
    }
  }

  return {
    handleDownload,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    trigger,
    workerType,
    enterpriseFull,
    workerFull,
    init,
    setInit,
    handleRefresh,
    loading,
  };
}

export const downloadWorkerReport = async (taskID) => {
  try {
    let response = await socketsApi.downloadReport(taskID);
    FileSaver.saveAs(
      response,
      `${"Reporte de movimientos"}_${format(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      )}`
    );
  } catch (error) {
    return error;
  }
}

export default useForm;
