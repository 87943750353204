import { useState } from "react";
import { absenteeismAPI } from "../services/payroll";
import { implementService } from "../services/implemet-service";
import { resolveError } from "../common/resolve-error";

export default function useAbsenteeismList() {

  const [absenteeism, setAbsenteeism] = useState([]);

  const getAbsenteeismList = async (locked = true) => {
    implementService(absenteeismAPI.get({
      is_active: true,
      tree: ""
    }), (response) => {
      setAbsenteeism(response);
    }, (error) => resolveError(error), locked, {}, locked);
  };

  return {
    absenteeism,
    getAbsenteeismList
  };
}
