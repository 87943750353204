import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { debounce, isBoolean, size } from "lodash";
import commands, { clearAccents } from "../utils/commands";
import { ACTIVATE_KEYS } from "../utils/keys";
import { setSpeech } from "../../../../store/actions";
import useAbrhilNavigation from "../../../../core/@components/navigation/contextsAndControllers/useAbrhilNavigation";

const ABRHIL_MESSAGE = "En que puedo ayudarte?";
const listeningProps = { continuous: true, language: 'es-MX' };

function useSpeechRecon() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { openWindowItem } = useAbrhilNavigation();
    const menu = useSelector((state) => state.navigate?.routes);
    const perms = useSelector((state) => state.configuration?.abrhil_package?.VOICE_ASSISTANCE_MODULE?.value);
    const config = useSelector((state) => state.userConfigs?.configs?.voice_assistance);
    const { is_always_listening } = useSelector((state) => state.userConfigs?.configs) ?? {};
    const customMessage = useSelector((state) => state.speech?.message);
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [always, setAlways] = useState(false);
    const [listening, setListening] = useState(false);
    const [startTyping, setStartTyping] = useState(false);
    const [message, setMessage] = useState(ABRHIL_MESSAGE);
    const [showTranscript, setShowTranscript] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [visibility, setVisibility] = useState(true);
    const [isHover, setIsHover] = useState(true);
    const shouldHide = useRef(true);
    const command = useRef(null);
    const shouldStop = useRef(true);
    const timer = useRef(null);

    function handleButtonHover(e) {
        if (e.type === 'mouseenter') {
            handleMouseEnter();
        } else if (e.type === 'mouseleave') {
            handleMouseLeave();
        }
    }

    useEffect(() => {
        if (customMessage) {
            handleMouseEnter();
            setShowTranscript(true);
            setMessage(customMessage);
            setTimeout(() => {
                handleMouseLeave();
                setShowTranscript(false);
                setMessage(null);
                dispatch(setSpeech(null));
            }, 2500);
        }
    }, [customMessage])

    useEffect(() => {
        if (isBoolean(is_always_listening)) {
            setAlways(is_always_listening);
        }
    }, [is_always_listening])

    useEffect(() => {
        let speechButton = document.querySelector('#speech-wrap .button-wrapper');
        handleMouseLeave();
        if (speechButton && showButton) {
            speechButton?.addEventListener('mouseleave', handleButtonHover);
            speechButton?.addEventListener('mouseenter', handleButtonHover);
            return () => {
                speechButton?.removeEventListener('mouseleave', handleButtonHover);
                speechButton?.removeEventListener('mouseenter', handleButtonHover);
            }
        }
    }, [showButton])

    useEffect(() => {
        if (size(menu) && navigator?.mediaDevices?.addEventListener && perms && config) {
            const handleDeviceChange = async () => {
                try {
                    const devices = await navigator.mediaDevices.enumerateDevices();
                    const hasMicrophone = devices.some(device => device.kind === 'audioinput');
                    setShowButton(hasMicrophone);
                    shouldStop.current = !always;
                    if (always && hasMicrophone) {
                        SpeechRecognition.startListening(listeningProps);
                    } else {
                        SpeechRecognition.stopListening();
                    }
                } catch (error) {
                    console.error('Error accessing microphone:', error);
                }
            };
            handleDeviceChange();
            navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
            return () => {
                navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
            };
        }
    }, [menu, perms, config, always]);

    useEffect(() => {
        command.current = transcript;
        if (listening) {
            debounceStop();
            return;
        }
        debounceStopListening();
    }, [transcript, listening, startTyping])

    function handleMouseLeave() {
        timer.current = setTimeout(() => {
            if (shouldHide.current) {
                setIsHover(false);
            }
        }, 3000);
    }

    function handleMouseEnter() {
        clearTimeout(timer.current);
        setIsHover(true);
    }

    function handleVisibility() {
        setVisibility(!visibility);
    }

    async function handleListening() {
        await getLocalStream();
        handleMouseEnter();
        setShowTranscript(true);
        setStartTyping(true);
        setVisibility(true);
        shouldHide.current = false;
        setMessage(ABRHIL_MESSAGE);
    }

    function handleStop() {
        setStartTyping(false);
        setListening(false);
        shouldHide.current = true;
        if (!isHover) {
            handleMouseLeave();
        }
        commands(command.current, navigate, null, dispatch, location, openWindowItem);
        setShowTranscript(false);
        setMessage(null);
        command.current = null;
        if (shouldStop.current) {
            SpeechRecognition.stopListening();
        }
        resetTranscript();
    }

    function handleHideStop() {
        const speech = clearAccents(command.current?.toLowerCase());
        if (ACTIVATE_KEYS.includes(speech)) {
            commands(command.current, navigate, handleListening, dispatch, openWindowItem);
        }
        command.current = null;
        shouldHide.current = true;
        resetTranscript();
        if (!isHover) {
            handleMouseLeave();
        }
    }

    const debounceStopListening = useCallback(debounce(() => handleHideStop(), 1300), []);

    const debounceStop = useCallback(debounce(() => handleStop(), 2500), []);

    async function getLocalStream() {
        if (navigator?.mediaDevices?.getUserMedia) {
            await navigator.mediaDevices
                .getUserMedia({ video: false, audio: true })
                .then(() => {
                    setListening(true);
                    shouldHide.current = false;
                    SpeechRecognition.startListening(listeningProps);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    return {
        listening,
        handleListening,
        handleStop,
        handleVisibility,
        showTranscript,
        startTyping,
        message,
        isHover,
        transcript,
        showButton,
        command,
    };
}

export default useSpeechRecon;