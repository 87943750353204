import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmNotification, stampType } from '../../../../../store/actions';
import { closeWindow } from '../../../../../store/triggers/navigate';
import { CustomTooltip } from '../../../../../App/components/Templates/cells';
import timbraLogo from '../../assets/images/timbra-logo.png';
import abrhillogo from '../../assets/images/abrhil-logo.png';


const Timbrasoft = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { active_windows } = useSelector(state => state.navigate);
	const { stamp } = useSelector(state => state.app);
	//const currentStamp = localStorage.getItem('stamp') === "true" ? true : false;

	const changeApp = () => {
		localStorage.setItem('stamp', !stamp);
		dispatch(stampType(!stamp))
	};

	const CloseTabs = () => {
		for (let index = 1; index <= 6; index++) {
			const key = `window_${index}`;
			dispatch(closeWindow(key));
		}
		changeApp();
	}

	const confirmChange = () => {
		if(!(active_windows.length)){
			changeApp();
			return
		}

		dispatch(
			showConfirmNotification({
				type: "warning",
				title: t("change-app-warning"),
				maxWidth: "md",
				description: t("change-app-message"),
				onConfirm: () => CloseTabs(),
				onCancel: () => {},
				confirmButtonText:t('accept'),
				cancelButtonText:t('cancel')
			})
		)
	}
	return (
		<div className='timbrasoft-container icon-pointer' >
			<CustomTooltip title={stamp ? t("back-abrhil") : t("go-timbrasoft")} className="menu-item-tooltip" placement="left">
				<div onClick={() => confirmChange()}>
					<span className='side-menu-item-icon'>
						{stamp
							? <img src={abrhillogo} alt="abrhil-logo" className='menu-abrhil-logo' />
							: <img src={timbraLogo} alt="timbra-logo" className='menu-timbra-logo' />
						}
					</span>
				</div>
			</CustomTooltip>
		</div>
	);
};

export default Timbrasoft;