import React, { createContext, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useForm, useWatch } from "react-hook-form";

function useFilters(props) {
    const { month, period, year } = props;

    const initValues = {
        month: month ?? null,
        year: year ?? null,
        ...(period ? { period } : {})
    };

    const [filters, setFilters] = useState({ filters: {} });
    const [fields, setFields] = useState({});
    const [hasFilter, setHasFilter] = useState(false);
    const onFilter = useRef({ execute: () => { } });
    const setLoading = useRef({ execute: () => { } });

    const {
        control,
        setValue,
        getValues,
    } = useForm({
        mode: 'onChange',
        defaultValues: initValues,
    });

    const currentPeriod = useWatch({ control: control, name: 'complete_period' }) ?? getValues('complete_period');

    const updateFilters = filters => {
        for (const prop in filters?.filters) {
            const value = filters?.filters?.[prop];
            if (value === undefined || value === null) {
                delete filters?.filters?.[prop];
            }
        }
        setFilters(() => ({ ...filters }));
    };

    const updateFields = fields => setFields(fields);

    const restartValues = () => {
        setFilters({});
        setFields({});
        onFilter.current = { execute: () => { } };
    }

    return {
        filters,
        fields,
        onFilter,
        hasFilter,
        setLoading,
        currentPeriod,
        control,
        getValues,
        setValue,
        updateFilters,
        updateFields,
        restartValues,
        setHasFilter,
    };
};

const FiltersContext = createContext({});

export const useFiltersContext = () => useContext(FiltersContext);

export default function FiltersController({
    children,
    ...others
}) {
    const filterValues = useFilters(others);

    return (
        <FiltersContext.Provider value={filterValues}>
            {children}
        </FiltersContext.Provider>
    );
};

FiltersController.propTypes = {
    children: PropTypes.any,
}