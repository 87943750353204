import { lazy } from '../utilities';

const clocksIcons = {
	audit: {
		allowedTag: "Audit",
		Icon: lazy(async () => import("./Bitacora.svg")),
	},
	rolesandpermissions: {
		allowedTag: "Roles-and-permissions",
		Icon: lazy(async () => import("./RolesAndPermissions.svg")),
	},
	key: {
		allowedTag: "Key",
		Icon: lazy(async () => import("./key.svg")),
	},
	commandkey: {
		allowedTag: "Command-key",
		Icon: lazy(async () => import("./cmKey.svg")),
	},
	expand: {
		allowedTag: "Expand",
		Icon: lazy(async () => import("./expand.svg")),
	},
};

export default clocksIcons;