import axios from 'axios';
import {lockedWindow, unlockedWindow} from '../../store/actions';
import {store} from '../../store';

function configMedia () {
  return {
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    },
  };
}

const items = {
  Grey: true,
  Green: true,
  Yellow: false,
  Orange: false,
  Red: false,
};

export const scanFile = file => {
  store.dispatch (lockedWindow ('scanning'));
  return axios
    .post (process.env.REACT_APP_THREAT_SCAN_API, {file}, configMedia ())
    .then (response => {
      if (!items[response.data.Zone]) {
        throw new SyntaxError (
          `Se encontró una amenaza en ${file.name} de tipo ${response.data.FileGeneralInfo.FileStatus}`
        );
      }
    })
    .finally (() => {
      store.dispatch (unlockedWindow ());
    });
};
