import React, { createContext, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DragAndDrop } from "@progress/kendo-react-common";
import { resolveError } from "../../../../../../common/resolve-error";
import { mopersESApi } from "../../../../../../services/mopers";
import { isFunction, omit } from "lodash";
import { showNotification } from "../../../../../../../App/components/Notifications";

function useDragCheck(props) {
    const { refresh, onChange } = props;
    const [dragged, setDragged] = useState(false);
    const [pressed, setPressed] = useState(false);
    const draggedCheck = useRef(null);
    const currentItem = useRef(null);
    const dropzone = useRef(null);
    const dragElement = useRef(null);
    const wasDropped = useRef(false);

    const switchCheck = async (value, visual = true) => {
        if (value.id_final === draggedCheck.current.id_start
            && value.type_final === draggedCheck.current.type_start) {
            currentItem.current = null;
            wasDropped.current = false;
            return;
        }
        const checkContent = dropzone.current?.firstChild?.firstChild?.firstChild;
        const checkContent2 = dragElement.current?._element?.firstChild?.firstChild;
        const check1 = checkContent2?.children?.[0]?.innerText;
        const clock1 = checkContent2?.children?.[1]?.innerText;
        const check2 = checkContent?.children?.[0]?.innerText;
        const clock2 = checkContent?.children?.[1]?.innerText;
        if (visual) {
            checkContent.children[0].innerText = check1;
            checkContent.children[1].innerText = clock1;
            checkContent2.children[0].innerText = check2;
            checkContent2.children[1].innerText = clock2;
        }
        const request = {
            type: value.id_final ? 1 : 2,
            start_check: draggedCheck.current.id_start,
            final_check: value.id_final,
            check_type_start: draggedCheck.current.type_start,
            check_type_final: value.type_final,
            date_new: value.date_final,
        };
        const keysOmit = value.id_final ? ['date_new'] : ['final_check'];
        const status = await performRequest(omit(request, keysOmit));
        if (status || !visual) return;
        checkContent.children[0].innerText = check2;
        checkContent.children[1].innerText = clock2;
        checkContent2.children[0].innerText = check1;
        checkContent2.children[1].innerText = clock1;
    };

    const performRequest = async (request) => {
        const id = Number(request.start_check);
        try {
            if (isFunction(onChange)) {
                onChange(currentItem.current);
            }
            await mopersESApi.changeES(id, request);
            showNotification({ message: 'El cambio E/S se ha realizado correctamente' });
            if (isFunction(refresh)) {
                refresh(currentItem.current);
            }
            wasDropped.current = false;
            currentItem.current = null;
            return true;
        } catch (error) {
            resolveError(error);
            wasDropped.current = false;
            currentItem.current = null;
            if (isFunction(onChange)) {
                onChange(null);
            }
        }
    }

    return {
        pressed,
        dragged,
        dropzone,
        wasDropped,
        currentItem,
        dragElement,
        draggedCheck,
        switchCheck,
        setDragged,
        setPressed
    };
}

const DragCheckContext = createContext({});

export const useDragContext = () => useContext(DragCheckContext);

export default function DragCheckController({
    children,
    ...others
}) {
    const checkValues = useDragCheck(others);

    return (
        <DragAndDrop>
            <DragCheckContext.Provider value={checkValues}>
                {children}
            </DragCheckContext.Provider>
        </DragAndDrop>
    );
};

DragCheckController.propTypes = {
    children: PropTypes.any
};