import React from 'react';
import { omitBy, isUndefined, groupBy, keys, map, pick } from "lodash";

export const mapData = (data, format) => {
  //Se eliminan los valores que no tengan valor
  const items = omitBy(data, isUndefined)
  if (!format) return items
  //se agrupan por id
  const groupedData = groupBy(keys(items), key => key.match(/\d+/)?.[0])
  // se hace el map para construir el array de objetos
  const result = map(groupedData, (keys, id) => {
    if (!parseInt(id)) return null
    const payData = pick(items, keys);
    return {
      id: parseInt(id),
      ...payData
    };
  }).filter(f => f?.id);
  result.map(m => {
    Object.keys(m).forEach(item => {
      m[item.replace(/\d/g, '').replace(/_([^_]*)$/, '$1')] = m[item]
      if (item.length !== 2) {
        delete m[item];
      }
    })
    return m
  })
  return result
}


export const fields_2 = [
  "day_calculation", //Calcular turno
  "week_journey_hours", //Modificar el horario de Lunes a Viernes
  "weekend_journey_hours", //Modificar el horario de Sábado y Domingo
  "double_multiple", //Multiplo horas dobles
  "multiple_time_for_time", //Multiplo txt
  "triple_multiple", //Multiplo horas triples
  "multiple_double_shift", //Multiplo turno doble
  "multiple_rest_worked", //Multiplo descanso trabajado
  "implicit_night_multiple", //implícitas liberará horas implícitas nocturnas
]

export const fields_3 = [
  "pay_holidays", //Festivo trabajado
  "pay_sunday_bonus", //Prima dominical
  "double_night_multiple", //Horas nocturnas dobles
  "triple_night_multiple", //Horas nocturnas triples
  "mix_implicit", // Implicitas de horario mixto
]

export const messages_2 = {
  day_calculation: <div style={{ display: "inline" }}>
    el cálculo del turno <div className='label-red'>cancelará</div> {`todos los Moper's de tiempo extraordinario que estén en proceso de firma o ya autorizados en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  week_journey_hours: <div style={{ display: "inline" }}>
    el horario de Lunes a Viernes <div className='label-red'>cancelará</div> {`todos los Moper's de tiempo extraordinario que estén en proceso de firma o ya autorizados en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  weekend_journey_hours: <div style={{ display: "inline" }}>
    el horario de Sábado y Domingo <div className='label-red'>cancelará</div> {`todos los Moper's de tiempo extraordinario que estén en proceso de firma o ya autorizados en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  double_multiple: <div style={{ display: "inline" }}>
    el múltiplo de Horas Dobles <div className='label-green'>actualizará</div> {`el importe según el nuevo múltiplo para todos los Moper's de horas dobles solicitadas, incluyendo los ya autorizados en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
    multiple_time_for_time: <div style={{ display: "inline" }}>
    el múltiplo de TXT <div className='label-green'>actualizará</div> {`el múltiplo de todos los Moper's de TXT el periodo actual `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  triple_multiple: <div style={{ display: "inline" }}>
    el múltiplo de Horas Triples <div className='label-green'>actualizará</div> {`el múltiplo de todos los Moper's de horas triples en el periodo actual `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  multiple_double_shift: <div style={{ display: "inline" }}>
    el múltiplo de Turno Doble <div className='label-green'>actualizará</div> {`el múltiplo de todos los Moper's de Turno Doble en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  multiple_rest_worked: <div style={{ display: "inline" }}>
    el múltiplo de Descanso Trabajado <div className='label-green'>actualizará</div> {`el múltiplo de todos los Moper's de Descanso Trabajado en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  implicit_night_multiple: <div style={{ display: "inline" }}>
    el número de implícitas <div className='label-green'>liberará</div> {`horas implícitas nocturnas en el `}
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  more_configs: <div style={{ display: "inline" }}>
    diferentes configuraciones puede <div className='label-red'>cancelar</div> {`todos los Moper's de tiempo extraordinario que estén en proceso de firma o ya autorizados en el `}
    <div className='label-period'>
      periodo actual.&nbsp;
    </div><div className='label-question'>¿Desea continuar?</div>
  </div>,
}

export const messages_3 = {
  pay_holidays: <div style={{ display: "inline" }}>
    el múltiplo de Festivo trabajado <div className='label-green'>actualizará</div>  el múltiplo de todos los Mopers de Festivo trabajado en el&nbsp;
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  pay_sunday_bonus: <div style={{ display: "inline" }}>
    el múltiplo de Prima dominical <div className='label-green'>actualizará</div>  el múltiplo de todos los Mopers de Prima dominical en el&nbsp;
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  double_night_multiple: <div style={{ display: "inline" }}>
    las Horas Nocturnas Dobles <div className='label-green'>actualizará</div>  el múltiplo de todos los Mopers de Turno nocturno en el&nbsp;
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  triple_night_multiple: <div style={{ display: "inline" }}>
    Horas nocturnas triples <div className='label-green'>actualizará</div>  el múltiplo de todos los Mopers de Turno nocturno en el&nbsp;
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  mix_implicit1: <div style={{ display: "inline" }}>
    el número de implícitas <div className='label-green'>liberará</div>  horas implícitas mixtas en el&nbsp;
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
  mix_implicit0: <div style={{ display: "inline" }}>
    el número de implícitas <div className='label-red'>cancelará</div>{` todos los Moper's de horas implícitas mixtas en el`} &nbsp;
    <div className='label-period'>
      periodo actual.
    </div>
  </div>,
}

export const CalculateSocketsDictionary = (tenant) => {

  const getUrl = route => `${tenant}/${route}/`;

  const constructor = (route) => ({
    url: getUrl(route),
  });

  return {
    multi_recalculate_mopers: constructor("multi-recalculate-mopers"),
    recalculate_mopers: constructor("recalculate-mopers"),
    recalculate_mopers_by_worker: constructor("workers-reconstruction-mopers")
  };
};