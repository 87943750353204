import React, { lazy } from "react";

const Corporate = lazy(() => import("../../../../modules/AbrhilApp/Corporate"));
const Company = lazy(() => import('../../../../modules/AbrhilApp/Company'));
const Migrations = lazy(() => import('../../../../modules/AbrhilApp/Migrations'));
const ContractingRegime = lazy(() => import('../../../../modules/TimbrasoftApp/ContractingRegime'));
const ResourceOrigin = lazy(() => import('../../../../modules/TimbrasoftApp/ResourceOrigin'));
const RegimeType = lazy(() => import('../../../../modules/TimbrasoftApp/RegimeType'));
const ProfessionalMinimum = lazy(() => import('../../../../modules/ProfessionalMinimum'));
const CalendarHolidays = lazy(() => import('../../../../modules/AbrhilApp/CalendarHolidays'));
const BloodType = lazy(() => import('../../../../modules/AbrhilApp/Bloodtype'));
const ContractCatalog = lazy(() => import('../../../../modules/AbrhilApp/ContractCatalog'));
const MigratoryState = lazy(() => import('../../../../modules/MigratoryState'));
const WorkerType = lazy(() => import('../../../../modules/WorkerType'));
const JobPositionType = lazy(() => import('../../../../modules/AbrhilApp/JobPositionType'));
const Professions = lazy(() => import('../../../../modules/Professions'));
const UMA = lazy(() => import('../../../../modules/UMA'));
const UMI = lazy(() => import('../../../../modules/UMI'));
const DailyMinimum = lazy(() => import('../../../../modules/AbrhilApp/DailyMinimum'));
const ImssReasonLeaving = lazy(() => import('../../../../modules/ImssReasonLeaving'));
const InabilityClassification = lazy(() => import('../../../../modules/InabilityClassification'));
const InsuranceBranch = lazy(() => import('../../../../modules/InsuranceBranch'));
const InabilityControl = lazy(() => import('../../../../modules/InabilityControl'));
const SequelConsequence = lazy(() => import('../../../../modules/SequelConsequence'));
const TypeOfRisk = lazy(() => import('../../../../modules/TypeOfRisk'));
const Covid = lazy(() => import('../../../../modules/AbrhilApp/Covid'));
const Class = lazy(() => import('../../../../modules/IMSSClass'));
const Division = lazy(() => import('../../../../modules/IMSSDivision'));
const Group = lazy(() => import('../../../../modules/IMSSGroup'));
const Fraction = lazy(() => import('../../../../modules/IMSSFraction'));
const QuotaIMSS = lazy(() => import('../../../../modules/QuotaIMSS'));
const PayrollTypes = lazy(() => import('../../../../modules/PayrollTypes'));
const CatAlimony = lazy(() => import('../../../../modules/CatAlimony'));
const FederalEntitiesTaxes = lazy(() => import('../../../../modules/FederalEntitiesTaxes')); //Revisar duplicidad en Abrhil y Modulos
const PaymentTypes = lazy(() => import('../../../../modules/PaymentTypes'));
const Schematics = lazy(() => import('../../../../modules/Schematics'));
const Periodicity = lazy(() => import('../../../../modules/Periodicity'));
const Banks = lazy(() => import('../../../../modules/AbrhilApp/Banks'));
const Layouts = lazy(() => import('../../../../modules/AbrhilApp/Layouts'));
const Absenteeism = lazy(() => import('../../../../modules/AbrhilApp/Absenteeism'));
const AbsenteeismReasons = lazy(() => import('../../../../modules/AbrhilApp/AbsenteeismReasons'));
const MoperCatalog = lazy(() => import('../../../../modules/Mopers'));
const EducationSTPS = lazy(() => import('../../../../modules/EducationSTPS'));
const Device = lazy(() => import('../../../../modules/ClockApp/Device'));
const HistoryCyV = lazy(() => import('../../../../modules/AbrhilApp/HistoryCyV'));
const Stampconcept = lazy(() => import('../../../../modules/TimbrasoftApp/StampConcept/StampConceptContainer'));
const RFC = lazy(() => import('../../../../modules/TimbrasoftApp/RFC'));
const VoucherBanks = lazy(() => import('../../../../modules/VoucherBanks'));
const VoucherLayouts = lazy(() => import('../../../../modules/VoucherLayouts'));
const PostalCode = lazy(() => import('../../../../modules/PostalCode'));
const FederalEntities = lazy(() => import('../../../../modules/AbrhilApp/FederalEntities'));
const ContractSAT = lazy(() => import('../../../../modules/TimbrasoftApp/ContractSAT'));
const PeriodicitySAT = lazy(() => import('../../../../modules/TimbrasoftApp/PeriodicitySAT'));
const Taxes = lazy(() => import('../../../../modules/Taxes'));


import {
    faFolderGear,
} from "@fortawesome/pro-light-svg-icons";

const abrilItems = [
    {
        id: 'abrhil@corporate',
        type: 'item',
        validateItem: true,
        icon: "corporate",
        componentElm: <Corporate />
    },
    {
        id: 'abrhil@business',
        type: 'item',
        icon: "enterprise",
        componentElm: <Company />,
        sub_routes: [
            {
                index: true, // Indica que será el componente representante
                componentElm: <Company />,
            },
        ]
    },
    {
        id: 'abrhil@migrations',
        type: 'item',
        validateItem: true,
        icon: "migration",
        componentElm: <Migrations />
    },
    {
        id: 'abrhil@packages_admin',
        type: 'item',
        inConstruction: true,
        validateItem: true,
        icon: faFolderGear,
        // componentElm: <PackagesAdmin />
    },
    {
        id: 'abrhil@nationalities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@contract-catalog',
        type: 'item',
        componentElm: <ContractCatalog />
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        componentElm: <FederalEntities />
    },
    {
        id: 'abrhil@migratory-status',
        type: 'item',
        componentElm: <MigratoryState />
    },
    {
        id: 'abrhil@blood-type',
        type: 'item',
        componentElm: <BloodType />
    },
    {
        id: 'abrhil@calendar-holidays',
        type: 'item',
        componentElm: <CalendarHolidays />
    },
    {
        id: 'abrhil@zipcode',
        type: 'item',
        componentElm: <PostalCode />
    },
    {
        id: 'abrhil@coworker-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: <WorkerType />
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <Taxes />
    },
    {
        id: 'abrhil@job-position-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: <JobPositionType />
    },
    // {
    //     id: 'abrhil@position-classification',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@daily-minimun-salary',
        type: 'item',
        componentElm: <DailyMinimum />
    },
    {
        id: 'abrhil@professions',
        type: 'item',
        componentElm: <Professions />
    },
    {
        id: 'abrhil@uma',
        type: 'item',
        componentElm: <UMA />
    },
    {
        id: 'abrhil@umi',
        type: 'item',
        componentElm: <UMI />
    },
    {
        id: 'abrhil@profesional-minimun-salary',
        type: 'item',
        componentElm: <ProfessionalMinimum />
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    // {
    //     id: 'abrhil@municipalities',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@type-sat-regime-list',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <RegimeType />
    },
    {
        id: 'abrhil@pay-frequency-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <PeriodicitySAT />
    },
    {
        id: 'abrhil@resource-origin',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <ResourceOrigin />
    },
    {
        id: 'abrhil@contracting-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <ContractingRegime />
    },
    {
        id: 'abrhil@tax-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@type-contract-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <ContractSAT />
    },
    // {
    //     id: 'abrhil@imss-classification',
    //     type: 'item',
    //     package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@imss-division',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: <Division />
    },
    {
        id: 'abrhil@imss-fraction',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: <Fraction />
    },
    {
        id: 'abrhil@imss-group',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: <Group />
    },
    {
        id: 'abrhil@imss-class',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: <Class />
    },
    {
        id: 'abrhil@payment-percentage-history',
        type: 'item',
        componentElm: <HistoryCyV />
    },
    {
        id: 'abrhil@working-day',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@salary-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    // {
    //     id: 'abrhil@shifts',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@inability-classification',
        type: 'item',
        componentElm: <InabilityClassification />
    },
    {
        id: 'abrhil@insurance-branch',
        type: 'item',
        componentElm: <InsuranceBranch />
    },
    {
        id: 'abrhil@inability-control',
        type: 'item',
        componentElm: <InabilityControl />
    },
    {
        id: 'abrhil@sequel-consequence',
        type: 'item',
        componentElm: <SequelConsequence />
    },
    {
        id: 'abrhil@type-of-risk',
        type: 'item',
        componentElm: <TypeOfRisk />
    },
    {
        id: 'abrhil@inability-covid',
        type: 'item',
        componentElm: <Covid />
    },
    {
        id: 'abrhil@quota-imss',
        type: 'item',
        componentElm: <QuotaIMSS />
    },
    {
        id: 'abrhil@imss-reason-leaving',
        type: 'item',
        componentElm: <ImssReasonLeaving />
    },
    {
        id: 'abrhil@payroll-types',
        type: 'item',
        componentElm: <PayrollTypes />
    },
    {
        id: 'abrhil@child-support',
        type: 'item',
        componentElm: <CatAlimony />
    },
    {
        id: 'abrhil@federal-entities-taxes',
        type: 'item',
        componentElm: <FederalEntitiesTaxes />
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@pay-frequency',
        type: 'item',
        componentElm: <Periodicity />
    },
    // {
    //     id: 'abrhil@type-tax-adjustment',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@schematics',
        type: 'item',
        componentElm: <Schematics />
    },
    {
        id: 'abrhil@payment-types',
        type: 'item',
        componentElm: <PaymentTypes />
    },
    {
        id: 'abrhil@status-payroll',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-discount',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@movement-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-place',
        type: 'item',
        inConstruction: true,
        showInMenu: false,
        componentElm: true
    },
    {
        id: 'abrhil@banks',
        type: 'item',
        componentElm: <Banks />
    },
    {
        id: 'abrhil@banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@layouts',
        type: 'item',
        componentElm: <Layouts />
    },
    {
        id: 'abrhil@dynamic-banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@food-voucher-enterprise',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@dynamic-voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@absenteeism-list',
        type: 'item',
        componentElm: <Absenteeism />
    },
    {
        id: 'abrhil@absenteeism-reasons',
        type: 'item',
        componentElm: <AbsenteeismReasons />
    },
    {
        id: 'abrhil@moper-catalog',
        type: 'item',
        componentElm: <MoperCatalog />
    },
    {
        id: 'abrhil@education-stps',
        type: 'item',
        componentElm: <EducationSTPS />
    },
    {
        id: 'abrhil@device',
        type: 'item',
        icon: 'clock',
        componentElm: <Device />
    },
    {
        id: 'abrhil@concept-sat',
        type: 'item',
        componentElm: <Stampconcept />
    },
    {
        id: 'abrhil@rfc',
        type: 'item',
        componentElm: <RFC />
    },
    {
        id: 'abrhil@voucher-banks',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: <VoucherBanks />
    },
    {
        id: 'abrhil@voucher-layouts',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: <VoucherLayouts />
    }
];

export default abrilItems;