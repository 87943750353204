import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { initialFilter } from "../../../../App/components/GridCustomProps";
import { initialDialog } from "../../../../App/components/dialog/FormDialog";

function useGridController() {
    const [filters, setFilters] = useState(initialFilter);
    const [counter, setCounter] = useState(0);
    const [dialog, setDialog] = useState(initialDialog);

    const onRefresh = () => setCounter(prev => prev + 1);

    const handleEdit = (item) => setDialog({ data: item, isEdit: !!item, open: true });

    const handleOpen = () => setDialog(prev => ({ ...prev, open: true }));

    return {
        counter,
        filters,
        dialog,
        handleOpen,
        setDialog,
        onRefresh,
        setFilters,
        handleEdit
    };
};

const GridContext = createContext({});

/**
 * @typedef {Object} GridContextValue
 * @property {number} counter
 * @property {Object} filters
 * @property {Object} dialog
 * @property {Function} onRefresh
 * @property {Function} setFilters
 * @property {Function} handleEdit
 * @property {Function} setDialog
 * @property {Function} handleOpen
 */

/**
 * @returns {GridContextValue}
 */

export const useGridContext = () => useContext(GridContext);

export default function GridController({
    children,
}) {
    const values = useGridController();

    return (
        <GridContext.Provider value={values}>
            {children}
        </GridContext.Provider>
    );
};

GridController.propTypes = {
    children: PropTypes.any,
};